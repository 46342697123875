import { CurrencyEnum } from '@goparrot/common';
import type { IOrder } from '@goparrot/order-sdk';
import type { ChargeVerifyBuyerDetails, StoreVerifyBuyerDetails } from '@square/web-payments-sdk-types';

import { useSquareContext } from '../contexts/SquareProvider';

export const useSquareVerifyBuyer = () => {
  const { squarePayments } = useSquareContext();
  if (!squarePayments) return { onVerifyBuyer: () => undefined };
  const handleVerifyBuyer = async (token: string, intent: 'CHARGE' | 'STORE' | 'CHARGE_AND_STORE', order: IOrder) => {
    const verificationDetails: ChargeVerifyBuyerDetails | StoreVerifyBuyerDetails = {
      amount: `${order.total}`,
      billingContact: {},
      currencyCode: order.currency ?? CurrencyEnum.USD,
      intent,
    };

    const verificationResults = await squarePayments.verifyBuyer(token, verificationDetails);

    return verificationResults?.token;
  };
  return { onVerifyBuyer: handleVerifyBuyer };
};
