import { DiningOptionEnum } from '@goparrot/common';
import { DelayedOrdersUtils, DiningOptionsUtil, HumanReadableUtils, ReceiveMethodEnum } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { DayName } from '@webstore-monorepo/shared/enums';
import { capitalizeFirstLetter } from '@webstore-monorepo/shared/utils/text';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

export const InfoBar: React.FC = () => {
  const store = useStoreState();
  const merchant = useMerchantState();
  const { header } = useComponentsConfig();
  const isDelivery = DiningOptionsUtil.getEnabledDiningOptions(store).includes(DiningOptionEnum.DELIVERY);
  const isPickUp = DiningOptionsUtil.getEnabledDiningOptions(store).some((option: DiningOptionEnum) =>
    [DiningOptionEnum.CURBSIDE, DiningOptionEnum.TAKE_OUT].includes(option),
  );
  const { height, ...infoBarStyles } = header?.infoBar?.style ?? {};
  const orderAheadAvailabilityForMethods = DelayedOrdersUtils.isOrderAheadAvailable(store);
  const { openingTime, isOpen } = HumanReadableUtils.getHumanReadableDataFromStore(store);
  const isOrderAheadAvailable =
    (isPickUp && orderAheadAvailabilityForMethods[ReceiveMethodEnum.PICKUP]) || (isDelivery && orderAheadAvailabilityForMethods[ReceiveMethodEnum.DELIVERY]);
  const { isOrderingStopped } = merchant.shortTermFeatureFlags || {};

  const topBarMessage = () => {
    if (isOrderingStopped) {
      return 'Unsupported Product. Orders cannot be placed.';
    }

    if (store.order?.isEmergencyStop) {
      return header?.infoBar?.options?.emergencyStop ?? 'Online ordering is closed temporarily and will return shortly. Please refresh the page for updates.';
    }

    const replacerString =
      openingTime === 'never'
        ? 'soon'
        : openingTime
            ?.split(' ')
            ?.map((word: string) => (Object.values(DayName).includes(word.replace(/[^a-zA-Z]+/g, '') as DayName) ? capitalizeFirstLetter(word) : word))
            ?.join(' ');
    if (!replacerString) return 'Store is currently closed.';

    if (!isOrderAheadAvailable) {
      const message = header?.infoBar?.options?.storeIsClosed ?? 'Online orders are not available at this moment. The service will be available %openingTime%.';
      return message.replace('%openingTime%', replacerString);
    }

    if (isOrderAheadAvailable) {
      const message = header?.infoBar?.options?.orderAheadAvailable ?? 'Order Ahead is available. The store opens %openingTime%.';
      return message.replace('%openingTime%', replacerString);
    }
  };
  return !isOpen || isOrderingStopped ? (
    <Box
      testID="info-bar"
      paddingLeft={3}
      paddingRight={3}
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      minHeight={height ?? 50}
      {...infoBarStyles}
    >
      <Text accessibilityLiveRegion="assertive" fontSize="xs" textAlign="center" {...header?.infoBar?.text?.style}>
        {topBarMessage()}
      </Text>
    </Box>
  ) : null;
};
