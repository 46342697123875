import { CartProvider } from '@webstore-monorepo/shared/contexts/cart-provider';
import { MerchantProvider } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { StoreProvider } from '@webstore-monorepo/shared/contexts/store-provider';
import { UserContextProvider, useUserContextState, useUserContextStateDispatch } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { WebStoreProvider } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { AppContextProvider } from '../../contexts/app-context-provider';
import { NotificationProvider } from '../../contexts/notification-provider';
import type { SSRContext } from '../../interfaces/AppState';
import { PrefetchData } from '../PrefetchData';

const CartProviderWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { auth, ...userContext } = useUserContextState();
  const { updateUserContext } = useUserContextStateDispatch();

  const handleUpdateCartInUserContext = (cart?: any) => {
    updateUserContext({ type: 'update', payload: { cart } });
  };

  return (
    <CartProvider cart={userContext.cart} onUpdateCartInUserContext={handleUpdateCartInUserContext}>
      {children}
    </CartProvider>
  );
};

interface Props {
  appContext: SSRContext;
}

export const ContextWrapper: React.FC<PropsWithChildren<Props>> = ({ appContext, children }) => (
  <NotificationProvider>
    <WebStoreProvider initial={appContext.webstore}>
      <UserContextProvider DD_RUM_ENABLED={!!window.DD_RUM_ENABLED} storeId={appContext.webstore.storeId} merchantId={appContext.webstore.merchantId}>
        <AppContextProvider initial={appContext}>
          <MerchantProvider initial={appContext.merchant}>
            <StoreProvider initial={appContext.newStore}>
              <CartProviderWrapper>
                <PrefetchData />
                {children}
              </CartProviderWrapper>
            </StoreProvider>
          </MerchantProvider>
        </AppContextProvider>
      </UserContextProvider>
    </WebStoreProvider>
  </NotificationProvider>
);
