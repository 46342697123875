import { Box } from '@webstore-monorepo/ui/box';
import { Input } from '@webstore-monorepo/ui/input';
import { Text } from '@webstore-monorepo/ui/text';
import isInteger from 'lodash/isInteger';
import isUndefined from 'lodash/isUndefined';
import React, { useCallback, useEffect, useRef } from 'react';
import { Platform, Pressable } from 'react-native';

type NumberInputProps = {
  value: number;
  defaultValue?: number;
  style?: Record<string, unknown>;
  onChange?: any;
  countable?: boolean;
  step?: number;
  min?: number;
  max?: number;
  globalMin?: number;
  globalMax?: number;
  minMaxAchieveCallback?: (extremum: 'min' | 'max', extremumValue?: number) => void;
};

export const NumberInput = (props: NumberInputProps) => {
  const inputRef = useRef<HTMLInputElement>();

  const { step = 1, min = -Infinity, max = Infinity, globalMin, globalMax, onChange, value, countable, minMaxAchieveCallback, defaultValue = value } = props;

  const isMinReached = value <= min;
  const isMaxReached = value >= max;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isArrowUp = e.which === 38;
    const isArrowDown = e.which === 40;

    if ((isArrowDown && isMinReached) || (isArrowUp && isMaxReached)) {
      e.preventDefault();
      minMaxAchieveCallback?.(isArrowUp ? 'max' : 'min', isArrowUp ? globalMax : globalMin);
    } else if (isArrowDown) {
      e.preventDefault();
      handleDecreaseButton();
    } else if (isArrowUp) {
      e.preventDefault();
      handleIncreaseButton();
    }
  };

  const handleBlur = () => {
    if (isUndefined(value) || !value) {
      onChange(isUndefined(defaultValue) ? min : defaultValue);
    }
  };

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      inputRef?.current?.focus();

      if (isInteger(value)) {
        onChange(value);
      } else {
        onChange(0);
      }
    },
    [onChange],
  );

  const handleDecreaseButton = useCallback(() => {
    if (value - step >= min) {
      onChange?.(value - 1);
    } else if (countable && minMaxAchieveCallback) {
      minMaxAchieveCallback('min', globalMin);
    }
  }, [countable, globalMin, min, minMaxAchieveCallback, onChange, step, value]);

  const handleIncreaseButton = useCallback(() => {
    if (value + step <= max) {
      onChange?.(props.value + 1);
    } else if (countable && minMaxAchieveCallback) {
      minMaxAchieveCallback('max', globalMax);
    }
  }, [countable, globalMax, max, minMaxAchieveCallback, onChange, props.value, step, value]);

  useEffect(() => {
    if (isUndefined(value)) {
      if (!isUndefined(defaultValue)) {
        onChange(defaultValue);
      } else if (!isUndefined(min)) {
        onChange(min);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      borderRadius="md"
      borderWidth={1}
      borderColor="gray200"
      backgroundColor="white"
      width={80}
      height={Platform.OS === 'web' ? '100%' : 'auto'}
      flex={1}
      testID="number-input"
      flexDirection="row"
    >
      <Pressable testID="number-item-decrease" onPress={handleDecreaseButton}>
        <Box paddingLeft={2} paddingRight={2} height="100%" justifyContent="center" opacity={isMinReached ? 0.5 : 1}>
          <Text fontSize="m">&#8211;</Text>
        </Box>
      </Pressable>
      <Input
        {...props}
        paddingRight="none"
        paddingLeft="none"
        style={{ fontSize: 16, textAlign: 'center', ...(Platform.OS === 'web' ? { outline: 'none' } : {}) }}
        textAlign="center"
        borderWidth={0}
        value={String(props.value)}
        // @ts-ignore
        onChange={handleChange}
        onKeyPress={handleKeyDown}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        keyboardType="number-pad"
        height={44}
        ref={inputRef}
      />
      <Pressable testID="number-item-increase" onPress={handleIncreaseButton}>
        <Box paddingLeft={2} paddingRight={2} height="100%" justifyContent="center" opacity={isMaxReached ? 0.5 : 1}>
          <Text fontSize="m">+</Text>
        </Box>
      </Pressable>
    </Box>
  );
};
