/**
 * @param min
 * @param max
 * @param selected
 * @param allSelections
 */
export const getBadgeText = (min: number, max: number, selected: number, allSelections?: number): string => {
  const canSelectAll = max === allSelections;
  if ((min === 0 && canSelectAll) || selected >= min || selected === max || allSelections === selected) {
    return `${selected} selected`;
  }
  return `Choose ${min === 0 || max === 1 ? `up to ${max}` : `at least ${min}`}`;
};
