import type { IPromotionWithApplicationState } from '@goparrot/promotions-sdk';
import { PromotionTagEnum } from '@goparrot/promotions-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { getApplicablePromotions } from '@webstore-monorepo/shared/utils/promotions';
import { httpParamSerializerJQLike } from '@webstore-monorepo/shared/utils/url';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { QueryKeysEnum } from './keys';

export interface GetApplicablePromotionsQueryResult {
  promotions: IPromotionWithApplicationState[];
  numberOfApplicablePromos: number;
}
export const useGetApplicablePromotions = (
  options?: UseQueryOptions<GetApplicablePromotionsQueryResult, unknown, GetApplicablePromotionsQueryResult, (string | Date)[]>,
): UseQueryResult<{ promotions: IPromotionWithApplicationState[]; numberOfApplicablePromos: number }> => {
  const cart = useCartState();
  const { merchantId, storeId } = useWebStore();

  const getPromotions = async (params: {
    isApplicable?: boolean;
    isApplied?: boolean;
    tags?: PromotionTagEnum[];
  }): Promise<{ promotions: IPromotionWithApplicationState[]; numberOfApplicablePromos: number }> => {
    const promotions: IPromotionWithApplicationState[] =
      (await axios.get(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/applicable-promotions`, {
        params,
        paramsSerializer: httpParamSerializerJQLike,
      })) ?? [];

    const numberOfApplicablePromos = getApplicablePromotions(promotions).length;

    return { promotions, numberOfApplicablePromos };
  };

  return useQuery([QueryKeysEnum.applicablePromotions, cart?.updated_at], async () => await getPromotions({ tags: [PromotionTagEnum.DEALS_PAGE] }), {
    cacheTime: 0,
    retry: false,
    ...options,
  });
};
