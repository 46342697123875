import { OrderStoreItemElementSubTypeEnum } from '@goparrot/order-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CartIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box, InlineBox } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { Animated, Easing, Image, Pressable } from 'react-native';

export type HeaderCartButtonProps = {
  boxProps?: BoxProps<Theme>;
  onCartClick: () => void;
};

export const HeaderCartButton: FC<HeaderCartButtonProps> = ({ onCartClick, boxProps }) => {
  const cart = useCartState();
  const [itemsQuantity, setItemsQuantity] = useState(0);
  const { isMobile, isPortraitMobile } = useWindowDimensions();
  const { header } = useComponentsConfig();
  const ref = useRef();
  const { isHovered } = useHover({}, ref);
  const [offsetY] = useState(() => new Animated.Value(0));
  const jump = offsetY.interpolate({
    inputRange: [0, 15],
    outputRange: [-20, -5],
  });
  const cartLabel = header?.cart?.options?.label?.toLowerCase();

  const animatedStyles = [
    {
      position: 'absolute',
      top: jump,
      left: 20,
      width: 16,
      height: 16,
      borderRadius: 999,
      overflow: 'hidden',
      alignItems: 'center',
      justifyContent: 'center',
    },
  ];

  useEffect(() => {
    if (cart) {
      const cartElemQty: number = cart.elements.reduce((acc, curr) => {
        if (!curr.parentUniqueName && curr.subtype !== OrderStoreItemElementSubTypeEnum.PACKING_INSTRUCTIONS) {
          acc += curr.quantity;
        }

        return acc;
      }, 0);

      const giftCardQuantity: number =
        cart.giftcards?.reduce((acc, curr) => {
          acc += curr.quantity;
          return acc;
        }, 0) ?? 0;

      setItemsQuantity(cartElemQty + giftCardQuantity);
      offsetY.setValue(0);
      Animated.timing(offsetY, {
        toValue: 15,
        duration: 1000,
        useNativeDriver: false,
        easing: Easing.bounce,
      }).start();
    }
  }, [cart]);

  return (
    <Pressable testID="cart-button" accessibilityLabel="cart" accessibilityHint="press to open cart" accessibilityRole="button" onPress={onCartClick}>
      {/*//@ts-ignore*/}
      <InlineBox ref={ref} position="relative" alignItems="center" flexDirection="row" {...header?.cart?.style} {...boxProps}>
        <InlineBox>
          {header?.cart?.options?.icon ? (
            <Image
              accessibilityLabel="cart"
              accessibilityHint="cart image"
              accessibilityIgnoresInvertColors
              accessibilityRole="none"
              testID="cart-image"
              source={{ uri: header?.cart?.options?.icon }}
              style={{ width: 25, height: 32 }}
            />
          ) : (
            <CartIcon
              testID="cart-icon"
              stroke="transparent"
              fill={
                !isHovered
                  ? header?.text?.style?.color ?? header?.cart?.text?.style?.color
                  : header?.text?.hover?.style?.color ?? header?.cart?.hover?.style?.color
              }
            />
          )}
        </InlineBox>
        {itemsQuantity ? (
          // @ts-ignore
          <Animated.View style={animatedStyles}>
            <Box
              testID="quantity"
              backgroundColor="black"
              {...header?.circleBadge?.style}
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Text fontSize="xs" textAlign="center" color="white" {...header?.circleBadge?.text?.style} lineHeight={16}>
                {itemsQuantity}
              </Text>
            </Box>
          </Animated.View>
        ) : null}
        {header?.cart?.options?.label ? (
          !(isMobile || isPortraitMobile) ? (
            <Text
              fontSize={isMobile ? 's' : 'xxl'}
              {...header?.text?.style}
              {...header?.cart?.text?.style}
              {...(isHovered ? header?.text?.hover?.style : {})}
              {...(isHovered ? header?.cart?.hover?.style : {})}
              ml={1}
            >
              {cartLabel}
            </Text>
          ) : null
        ) : null}
      </InlineBox>
    </Pressable>
  );
};
