import { DiningOptionsUtil } from '@goparrot/store-v2-sdk';
import type { IFontStylesApp, WebMerchantComponentsType } from '@goparrot/webstore-sdk';
import type { BoxProps, TextProps } from '@shopify/restyle';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { DeliveryIcon, NewPickupIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { getInvalidMessageForDefaultDiningOption } from '@webstore-monorepo/shared/utils/dining-options';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React from 'react';

type SelectedDiningOptionProps = {
  iconBoxStyleProps?: BoxProps<Theme>;
  textStyleProps?: WebMerchantComponentsType<IFontStylesApp>;
  showFullAddress?: boolean;
};
export const SelectedDiningOption: FC<SelectedDiningOptionProps> = ({ iconBoxStyleProps, textStyleProps, showFullAddress = false }) => {
  const cart = useCartState();
  const store = useStoreState();
  const { diningOptionInfo } = cart || {};
  const { isMobile } = useWindowDimensions();
  const { actualDiningOption, showError, diningOptionText } = getInvalidMessageForDefaultDiningOption(cart, store);
  const readableOption = actualDiningOption ? DiningOptionsUtil.getHumanReadableName(actualDiningOption, store) : actualDiningOption;
  let fullReadable: string | undefined;
  if (readableOption?.toLowerCase().includes('deliver')) {
    fullReadable = showFullAddress ? diningOptionInfo.details.address?.formattedAddress : diningOptionInfo.details.address?.formattedAddress?.split(',')[0];
  }
  if (readableOption?.toLowerCase().includes('pickup')) {
    fullReadable = showFullAddress ? store.pickup?.address : store.pickup?.address?.split(',')[0];
  }

  const textStyles: TextProps<Theme> & {
    fontSize?: keyof Theme['fontSizes'];
  } = {
    lineHeight: 16,
    fontWeight: '600',
    // @ts-ignore
    fontSize: 's',
    ...(textStyleProps?.style || {}),
  };

  const renderIcon = () => {
    if (readableOption?.toLowerCase().includes('deliver')) {
      return <DeliveryIcon testID="order-setup-status-orderType-delivery-icon" fill={textStyleProps?.color} stroke="transparent" />;
    }
    return <NewPickupIcon testID="order-setup-status-orderType-pickup-icon" fill={textStyleProps?.color} stroke="transparent" />;
  };

  return (
    <>
      {readableOption ? (
        <Box marginRight={1} testID="order-setup-status-orderType-icon" {...iconBoxStyleProps}>
          {renderIcon()}
        </Box>
      ) : null}
      <Text marginRight={1} {...textStyles}>
        {readableOption ? (
          isMobile ? (
            readableOption
          ) : (
            <>
              {readableOption}: {showError && !!diningOptionText ? <Text color="gray300">{diningOptionText}</Text> : fullReadable}
            </>
          )
        ) : (
          'Select dining option'
        )}
      </Text>
    </>
  );
};
