import type { SearchResultsDto } from '@goparrot/giftcards-sdk';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import type { AxiosError, AxiosResponse } from 'axios';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { api } from '../api/api';
import { QueryKeysEnum } from './keys';

export const useGetGiftCards = (options?: UseQueryOptions<AxiosResponse<SearchResultsDto>, AxiosError, SearchResultsDto, QueryKeysEnum[]>) => {
  const store = useStoreState();
  const { user } = useUserContextState();

  const fetchData = () => {
    return api.get(`/api/v2/users/${user?.userId}/giftcards`, {
      params: { provider: 'SQUARE', storeId: store?.storeId ?? null },
    });
  };
  return useQuery([QueryKeysEnum.GET_GIFT_CARD_LIST], fetchData, { enabled: !!user?.userId, ...options });
};
