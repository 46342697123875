import { useItemFormContext, useItemFormDispatch } from '@webstore-monorepo/shared/contexts/item-form-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { ChangeEvent } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

import { getFormattedMessage } from '../../../../shared/utils/intl';
import * as s from '../../ItemModal.styled';

export const ItemModalNotes = () => {
  const selectedItem = useItemFormContext();
  const selectedItemDispatch = useItemFormDispatch();
  const store = useStoreState();
  const webstoreState = useWebStore();
  const itemNotes = webstoreState?.metadata?.customMessages?.itemNotes;
  const intl = useIntl();

  const handleNotesChange = (event: ChangeEvent<HTMLInputElement>) => {
    selectedItemDispatch({ type: 'update', payload: { metadata: { ...selectedItem.metadata, notes: event.target.value } } });
  };

  return store.featuresFlags?.isNotesEnabled ? (
    <s.Notes data-testid="special-requests">
      <s.OptionTitle data-testid="special-requests-title">{itemNotes?.title || getFormattedMessage('item-modal.notes.title')}</s.OptionTitle>
      <s.OptionSubtitle data-testid="special-requests-subtitle">{itemNotes?.disclaimer || getFormattedMessage('item-modal.notes.disclaimer')}</s.OptionSubtitle>
      <input
        name="notes"
        onChange={handleNotesChange}
        value={selectedItem.metadata?.notes ?? ''}
        max="500"
        placeholder={itemNotes?.placeholder || intl.formatMessage({ id: 'item-modal.notes.placeholder' })}
      />
    </s.Notes>
  ) : null;
};
