import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useParentWidth } from '@webstore-monorepo/shared/hooks/use-parent-width';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../../MenuItemCardProvider';
import { MenuItemContent } from '../../menu-item-content';
import { MenuItemPriceGroup } from '../../menu-item-price-group';
import { MenuItemCalories } from '../../menu-item-price-group/menu-item-calories';
import { MenuItemPrice } from '../../menu-item-price-group/menu-item-price';
import { MenuItemSchedule } from '../../menu-item-schedule';
import { MenuItemThumbnail } from '../../menu-item-thumbnail';
import { MenuItemTitle } from '../../menu-item-title';

export const CardDesktopThumbnailRight = forwardRef((props: BoxProps<Theme>, forwardedRef) => {
  const { isHovered } = useMenuItemCardState();
  const { menuItemCard } = useComponentsConfig();
  const { parentWidth, onParentChange } = useParentWidth({ defaultWidth: 180 });

  return (
    <Box
      ref={forwardedRef}
      {...props}
      justifyContent="space-between"
      p={4}
      {...menuItemCard?.wrapper?.style}
      {...(isHovered ? menuItemCard?.wrapperHover?.style : {})}
    >
      <Box height="100%" flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <Box justifyContent="space-between" height="100%" flexGrow={1} flexShrink={1} paddingRight={4}>
          <Box flexGrow={1} flexShrink={0} flexBasis="auto">
            <MenuItemSchedule marginBottom={2} />
            <MenuItemTitle marginBottom={2} />
            <MenuItemContent marginBottom={4} />
          </Box>
          <MenuItemPriceGroup>
            <MenuItemPrice textProps={{ textAlign: 'left' }} />
            <MenuItemCalories marginLeft={2} textProps={{ textAlign: 'right' }} />
          </MenuItemPriceGroup>
        </Box>
        <Box ref={onParentChange} flexBasis={menuItemCard?.thumbnailBlockWrapper?.style?.flexBasis || '50%'}>
          <MenuItemThumbnail parentWidth={parentWidth} />
        </Box>
      </Box>
    </Box>
  );
});

CardDesktopThumbnailRight.displayName = 'CardDesktopThumbnailRight';
