import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { useTheme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC, PropsWithChildren } from 'react';
import React, { useState } from 'react';
import { Modal, Pressable } from 'react-native';

export type AddNewCreditCardType = {
  isVisible: boolean;
  onClose: () => void;
  onSaveCard: () => Promise<void>;
};

export const AddNewCreditCard: FC<PropsWithChildren<AddNewCreditCardType>> = ({ children, isVisible, onClose, onSaveCard }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { height, isMobile } = useWindowDimensions();

  const theme = useTheme<Theme>();
  const parentVerticalPadding = 6;
  const buttonsMarginBottom = 6;
  const parentVerticalPaddingValue = theme.spacing[parentVerticalPadding];
  const buttonsMarginBottomValue = theme.spacing[buttonsMarginBottom];
  const mainContentHeight = isMobile ? height - 48 - parentVerticalPaddingValue * 2 - buttonsMarginBottomValue : 'auto';

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await onSaveCard();
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
    }
  };

  return (
    <Modal animationType="fade" transparent onRequestClose={onClose} visible={isVisible}>
      <Box flexDirection="row" justifyContent="center" minHeight="100%">
        <Pressable onPress={onClose} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Box backgroundColor="modalBackdrop" position="absolute" top={0} bottom={0} left={0} right={0} />
        </Pressable>
        <Box
          backgroundColor="white"
          flexGrow={1}
          minHeight={500}
          maxWidth={isMobile ? '100%' : 600}
          marginTop="none"
          marginBottom="none"
          borderRadius={isMobile ? 'none' : 'lg'}
          paddingTop={parentVerticalPadding}
          paddingBottom={parentVerticalPadding}
          paddingLeft={4}
          paddingRight={4}
          style={{
            marginTop: isMobile ? 0 : 'auto',
            marginBottom: isMobile ? 0 : 'auto',
          }}
        >
          <Box px={4}>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <Button testID="btn-close-modal" size="xxs" backgroundColor="gray100" onPress={onClose}>
                <CloseIcon stroke="black" fill="black" width={14} height={14} strokeWidth={1.5} />
              </Button>
              <Button isDisabled={isLoading} isLoading={isLoading} onPress={handleSave} buttonStyle={{ minWidth: 70 }} testID="add-cc-save-btn">
                Save
              </Button>
            </Box>
          </Box>
          <Box height={mainContentHeight} overflow="scroll" px={4}>
            <Box py={6}>
              <Text fontSize="xxl" lineHeight={32} fontWeight="700">
                Add new credit card
              </Text>
            </Box>
            {children}
            <Separator />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
