import type { CountryISOCodeEnum } from '@goparrot/common';
import { CurrencyEnum, PaymentMethodTypeEnum } from '@goparrot/common';
import type { BoxProps } from '@shopify/restyle';
import type { ApplePay } from '@square/web-payments-sdk-types';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { BlockLoader } from '@webstore-monorepo/ui/loading';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import { useOrderDispatch } from '../../../../contexts/order-provider';
import { useSquareContext } from '../../../../contexts/SquareProvider';
import { useReactNativeEvents } from '../../../../hooks/useReactNativeEvents';
import { useValidateGuestForm } from '../../../../hooks/useValidateGuestForm';
import { buildPaymentRequest, tokenize } from '../../../../utils/helpers';
import { postMessageRequestApplePayCardNonce } from '../../../../utils/rn-post-message';
import { APPLE_PAY_BUTTON_ID } from './constants';

export type ApplePayButtonProps = {
  onPayClick: () => Promise<void>;
  isPaymentLoading?: boolean;
  boxProps?: BoxProps<Theme>;
  onStopLoading: () => void;
};
export const ApplePayButton: FC<ApplePayButtonProps> = ({ onPayClick, onStopLoading, isPaymentLoading, boxProps }) => {
  const { chargeOrder } = useOrderDispatch();
  const { validateGuestForm } = useValidateGuestForm();
  const { currency, total } = useCartState();
  const { squarePayments } = useSquareContext();
  const { analytics, notification } = usePlatformStoreState();
  const store = useStoreState();
  const cart = useCartState();
  const [applePay, setApplePay] = useState<ApplePay>();
  const isReactNativeWebView: boolean = !!window.ReactNativeWebView;
  const lastPriceRef = React.useRef<number>(0);
  const initializedRef = React.useRef<boolean>(false);
  const hidePayButton = (initializedRef.current && !applePay) || isPaymentLoading;

  const initializeApplePay = useCallback(async () => {
    try {
      if (squarePayments && !applePay) {
        analytics.track('apple_pay_initialize');
        const paymentRequest = buildPaymentRequest(squarePayments, { currency, total }, store);
        const applePay: ApplePay = await squarePayments.applePay(paymentRequest);
        setApplePay(applePay);
        analytics.track('apple_pay_initialize_success');
        initializedRef.current = true;
      }
    } catch (error) {
      analytics.track('apple_pay_initialize_fail', { error });
      notification.error('Initializing Apple Pay failed');
    }
  }, [squarePayments, applePay, total, currency]);

  useEffect(() => {
    if (!isReactNativeWebView) {
      initializeApplePay();
    }

    return () => {
      applePay?.destroy();
    };
  }, [applePay, initializeApplePay, isReactNativeWebView]);

  useEffect(() => {
    if (applePay && !initializedRef.current && lastPriceRef.current !== total) {
      lastPriceRef.current = total;
      applePay?.destroy();
      setApplePay(undefined);
    }
  }, [applePay, total]);

  const handlePaymentMethodSubmission = async () => {
    try {
      if (validateGuestForm() && applePay) {
        analytics.track('apple_pay_token');
        const token = await tokenize(applePay);
        await onPayClick();
        await chargeOrder({ type: PaymentMethodTypeEnum.APPLE_PAY, cardNonce: token });
        notification.success('Payment Success');
        analytics.track('apple_pay_token_success');
      }
    } catch (error: any) {
      if (error) {
        analytics.track('apple_pay_token_fail');
        notification.error(error as string);
        console.error(error);
      }
      onStopLoading();
    }
  };

  const handleReactNativeSubmit = () => {
    postMessageRequestApplePayCardNonce({
      orderTotal: cart.total ?? 0,
      currencyCode: (cart.currency as unknown as CurrencyEnum) ?? CurrencyEnum.USD,
      countryCode: store?.location.countryCode as CountryISOCodeEnum,
    });
  };

  const handleReactNativeCardNonceSuccess = useCallback(
    async (cardNonce?: string) => {
      if (validateGuestForm()) {
        await onPayClick();
        await chargeOrder({ type: PaymentMethodTypeEnum.APPLE_PAY, cardNonce });
        notification.success('Payment Success');
        analytics.track('rn_apple_pay_token_success');
      }
    },
    [validateGuestForm, onPayClick, chargeOrder],
  );

  useReactNativeEvents({ onPayCardNonceSuccess: handleReactNativeCardNonceSuccess });

  return (
    <Box flexGrow={1} flexShrink={1} flexBasis="30%" {...boxProps}>
      <Pressable
        onPress={isReactNativeWebView ? handleReactNativeSubmit : handlePaymentMethodSubmission}
        testID={APPLE_PAY_BUTTON_ID}
        nativeID={APPLE_PAY_BUTTON_ID}
        style={{
          alignItems: 'center',
          display: !hidePayButton ? 'flex' : 'none',
          flexGrow: !hidePayButton ? 1 : 0,
          flexShrink: 1,
          justifyContent: 'center',
          backgroundColor: 'white',
          height: !hidePayButton ? 48 : 0,
          // @ts-ignore
          WebkitAppearance: '-apple-pay-button',
          ApplePayButtonType: 'plain',
          ApplePayButtonStyle: 'black',
        }}
      />
      {hidePayButton ? <BlockLoader flexGrow={1} flexShrink={1} height={48} borderRadius="xs" /> : null}
    </Box>
  );
};
