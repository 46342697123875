import { useItemFormContext } from '@webstore-monorepo/shared/contexts/item-form-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import DOMPurify from 'dompurify';
import React from 'react';

import { useWindowWidth } from '../../../../shared/utils/hooks/useWindowWidth';
import { getIntlString } from '../../../../shared/utils/intl';
import { LazyImage } from '../../../uielements/images/LazyImage';
import * as s from './ItemModalDescription.styled';

interface Props {
  showPreview: boolean;
  showSubtitle: boolean;
  showIngredients: boolean;
}
export const Description: React.FC<Props> = ({ showPreview, showIngredients, showSubtitle }) => {
  const selectedItem = useItemFormContext();
  const { metadata } = useWebStore();
  const isMobile = useWindowWidth();
  // @ts-ignore
  const { hover_image_url, image_url, image_url_detailed, image_url_detailed_mobile } = selectedItem;
  const img = isMobile ? image_url_detailed_mobile || image_url_detailed || image_url || hover_image_url : image_url_detailed || image_url || hover_image_url;

  return (
    <>
      {showPreview && img && (
        <s.Preview>
          <LazyImage width={620} className="img-width-100" imageSrc={img} alt={selectedItem.title} />
        </s.Preview>
      )}
      <s.Title>
        {showSubtitle && selectedItem.subtitle && <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedItem.subtitle) }}></p>}
        {selectedItem.metadata?.additional_info && <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedItem.metadata?.additional_info) }}></p>}
        {showIngredients && selectedItem.metadata.ingredients_description && (
          <s.Ingredients tabIndex={0}>
            <strong>{metadata.customMessages?.itemModal?.ingredientsLabel ?? getIntlString('item-modal.ingredients')}</strong>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedItem.metadata.ingredients_description) }}></span>
          </s.Ingredients>
        )}
      </s.Title>
    </>
  );
};
