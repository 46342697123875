import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import { Video } from '@webstore-monorepo/ui/video';
import type { FC } from 'react';
import React from 'react';
import { Image } from 'react-native';

import { CustomDescription } from './components';
import { Description } from './components/description/Description.web';
import { HeroBlockText } from './components/hero-block-text';
import { getDescriptionInfo } from './utils';

function getBackgroundVideo(backgroundImage = ''): string {
  return window.innerWidth > 1 ? backgroundImage : '';
}

function bgIsVideo(backgroundImage = '') {
  const videoFormat = backgroundImage?.split('.')?.pop()?.toLowerCase() ?? '';
  return ['mp4', 'ogg', 'webm'].includes(videoFormat);
}

export const HeroBlock: FC = () => {
  const store = useStoreState();
  const { heroBlock } = useComponentsConfig();
  const {
    title,
    subtitle,
    description,
    backgroundImage,
    customDescription,
    heroBlockOptions = {},
    showMobileHeroBlock,
    showMobileAddressBlock,
    backgroundImageMobile,
  } = heroBlock?.wrapper?.options ?? {};
  const { isFluidBackgroundImage } = heroBlockOptions;
  const isBgIsVideo = bgIsVideo(backgroundImage);
  const { isMobile } = useWindowDimensions();
  const descriptionInfo = getDescriptionInfo(store, description ?? '', heroBlockOptions);
  const mobileSrc = backgroundImageMobile ?? backgroundImage ?? '';

  if (isMobile) {
    const isBgIsVideo = bgIsVideo(mobileSrc);
    const mobileHeight =
      !heroBlock?.mobile?.style?.height ||
      heroBlock?.mobile?.style?.height === 'auto' ||
      //@ts-expect-error allow string value for height
      heroBlock?.mobile?.style?.height === 'inherit' ||
      heroBlock?.mobile?.style?.height === '100%'
        ? 140
        : heroBlock?.mobile?.style?.height;
    return showMobileHeroBlock && mobileSrc ? (
      <Box position="relative" testID="hero-block-mobile" {...heroBlock?.mobile?.style} height={isBgIsVideo ? 'auto' : mobileHeight}>
        {isBgIsVideo ? (
          <Video autoPlay muted loop playsInline src={mobileSrc}>
            Your browser Your browser doesn`&apos;`t support HTML5 video tag.
          </Video>
        ) : mobileSrc ? (
          <Image
            accessibilityLabel={title}
            accessibilityHint="Hero block image"
            accessibilityIgnoresInvertColors
            source={{ uri: mobileSrc }}
            resizeMode="cover"
            style={{
              width: '100%',
              height: '100%',
              maxHeight: mobileHeight,
            }}
          />
        ) : null}
        {showMobileAddressBlock ? (
          <Box
            width="100%"
            backgroundColor={heroBlock?.mobile?.style?.backgroundColor}
            paddingTop={5}
            paddingBottom={5}
            paddingRight={4}
            paddingLeft={4}
            testID="mobile-description-block"
            {...(showMobileHeroBlock
              ? {
                  position: 'absolute',
                  bottom: 0,
                }
              : {
                  position: 'relative',
                })}
          >
            <Text fontSize="l" fontWeight="bold" marginBottom={2} lineHeight={23.4}>
              {store.title}
            </Text>
            <Text fontSize="m" lineHeight={19.5}>
              {store.location.formattedAddress}
            </Text>
          </Box>
        ) : null}
      </Box>
    ) : null;
  }

  return heroBlock && backgroundImage ? (
    <Box>
      <Box
        {...(isFluidBackgroundImage ? { paddingBottom: heroBlock?.wrapper?.style?.paddingBottom } : {})}
        position="relative"
        width="100%"
        testID="hero-block-wrapper"
        flexDirection="row"
        overflow="hidden"
        justifyContent={heroBlock?.wrapper?.style?.justifyContent}
        {...heroBlock?.wrapper?.style}
        opacity={1}
        height={isBgIsVideo ? 'auto' : heroBlock?.wrapper?.style?.height}
      >
        {isBgIsVideo ? (
          <Video autoPlay muted loop playsInline src={getBackgroundVideo(backgroundImage)}>
            Your browser Your browser doesn`&apos;`t support HTML5 video tag.
          </Video>
        ) : (
          <>
            <Box
              position="absolute"
              left={0}
              top={0}
              right={0}
              bottom={0}
              backgroundColor="black"
              zIndex={2}
              opacity={heroBlock?.wrapper?.style?.opacity ?? 0.3}
            />
            <Box position="absolute" left={0} right={0} top={0} bottom={0} zIndex={1}>
              <Image
                accessibilityLabel={title}
                accessibilityHint="Hero block image"
                accessibilityIgnoresInvertColors
                accessibilityRole="none"
                source={{ uri: backgroundImage }}
                resizeMode="cover"
                //@ts-expect-error allow string value for paddingBottom
                style={{
                  width: '100%',
                  height: '100%',
                  paddingBottom: heroBlock?.wrapper?.options?.heroBlockOptions?.isFluidBackgroundImage
                    ? heroBlock?.wrapper?.backgroundWrapper?.style?.paddingBottom
                    : 0,
                }}
              />
            </Box>
            <Box zIndex={3} width="100%" flexDirection="row" height="100%" paddingLeft={7} paddingRight={7}>
              {title ? (
                <Box
                  height="100%"
                  paddingTop={6}
                  paddingBottom={6}
                  position="relative"
                  zIndex={5}
                  justifyContent={heroBlock?.descriptionBlock?.style?.justifyContent ?? 'space-between'}
                  testID="description-block"
                  {...heroBlock?.descriptionBlock?.style}
                >
                  {title.trim() || subtitle?.trim() ? (
                    <Box>
                      {title ? (
                        <HeroBlockText marginBottom={2} fontSize="5xl" fontWeight="bold" {...heroBlock?.descriptionBlock?.title?.style}>
                          {title}
                        </HeroBlockText>
                      ) : null}
                      {subtitle ? (
                        <HeroBlockText marginBottom={2} fontWeight="normal" fontSize="xl">
                          {subtitle}
                        </HeroBlockText>
                      ) : null}
                    </Box>
                  ) : null}
                  {customDescription?.length ? (
                    <Box>
                      <Box>
                        <CustomDescription />
                      </Box>
                    </Box>
                  ) : (
                    <Description descriptionInfo={descriptionInfo} />
                  )}
                </Box>
              ) : null}
            </Box>
          </>
        )}
      </Box>
    </Box>
  ) : null;
};
