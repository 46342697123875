import React from 'react';

import type { IconProps } from './icons.interface';

export const CloseIcon: React.FC<IconProps> = ({ className, width, height }) => {
  return (
    <div data-testid="close" className={className}>
      <svg viewBox="0 0 16 16" version="1.1" width={width} height={height}>
        <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
          <path
            d="M8.96404,7.49977 L14.6988,1.7671 C15.1005,1.36324 15.1005,0.706689 14.6988,0.302896 C14.2949,-0.100965 13.6363,-0.100965 13.2346,0.302896 L7.49984,6.03557 L1.7671,0.302896 C1.36324,-0.100965 0.706689,-0.100965 0.302896,0.302896 C-0.100897,0.706757 -0.100965,1.3633 0.302896,1.7671 L6.03557,7.49977 L0.302896,13.2345 C-0.100965,13.6362 -0.100965,14.2949 0.302896,14.6987 C0.706757,15.1004 1.3633,15.1004 1.7671,14.6987 L7.49977,8.96397 L13.2345,14.6987 C13.6362,15.1004 14.2949,15.1004 14.6987,14.6987 C15.1004,14.2948 15.1004,13.6362 14.6987,13.2345 L8.96404,7.49977 Z"
            id="Path"
          ></path>
        </g>
      </svg>
    </div>
  );
};
