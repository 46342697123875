import type { IFontStylesApp } from '@goparrot/webstore-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React from 'react';
import type { AccessibilityProps } from 'react-native';

export const HeroBlockText: FC<AccessibilityProps & IFontStylesApp & TextPropsExtended> = ({ children, color, ...overwriteStyles }) => {
  const { heroBlock } = useComponentsConfig();
  const { color: textColor, ...baseStyles } = heroBlock?.wrapper?.text?.style ?? {};
  return (
    <Text color={color ? color : textColor ?? 'white'} {...baseStyles} {...overwriteStyles}>
      {children}
    </Text>
  );
};
