import { CloseIcon } from '@webstore-monorepo/shared/icons';
import type { ButtonProps } from '@webstore-monorepo/ui/button';
import { Button } from '@webstore-monorepo/ui/button';
import React from 'react';

export type UiCloseBtnProps = ButtonProps & {
  showIcon?: boolean;
  iconColor?: string;
};

export const UiCloseBtn = ({ showIcon, iconColor, ...rest }: UiCloseBtnProps) => {
  return (
    <Button
      testID="ui-close-btn"
      color="gray400"
      fontSize="m"
      size="custom"
      borderRadius="xs"
      leftIcon={showIcon === false ? undefined : <CloseIcon stroke={iconColor ?? 'gray400'} height={14} width={14} strokeWidth={1.5} />}
      {...rest}
      buttonStyle={{
        width: 44,
        height: 44,
        justifyContent: 'center',
        backgroundColor: 'gray50',
        ...rest.buttonStyle,
      }}
    />
  );
};
