import { UserTypeEnum } from '@goparrot/customer-sdk';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { useGetFeatureFlag } from '@webstore-monorepo/shared/hooks/use-get-feature-flag';
import { useCallback } from 'react';

import { useOrder } from '../contexts/order-provider';

export const useValidateGuestForm = () => {
  const { guestFormRef, guestFormBlockRef } = useOrder();
  const { user } = useUserContextState();
  const isGuestCheckoutEnabled = useGetFeatureFlag('isGuestCheckoutEnabled');
  const { notification } = usePlatformStoreState();

  const validateGuestForm = useCallback(() => {
    if (isGuestCheckoutEnabled && user.type !== UserTypeEnum.AUTHENTICATED && guestFormRef?.current) {
      guestFormRef?.current?.setFieldTouched('email', true);
      guestFormRef?.current?.setFieldTouched('firstName', true);
      guestFormRef?.current?.setFieldTouched('lastName', true);
      guestFormRef?.current?.setFieldTouched('phoneNumber', true);
      guestFormRef?.current?.validateForm();
    }
    if (guestFormRef?.current && (!Object.values(guestFormRef?.current?.values ?? {}).some(Boolean) || !guestFormRef?.current?.isValid)) {
      notification.error('Please fill the Contact Information');
      guestFormBlockRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
    return true;
  }, [guestFormRef, isGuestCheckoutEnabled, notification, user.type]);

  return { validateGuestForm };
};
