import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { useGetImageDimensions } from '@webstore-monorepo/shared/utils/image';
import { sanitizeUrl } from '@webstore-monorepo/shared/utils/url';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Image, Pressable } from 'react-native';

export type HeaderLogoProps = {
  onPress: (url: string, openInNewTab?: boolean) => void;
};
export const HeaderLogo: React.FC<HeaderLogoProps> = ({ onPress }) => {
  const webstore = useWebStore();
  const { isMobile, isDesktop } = useWindowDimensions();
  const { header, headerLogoBlock } = useComponentsConfig();
  const { logoPath, url, openInNewTab } = (isMobile ? headerLogoBlock?.wrapperMobile?.options : headerLogoBlock?.wrapper?.options) ?? {};
  const storeLogo = logoPath ? logoPath.replace('http://', 'https://') : '';
  const { width, height } = useGetImageDimensions(storeLogo);
  const storeName = webstore.storeName?.toLowerCase();

  const handlePress = () => {
    if (url) {
      onPress(sanitizeUrl(url), openInNewTab);
    }
  };

  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      maxHeight={
        (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.maxHeight : headerLogoBlock?.wrapper?.image?.style?.maxHeight) ??
        (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.height : headerLogoBlock?.wrapper?.image?.style?.height) ??
        25
      }
    >
      <Pressable
        testID="header-logo-wrap"
        accessibilityLabel="Store logo"
        accessibilityHint="press the store logo"
        accessibilityRole="link"
        onPress={handlePress}
      >
        {storeLogo ? (
          <Image
            testID="header-logo"
            source={{ uri: storeLogo }}
            accessibilityLabel="Store Logo"
            accessibilityHint="Sore Logo"
            accessibilityIgnoresInvertColors
            accessibilityRole="none"
            resizeMode="contain"
            style={{
              width: (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.width : headerLogoBlock?.wrapper?.image?.style?.width) || width,
              height: (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.height : headerLogoBlock?.wrapper?.image?.style?.height) || height,
              maxHeight:
                (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.maxHeight : headerLogoBlock?.wrapper?.image?.style?.maxHeight) ||
                (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.height : headerLogoBlock?.wrapper?.image?.style?.height) ||
                25,
              maxWidth:
                (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.maxWidth : headerLogoBlock?.wrapper?.image?.style?.maxWidth) ||
                (isMobile ? headerLogoBlock?.wrapperMobile?.image?.style?.width : headerLogoBlock?.wrapper?.image?.style?.width) ||
                (!isDesktop ? 100 : 270),
            }}
          />
        ) : (
          <Text
            fontSize={isMobile ? 's' : 'm'}
            {...header?.text?.style}
            {...(isMobile ? headerLogoBlock?.wrapperMobile?.text?.style : headerLogoBlock?.wrapper?.text?.style)}
          >
            {storeName}
          </Text>
        )}
      </Pressable>
    </Box>
  );
};
