import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { ButtonProps } from '@webstore-monorepo/ui/button';
import { Button } from '@webstore-monorepo/ui/button';
import React from 'react';

import { usePromoPopupContext } from '../PromoPopupProvider';

export const NoButton = (props: Partial<ButtonProps>) => {
  const { isMobile } = useWindowDimensions();
  const { promoPopup } = useComponentsConfig();
  const { onCancel } = usePromoPopupContext();

  const buttonOptions = isMobile ? promoPopup?.wrapperMobile?.negativeButton?.options : promoPopup?.wrapper?.negativeButton?.options;

  return (
    <Button
      onPress={onCancel}
      size={buttonOptions?.size ?? 'sm'}
      {...props}
      variant={buttonOptions?.variant ?? 'link'}
      colorScheme={buttonOptions?.colorScheme ?? 'primary'}
    >
      {buttonOptions?.text ?? 'No, thanks'}
    </Button>
  );
};
