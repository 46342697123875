import styled from 'styled-components';

import { applyCustomStyles, media } from '../../../../shared/utils/media-queries';

export const Container = styled.div`
  .col-main {
    width: 1400px;
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;

    ${media.tablet} {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  #main {
    ${media.mobileOnly} {
      min-height: 100vh;
    }
  }

  .previous-orders {
    display: none;

    ${media.tablet} {
      display: block;
    }
  }
`;

export const EmptyMenuText = styled.div<{ customStyles?: any }>`
  padding: 2em 1em;
  font-size: 24px;
  font-weight: 700;
  text-align: center;

  ${applyCustomStyles()}
`;
