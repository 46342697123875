import type { OrderValidity } from '@webstore-monorepo/shared/api/cart-api';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

import { api } from './api';

export const useValidateCart = (options?: UseMutationOptions<OrderValidity, Error, string>): UseMutationResult<OrderValidity, Error, string> => {
  return useMutation(async (orderId: string) => {
    return api.get(`/api/order/isCartValid/${orderId}`);
  }, options);
};
