import type { IOrder } from '@goparrot/order-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ClockBack } from '@webstore-monorepo/shared/icons';
import { getFormattedNumber } from '@webstore-monorepo/shared/utils/format-strings';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import { DateTime } from 'luxon';
import React, { forwardRef } from 'react';

export type OrderReminderBannerProps = {
  isMinimized?: boolean;
  isArrivalConfirmed?: boolean;
  activeOrder?: IOrder;
  onMaximize: () => void;
  onMinimize: () => void;
  onShowDetails: () => void;
};
export const OrderReminderBanner = forwardRef(
  ({ isMinimized, isArrivalConfirmed, activeOrder, onMinimize, onShowDetails, onMaximize }: OrderReminderBannerProps, forwardedRef) => {
    const { isMobile } = useWindowDimensions();
    const { orderReminderBanner } = useComponentsConfig();
    const store = useStoreState();

    const dateInfo = DateTime.fromISO(activeOrder?.dateInfo?.fulfilledAt as string, {
      zone: store.contactInfo.timeZone.id ?? 'America/New_York',
      locale: 'en-US',
    });
    const orderReceiveHour = dateInfo?.toLocaleString(DateTime.TIME_SIMPLE);
    const orderDetailsText = `Curbside Pickup, Today at ${orderReceiveHour}\n${store.pickup?.address}\nOrder ID: #${activeOrder?.orderNumber}`;

    if (
      !(isMobile ? orderReminderBanner?.wrapperMobile?.options?.showOrderReminder : orderReminderBanner?.wrapper?.options?.showOrderReminder) &&
      !activeOrder
    ) {
      return null;
    }

    const Title = () => (
      <Box
        flexDirection="row"
        alignItems="center"
        marginRight={8}
        {...orderReminderBanner?.wrapper?.title?.style}
        {...(isMobile ? orderReminderBanner?.wrapperMobile?.title?.style : {})}
      >
        <Text
          marginRight={1}
          fontSize="xl"
          fontWeight="700"
          lineHeight={22}
          color="primaryColor"
          testID="order-in-progress-title"
          {...orderReminderBanner?.wrapper?.title?.text?.style}
          {...(isMobile ? orderReminderBanner?.wrapperMobile?.title?.text?.style : {})}
        >
          Order in Progress
        </Text>
        <ClockBack
          stroke={
            (isMobile ? orderReminderBanner?.wrapperMobile?.title?.text?.style?.color : orderReminderBanner?.wrapper?.title?.text?.style?.color) ??
            'primaryColor'
          }
        />
      </Box>
    );

    const MinimizedView = () => (
      <Box flexDirection="row" alignItems="center" justifyContent="space-between" testID="order-reminder-minimized">
        <Title />
        <Box>
          <Button size="sm" buttonStyle={{ height: 28, minWidth: 100 }} onPress={onMaximize} testID="order-reminder-details-btn">
            Details
          </Button>
        </Box>
      </Box>
    );

    return (isMobile ? orderReminderBanner?.wrapperMobile?.options?.showOrderReminder : orderReminderBanner?.wrapper?.options?.showOrderReminder) ? (
      <Box ref={forwardedRef} testID="im-here-popup">
        {isMinimized ? (
          <MinimizedView />
        ) : (
          <Box
            borderRadius="lg"
            shadowColor="black"
            shadowRadius={10}
            shadowOffset={{
              width: 0,
              height: 2,
            }}
            shadowOpacity={0.15}
            {...orderReminderBanner?.wrapper?.style}
            {...(isMobile ? orderReminderBanner?.wrapperMobile?.style : {})}
          >
            <Box paddingTop={4} paddingRight={4} paddingBottom={4} paddingLeft={4}>
              <Box flexDirection="row" marginBottom={2} alignItems="center" justifyContent="space-between">
                <Title />
                {activeOrder?.total ? (
                  <Box {...orderReminderBanner?.wrapper?.total?.style} {...(isMobile ? orderReminderBanner?.wrapperMobile?.total?.style : {})}>
                    <Text
                      fontSize="xl"
                      fontWeight="700"
                      color="primaryColor"
                      {...orderReminderBanner?.wrapper?.total?.text?.style}
                      {...(isMobile ? orderReminderBanner?.wrapperMobile?.total?.text?.style : {})}
                      testID="order-in-progress-total"
                    >
                      {getFormattedNumber(activeOrder?.total)}
                    </Text>
                  </Box>
                ) : null}
              </Box>
              <Box {...orderReminderBanner?.wrapper?.content?.style} {...(isMobile ? orderReminderBanner?.wrapperMobile?.content?.style : {})}>
                <Text
                  fontSize="m"
                  lineHeight={16}
                  testID="order-in-progress-detail"
                  {...orderReminderBanner?.wrapper?.content?.text?.style}
                  {...(isMobile ? orderReminderBanner?.wrapperMobile?.content?.text?.style : {})}
                >
                  {orderDetailsText}
                </Text>
              </Box>
            </Box>
            <Box>
              <Box
                backgroundColor="gray100"
                paddingTop={3}
                paddingRight={4}
                paddingBottom={4}
                paddingLeft={4}
                {...orderReminderBanner?.wrapper?.note?.style}
                {...(isMobile ? orderReminderBanner?.wrapperMobile?.note?.style : {})}
              >
                <Text
                  marginBottom={3}
                  fontSize="m"
                  lineHeight={16}
                  color="gray500"
                  {...orderReminderBanner?.wrapper?.note?.text?.style}
                  {...(isMobile ? orderReminderBanner?.wrapperMobile?.note?.text?.style : {})}
                >
                  {isMobile ? orderReminderBanner?.wrapperMobile?.options?.note : orderReminderBanner?.wrapper?.options?.note}
                </Text>
                <Box flexDirection="row" alignItems="center" justifyContent="space-between">
                  <Box flexGrow={1} marginRight={!isArrivalConfirmed ? 3 : 'none'}>
                    <Button size="full-width" variant="stroked" onPress={onMinimize} testID="hide-order-in-progress-btn">
                      Hide
                    </Button>
                  </Box>
                  {!isArrivalConfirmed ? (
                    <Box flexGrow={1}>
                      <Button size="full-width" onPress={onShowDetails} testID="arrived-btn">
                        I have arrived!
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    ) : null;
  },
);
