import type { PropsWithChildren } from 'react';
import React from 'react';
import styled from 'styled-components';

interface Props {
  backgroundColor?: string;
  color?: string;
  hasError?: boolean;
}

const BadgeStyled = styled.span<{ hasError?: boolean; color?: string; backgroundColor?: string }>`
  ${({ hasError, backgroundColor = '#1F1F1F', color = '#fff' }) => `
    background-color: ${hasError ? 'red' : backgroundColor};
    color: ${hasError ? '#fff' : color};
  `};
  border-radius: 100px;
  margin: 0 5px;
  padding: 1px 8px;
  font-size: 12px;
  white-space: nowrap;
`;

export const Badge: React.FC<PropsWithChildren<Props>> = ({ hasError, backgroundColor, color, children }) => {
  return (
    <BadgeStyled hasError={hasError} backgroundColor={backgroundColor} color={color}>
      {children}
    </BadgeStyled>
  );
};
