import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useParentWidth } from '@webstore-monorepo/shared/hooks/use-parent-width';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../../MenuItemCardProvider';
import { MenuItemContent } from '../../menu-item-content';
import { MenuItemPriceGroup } from '../../menu-item-price-group';
import { MenuItemCalories } from '../../menu-item-price-group/menu-item-calories';
import { MenuItemPrice } from '../../menu-item-price-group/menu-item-price';
import { MenuItemSchedule } from '../../menu-item-schedule';
import { MenuItemThumbnail } from '../../menu-item-thumbnail';
import { MenuItemTitle } from '../../menu-item-title';

export const CardDesktopThumbnailTop = forwardRef((props: BoxProps<Theme>, forwardedRef) => {
  const { isHovered, price, orderDate, calories, columnsNumberDesktop } = useMenuItemCardState();
  const { menuItemCard } = useComponentsConfig();
  const { parentWidth, onParentChange } = useParentWidth({ defaultWidth: columnsNumberDesktop < 4 ? 680 : 340 });
  const { textAlign } = menuItemCard?.price?.text?.style ?? {};

  return (
    <Box
      ref={forwardedRef}
      alignItems="center"
      justifyContent="space-between"
      {...props}
      {...menuItemCard?.wrapper?.style}
      {...(isHovered ? menuItemCard?.wrapperHover?.style : {})}
    >
      <Box ref={onParentChange} width="100%" flexGrow={1} flexShrink={0}>
        <MenuItemThumbnail parentWidth={parentWidth} />
        <Box width="100%" paddingTop={4} paddingLeft={4} paddingRight={4} flexGrow={1} flexShrink={0}>
          <MenuItemSchedule marginBottom={2} textProps={{ textAlign: 'center' }} />
          <MenuItemTitle marginBottom={2} textProps={{ textAlign: 'center' }} />
          <MenuItemContent
            textProps={{ textAlign: 'center' }}
            paddingTop={1}
            marginBottom={4}
            maxHeight={(menuItemCard?.wrapper?.options?.itemDescriptionFormat?.lineCount ?? 4) * 20 + 2}
          />
        </Box>
      </Box>
      <MenuItemPriceGroup paddingBottom={4} paddingLeft={4} paddingRight={4}>
        <MenuItemPrice textProps={{ textAlign: !calories && !orderDate ? textAlign ?? 'center' : 'left' }} />
        <MenuItemCalories marginLeft={2} textProps={{ textAlign: !price ? 'center' : 'right' }} />
      </MenuItemPriceGroup>
    </Box>
  );
});
