import type { IOrderPromotionElement } from '@goparrot/order-sdk';
import { PromotionDiscountLevelEnum } from '@goparrot/promotions-sdk';

export const showPaidModifiersDiscountNotice = (promotions: IOrderPromotionElement[], specificPromo?: IOrderPromotionElement) => {
  let hasDisabledCheckBasedPaidModifiers = false;

  const hasCheckBasedDiscountForPaidModifiers = promotions?.some(({ discount }) => {
    if (PromotionDiscountLevelEnum.CHECK_BASED !== discount.level) {
      return false;
    }

    if (discount.appliesToModifiers === false) {
      hasDisabledCheckBasedPaidModifiers = true;
      return false;
    }

    return true;
  });

  if (hasCheckBasedDiscountForPaidModifiers) {
    return false;
  }

  if (hasDisabledCheckBasedPaidModifiers) {
    if (!specificPromo) {
      return true;
    }

    return specificPromo.discount.appliesToModifiers === false;
  }

  return specificPromo && specificPromo.discount.appliesToModifiers === false;
};
