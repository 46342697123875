import type { ComponentType } from 'react';

export async function lazyRetry<T extends ComponentType<any>>(componentImport: () => Promise<{ default: T }>): Promise<{ default: T }> {
  const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
  try {
    const component = await componentImport();
    window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
    return component;
  } catch (error) {
    if (!hasRefreshed) {
      // not been refreshed yet
      window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
      window.location.reload();
      // @ts-ignore
      return { default: () => null };
    }
    throw error;
  }
}
