import { css } from 'styled-components';
import get from 'lodash/get';
interface Devices {
  [key: string]: string;
}

type MediaSize = 'sm' | 'md' | 'lg';

const sizes = {
  tabletWidth: 768,
  desktopWidth: 1024,
};

export const devices: Devices = {
  tablet: `(min-width: ${sizes.tabletWidth}px)`,
  tabletW: `(max-width: ${sizes.tabletWidth}px)`,
  desktop: `(min-width: ${sizes.desktopWidth}px)`,
  mobileOnly: `(max-width: ${sizes.tabletWidth - 1}px)`,
};

export const media = Object.keys(devices).reduce((acc: Devices, deviceQuery: string) => {
  acc[deviceQuery] = `@media ${devices[deviceQuery]}`;
  return acc;
}, {});

export const applyArrayCustomStyles =
  (key?: string) =>
  ({ customStyles }: Record<string, any>) => {
    const styles = key ? get(customStyles, key) : customStyles;

    if (styles) {
      return css(
        Array.isArray(styles)
          ? styles.reduce((acc, curr, index) => {
              const mediaSelectors = ['', media.tablet, media.desktop];
              return mediaSelectors[index] ? { ...acc, [mediaSelectors[index]]: { ...curr } } : { ...acc, ...curr };
            }, {})
          : styles,
      );
    }

    return {};
  };

export const applyCustomStyles =
  (key?: string) =>
  ({ customStyles }: Record<string, any>) => {
    const styles = key ? get(customStyles, key) : customStyles;

    if (styles) {
      const mediaStyles: Record<string, string> = {};
      const mediaSelectors = { sm: '', md: media.tablet, lg: media.desktop };

      Object.keys(styles).forEach((key: string) => {
        mediaStyles[mediaSelectors[key as MediaSize]] = styles[key];
      });

      return css(mediaStyles);
    }

    return {};
  };
