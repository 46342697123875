import type { IOrder } from '@goparrot/order-sdk';
import type { IPromotionWithApplicationState } from '@goparrot/promotions-sdk';
import type { IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const useRemovePromotionFromCart = (options?: UseMutationOptions<IOrder, AxiosError, string>): UseMutationResult<IOrder, AxiosError, string> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async (uuid?: string) => {
    return axios.delete(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/promotions/${uuid}`);
  }, options);
};

export const useCheckPromoCodeValidation = (
  options?: UseMutationOptions<IPromotionWithApplicationState, AxiosError, string>,
): UseMutationResult<IPromotionWithApplicationState, AxiosError, string> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async (promoCode) => {
    return axios.get(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/promo-codes/${encodeURIComponent(promoCode)}`);
  }, options);
};

export const useApplyPromoCode = (
  options?: UseMutationOptions<IOrder, AxiosError, { promoCode: string; storeItems?: IStoreItemWithOptions }>,
): UseMutationResult<IOrder, AxiosError, { promoCode: string; storeItems?: IStoreItemWithOptions }> => {
  const { merchantId, storeId } = useWebStore();

  return useMutation(async ({ promoCode, storeItems }) => {
    return axios.post(`/api/v2/merchants/${merchantId}/stores/${storeId}/carts/promo-codes/${encodeURIComponent(promoCode)}`, { storeItems });
  }, options);
};
