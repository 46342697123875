import { InfoIcon } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC, ReactElement } from 'react';
import React, { useState } from 'react';

interface InfoTooltipProps {
  tooltip: string | ReactElement;
  fill?: string;
}
export const InfoTooltip: FC<InfoTooltipProps> = ({ tooltip, fill = 'gray900' }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const handleMouseOver = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);
  return (
    <div onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeave} style={{ position: 'relative', zIndex: 10, paddingLeft: 4 }}>
      <InfoIcon width={14} height={14} fill={fill} />
      {showTooltip ? (
        <Box position="absolute" bottom={20} left={-20} backgroundColor="white" padding="m" borderRadius="md" borderWidth={1} borderColor="gray100" width={280}>
          <Text fontSize="s">{tooltip}</Text>
        </Box>
      ) : null}
    </div>
  );
};
