import { OrderFlowEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import { useGetApplicableLoyalties, useHasLoyaltyQuery } from '@webstore-monorepo/shared/api/loyalty-api';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { CheckmarkCircle } from '@webstore-monorepo/shared/icons';
import { pluralizeLoyalty } from '@webstore-monorepo/shared/utils/text';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React from 'react';

import { BlockTitle } from '../block-title';
import { RedeemRewardsIcon } from './RedeemRewardsIcon';

export type RedeemRewardsBlockType = {
  onHandleSelectReward: () => void;
};
export const RedeemRewardsBlock: FC<RedeemRewardsBlockType> = ({ onHandleSelectReward }) => {
  const store = useStoreState();
  const { user } = useUserContextState();
  const cart = useCartState();
  const isAuthUser = user.type === UserTypeEnum.AUTHENTICATED;
  const { data: loyaltyBase } = useHasLoyaltyQuery(store?.storeId ?? '', { enabled: isAuthUser });
  const { data: applicableLoyalties } = useGetApplicableLoyalties({ enabled: isAuthUser });
  const appliedLoyalties = applicableLoyalties?.filter((loyalty) => loyalty.isApplied);

  if (cart?.flow === OrderFlowEnum.DIGITAL) {
    return null;
  }

  return isAuthUser && loyaltyBase?.loyaltyProgram?.programUuid ? (
    <Box testID="payment-redeem-rewards" mb={4}>
      <BlockTitle>Redeem rewards</BlockTitle>
      <Box backgroundColor="gray100" borderRadius="md" flexDirection="row" p={4}>
        {appliedLoyalties?.length === 0 ? <RedeemRewardsIcon fill="gray900" /> : <CheckmarkCircle fill="#00B23B" stroke="transparent" />}
        <Box
          flexDirection={appliedLoyalties?.length === 0 ? 'column' : 'row'}
          alignItems={(appliedLoyalties?.length ?? 0) <= 1 ? 'flex-start' : 'center'}
          justifyContent="space-between"
          marginLeft={2}
          flexGrow={1}
          flexShrink={1}
        >
          {appliedLoyalties?.length === 0 ? (
            <>
              <Text fontSize="m" lineHeight={22}>
                You have {loyaltyBase.availablePoints} {pluralizeLoyalty(loyaltyBase.availablePoints, loyaltyBase.loyaltyProgram.terminology)}{' '}
                {loyaltyBase.availablePoints > 0 ? 'and a reward available' : null}
              </Text>
              <Button variant="link" onPress={onHandleSelectReward} testID="select-reward-button">
                {loyaltyBase.availablePoints > 0 ? 'Select a reward' : 'View rewards'}
              </Button>
            </>
          ) : (
            <>
              <Box alignItems="flex-start" flexShrink={1}>
                <Text fontSize="s" color="gray900" fontWeight="500">
                  {(appliedLoyalties?.length ?? 0) > 1 ? `${appliedLoyalties?.length} rewards applied` : 'Reward applied'}
                </Text>
                {appliedLoyalties?.length === 1 ? <Text>{appliedLoyalties[0]?.rule?.promotion?.subTitle}</Text> : null}
              </Box>
              <Button variant="link" onPress={onHandleSelectReward} testID="change-reward-button">
                Change
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Separator />
    </Box>
  ) : null;
};
