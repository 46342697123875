/* eslint-disable no-console */
export const imagePipe = (src: string, size: number | 'auto' = 'auto', quality: string = 'eco', dpr?: string, lqip?: string): string => {
  if (src.includes('via.placeholder.com')) {
    return src;
  }

  if (!src.includes('cloudinary')) {
    console.warn(`"${src}" should come from cloudinary`);

    return src;
  }

  let criteria;
  if (lqip !== undefined) {
    criteria = `upload/w_${size},c_scale,e_blur:1000,q_1,f_auto,${dpr ? `dpr_${dpr}` : ''}/`;
  } else {
    criteria = `upload/w_${size},c_scale,q_auto:${quality},f_auto,e_sharpen:120,${dpr ? `dpr_${dpr}` : ''}/`;
  }

  return src ? src.replace('http://', 'https://').replace('upload/', criteria) : src;
};
