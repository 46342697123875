import { DiningOptionEnum, OrderFlowEnum } from '@goparrot/common';
import type { BoxProps } from '@shopify/restyle';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { ChevronRight } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { getOrderAheadAvailable } from '@webstore-monorepo/shared/utils/store';
import { Box } from '@webstore-monorepo/ui/box';
import { Collapsable } from '@webstore-monorepo/ui/collapsable';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Pressable } from 'react-native';

import { SelectedDelayedInfo } from './selected-delayed-info';
import { SelectedDiningOption } from './selected-dining-option';
import { getAdditionalFields } from './utils';

export type HowToGetItType = {
  isReadOnly?: boolean;
  onChangeOrderSetup?: () => void;
};
export const HowToGetIt: FC<HowToGetItType> = ({ isReadOnly, onChangeOrderSetup }) => {
  const cart = useCartState();
  const store = useStoreState();
  const isCurbside = cart.diningOptionInfo.type === DiningOptionEnum.CURBSIDE;
  const infoMessage = store.diningOptionsConfig ? store.diningOptionsConfig[cart.diningOptionInfo.type]?.infoMessage : '';
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const isOrderAheadAvailable = getOrderAheadAvailable(store, cart.diningOptionInfo.type);

  const optionsWrapper: BoxProps<Theme> = {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: 48,
    flexBasis: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
  };
  const readOnlyWrapperOptions: BoxProps<Theme> = {
    paddingLeft: 3,
    paddingRight: 2,
    paddingTop: 3,
  };
  const pressableWrapperStyles: BoxProps<Theme> = {
    borderRadius: 'xs',
    backgroundColor: 'gray100',
  };

  const onToggleCollapse = () => {
    setIsExpanded((prev) => !prev);
  };

  if (cart?.flow === OrderFlowEnum.DIGITAL) {
    return null;
  }

  if (isReadOnly) {
    // our backend retains the details of all other dining option types, so we need to ensure to show only the ones that are specified in the config of the store.
    const additionalInfoKeys = getAdditionalFields(cart.diningOptionInfo, store.diningOptionsConfig);

    return (
      <>
        <Box testID="how-to-get-it__read-only" marginBottom={8} alignItems="flex-start" justifyContent="flex-start">
          <Box marginBottom={3}>
            <Text textTransform="uppercase" fontSize="xs">
              How to get it
            </Text>
          </Box>
          <Box {...optionsWrapper} {...readOnlyWrapperOptions} paddingBottom={3}>
            <SelectedDiningOption iconBoxStyleProps={{ marginRight: 2 }} textStyleProps={{ color: 'black' }} showFullAddress={isReadOnly} />
          </Box>
          <Box {...optionsWrapper} {...readOnlyWrapperOptions}>
            <SelectedDelayedInfo iconBoxStyleProps={{ marginRight: 2 }} textStyleProps={{ color: 'black' }} isReadOnly />
          </Box>

          {infoMessage ? (
            <Text color="gray500" lineHeight={22} fontSize="m" fontWeight="400">
              {infoMessage}
            </Text>
          ) : null}
        </Box>
        {additionalInfoKeys?.length || cart.diningOptionInfo.details.comments ? (
          <Box mb={8} testID="cart-how-to-get-info">
            <Separator />
            <Collapsable
              isDefaultOpen={isExpanded}
              onToggleCollapse={onToggleCollapse}
              title={
                <Box flexGrow={1} flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Text fontWeight="500" textTransform="uppercase" fontSize="xs">
                    {isCurbside ? 'Car Details' : 'Additional info'}
                  </Text>
                </Box>
              }
            >
              <Box mt={4}>
                {cart.diningOptionInfo.details?.comments ? (
                  <Text fontSize="m" lineHeight={22} color="gray900">
                    {cart.diningOptionInfo.details.comments}
                  </Text>
                ) : null}
                {additionalInfoKeys.map(({ label, value }, index) =>
                  typeof value === 'string' ? (
                    <Box key={index} flexDirection="row" alignItems="flex-start" justifyContent="flex-start">
                      <Text fontSize="m" lineHeight={22} fontWeight="500" color="gray900">
                        {label}:
                      </Text>
                      <Text fontSize="m" lineHeight={22} fontWeight="400" color="gray900" marginLeft={3}>
                        {value}
                      </Text>
                    </Box>
                  ) : null,
                )}
              </Box>
            </Collapsable>
          </Box>
        ) : null}
      </>
    );
  }

  return (
    <Box testID="how-to-get-it" marginBottom={8}>
      <Box marginBottom={3}>
        <Text textTransform="uppercase" fontSize="xs">
          How to get it
        </Text>
      </Box>
      <Pressable
        accessibilityLabel="change order setup"
        accessibilityHint="press on the order type bar to open the page and change"
        accessibilityRole="button"
        onPress={onChangeOrderSetup}
      >
        <Box alignItems="flex-start" justifyContent="flex-start">
          <Box
            {...optionsWrapper}
            {...pressableWrapperStyles}
            paddingBottom={3}
            marginBottom={2}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box flexDirection="row" justifyContent="flex-start" alignItems="center">
              <SelectedDiningOption iconBoxStyleProps={{ marginRight: 2 }} textStyleProps={{ color: 'black' }} showFullAddress={isReadOnly} />
            </Box>
            <ChevronRight fill="transparent" />
          </Box>
          {isOrderAheadAvailable ? (
            <Box {...optionsWrapper} {...pressableWrapperStyles} flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box flexDirection="row" justifyContent="flex-start" alignItems="center">
                <SelectedDelayedInfo iconBoxStyleProps={{ marginRight: 2 }} textStyleProps={{ color: 'black' }} />
              </Box>
              <ChevronRight fill="transparent" />
            </Box>
          ) : null}
        </Box>
      </Pressable>
    </Box>
  );
};
