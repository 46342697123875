import { Alert, Platform } from 'react-native';

export const platformAlert = ({ confirmMessage, onConfirm, onCancel }: { confirmMessage: string; onConfirm: () => void; onCancel: () => void }) => {
  if (Platform.OS === 'web') {
    const result = window.confirm(confirmMessage);
    if (result) {
      onConfirm();
      return;
    }
    onCancel();
    return;
  }
  Alert.alert('Warning', 'Are you sure you want to remove this credit card?', [
    {
      text: 'Cancel',
      onPress: onCancel,
      style: 'cancel',
    },
    {
      text: 'OK',
      onPress: onConfirm,
    },
  ]);
};
