import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { useWebStoreItems } from '@webstore-monorepo/shared/api/cart-api';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useLocation, useParams } from 'react-router-dom';

export interface RouteStateParams {
  type?: StoreItemTypeEnum.COMBO | StoreItemTypeEnum.STORE_ITEM_BUNDLE;
  comboUidFromCart?: string;
  fromStoreitemGroups?: string;
}

export const useIsNativeCombo = (): boolean | null => {
  const { comboUniqueName = '' } = useParams<{ comboUniqueName: string }>();
  const { state }: { state: RouteStateParams | null } = useLocation();
  const { featuresFlags } = useMerchantState();
  const { data: menu } = useWebStoreItems();

  if (!featuresFlags?.isNativeComboEnabled) {
    return false;
  }

  if (state?.type) {
    return StoreItemTypeEnum.COMBO === state.type;
  }

  if (!menu) {
    return null;
  }

  return menu.combos.some((combo) => combo.uniqueName === comboUniqueName);
};
