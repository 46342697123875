import type { Theme } from '@webstore-monorepo/shared/theme';
import type { CSSProperties } from 'react';

export const getControlStyles = (theme: Theme, hasError?: boolean): CSSProperties & { ':hover': CSSProperties } => ({
  borderColor: hasError ? theme.colors['danger'] : theme.colors['gray300'],
  borderRadius: theme.borderRadii['md'],
  borderWidth: 1,
  paddingTop: theme.spacing[3],
  paddingBottom: theme.spacing[3],
  paddingLeft: theme.spacing[4],
  paddingRight: theme.spacing[4],
  ':hover': {
    borderColor: theme.colors['primaryColor'],
  },
});
export const getValueContainerStyles = (theme: Theme): CSSProperties => ({
  fontSize: theme.fontSizes['16'],
  paddingTop: 0,
  paddingBottom: 0,
});
export const getPlaceholderStyles = (theme: Theme, isMobile?: boolean, hasError?: boolean): CSSProperties => ({
  fontSize: theme.fontSizes['16'],
  ...(isMobile
    ? {
        top: 0,
        transform: 'unset',
      }
    : {}),
  ...(hasError ? { color: theme.colors['danger'] } : {}),
});
export const getDropdownIndicatorStyles = (theme: Theme, hasError?: boolean): CSSProperties => ({
  ...(hasError ? { color: theme.colors['danger'] } : {}),
});
export const getIndicatorSeparatorStyles = (): CSSProperties => ({
  display: 'none',
});
