import { useTheme } from '@shopify/restyle';
import { usePatchVendorOrder } from '@webstore-monorepo/shared/api/vendor-orders-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { DoubleCheckMark, Pin } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef, useState } from 'react';

export type ConfirmArrivalProps = {
  onClose: () => void;
  onConfirm: () => void;
  orderId: string;
  firstName?: string;
};
export const ConfirmArrival = forwardRef(({ onConfirm, orderId, onClose, firstName }: ConfirmArrivalProps, ref) => {
  const { isMobile } = useWindowDimensions();
  const { orderReminderBanner } = useComponentsConfig();
  const { notification } = usePlatformStoreState();
  const [isSuccessState, setSuccessState] = useState(false);
  const { mutate: patchVendorOrder, isLoading } = usePatchVendorOrder({
    orderUuid: orderId,
    options: {
      onSuccess: () => {
        setSuccessState(true);
        onConfirm();
      },
      onError: (error) => {
        if (error?.response?.data?.message) {
          notification.error(error.response.data.message);
        }
      },
    },
  });
  const theme = useTheme<Theme>();

  const primaryBackground = theme.colors['primaryBackground'];
  const opacityBackground = !primaryBackground.includes('#')
    ? primaryBackground
        .split(',')
        .map((item, index) => {
          if (index === 3) {
            return ' 0.1)';
          }
          return item;
        })
        .join(',')
    : `${primaryBackground}10`;

  const handleConfirmArrival = () => {
    patchVendorOrder();
  };

  return (
    <Box ref={ref} {...orderReminderBanner?.wrapper?.confirmModal?.style} {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.style : {})}>
      {!isSuccessState ? (
        <>
          <Box justifyContent="center" alignItems="center">
            <Box
              width={52}
              minWidth={52}
              height={52}
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              marginBottom={2}
              borderRadius="rounded"
              testID="confirm-arrival-textbox"
              style={{ backgroundColor: opacityBackground }}
            >
              <Pin stroke="primaryColor" fill="primaryColor" />
            </Box>
            <Text
              textAlign="center"
              fontSize="xl"
              lineHeight={24}
              fontWeight="700"
              marginBottom={1}
              testID="confirm-arrival-primary-text"
              {...orderReminderBanner?.wrapper?.confirmModal?.primary?.style}
              {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.primary?.style : {})}
            >
              {isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.options?.primary : orderReminderBanner?.wrapper?.confirmModal?.options?.primary}
            </Text>
            <Text
              textAlign="center"
              fontSize="m"
              lineHeight={16}
              marginBottom={4}
              testID="confirm-arrival-secondary-text"
              {...orderReminderBanner?.wrapper?.confirmModal?.secondary?.style}
              {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.secondary?.style : {})}
            >
              {isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.options?.secondary : orderReminderBanner?.wrapper?.confirmModal?.options?.secondary}
            </Text>
          </Box>
          <Box flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box flexGrow={1} marginRight={3}>
              <Button size="full-width" buttonStyle={{ height: 40 }} onPress={onClose} variant="stroked" testID="confirm-arrival-cancel-btn">
                Cancel
              </Button>
            </Box>
            <Box flexGrow={1}>
              <Button isLoading={isLoading} size="full-width" buttonStyle={{ height: 40 }} onPress={handleConfirmArrival} testID="confirm-arrival-im-here-btn">
                I'm here
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <Box justifyContent="center" alignItems="center" paddingTop={7} paddingBottom={7} testID="thank-you-box">
          <Box
            width={52}
            minWidth={52}
            height={52}
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            marginBottom={2}
            borderRadius="rounded"
            style={{ backgroundColor: opacityBackground }}
          >
            <DoubleCheckMark width={36} height={36} stroke="primaryColor" />
          </Box>
          <Text
            textAlign="center"
            fontSize="xl"
            lineHeight={24}
            fontWeight="700"
            marginBottom={1}
            testID="thank-you-title"
            {...orderReminderBanner?.wrapper?.confirmModal?.primary?.style}
            {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.primary?.style : {})}
          >
            Thank you, {firstName}
          </Text>
          <Text
            textAlign="center"
            fontSize="m"
            lineHeight={16}
            marginBottom={4}
            testID="thank-you-subtitle"
            {...orderReminderBanner?.wrapper?.confirmModal?.primary?.style}
            {...(isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.primary?.style : {})}
          >
            {isMobile ? orderReminderBanner?.wrapperMobile?.confirmModal?.options?.success : orderReminderBanner?.wrapper?.confirmModal?.options?.success}
          </Text>
        </Box>
      )}
    </Box>
  );
});
