import type { CountryISOCodeEnum } from '@goparrot/common';
import { CurrencyEnum, PaymentMethodTypeEnum } from '@goparrot/common';
import type { GooglePay } from '@square/web-payments-sdk-types';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { Box } from '@webstore-monorepo/ui/box';
import { BlockLoader } from '@webstore-monorepo/ui/loading';
import type { FC } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import { useOrderDispatch } from '../../../../contexts/order-provider';
import { useSquareContext } from '../../../../contexts/SquareProvider';
import { useReactNativeEvents } from '../../../../hooks/useReactNativeEvents';
import { useValidateGuestForm } from '../../../../hooks/useValidateGuestForm';
import { buildPaymentRequest, tokenize } from '../../../../utils/helpers';
import { postMessageRequestGooglePayCardNonce } from '../../../../utils/rn-post-message';
import { GOOGLE_PAY_BUTTON_ID } from './constants';

export type GooglePayButtonProps = {
  onPayClick: () => Promise<void>;
  isPaymentLoading?: boolean;
  onStopLoading: () => void;
};
export const GooglePayButton: FC<GooglePayButtonProps> = ({ onPayClick, onStopLoading, isPaymentLoading }) => {
  const { chargeOrder } = useOrderDispatch();
  const { currency, total } = useCartState();
  const { squarePayments, locationId } = useSquareContext();
  const { notification, analytics } = usePlatformStoreState();
  const store = useStoreState();
  const cart = useCartState();
  const { validateGuestForm } = useValidateGuestForm();
  const [googlePay, setGooglePay] = useState<GooglePay>();
  const isReactNativeWebView: boolean = !!window.ReactNativeWebView;
  const lastPriceRef = React.useRef<number>(0);
  const initializedRef = React.useRef<boolean>(false);
  const hidePayButton = !googlePay || isPaymentLoading;

  const initializeGooglePay = useCallback(async () => {
    try {
      if (squarePayments && !googlePay) {
        analytics.track('google_pay_init');
        const paymentRequest = buildPaymentRequest(squarePayments, { currency, total }, store);
        const googlePay = await squarePayments.googlePay(paymentRequest);
        setGooglePay(googlePay);
        analytics.track('google_pay_init_success');
        return googlePay;
      }
    } catch (error) {
      analytics.track('google_pay_init_failed');
      notification.error('Initializing Google Pay failed');
    }
  }, [squarePayments, googlePay, total, currency]);

  useEffect(() => {
    if (!googlePay) {
      initializeGooglePay().then(async (sqGooglePay?: GooglePay) => {
        if (sqGooglePay && document.getElementById(GOOGLE_PAY_BUTTON_ID)?.children.length === 0) {
          await sqGooglePay?.attach(`#${GOOGLE_PAY_BUTTON_ID}`, { buttonColor: 'white', buttonSizeMode: 'fill' });
          initializedRef.current = true;
        }
      });
    }
    return () => {
      googlePay?.destroy();
    };
  }, [total, googlePay, initializeGooglePay]);

  useEffect(() => {
    if (googlePay && initializedRef.current && lastPriceRef.current !== total) {
      lastPriceRef.current = total;
      googlePay?.destroy();
      setGooglePay(undefined);
    }
  }, [googlePay, total]);

  const handlePaymentMethodSubmission = async () => {
    try {
      if (validateGuestForm() && googlePay) {
        const token = await tokenize(googlePay);
        await onPayClick();
        await chargeOrder({ type: PaymentMethodTypeEnum.GOOGLE_PAY, cardNonce: token });
        notification.success('Payment Success');
        analytics.track('google_pay_submit_success');
      }
    } catch (error) {
      if (error) {
        analytics.track('google_pay_submit_failed');
        notification.error(error as string);
        console.error(error);
      }
      onStopLoading();
    }
  };

  const handleReactNativeSubmit = () => {
    postMessageRequestGooglePayCardNonce({
      orderTotal: cart.total ?? 0,
      squareLocationId: locationId ?? '',
      countryCode: store?.location.countryCode as CountryISOCodeEnum,
      currencyCode: (cart.currency as unknown as CurrencyEnum) ?? CurrencyEnum.USD,
    });
  };

  const handleReactNativeCardNonceSuccess = useCallback(
    async (cardNonce?: string) => {
      if (validateGuestForm()) {
        await onPayClick();
        await chargeOrder({ type: PaymentMethodTypeEnum.GOOGLE_PAY, cardNonce });
        notification.success('Payment Success');
        analytics.track('rn_google_pay_submit_success');
      }
    },
    [validateGuestForm, onPayClick, chargeOrder],
  );

  useReactNativeEvents({ onPayCardNonceSuccess: handleReactNativeCardNonceSuccess });

  return (
    <Box flexGrow={1} flexShrink={1} flexBasis="30%">
      <Pressable
        key={lastPriceRef.current}
        onPress={isReactNativeWebView ? handleReactNativeSubmit : handlePaymentMethodSubmission}
        testID={GOOGLE_PAY_BUTTON_ID}
        nativeID={GOOGLE_PAY_BUTTON_ID}
        style={{
          alignItems: 'center',
          flexGrow: !hidePayButton ? 1 : 0,
          flexShrink: 1,
          justifyContent: 'center',
          backgroundColor: 'white',
          height: !hidePayButton ? 48 : 0,
        }}
      />
      {hidePayButton ? <BlockLoader flexGrow={1} flexShrink={1} height={48} borderRadius="xs" /> : null}
    </Box>
  );
};
