import type { IOrderElement } from '@goparrot/order-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { DefaultCartImage } from '@webstore-monorepo/shared/icons';
import { NoteIcon } from '@webstore-monorepo/shared/icons';
import { getSelectionsWithPrice } from '@webstore-monorepo/shared/utils/cart';
import { Box } from '@webstore-monorepo/ui/box';
import { LazyImage } from '@webstore-monorepo/ui/lazy-image';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Platform } from 'react-native';

import { ItemValidationWarning } from '../ItemValidationWarning';

interface Props {
  items: IOrderElement[];
  isEditable?: boolean;
  isOrderDetails: boolean;
}

export const ComboDetails = ({ items, isOrderDetails }: Props) => {
  const { intl } = usePlatformStoreState();
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const cartOptions = isMobile && Platform.OS === 'web' ? cartScreen?.wrapperMobile : cartScreen?.wrapper;

  const getModifiers = (comboItem: IOrderElement): string => {
    const modifiers = getSelectionsWithPrice(comboItem);

    return modifiers
      ?.map((mod) => (mod ? `${mod.title}${mod.price > 0 ? ` (+${intl.formatNumber(mod.price)})` : ''}` : ''))
      .filter((selected) => !!selected)
      .join(', ');
  };

  return (
    <Box>
      {items.map((comboItem) => {
        const imageUrl = comboItem?.image_url_square || comboItem?.image_url || cartOptions?.items?.image?.options?.defaultImage;

        return (
          <Box
            testID={`combo-detail-${comboItem.title}`}
            key={comboItem.uniqueName}
            borderBottomWidth={1}
            borderColor="gray100"
            {...cartOptions?.items?.separator?.style}
          >
            <Box paddingTop={4} paddingBottom={4} flexDirection="row" testID={`recommended-item-${comboItem.uniqueName}`}>
              <Box overflow="hidden" marginRight={4} width={70} height={70} alignItems="center" {...cartOptions?.items?.image?.style}>
                {imageUrl ? (
                  // @ts-expect-error allow string value for width
                  <Box width={Platform.OS === 'web' ? 'max-content' : '100%'} height={cartOptions?.items?.image?.style?.height || 70}>
                    <LazyImage
                      style={{ flex: 1 }}
                      height={cartOptions?.items?.image?.style?.height || 70}
                      accessibilityLabel={comboItem.title}
                      accessibilityHint="your order item thumbnail"
                      src={imageUrl}
                      alt={comboItem.title}
                      resizeMode="cover"
                    />
                  </Box>
                ) : (
                  <DefaultCartImage width={70} height={70} fill="gray300" stroke="transparent" />
                )}
              </Box>
              <Box flex={1}>
                <Box marginBottom={1}>
                  <Text color="text" lineHeight={24} fontSize="m" fontWeight="700" marginBottom={1} {...cartOptions?.items?.title?.style}>
                    {comboItem.titleOriginal || comboItem.title}
                  </Text>
                </Box>

                <Box>
                  {!isOrderDetails && <ItemValidationWarning item={comboItem} />}
                  <Text lineHeight={22} fontSize="s" color="gray500" {...cartOptions?.items?.text?.style}>
                    {getModifiers(comboItem)}
                  </Text>
                </Box>
                {comboItem.metadata.notes ? (
                  <Box>
                    <Box alignItems="center" flexDirection="row">
                      {cartOptions?.itemsList?.itemNotesMessage ? (
                        <Text color="text" lineHeight={22} fontSize="s" fontWeight="700">
                          {cartOptions?.itemsList?.itemNotesMessage}
                        </Text>
                      ) : (
                        <>
                          <NoteIcon stroke="none" fill={cartOptions?.items?.subTitle?.style?.color || 'gray900'} width={24} height={24} />
                          <Text color="text" marginLeft={1} lineHeight={22} fontSize="s" fontWeight="700" {...cartOptions?.items?.subTitle?.style}>
                            Note
                          </Text>
                        </>
                      )}
                    </Box>
                    <Text lineHeight={22} fontSize="s" color="gray500" {...cartOptions?.items?.text?.style}>
                      {comboItem.metadata.notes}
                    </Text>
                  </Box>
                ) : null}
              </Box>
              <Box>
                <Text lineHeight={24} color="text" fontSize="m" marginLeft={2} {...cartOptions?.items?.quantity?.style}>
                  {comboItem.quantity}
                </Text>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
