import { OrderFlowEnum, PromotionDiscountBehaviourEnum, PromotionDiscountLevelEnum } from '@goparrot/common';
import type { IOrder, IOrderPromotionElement } from '@goparrot/order-sdk';
import type { IReadPromotionDto, IReadPromotionWithApplicationStateDto } from '@goparrot/promotions-sdk';
import { PromotionSubTypeEnum } from '@goparrot/promotions-sdk';
import { useRemoveLoyaltyReward } from '@webstore-monorepo/shared/api/loyalty-api';
import { useApplyPromoCode, useCheckPromoCodeValidation, useRemovePromotionFromCart } from '@webstore-monorepo/shared/api/promotions-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { PROMO_CODE_MAX_LENGTH } from '@webstore-monorepo/shared/enums';
import { applyPromoCodeValidation } from '@webstore-monorepo/shared/utils/promotions';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Input } from '@webstore-monorepo/ui/input';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';

export type PromoCodeInputProps = {
  onStartAddingManualPromotion: (promotion: IReadPromotionWithApplicationStateDto) => void;
};
export const PromoCodeInput: FC<PromoCodeInputProps> = ({ onStartAddingManualPromotion }) => {
  const { analytics, notification } = usePlatformStoreState();
  const [value, setValue] = useState('');
  const [selectedPromo, setSelectedPromo] = useState<IReadPromotionDto | IOrderPromotionElement | null>(null);
  const cart = useCartState();
  const handleCartUpdate = useCartDispatch();
  const { mutate: handleRemoveLoyaltyReward } = useRemoveLoyaltyReward();
  const { mutate: handleRemovePromoFromCart } = useRemovePromotionFromCart({
    onSuccess: (updatedCart) => {
      handleUpdateCart(updatedCart);
    },
    onError: () => {
      notification.error("Sorry, can't remove promo code");
      analytics.track('cart_promo_code_error', {
        promoCode: selectedPromo?.promoCode,
      });
    },
  });

  const { mutate: handleApplyPromoCode } = useApplyPromoCode({
    onSuccess: (updatedCart) => {
      if (selectedPromo) {
        const validationErrorMessage = applyPromoCodeValidation(updatedCart, cart);

        if (validationErrorMessage) {
          notification.error(validationErrorMessage);
          analytics.track('cart_promo_code_error', {
            uuid: selectedPromo.uuid,
            promoCode: selectedPromo.promoCode,
            message: validationErrorMessage,
          });
          if (PromotionSubTypeEnum.LOYALTY === selectedPromo.subtype && 'loyaltyInfo' in selectedPromo) {
            handleRemoveLoyaltyReward(selectedPromo.loyaltyInfo.rule.uuid);

            analytics.track('cart_reward_deleted', {
              uuid: selectedPromo.loyaltyInfo.rule.uuid,
            });
          } else {
            handleRemovePromoFromCart(selectedPromo.uuid);

            analytics.track('cart_promo_deleted', {
              uuid: selectedPromo.uuid,
              promoCode: selectedPromo.promoCode,
            });
          }
          return;
        }

        handleUpdateCart(updatedCart);
        analytics.track('cart_promo_applied_to_cart', {
          uuid: selectedPromo.uuid,
          promoCode: selectedPromo.promoCode,
        });
        setValue('');
        notification.success('Promotion was added to your cart');
      }
    },
    onError: () => {
      notification.error("Sorry, can't apply promo code");
      analytics.track('cart_promo_code_error', {
        promoCode: selectedPromo?.promoCode,
      });
    },
  });

  const { mutate: handleValidatePromoCode } = useCheckPromoCodeValidation({
    onSuccess: (validPromotion) => {
      const { promotion, isApplicable, validationReasons } = validPromotion;
      if (!isApplicable) {
        const [reason] = validationReasons;
        notification.error(reason.message);
        analytics.track('cart_promo_code_error', {
          uuid: promotion?.uuid,
          promoCode: value,
          message: reason.message,
        });
        return;
      }
      setSelectedPromo(promotion);

      const { level, behaviour } = promotion.discount;

      if ([PromotionDiscountLevelEnum.ITEM_BASED, PromotionDiscountLevelEnum.BOGO].includes(level) && PromotionDiscountBehaviourEnum.ADD_ITEM === behaviour) {
        onStartAddingManualPromotion(validPromotion);
        return;
      }
    },
    onError: () => {
      notification.error('Sorry, this Promo Code is not valid.');
      analytics.track('cart_promo_code_error', {
        promoCode: selectedPromo?.promoCode,
      });
    },
  });

  useEffect(() => {
    if (selectedPromo) {
      handleApplyPromoCode({ promoCode: value ?? '' });
    }
  }, [selectedPromo]);

  const handleUpdateCart = (updatedCart: IOrder) => {
    handleCartUpdate({ type: 'update', payload: updatedCart });
  };

  const handlePressApply = () => {
    if (!value) {
      notification.error('Please enter a valid promo code.');
      return;
    }
    analytics.track('cart_promo_code_entered', {
      promoCode: value,
    });
    handleValidatePromoCode(value);
  };

  const handleChangeInput = (value: string) => {
    setValue(value);
  };

  if (cart?.flow === OrderFlowEnum.DIGITAL) {
    return null;
  }
  return (
    <Box
      testID="promoCode"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      marginBottom={8}
      paddingTop={2}
      paddingBottom={2}
      paddingLeft={3}
      paddingRight={3}
      borderWidth={1}
      borderColor="gray300"
      backgroundColor="white"
      borderRadius="md"
    >
      <Input
        fullWidth
        value={value}
        // @ts-ignore
        onChangeText={handleChangeInput}
        placeholder="Enter promo code"
        height={48}
        borderWidth={0}
        backgroundColor="transparent"
        padding="none"
        maxLength={PROMO_CODE_MAX_LENGTH}
        testID="promo-code-input"
      />
      <Button isDisabled={!value} onPress={handlePressApply} buttonStyle={{ minHeight: 48, minWidth: 75 }} testID="promo-code-submit" fontSize="m">
        Apply
      </Button>
    </Box>
  );
};
