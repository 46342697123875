import type { IMenuConcept } from '@goparrot/storeitems-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Image } from 'react-native';

type MenuConceptsDetailsProps = {
  selectedConcept?: IMenuConcept;
};
export const MenuConceptsDetails: FC<MenuConceptsDetailsProps> = ({ selectedConcept }) => {
  const { menuConceptsBar } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const menuConceptsConfig = {
    ...menuConceptsBar?.block,
    ...(isMobile ? menuConceptsBar?.blockMobile : {}),
  };
  const [isShowMore, setIsShowMore] = useState(false);

  const handleToggleShowMore = () => setIsShowMore((prev) => !prev);

  return menuConceptsConfig?.options?.showConceptBlock && selectedConcept?.title && selectedConcept?.description ? (
    <Box {...menuConceptsConfig?.style}>
      <Box
        testID={`selected-concept-item-${selectedConcept.uniqueName}`}
        marginTop={5}
        marginBottom={5}
        marginLeft={5}
        marginRight={5}
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        alignItems="flex-start"
        borderRadius="md"
        minHeight={135}
        backgroundColor="white"
        padding={4}
        {...menuConceptsConfig?.container?.style}
      >
        {/*@ts-expect-error allow string value for width*/}
        <Box flexGrow={1} flexShrink={0} flexBasis="min-content" padding={2} alignItems="flex-start" width={!selectedConcept?.imageUrl ? '100%' : 'unset'}>
          <Box {...menuConceptsConfig?.title?.style}>
            <Text fontWeight="700" fontSize="xl" {...menuConceptsConfig?.title?.style}>
              {selectedConcept?.title}
            </Text>
          </Box>
          <Box {...menuConceptsConfig?.description?.style} width="100%">
            <Text marginTop={2} marginBottom={1} fontSize="m" {...menuConceptsConfig?.description?.style}>
              {!isShowMore && selectedConcept?.description.length > 250
                ? selectedConcept?.description.substring(0, 250) + ' ...'
                : selectedConcept?.description}
            </Text>
          </Box>
          {selectedConcept?.description.length > 250 ? (
            <Button variant="link" onPress={handleToggleShowMore}>
              <Text textDecorationLine="underline" color="primaryButtonColor" {...menuConceptsConfig?.showMore?.style}>
                {!isShowMore ? 'Show more' : 'Show less'}
              </Text>
            </Button>
          ) : null}
        </Box>
        {selectedConcept?.imageUrl ? (
          <Box flexDirection="row" alignItems="center" marginTop={isMobile ? 1 : 'none'} {...menuConceptsConfig?.logoContainer?.style}>
            <Image
              accessibilityLabel={selectedConcept?.title}
              accessibilityHint="Menu concepts selected"
              accessibilityIgnoresInvertColors
              accessibilityRole="none"
              source={{ uri: selectedConcept?.imageUrl }}
              style={{
                width: isMobile ? 56 : 115,
                height: isMobile ? 56 : 115,
                borderRadius: 8,
              }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : null;
};
