import type { ReadSquarePaymentMetadataDto } from '@goparrot/payment-sdk';
import type { Payments } from '@square/web-payments-sdk-types';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { usePaymentContext } from './PaymentProvider';

interface ISquareContext {
  squarePayments?: Payments;
  locationId?: string;
}

export const SquareContext = createContext<ISquareContext>({ squarePayments: undefined, locationId: undefined });

export const SquareProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { paymentStore } = usePaymentContext();
  const { applicationId, locationId } = (paymentStore?.metadata as ReadSquarePaymentMetadataDto) ?? {};
  const [payments, setPayments] = useState<Payments>();
  const { analytics, notification } = usePlatformStoreState();

  useEffect(() => {
    try {
      if (!payments && applicationId && locationId) {
        const squarePayments = window.Square?.payments(applicationId, locationId);
        analytics.track('square_payments_init');
        if (squarePayments) {
          analytics.track('square_payments_init_success', { squarePayments });
          setPayments(squarePayments);
        }
      }
    } catch (e) {
      analytics.track('square_payments_init_fail', { error: e });
      notification.error('Square initializing failed');
    }
  }, [analytics, applicationId, locationId, notification, payments]);

  return (
    <SquareContext.Provider
      value={{
        squarePayments: payments,
        locationId,
      }}
    >
      {children}
    </SquareContext.Provider>
  );
};

export const useSquareContext = () => {
  const context = useContext(SquareContext);
  if (context === undefined) {
    throw new Error('useSquareContext must be used within a SquareProvider');
  }
  return context;
};
