import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { PropsWithChildren, ReactNode } from 'react';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../MenuItemCardProvider';

export const MenuItemPriceGroup = forwardRef(({ children, ...props }: PropsWithChildren & BoxProps<Theme>, forwardedRef) => {
  const { title } = useMenuItemCardState();
  const { isMobile } = useWindowDimensions();
  const { menuItemCard, menuItemCardMobile } = useComponentsConfig();

  return title ? (
    <Box
      ref={forwardedRef}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-end"
      flexShrink={0}
      flexBasis="auto"
      flexGrow={1}
      overflow="hidden"
      margin="none"
      width="100%"
      {...props}
      {...menuItemCard?.priceGroup?.style}
      {...(isMobile ? menuItemCardMobile?.priceGroup?.style : {})}
    >
      {children}
    </Box>
  ) : null;
});
