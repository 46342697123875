import type { IStoreItemOptionSelectionsProperty, IStoreItemOptionWithSelections } from '@goparrot/storeitems-sdk';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import React, { useEffect, useState } from 'react';

import { Selection } from '../../../uielements/selection';
import type { SelectionObj } from '../../../uielements/selection/interfaces';
import type { AddOnsSelection } from '../../interface';

export interface Props {
  index: number;
  selection: AddOnsSelection;
  property: IStoreItemOptionWithSelections;
  onSelectionUpdate(property: AddOnsSelection): void;
  disabled?: boolean;
}

export const AddOnItemMetaData: React.FC<Props> = ({ onSelectionUpdate, property, selection, index, disabled }) => {
  const [selected, setSelected] = useState(false);
  const webstore = useWebStore();
  const showZeroPrice = webstore.metadata?.modifiers.showZeroPrice || false;

  const handleChangeAddon = () => {
    const updatedSelected = !selection.selected;
    const changed: AddOnsSelection = {
      ...selection,
      selected: updatedSelected,
    };

    setSelected(updatedSelected);
    onSelectionUpdate(changed);
  };

  const handleItemBaseClick = (prop: IStoreItemOptionSelectionsProperty) => {
    const updatedSelected = !prop.selected;
    const changed: AddOnsSelection = {
      ...selection,
      properties: selection.properties.map((item) => ({ ...item, selected: item.uid === prop.uid ? !item.selected : false })),
      selected: updatedSelected,
      price: updatedSelected ? prop.price : 0,
      selectedItem: updatedSelected ? prop : null,
    };

    setSelected(updatedSelected);
    onSelectionUpdate(changed);
  };

  const handleDeselectItemBaseGroupClick = () => {
    const changed: AddOnsSelection = {
      ...selection,
      properties: selection.properties.map((item) => ({ ...item, selected: false })),
      selected: false,
      price: 0,
      selectedItem: null,
    };

    setSelected(false);
    onSelectionUpdate(changed);
  };

  useEffect(() => {
    if ('selected' in selection) {
      setSelected(!!selection.selected);
    }
  }, [selection]);

  const selectionObj: SelectionObj = {
    uid: `${property.uid}-${selection.uid}`,
    title: selection.title,
    titleHTML: selection.titleHTML,
    price: selection.price,
    properties: selection.properties,
    selectedItem: selection?.selectedItem || selection.properties?.find((item) => item.selected),
    selected,
    type: selection.type,
    unselected_icon: selection.unselected_icon,
    unselected_order: selection.unselected_order,
    validity: selection.validity,
    disabled,
    imageUrl: selection.image_url_square,
  };

  return (
    <Selection
      index={index}
      showZeroPrice={showZeroPrice}
      selection={selectionObj}
      onChange={handleChangeAddon}
      onItemBaseGroupClick={handleItemBaseClick}
      onDeselectItemBaseGroupClick={handleDeselectItemBaseGroupClick}
    />
  );
};
