import type { ImageProps } from '@webstore-monorepo/ui/lazy-image';
import { LazyImage } from '@webstore-monorepo/ui/lazy-image';
import React from 'react';

import { usePromoPopupContext } from '../PromoPopupProvider';

export const PopupImage = ({ ...props }: ImageProps) => {
  const { promotion } = usePromoPopupContext();

  return <LazyImage src={promotion?.images.imageSquareUrl} width={137} height={137} {...props} />;
};
