import type { INestedStoreItemGroup, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { StoreItemVariantDesktopEnum, StoreItemVariantMobileEnum } from '@goparrot/webstore-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { formatPrice } from '@webstore-monorepo/shared/utils/format-strings';
import { sliceByWord } from '@webstore-monorepo/shared/utils/text';
import { Box } from '@webstore-monorepo/ui/box';
import DOMPurify from 'dompurify';
import React, { forwardRef, useRef } from 'react';
import { Pressable } from 'react-native';

import { CardDesktopThumbnailLeft, CardDesktopThumbnailRight, CardDesktopThumbnailTop } from './components/variants/desktop';
import { CardMobileStrip, CardMobileThumbnailLeft, CardMobileThumbnailRight, CardMobileThumbnailTop } from './components/variants/mobile';
import { CardMobileLegacy } from './components/variants/mobile/CardMobileLegacy';
import type { LayoutCustomizationProps } from './MenuItemCardProvider';
import { MenuItemCardProvider } from './MenuItemCardProvider';

export type MenuItemCardProps = {
  showImage?: boolean;
  item: IStoreItemWithOptions | INestedStoreItemGroup;
  onClick: (item: IStoreItemWithOptions | INestedStoreItemGroup) => void;
  width?: number | 'auto' | `${number}%`;
  testId?: string;
  variant?: {
    desktop?: StoreItemVariantDesktopEnum;
    mobile?: StoreItemVariantMobileEnum;
  };
  columnsNumberDesktop?: number;
  boxProps?: BoxProps<Theme>;
  layoutCustomization?: LayoutCustomizationProps;
};

export const MenuItemCard = forwardRef(
  (
    {
      item,
      showImage,
      variant,
      width,
      columnsNumberDesktop = 4,
      testId = `menu-item-card-${item.uniqueName}`,
      onClick,
      boxProps,
      layoutCustomization,
    }: MenuItemCardProps,
    forwardedRef,
  ) => {
    const wrapperRef = useRef<HTMLElement>(null);
    const { menuItemCard, menuItemCardMobile } = useComponentsConfig();
    const { isMobile } = useWindowDimensions();
    const { isHovered } = useHover({}, wrapperRef);
    const formattedPrice = formatPrice(
      item.price,
      isMobile ? menuItemCardMobile?.wrapper?.options?.showZeroPrice : menuItemCard?.wrapper?.options?.showZeroPrice,
    );
    const descriptionFormat = (isMobile
      ? menuItemCardMobile?.wrapper?.options?.itemDescriptionFormat
      : menuItemCard?.wrapper?.options?.itemDescriptionFormat) ?? {
      ellipsis: true,
      characterCount: 80,
    };

    // replace &gt; and &lt; to their equivalent,because DOMPurify thinks it can be malicious code and converts them to
    // html entities. `content` can't be inserted to ReactNative components via dangerouslySetInnerHTML, that's why
    // we need to replace to > and < manually
    const cleanSubtitle = DOMPurify.sanitize(item.subtitle || '', { ALLOWED_TAGS: [] })
      .replace('&gt;', '>')
      .replace('&lt;', '<');
    const content = sliceByWord(cleanSubtitle, descriptionFormat.characterCount);
    const selectedDesktopVariant = variant?.desktop ?? menuItemCard?.wrapper?.options?.variant ?? StoreItemVariantDesktopEnum.THUMBNAIL_TOP;
    const selectedMobileVariant = variant?.mobile ?? menuItemCardMobile?.wrapper?.options?.variant ?? StoreItemVariantMobileEnum.THUMBNAIL_TOP;
    const generalStyles: BoxProps<Theme> = {
      borderStyle: 'solid',
      borderColor: 'gray200',
      borderWidth: 1,
      minHeight: 135,
      width,
      maxWidth: width,
      overflow: 'hidden',
      borderRadius: 'xs',
      ...boxProps,
      ...menuItemCard?.wrapper?.style,
    };

    const handleClick = () => {
      onClick(item);
    };

    const renderMobileVariant = () => {
      switch (selectedMobileVariant) {
        case StoreItemVariantMobileEnum.STRIP: {
          const { ...rest } = generalStyles;
          return <CardMobileStrip ref={forwardedRef} {...rest} />;
        }
        case StoreItemVariantMobileEnum.THUMBNAIL_TOP:
          return <CardMobileThumbnailTop ref={forwardedRef} {...generalStyles} />;
        case StoreItemVariantMobileEnum.LEGACY:
          return <CardMobileLegacy ref={forwardedRef} {...generalStyles} />;
        case StoreItemVariantMobileEnum.THUMBNAIL_LEFT:
          return <CardMobileThumbnailLeft ref={forwardedRef} {...generalStyles} />;
        case StoreItemVariantMobileEnum.THUMBNAIL_RIGHT:
          return <CardMobileThumbnailRight ref={forwardedRef} {...generalStyles} />;
      }
    };

    const renderDesktopVariant = () => {
      switch (selectedDesktopVariant) {
        case StoreItemVariantDesktopEnum.THUMBNAIL_TOP:
          return <CardDesktopThumbnailTop ref={forwardedRef} {...generalStyles} />;
        case StoreItemVariantDesktopEnum.THUMBNAIL_LEFT:
          return <CardDesktopThumbnailLeft ref={forwardedRef} {...generalStyles} />;
        case StoreItemVariantDesktopEnum.THUMBNAIL_RIGHT:
          return <CardDesktopThumbnailRight ref={forwardedRef} {...generalStyles} />;
      }
    };

    return (
      <MenuItemCardProvider
        isHovered={isHovered}
        showImage={showImage ?? true}
        title={item.title}
        price={formattedPrice}
        content={content}
        imageUrl={item.image_url}
        hoverImageUrl={'hover_image_url' in item ? item.hover_image_url : undefined}
        schedule={'availabilitySchedule' in item ? item.availabilitySchedule : undefined}
        calories={'calories' in item.metadata ? item.metadata.calories : undefined}
        // @ts-ignore
        pricePrefixText={item.metadata.price_prefixtext}
        variantMobile={selectedMobileVariant}
        variantDesktop={selectedDesktopVariant}
        columnsNumberDesktop={columnsNumberDesktop}
        layoutCustomization={layoutCustomization}
      >
        <Pressable
          accessibilityLabel={`Menu item ${item.title}`}
          accessibilityHint={`press on the menu item ${item.title} to open it`}
          accessibilityRole="button"
          testID={testId}
          nativeID={item.uniqueName}
          onPress={handleClick}
          style={{ height: '100%', width: '100%' }}
        >
          <Box ref={wrapperRef} flexDirection="row" flexGrow={1} flexShrink={0}>
            {isMobile ? renderMobileVariant() : null}
            {!isMobile ? renderDesktopVariant() : null}
          </Box>
        </Pressable>
      </MenuItemCardProvider>
    );
  },
);

MenuItemCard.displayName = 'MenuItemCard';
