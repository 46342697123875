import { HumanReadableIntervalsUtils } from '@goparrot/store-v2-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { PrepareIcon } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Platform } from 'react-native';

interface Props {
  prepTime: number;
}

export const PrepTime = ({ prepTime }: Props) => {
  const merchant = useMerchantState();
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const cartOptions = isMobile && Platform.OS === 'web' ? cartScreen?.wrapperMobile : cartScreen?.wrapper;

  const convertedPrepTime = HumanReadableIntervalsUtils.convertMinutesToHumanReadable(prepTime);
  const text = cartOptions?.itemsList?.prepTimeMessage?.replace('%x%', convertedPrepTime + '') ?? `Takes ≈${convertedPrepTime} to prepare`;

  return merchant.featuresFlags?.isItemPrepTimeEnabled ? (
    <Box marginTop={2} alignItems="center" flexDirection="row">
      <PrepareIcon width={24} height={24} fill="danger" stroke="none" />
      <Text lineHeight={22} fontSize="m" fontWeight="600" color="danger">
        {text}
      </Text>
    </Box>
  ) : null;
};
