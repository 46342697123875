import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { sliceByWord } from '@webstore-monorepo/shared/utils/text';
import { Box } from '@webstore-monorepo/ui/box';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../MenuItemCardProvider';

export const MenuItemContent = forwardRef((props: BoxProps<Theme> & { textProps?: TextPropsExtended }, forwardedRef) => {
  const { content, isHovered, layoutCustomization } = useMenuItemCardState();
  const { isMobile } = useWindowDimensions();
  const { menuItemCard, menuItemCardMobile } = useComponentsConfig();
  const { options: cardOptions } = isMobile ? menuItemCardMobile?.wrapper ?? {} : menuItemCard?.wrapper ?? {};
  const sliced = sliceByWord(content, isMobile ? cardOptions?.itemDescriptionFormat?.characterCount : cardOptions?.itemDescriptionFormat?.characterCount);
  const { textProps, ...boxProps } = props;
  const maxHeightStyle = layoutCustomization?.maxContentHeight ? { maxHeight: layoutCustomization?.maxContentHeight } : {};

  return content ? (
    <Box
      ref={forwardedRef}
      minHeight={30}
      overflow="hidden"
      testID="menu-item-description"
      {...menuItemCard?.content?.style}
      {...(isMobile ? menuItemCardMobile?.content?.style : {})}
      {...boxProps}
      {...(isHovered ? menuItemCard?.contentHover?.style : {})}
      {...(!content ? { margin: 'none', padding: 'none', maxHeight: 30 } : {})}
      {...maxHeightStyle}
    >
      <Text
        fontSize={isMobile ? 's' : 'm'}
        numberOfLines={cardOptions?.itemDescriptionFormat?.lineCount}
        {...textProps}
        {...menuItemCard?.content?.text?.style}
        {...(isMobile ? menuItemCardMobile?.content?.text?.style : {})}
        {...(isHovered ? menuItemCard?.contentHover?.text?.style : {})}
      >
        {sliced}
      </Text>
    </Box>
  ) : null;
});
