import type { ReadStoreDto } from '@goparrot/store-v2-sdk';

export const getFormattedStoreInformation = (store: ReadStoreDto, footerContactsTemplate: string): string[] => {
  const { title = '' } = store;
  const { formattedAddress = '' } = store.location ?? {};
  const { address = '' } = store.pickup ?? {};
  const { phone = '' } = store.contactInfo ?? {};

  /**
   * TODO: write migration to replace legacy store object path with store-v2
   */
  const footerInfoVariables: { [key: string]: string } = {
    '%store.title%': title,
    '%store.details.restaurantAddress.name%': formattedAddress,
    '%store.details.pickUpAddress%': address,
    '%store.details.contactInfo.phoneNumber%': phone,
  };

  const footerInfoTemplate = footerContactsTemplate.replace(
    /%store.title%|%store.details.restaurantAddress.name%|%store.details.contactInfo.phoneNumber%|%store.details.pickUpAddress%/gi,
    (matched: string) => footerInfoVariables[matched],
  );

  return footerInfoTemplate
    .split('|')
    .map((item) => item.trim())
    .filter(Boolean);
};
