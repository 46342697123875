import type { IOrder } from '@goparrot/order-sdk';
import { useLastOrders } from '@webstore-monorepo/shared/api/cart-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ChevronRight } from '@webstore-monorepo/shared/icons';
import { findCorrectAlign } from '@webstore-monorepo/shared/utils/text';
import { Box, ScrollableBox } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { EmptyFallback } from '@webstore-monorepo/ui/error-fallback';
import { Text } from '@webstore-monorepo/ui/text';
import { TextGradient } from '@webstore-monorepo/ui/text-gradient';
import React, { forwardRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FlatGrid } from 'react-native-super-grid';

import { PreviousOrdersItem } from './previous-orders-item';

export type PreviousOrdersProps = {
  onItemClick: (order: IOrder) => void;
  onSeeMoreClick: () => void;
};
export const PreviousOrdersList = forwardRef(({ onItemClick, onSeeMoreClick }: PreviousOrdersProps, forwardedRef) => {
  const { previousOrdersList, categoryItemsGroup } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const { data: lastOrders = [] } = useLastOrders({ enabled: true });
  const slicedLastOrders = [...lastOrders]?.splice(0, 4);
  const remainingOrders = lastOrders.length - slicedLastOrders?.length;
  const showPreviousOrders = isMobile
    ? previousOrdersList?.wrapperMobile?.options?.showPreviousOrders
    : previousOrdersList?.wrapper?.options?.showPreviousOrders;
  const titleAlignment = findCorrectAlign(
    (isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style?.textAlign : categoryItemsGroup?.wrapper?.title?.text?.style?.textAlign) ?? 'center',
  );

  const customTitle =
    (isMobile && previousOrdersList?.wrapperMobile?.options?.title
      ? previousOrdersList?.wrapperMobile?.options?.title
      : previousOrdersList?.wrapper?.options?.title) ?? 'Previous Orders';

  const handleItemClick = (order: IOrder) => {
    onItemClick(order);
  };

  return showPreviousOrders && slicedLastOrders?.length > 0 ? (
    <Box
      ref={forwardedRef}
      testID="previous-orders-list-wrapper"
      paddingLeft={1}
      paddingRight={1}
      marginTop={4}
      marginBottom={4}
      {...categoryItemsGroup?.wrapper?.style}
      {...(isMobile ? categoryItemsGroup?.wrapperMobile?.style : {})}
      {...previousOrdersList?.wrapper?.style}
      {...(isMobile ? previousOrdersList?.wrapperMobile?.style : {})}
    >
      <Box
        flexGrow={1}
        flexShrink={0}
        flexBasis="auto"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={titleAlignment ?? 'center'}
        alignItems="center"
        overflow="hidden"
        {...categoryItemsGroup?.wrapper?.title?.style}
        {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.style : {})}
        {...previousOrdersList?.wrapper?.title?.style}
        {...(isMobile ? previousOrdersList?.wrapperMobile?.title?.style : {})}
        marginBottom={6}
      >
        {(
          isMobile
            ? categoryItemsGroup?.wrapperMobile?.options?.showTitleTextGradient || previousOrdersList?.wrapperMobile?.options?.showTextGradient
            : categoryItemsGroup?.wrapper?.options?.showTitleTextGradient || previousOrdersList?.wrapper?.options?.showTextGradient
        ) ? (
          <TextGradient
            width="auto"
            fontSize="4xl"
            colors={
              isMobile
                ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.colors ||
                  previousOrdersList?.wrapperMobile?.title?.textGradient?.options?.colors
                : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.colors || previousOrdersList?.wrapper?.title?.textGradient?.options?.colors
            }
            start={
              isMobile
                ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.start ||
                  previousOrdersList?.wrapperMobile?.title?.textGradient?.options?.start
                : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.start || previousOrdersList?.wrapper?.title?.textGradient?.options?.start
            }
            end={
              isMobile
                ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.end || previousOrdersList?.wrapperMobile?.title?.textGradient?.options?.end
                : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.end || previousOrdersList?.wrapper?.title?.textGradient?.options?.end
            }
            {...categoryItemsGroup?.wrapper?.title?.text?.style}
            {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
            {...previousOrdersList?.wrapper?.title?.text?.style}
            {...(isMobile ? previousOrdersList?.wrapperMobile?.title?.text?.style : {})}
            {...categoryItemsGroup?.wrapper?.title?.textGradient?.style}
            {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.style : {})}
            {...previousOrdersList?.wrapper?.title?.textGradient?.style}
            {...(isMobile ? previousOrdersList?.wrapperMobile?.title?.textGradient?.style : {})}
          >
            {customTitle}
          </TextGradient>
        ) : (
          <Text
            fontSize="4xl"
            {...categoryItemsGroup?.wrapper?.title?.text?.style}
            {...previousOrdersList?.wrapper?.title?.text?.style}
            {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
            {...(isMobile ? previousOrdersList?.wrapperMobile?.title?.text?.style : {})}
          >
            {customTitle}
          </Text>
        )}
        <Box marginLeft={isMobile ? 'none' : 3} marginBottom={isMobile ? 1 : 'none'}>
          <Button
            testID="see-more"
            onPress={onSeeMoreClick}
            size="sm"
            buttonStyle={{
              borderRadius: 'rounded',
              ...previousOrdersList?.wrapper?.showMoreButton?.style,
              ...(isMobile ? previousOrdersList?.wrapperMobile?.showMoreButton?.style : {}),
            }}
            rightIcon={
              <ChevronRight
                fill="transparent"
                width={10}
                height={10}
                stroke={
                  (isMobile
                    ? previousOrdersList?.wrapperMobile?.showMoreButton?.text?.style?.color
                    : previousOrdersList?.wrapper?.showMoreButton?.text?.style?.color) ?? 'white'
                }
              />
            }
            {...(isMobile ? previousOrdersList?.wrapperMobile?.showMoreButton?.options : previousOrdersList?.wrapper?.showMoreButton?.options)}
            color={
              (
                isMobile
                  ? previousOrdersList?.wrapperMobile?.showMoreButton?.options?.colorScheme
                  : previousOrdersList?.wrapper?.showMoreButton?.options?.colorScheme
              )
                ? isMobile
                  ? previousOrdersList?.wrapperMobile?.showMoreButton?.text?.style?.color
                  : previousOrdersList?.wrapper?.showMoreButton?.text?.style?.color
                : 'black'
            }
            backgroundColor={
              (
                isMobile
                  ? previousOrdersList?.wrapperMobile?.showMoreButton?.options?.colorScheme
                  : previousOrdersList?.wrapper?.showMoreButton?.options?.colorScheme
              )
                ? isMobile
                  ? previousOrdersList?.wrapperMobile?.showMoreButton?.style?.backgroundColor
                  : previousOrdersList?.wrapper?.showMoreButton?.style?.backgroundColor
                : 'gray100'
            }
          >
            See {remainingOrders} more
          </Button>
        </Box>
      </Box>
      <ScrollableBox>
        <FlatGrid
          testID="previous-orders-list"
          maxItemsPerRow={4}
          spacing={16}
          additionalRowStyle={{
            paddingLeft: 0,
            paddingBottom: 0,
          }}
          contentContainerStyle={{ flexDirection: 'row' }}
          data={slicedLastOrders}
          style={{
            paddingTop: 0,
            marginBottom: 0,
            paddingLeft: 0,
          }}
          renderItem={({ item, index }) => (
            <ErrorBoundary FallbackComponent={EmptyFallback}>
              <Box
                key={index}
                height="100%"
                {...previousOrdersList?.wrapper?.items?.style}
                {...(isMobile ? previousOrdersList?.wrapperMobile?.items?.style : {})}
              >
                <PreviousOrdersItem item={item} onItemClick={handleItemClick} />
              </Box>
            </ErrorBoundary>
          )}
        />
      </ScrollableBox>
    </Box>
  ) : null;
});
