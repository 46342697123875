import type { IReadPromotionDto } from '@goparrot/promotions-sdk';
import { PromotionSubTypeEnum, PromotionTargetTypeEnum } from '@goparrot/promotions-sdk';
import { MENU_PATHS } from '../../pages/paths';
import type { UseAppHistory } from '../hooks/use-app-history';
import type { PromotionRouteParam } from '../services';
import { getPromoTargets } from '@webstore-monorepo/shared/utils/promotions';

export const startAddingManualPromo = (promotion: IReadPromotionDto, history: UseAppHistory, storeId: string, numberOfApplies?: number) => {
  const {
    uuid: promoUuid,
    subtype,
    discount: { targets },
  } = promotion;
  const targetsList = getPromoTargets(targets, storeId);
  const prevModal = PromotionSubTypeEnum.PROMOTION === subtype ? MENU_PATHS.promos : MENU_PATHS.cart;
  const [promoTarget] = targetsList;

  if (targetsList.length === 1 && promoTarget.type === PromotionTargetTypeEnum.ITEM) {
    const withPromo: PromotionRouteParam = {
      uuid: promoUuid,
      subtype,
      numberOfApplies,
      targetType: promoTarget.type,
      promoCode: promotion.promoCode,
      targetId: promoTarget.uuid,
      targetQty: promoTarget.quantity,
    };

    return history.push(MENU_PATHS.item_modal.replace(':itemUniqueName', promoTarget.uuid), {
      prevModal,
      withPromo,
    });
  }

  return history.push(MENU_PATHS.promoItems.replace(':uuid', promoUuid), { prevModal });
};
