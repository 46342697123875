import type { SupportedCurrencyEnum } from '@goparrot/common';
import { CountryISOCodeEnum, CurrencyEnum } from '@goparrot/common';
import type { ILegacyPaymentContext } from '@goparrot/payment-sdk';
import type { ApplePay, GooglePay, Payments } from '@square/web-payments-sdk-types';

export const tokenize = async (paymentMethod: ApplePay | GooglePay): Promise<string | undefined> => {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === 'OK') {
    return tokenResult.token;
  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }

    throw new Error(errorMessage);
  }
};

export const buildPaymentRequest = (
  payments: Payments,
  { currency, total }: { currency: SupportedCurrencyEnum | CurrencyEnum; total?: number },
  store: ILegacyPaymentContext['store'],
) => {
  return payments.paymentRequest({
    countryCode: (store?.location.countryCode ?? CountryISOCodeEnum.United_States).toUpperCase(),
    currencyCode: currency ?? CurrencyEnum.USD,
    total: {
      amount: `${total}`,
      label: 'Total',
    },
  });
};
