import { StoreItemTypeEnum, StoreItemValidityEnum } from '@goparrot/common';
import type { IStoreItemOptionSelection, IStoreItemOptionWithSelections } from '@goparrot/storeitems-sdk';
import { useItemFormContext } from '@webstore-monorepo/shared/contexts/item-form-provider';
import type { RefObject } from 'react';
import React, { createRef, useEffect, useState } from 'react';

import { AddOnItem } from '../AddOnItem';

interface SelectableStoreItem extends IStoreItemOptionWithSelections {
  property?: any;
  changed?: boolean;
}
interface Props {
  itemsRef: RefObject<RefObject<HTMLElement>[]>;
  addToCartButtonClicked: boolean;
  onValidateSelection(properties: IStoreItemOptionWithSelections[]): void;
  onPropertiesChange(properties: IStoreItemOptionWithSelections[]): void;
}

export const AddOns: React.FC<Props> = ({ itemsRef, addToCartButtonClicked, onValidateSelection, onPropertiesChange }) => {
  const { properties: defaultProperties } = useItemFormContext();
  const [properties, setProperties] = useState<SelectableStoreItem[]>([]);

  const filteredProperties = properties
    .filter(
      ({ property }) =>
        (!property?.validity || StoreItemValidityEnum.MISSING_ITEM !== property?.validity) &&
        !(property?.selections?.length === 0 && StoreItemTypeEnum.ITEM_BASE_GROUP !== property?.selections[0]?.type),
    )
    .filter((item) => !item.isHidden);

  useEffect(() => {
    if (defaultProperties) {
      const mappedProperties = defaultProperties.map((property) => {
        const selections = property?.selections?.map((selection) => {
          if (StoreItemTypeEnum.ITEM_BASE_GROUP === selection.type && selection.selected) {
            selection.price = selection.properties.find(({ selected }) => selected)?.price ?? 0;
          }
          return selection;
        });
        return { ...property, selections };
      });
      setProperties(mappedProperties);
    }
  }, [defaultProperties]);

  const handleUpdateProperty = (propIndex: number) => (updatedProperty: IStoreItemOptionSelection[]) => {
    const updatedProperties = filteredProperties.map((item, index) => {
      if (propIndex === index) {
        return { ...item, selections: updatedProperty, selected: updatedProperty.some((prop) => prop.selected) };
      }

      return item;
    });

    const combinedProperties = defaultProperties.map((property) => {
      const foundInUpdated = updatedProperties.find((updProp) => updProp.uid === property.uid);
      return foundInUpdated ? { ...foundInUpdated } : property;
    });
    onValidateSelection(combinedProperties);
    onPropertiesChange(combinedProperties);
    setProperties(updatedProperties);
  };

  const currentItemRef = itemsRef.current ?? [];

  return (
    <>
      {filteredProperties?.map((property, index) => (
        <div key={index} className={`add-ons--option ${property.changed ? 'changed' : ''}`}>
          <AddOnItem
            itemRef={(currentItemRef[index] = createRef())}
            clicked={addToCartButtonClicked}
            property={property}
            onSelectionsUpdate={handleUpdateProperty(index)}
          />
        </div>
      ))}
    </>
  );
};
