import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Separator } from '@webstore-monorepo/ui/separator';
import type { FC } from 'react';
import React from 'react';
import { isIOS, isSafari } from 'react-device-detect';

import { useFeatureFlags } from '../../contexts/feature-flags-provider';
import { BlockTitle } from '../block-title';
import { ApplePayButton } from './components/ApplePayButton';
import { GooglePayButton } from './components/GooglePayButton';

export type ExpressCheckoutProps = {
  onPayClick: () => Promise<void>;
  onStopLoading: () => void;
  isPaymentLoading?: boolean;
};
export const ExpressCheckout: FC<ExpressCheckoutProps> = ({ onPayClick, onStopLoading, isPaymentLoading }) => {
  const { isMobile } = useWindowDimensions();
  const { isApplePayEnabled, isGooglePayEnabled } = useFeatureFlags();

  return (isApplePayEnabled && (isIOS || isSafari)) || isGooglePayEnabled ? (
    <Box testID="express-checkout-wrapper">
      <BlockTitle>Express checkout</BlockTitle>
      <Box flexDirection={isMobile ? 'column' : 'row'} mb={4}>
        {isApplePayEnabled && (isIOS || isSafari) ? (
          <ApplePayButton
            onPayClick={onPayClick}
            isPaymentLoading={isPaymentLoading}
            onStopLoading={onStopLoading}
            boxProps={{
              marginRight: isMobile ? 'none' : 8,
              marginBottom: isMobile ? 8 : 'none',
            }}
          />
        ) : null}
        {isGooglePayEnabled ? <GooglePayButton isPaymentLoading={isPaymentLoading} onPayClick={onPayClick} onStopLoading={onStopLoading} /> : null}
      </Box>
      <Separator />
    </Box>
  ) : null;
};
