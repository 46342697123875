import type { IOrderGiftCardElement } from '@goparrot/giftcards-sdk';
import { ItemCounter } from '@webstore-monorepo/features/item-counter';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { formatPrice } from '@webstore-monorepo/shared/utils/format-strings';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { LazyImage } from '@webstore-monorepo/ui/lazy-image';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Platform, Pressable } from 'react-native';

interface Props {
  isReadOnly?: boolean;
  showQuantity?: boolean;
  showPrice?: boolean;
  cards: IOrderGiftCardElement[];
  hasTopBorder?: boolean;
  onRemoveGiftCard?: (cardId: string) => void;
  onUpdateQuantity?: (cardId: string, qty: number) => void;
  onGiftCardEdit?: (uid: string) => void;
}

const getFontSize = (amount: number): keyof Theme['fontSizes'] => {
  if (amount < 99) return 'xxxl';
  if (amount < 9999) return 'xl';
  if (amount < 99999) return 'l';
  return 'm';
};

export const GiftCardsList: React.FC<Props> = ({
  cards,
  showPrice = true,
  showQuantity = true,
  onRemoveGiftCard,
  onUpdateQuantity,
  onGiftCardEdit,
  isReadOnly,
}) => {
  const { intl } = usePlatformStoreState();
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const cartOptions = isMobile && Platform.OS === 'web' ? cartScreen?.wrapperMobile : cartScreen?.wrapper;

  const handleRemoveItem = (cardId: string) => () => {
    onRemoveGiftCard?.(cardId);
  };

  const handleEditItem = (card: IOrderGiftCardElement) => () => {
    onGiftCardEdit?.(card.uuid);
  };

  const handleChangeQuantity = (cardId: string) => (qty: number) => {
    onUpdateQuantity?.(cardId, qty);
  };

  return (
    <>
      {cards.map((card) => (
        <Box key={card.uuid} borderBottomWidth={1} borderColor="gray100" {...cartOptions?.items?.separator?.style}>
          <Box paddingTop={4} paddingBottom={4} flexDirection="row">
            <Pressable
              accessibilityLabel="Edit item"
              accessibilityHint="press on the gift card to edit it"
              accessibilityRole="button"
              onPress={handleEditItem(card)}
              style={{ alignSelf: 'flex-start' }}
            >
              <Box
                marginRight={4}
                width={70}
                height={70}
                alignItems="flex-start"
                justifyContent="center"
                position="relative"
                overflow="hidden"
                {...cartOptions?.items?.image?.style}
              >
                {/*@ts-expect-error allow string value for width*/}
                <Box height={cartOptions?.items?.image?.style?.height || 70} width={Platform.OS === 'web' ? 'max-content' : '100%'} backgroundColor="gray500">
                  <LazyImage
                    style={{ flex: 1 }}
                    height={cartOptions?.items?.image?.style?.height || 70}
                    accessibilityLabel="gift card"
                    accessibilityHint="your order item thumbnail"
                    src={card.image}
                    alt="gift card"
                    resizeMode="cover"
                  />
                </Box>
                <Box
                  position="absolute"
                  width={cartOptions?.items?.image?.style?.width || 70}
                  height={cartOptions?.items?.image?.style?.height || 70}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="white" fontSize={getFontSize(card.load / 100)}>
                    {formatPrice(card.load / 100, true, '$', 0)}
                  </Text>
                </Box>
              </Box>
            </Pressable>
            <Box flex={1}>
              <Box marginBottom={1}>
                <Box marginBottom={1}>
                  <Pressable
                    accessibilityLabel="Edit item"
                    accessibilityHint="press on the gift card to edit it"
                    accessibilityRole="button"
                    onPress={handleEditItem(card)}
                    style={{ alignSelf: 'flex-start', marginBottom: 1 }}
                  >
                    <Text color="text" lineHeight={24} fontSize="m" fontWeight="700" {...cartOptions?.items?.title?.style}>
                      {formatPrice(card.load / 100, true, '$', 0)} Gift Card
                    </Text>
                  </Pressable>
                </Box>
                {isReadOnly && (
                  <Text marginBottom={2} color="text" {...cartOptions?.items?.quantity?.style}>
                    {cartOptions?.items?.quantity?.options?.text || 'Qty'}: {card.quantity}
                  </Text>
                )}
                {showPrice && !isReadOnly ? (
                  <Text
                    color="text"
                    accessibilityLabel={`Item price ${card.load / 100}`}
                    lineHeight={24}
                    fontSize="m"
                    marginBottom={1}
                    {...cartOptions?.items?.price?.style}
                  >
                    {intl.formatNumber(card.load / 100)}
                  </Text>
                ) : null}

                {card.delivery ? (
                  <Box>
                    <Text color="text" lineHeight={24} fontSize="s" marginBottom={1} {...cartOptions?.items?.text?.style}>
                      <Text color="text" lineHeight={24} fontSize="s" fontWeight="700" {...cartOptions?.items?.subTitle?.style}>
                        Name:{' '}
                      </Text>
                      {card.delivery.name}
                    </Text>
                    <Text color="text" lineHeight={24} fontSize="s" marginBottom={1} {...cartOptions?.items?.text?.style}>
                      <Text color="text" lineHeight={24} fontSize="s" fontWeight="700" {...cartOptions?.items?.subTitle?.style}>
                        Email:{' '}
                      </Text>
                      {card.delivery.email}
                    </Text>
                  </Box>
                ) : null}
              </Box>

              {!isReadOnly && (
                <Box flexDirection="row" alignItems="center">
                  {showQuantity ? (
                    <Box>
                      <ItemCounter elementMin={1} onChange={handleChangeQuantity(card.uuid)} value={card.quantity} />
                    </Box>
                  ) : (
                    <Box>
                      <ItemCounter disabled={true} elementMin={1} onChange={() => console.log('onChange')} value={card.quantity} />
                    </Box>
                  )}

                  <Box marginLeft={4} aria-label={`Item total ${card.price / 100}`}>
                    <Box flexDirection="row">
                      <Text
                        lineHeight={24}
                        fontSize="m"
                        marginRight={2}
                        textDecorationLine={card.discount.amount ? 'line-through' : undefined}
                        {...cartOptions?.items?.price?.style}
                        color={card.discount.amount ? 'gray500' : cartOptions?.items?.price?.style?.color || 'gray900'}
                      >
                        {card.discount.amount ? intl.formatNumber((card.load * card.quantity) / 100) : intl.formatNumber(card.price / 100)}
                      </Text>
                      {card.discount.amount ? (
                        <Text lineHeight={24} fontSize="m" {...cartOptions?.items?.price?.style}>
                          {intl.formatNumber(card.price / 100)}
                        </Text>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box marginLeft={1}>
              {isReadOnly ? (
                <Box alignItems="flex-end">
                  {card.discount.amount ? (
                    <Text color="text" lineHeight={24} fontSize="m" {...cartOptions?.items?.price?.style}>
                      {intl.formatNumber(card.price / 100)}
                    </Text>
                  ) : null}
                  <Text
                    lineHeight={24}
                    fontSize="m"
                    textDecorationLine={card.discount.amount ? 'line-through' : undefined}
                    {...cartOptions?.items?.price?.style}
                    color={card.discount.amount ? 'gray500' : cartOptions?.items?.price?.style?.color || 'gray900'}
                  >
                    {card.discount.amount ? intl.formatNumber((card.load * card.quantity) / 100) : intl.formatNumber(card.price / 100)}
                  </Text>
                </Box>
              ) : (
                <Button
                  accessibilityLabel="Remove item from cart"
                  buttonStyle={{
                    alignItems: 'flex-start',
                  }}
                  color="gray400"
                  variant="link"
                  onPress={handleRemoveItem(card.uuid)}
                  leftIcon={<CloseIcon stroke="gray900" width={14} height={14} strokeWidth={1.5} />}
                />
              )}
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};
