import { OrderGratuityTypeEnum } from '@goparrot/common';
import type { State, GratuityInitState } from './types';
import { AmountTypeEnum } from './types';

export const defaultGratuityValues: Record<OrderGratuityTypeEnum, number[]> = {
  [OrderGratuityTypeEnum.PERCENT]: [15, 20, 25],
  [OrderGratuityTypeEnum.FIX]: [1, 2, 3],
};
export const defaultGratuityType = OrderGratuityTypeEnum.PERCENT;

export const getGratuityState = (options: GratuityInitState): State => {
  const { cartGratuityAmount, cartGratuityType, defaultGratuity, originGratuityType }: GratuityInitState = options;

  if (cartGratuityAmount && cartGratuityType !== originGratuityType) {
    return {
      value: cartGratuityAmount,
      type: AmountTypeEnum.CUSTOM,
    };
  }

  const definedAmount = isNaN(cartGratuityAmount ?? 0) ? defaultGratuity?.amount : cartGratuityAmount;

  if (isNaN(Number(definedAmount))) return {};

  if (definedAmount === 0) {
    return {
      value: 0,
      type: AmountTypeEnum.NO_TIP,
    };
  }

  const isPreset: boolean = (defaultGratuity?.range || defaultGratuityValues[originGratuityType]).includes(definedAmount ?? 0);

  return {
    value: definedAmount,
    type: isPreset ? AmountTypeEnum.PRE_SET : AmountTypeEnum.CUSTOM,
  };
};
