export const enum Analytics {
  profile_opened = 'profile_opened',
  account_my_loyalty_click = 'account_my_loyalty_click',
  cart_opened = 'cart_opened',
  account_login_click = 'account_login_click',
  select_dining_option = 'select_dining_option',
  select_delayed_info = 'select_delayed_info',
  modal_back_button_click = 'modal_back_button_click',
  order_to_table_set_actions = 'order_to_table_set_actions',
  order_setup_page_set_actions = 'order_setup_page_set_actions',
  dining_options_page_set_actions = 'dining_options_page_set_actions',
  delivery_address_error = 'delivery_address_error',
  order_confirmation_page_set_actions = 'order_confirmation_page_set_actions',
}
