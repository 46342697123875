import React from 'react';

export interface ImageProps {
  alt: string;
  src?: string;
  lazyload?: boolean;
  className?: string;
  srcSet?: string;
  dataSrc?: string;
  dataSrcSet?: string;
  loading?: 'eager' | 'lazy';
  title?: string;
}

export const Image: React.FC<ImageProps> = (props) => {
  const { alt, src, lazyload, className, srcSet, dataSrc, dataSrcSet, loading = 'eager', title } = props;

  return (
    <img
      alt={alt}
      title={title}
      src={src}
      srcSet={srcSet}
      data-src={dataSrc}
      data-srcset={dataSrcSet}
      className={`${className ?? ''} ${lazyload ? 'lazyload' : ''}`}
      onDragStart={(e) => e.preventDefault()}
      loading={loading}
    />
  );
};
