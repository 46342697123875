import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import type { PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useReducer } from 'react';

import type { AppState, SSRContext } from '../../interfaces/AppState';
import { getWaterMark } from '../../utils/watermark';

type Action = { type: 'update'; payload: Partial<AppState> } | { type: 'logout' };
type Dispatch = (action: Action) => void;

export const AppStateContext = React.createContext<AppState>({ webMerchant: window.SSRContext?.webMerchant });
export const AppDispatchContext = React.createContext<Dispatch>(() => void 0);

interface Props {
  initial: SSRContext;
}

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'logout': {
      const stateCopy = { ...state };
      delete stateCopy.loyaltyBase;
      return { ...stateCopy };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

export const AppContextProvider: React.FC<PropsWithChildren<Props>> = ({ initial, children }) => {
  const [state, dispatch] = useReducer(reducer, { webMerchant: window.SSRContext.webMerchant });
  const userContext = useUserContextState();

  const handleDispatch = useCallback((action: Action) => {
    dispatch(action);
  }, []);

  useEffect(() => {
    const handleUpdateAppContext = () => {
      if (userContext) {
        const { featuresFlags: storeFeatureFlags } = initial.newStore;
        const appContext: AppState = {
          storePermissions: initial.storePermissions ?? [],
          merchantFeatureFlags: initial.merchantFeatureFlags,
          soldOutWatermark: getWaterMark(initial.webstore.metadata?.menu?.soldOutWatermark),
          webMerchant: initial.webMerchant,
          isOrderThrottlingEnabled: !!initial.merchantFeatureFlags?.isOrderThrottlingEnabled && !!storeFeatureFlags?.isOrderThrottlingEnabled,
          isDynamicMenuEnabled: true,
          isMinMaxRelativeToGrossTotal: !!initial.merchantFeatureFlags?.isMinMaxRelativeToGrossTotal && !!storeFeatureFlags?.isMinMaxRelativeToGrossTotal,
          hideDelayedOrderTime: initial.webstore.metadata?.hideDelayedOrderTime,
          hidePromotionBar: initial.webstore.metadata.hidePromotionBar,
        };

        handleDispatch({ type: 'update', payload: { ...appContext } });
      }
    };

    handleUpdateAppContext();
  }, [userContext]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={handleDispatch}>{state && children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

export const useAppContextState = () => {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppContextState must be used within a AppContextProvider');
  }
  return context;
};

export const useAppContextDispatch = () => {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppContextDispatch must be used within a AppContextProvider');
  }
  return context;
};
