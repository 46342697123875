import styled from 'styled-components';

import { media } from '../../shared/utils/media-queries';

export const LoaderWrapper = styled.div`
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Price = styled.span`
  display: inline;

  &:before {
    content: '–';
    margin: 0 5px;
  }
`;

export const Notes = styled.div`
  margin-top: 20px;
  padding-bottom: 25px;

  input {
    margin: 0 0 15px 0;
    width: 100%;
    height: 35px;
    padding: 0 5px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid lightgrey;
  }
`;
export const OptionTitle = styled.h4`
  font-size: 18px;
  ${media.tablet} {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
  }
`;

export const OptionSubtitle = styled.h5`
  margin: 10px 0 20px 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: flex-end;
  align-items: center;
  height: 43px;
  > button {
    min-height: 35px;
    height: 35px;
    padding: 0;
    font-size: 12px;
  }
`;

export const QuantityBox = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const Quantity = styled.span`
  margin-right: 5px;
  align-self: center;
  display: none;
  ${media.tablet} {
    display: initial;
    color: gray;
    font-size: 14px;
    height: 43px;
    line-height: 43px;
    margin-right: 10px;
  }
`;

export const QuantityInputBox = styled.div`
  margin-right: 5px;
  width: 70px;
  ${media.tablet} {
    align-self: center;
    margin-right: 10px;
    width: 80px;
    font-size: 16px;
  }

  input {
    border: 1px solid lightgrey;
    border-radius: 5px;
  }
`;

export const Schedule = styled.div`
  background: var(--item-modal-schedule-bg);
  text-align: left;
  p {
    color: var(--item-modal-schedule-color);
    font-size: var(--item-modal-schedule-fontSize);
    font-weight: var(--item-modal-schedule-fontWeight);
    font-family: var(--item-modal-schedule-fontFamily);
  }
`;
