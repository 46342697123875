import type { IReadPromotionCategoryMustBuyConditionDto, IReadPromotionItemMustBuyConditionDto } from '@goparrot/promotions-sdk';
import { PromotionMustBuyConditionTypeEnum } from '@goparrot/promotions-sdk';
import type { ICategory, INestedStoreItemGroup, IStoreItem, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { StoreItemTypeEnum } from '@goparrot/storeitems-sdk';
import { useWebStoreItems } from '@webstore-monorepo/shared/api/cart-api';
import isArray from 'lodash/isArray';
import { useCallback } from 'react';

import { MENU_PATHS } from '../../../pages/paths';
import { useAppContextDispatch, useAppContextState } from '../../contexts/app-context-provider';
import { useNotification } from '../../contexts/notification-provider';
import type { PromotionRouteParam } from '../../services';
import { getIntlString } from '../../utils/intl';
import { useAnalytics } from '../use-analytics';
import { useAppHistory } from '../use-app-history';

interface ShowItemProps {
  item: IStoreItemWithOptions | INestedStoreItemGroup;
  withPromo?: boolean;
  fromTopSelling?: boolean;
  fromRecommendedItems?: boolean;
  quantity?: number;
}

export const useStoreStateItemUtils = () => {
  const analytics = useAnalytics();
  const history = useAppHistory();
  const appState = useAppContextState();
  const appStateDispatch = useAppContextDispatch();
  const notify = useNotification();
  const { selectedMenuConceptUniqueNames } = appState;
  const { data } = useWebStoreItems({ selectedMenuConceptUniqueNames });

  const handleShowItemNew = useCallback(
    ({ item, withPromo, fromTopSelling, fromRecommendedItems, quantity }: ShowItemProps) => {
      let selectedItemGroups = [];

      const getItemGroupUniqueName = () => {
        if (appState.selectedItemGroups?.length) {
          const [last] = appState.selectedItemGroups.slice(-1);
          return last.uniqueName;
        }
      };
      if (item.type === StoreItemTypeEnum.STORE_ITEM_GROUP) {
        if (item?.items.some((storeItem: any) => storeItem.type === StoreItemTypeEnum.STORE_ITEM_GROUP)) {
          selectedItemGroups = [item];
        } else {
          selectedItemGroups = [...(isArray(appState.selectedItemGroups) ? [...appState.selectedItemGroups, item] : [item])];
        }
        appStateDispatch({ type: 'update', payload: { selectedItemGroups } });

        history.push(MENU_PATHS.item_group.replace(':itemUniqueName', item.uniqueName), {
          withPromo,
          fromStoreItemGroups: true,
          fromRecommendedItems,
        });
        return;
      }

      if ([StoreItemTypeEnum.STORE_ITEM_BUNDLE, StoreItemTypeEnum.COMBO].includes(item.type)) {
        if (!item.isAvailable) {
          notify.error(getIntlString('item.notification.soldOut'));
          return;
        }

        const itemParams: any = {
          type: item.type,
          comboUniqueName: item.uniqueName,
          fromStoreitemGroups: getItemGroupUniqueName(),
          fromRecommendedItems,
        };

        history.push(MENU_PATHS.add_combo.replace(':comboUniqueName', item.uniqueName), itemParams);
        return;
      }

      const itemParams: any = {
        fromStoreItemGroups: getItemGroupUniqueName(),
        withPromo,
        fromTopSelling,
        fromRecommendedItems,
        quantity,
      };

      history.push(MENU_PATHS.item_modal.replace(':itemUniqueName', item.uniqueName).replace(':editable', ''), itemParams);
    },
    [appState.selectedItemGroups, appStateDispatch, history, notify],
  );

  const handleShowItem = useCallback(
    ({ event, item, fromTopSelling, withPromo }: { item: any; event: any; withPromo?: PromotionRouteParam | null; fromTopSelling?: boolean }) => {
      const showAddonsFirst = event.target.className !== 'store-item__info-icon';

      item.isValid = true;

      const getActionNameSelectedItem = (fromTopSelling: boolean, showAddonsFirst: boolean) => {
        if (fromTopSelling) {
          return showAddonsFirst ? 'top_selling_item_select' : 'top_selling_item_description';
        }
        return showAddonsFirst ? 'item_select' : 'item_description';
      };

      analytics.track(getActionNameSelectedItem(!!fromTopSelling, showAddonsFirst), { uid: item.uniqueName });
      let selectedItemGroups = [];
      if (item.type === StoreItemTypeEnum.STORE_ITEM_GROUP) {
        if (item?.items.some((storeItem: any) => storeItem.type === StoreItemTypeEnum.STORE_ITEM_GROUP)) {
          selectedItemGroups = [item];
        } else {
          selectedItemGroups = [...(isArray(appState.selectedItemGroups) ? [...appState.selectedItemGroups, item] : [item])];
        }
        appStateDispatch({ type: 'update', payload: { selectedItemGroups } });

        history.push(MENU_PATHS.item_group.replace(':itemUniqueName', item.uniqueName), {
          withPromo,
          fromStoreItemGroups: true,
        });
        return;
      }

      const getItemGroupUniqueName = () => {
        if (appState.selectedItemGroups?.length) {
          const [last] = appState.selectedItemGroups.slice(-1);
          return last.uniqueName;
        }
      };

      if ([StoreItemTypeEnum.STORE_ITEM_BUNDLE, StoreItemTypeEnum.COMBO].includes(item.type)) {
        if (!item.isAvailable) {
          notify.error(getIntlString('item.notification.soldOut'));
          return;
        }

        const itemParams: any = {
          type: item.type,
          comboUniqueName: item.uniqueName,
          fromStoreitemGroups: getItemGroupUniqueName(),
        };

        history.push(MENU_PATHS.add_combo.replace(':comboUniqueName', item.uniqueName), itemParams);
        return;
      }

      const itemParams: any = {
        fromStoreItemGroups: getItemGroupUniqueName(),
        withPromo,
        fromTopSelling,
      };

      history.push(MENU_PATHS.item_modal.replace(':itemUniqueName', item.uniqueName).replace(':editable', ''), itemParams);
    },
    [analytics, appState.selectedItemGroups, appStateDispatch, history, notify],
  );

  const handleGetStoreItemTitle = useCallback(
    ({ type, uuid }: IReadPromotionItemMustBuyConditionDto | IReadPromotionCategoryMustBuyConditionDto): string => {
      if (PromotionMustBuyConditionTypeEnum.CATEGORY === type) {
        return data?.categories.find((category: ICategory) => category.uniqueName === uuid)?.title ?? '';
      }
      let title = '';
      data?.storeItemsByCategory.some((category: ICategory | { items: IStoreItem[] }) => {
        // @ts-ignore
        title = category.items?.find((storeItem: IStoreItem) => storeItem.uniqueName === uuid)?.title;
        return title;
      });

      return title;
    },
    [data?.categories, data?.storeItemsByCategory],
  );

  return {
    handleShowItem,
    handleShowItemNew,
    handleGetStoreItemTitle,
  };
};
