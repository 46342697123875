import type { IFontStylesApp, IShapeStylesApp, IWebMerchantThemeColorsDto } from '@goparrot/webstore-sdk';
import { Box } from '@webstore-monorepo/ui/box';
import { UiCloseBtn } from '@webstore-monorepo/ui/close-btn';
import { Text } from '@webstore-monorepo/ui/text';
import type { PropsWithChildren } from 'react';
import React from 'react';

export type UiSidebarHeaderProps = {
  onClose: () => void;
  showIcon?: boolean;
  iconColor?: keyof IWebMerchantThemeColorsDto;
  wrapperStyle?: IShapeStylesApp;
  closeButtonStyles?: IShapeStylesApp;
  titleStyles?: IFontStylesApp;
};

export const UiSidebarHeader = ({
  iconColor,
  onClose,
  showIcon,
  wrapperStyle,
  titleStyles,
  closeButtonStyles,
  children,
}: PropsWithChildren<UiSidebarHeaderProps>) => {
  return (
    <Box
      testID="ui-sidebar-header"
      paddingBottom={5}
      paddingTop={5}
      paddingLeft={4}
      paddingRight={4}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      {...wrapperStyle}
    >
      <UiCloseBtn onPress={onClose} showIcon={showIcon} iconColor={iconColor} buttonStyle={closeButtonStyles} />
      <Box flex={1} flexBasis="auto">
        <Text fontWeight="bold" lineHeight={32} textAlign="center" fontSize="xxl" {...titleStyles} testID="ui-sidebard-title">
          {children}
        </Text>
      </Box>
      {/*This is so that the text is centered, otherwise the close button is holding space and the text is not centered*/}
      <Box width={44} height={1} />
    </Box>
  );
};
