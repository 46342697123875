import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { InfoTooltip } from '@webstore-monorepo/ui/info-tooltip';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

interface OrderTotalRowProps {
  title: string | React.ReactElement;
  amount: string | React.ReactElement;
  tooltip?: string | React.ReactElement;
  fontSize?: keyof Theme['fontSizes'];
  bold?: boolean;
}

export const OrderTotalRow: React.FC<OrderTotalRowProps> = ({ title, amount, tooltip, fontSize, bold = false }) => {
  return (
    <Box flexDirection="row" justifyContent="space-between" marginBottom={2}>
      <Box flexDirection="row" justifyContent="flex-start">
        <Text fontWeight={bold ? 'bold' : 'normal'} fontSize={fontSize}>
          {title}
        </Text>
        {tooltip ? <InfoTooltip tooltip={tooltip} /> : null}
      </Box>
      <Box>
        <Text fontWeight={bold ? 'bold' : 'normal'} fontSize={fontSize}>
          {amount}
        </Text>
      </Box>
    </Box>
  );
};
