import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React from 'react';
import { Pressable } from 'react-native';

type SubtitleProps = { isReadOnly?: boolean; isMobile: boolean; onChangeTable?: () => void };

export const Subtitle: FC<SubtitleProps> = ({ isReadOnly, isMobile, onChangeTable }) => (
  <Text paddingLeft={isMobile ? 4 : 10} color="gray500" lineHeight={22}>
    The order will be brought to the table soon.{' '}
    {!isReadOnly ? (
      <Pressable
        accessibilityLabel="change table number"
        accessibilityHint="press the button to change order table number"
        accessibilityRole="button"
        testID="order-to-table-change-table"
        onPress={onChangeTable}
      >
        <Text color="primaryButtonColor">Change table</Text>
      </Pressable>
    ) : null}
  </Text>
);
