import { createSvgIcon } from '@webstore-monorepo/shared/icons';

export const RedeemRewardsIcon = createSvgIcon({
  iconConfig: {
    paths: [
      'M12 3H11V5H12C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12V11H3V12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z',
      'M8.7621 5.79203L9.6483 5.32873L8.7217 3.55632L7.8355 4.01962C7.46679 4.21238 7.11322 4.42987 6.77697 4.66984C6.36991 4.96035 5.98817 5.28386 5.63562 5.63646C5.2827 5.98943 4.95893 6.37164 4.66824 6.77921C4.49331 7.02448 4.33035 7.27895 4.18022 7.54178C4.12626 7.63624 4.07397 7.73177 4.02337 7.82834L3.55927 8.71413L5.33084 9.64232L5.79494 8.75654C5.83426 8.68148 5.87491 8.60722 5.91686 8.53378C6.0336 8.32942 6.16037 8.13144 6.29653 7.94053C6.52281 7.62326 6.77497 7.32557 7.04992 7.05058C7.32459 6.77587 7.62192 6.52392 7.93879 6.29778C8.20052 6.11099 8.47554 5.94184 8.7621 5.79203Z',
      'M14.4472 16L13.9756 13.1125L16 11.0184L13.2033 10.5849L12 8L10.7967 10.5849L8 11.0184L10.0244 13.1125L9.55285 16L12 14.6421L14.4472 16Z',
    ],
    viewBox: '0 0 24 24',
  },
  width: 24,
  height: 24,
});
