import type { UseQueryResult } from '@webstore-monorepo/shared/contexts/react-query-provider';
import { useQuery } from '@webstore-monorepo/shared/contexts/react-query-provider';

import axios from '../api/setup';
import { QueriesKeysEnum } from './queries-keys-enum';

const { merchantId } = window;
export const getMobileApps = async (): Promise<[]> => await axios.get(`${window.WEBSTORE_GATEWAY_URL}/api/v2/merchants/${merchantId}/mobile-apps`);

export const useHasMobileApps = (): UseQueryResult<[]> => {
  return useQuery([QueriesKeysEnum.mobileApps], getMobileApps, { staleTime: Infinity });
};
