import { UserTypeEnum } from '@goparrot/customer-sdk';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { useGetFeatureFlag } from '@webstore-monorepo/shared/hooks/use-get-feature-flag';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import { NewUserForm } from '@webstore-monorepo/ui/user-form';
import React from 'react';

import { useOrder } from '../../contexts/order-provider';
import { BlockTitle } from '../block-title';

export type GuestCheckoutFormBlockType = {
  isLoading: boolean;
  onPressSignIn: () => void;
};
export const GuestCheckoutFormBlock = ({ isLoading, onPressSignIn }: GuestCheckoutFormBlockType) => {
  const { user } = useUserContextState();
  const isGuestCheckoutEnabled = useGetFeatureFlag('isGuestCheckoutEnabled');
  const { guestFormRef, guestFormBlockRef } = useOrder();

  return isGuestCheckoutEnabled && user.type !== UserTypeEnum.AUTHENTICATED ? (
    <Box ref={guestFormBlockRef}>
      <Box>
        <BlockTitle>Contact information</BlockTitle>
        <Box flexDirection="row">
          <Text mr={1}>Have an account?</Text>
          <Button onPress={onPressSignIn} variant="link">
            Sign in
          </Button>
        </Box>
      </Box>
      <Box>
        <NewUserForm
          ref={guestFormRef}
          disabled={isLoading}
          showPhoneNumberInput
          user={{
            phoneNumber: user.profile?.phone_number ?? '',
            formattedPhoneNumber: user.profile?.phone_number ?? '',
            email: user.profile?.email ?? '',
            firstName: user.profile?.first_name ?? '',
            lastName: user.profile?.last_name ?? '',
          }}
          showErrors
          autoFocus={false}
          formikConfig={{
            validateOnMount: false,
            validateOnBlur: true,
            validateOnChange: false,
          }}
        />
      </Box>
      <Separator mt={8} />
    </Box>
  ) : null;
};
