import { DiningOptionEnum } from '@goparrot/common';
import type { IOrder } from '@goparrot/order-sdk';

export interface IOrderToTableInfo {
  tableNumber?: number;
  isOrderToTable: boolean;
  diningOption?: DiningOptionEnum;
}

export function getOrderToTableInfo($rootScope: any, cart?: IOrder): IOrderToTableInfo {
  const {
    store: { diningOptionsConfig },
    availableDiningOptions,
  } = $rootScope;

  const isDineInAvailable = availableDiningOptions?.includes(DiningOptionEnum.DINE_IN) && diningOptionsConfig?.[DiningOptionEnum.DINE_IN]?.isEnabled;

  if (isDineInAvailable) {
    const urlParams = new URLSearchParams(window.location.search);
    const tableNumber = urlParams.get('tableNumber');
    const tableNumberFromCart = cart?.diningOptionInfo?.details?.tableNumber;
    const diningOption = urlParams.get('diningOption');
    const areDineInParamsValid = tableNumber && DiningOptionEnum.DINE_IN === diningOption;

    if (areDineInParamsValid || tableNumberFromCart) {
      return {
        diningOption: DiningOptionEnum.DINE_IN,
        isOrderToTable: true,
        tableNumber: tableNumber || tableNumberFromCart,
      };
    }
  }

  return {
    isOrderToTable: false,
  };
}
