import styled from 'styled-components';

import { media } from '../../../../shared/utils/media-queries';

export const Wrapper = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
  }
`;
export const MobileWrapper = styled.div`
  display: block;
  ${media.tablet} {
    display: none;
  }
`;
export const Preview = styled.div`
  display: block;
  margin-bottom: 20px;
`;
export const Title = styled.div`
  margin-bottom: 10px;
`;

export const Ingredients = styled.p`
  color: var(--item-ingredients-color);
  font-family: var(--item-ingredients-ff);
  font-size: var(--item-ingredients-font-size);
  font-weight: var(--item-ingredients-font-weight);
  font-style: var(--item-ingredients-font-style);
  text-transform: var(--item-ingredients-text-transform);
  line-height: 1.8em;
`;
