import type { Theme } from '@webstore-monorepo/shared/theme';

export const squareIframeCustomStyles = (config: Theme) => ({
  '.input-container': {
    borderRadius: '4px',
  },
  '.input-container.is-focus': {
    borderColor: config.colors?.primary,
    borderWidth: '1px',
  },
});
