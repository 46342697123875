import type { OrderGratuityTypeEnum } from '@goparrot/common';

export enum AmountTypeEnum {
  CUSTOM = 'custom',
  NO_TIP = 'no_tip',
  PRE_SET = 'preset',
}

export interface State {
  value?: number;
  type?: AmountTypeEnum;
}

export interface GratuityInitState {
  cartGratuityAmount?: number;
  cartGratuityType?: OrderGratuityTypeEnum;
  defaultGratuity?: DefaultTip;
  originGratuityType: OrderGratuityTypeEnum;
}

export interface DefaultTip {
  type?: OrderGratuityTypeEnum;
  amount?: number;
  range?: Array<number>;
}
