import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { NumberInput } from '@webstore-monorepo/ui/number-input';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';

interface Props {
  value: number;
  onChange: (value: number, metadata: any) => void;
  step?: number;
  elementMin?: number;
  elementMax?: number;
  itemMin?: number;
  itemMax?: number;
  defaultValue?: number;
  metaData?: any;
  className?: string;
  delay?: number;
  disabled?: boolean;
  [key: string]: any;
}

export const ItemCounter: React.FC<Props> = (props) => {
  const { notification } = usePlatformStoreState();
  const { value, metaData, elementMin, elementMax, itemMin, itemMax, delay = 900 } = props;
  const [inputValue, setInputValue] = useState(value);
  const [isReadyToUpdate, setReadyUpdateState] = useState(false);

  const showQuantityAutoChangeNotification = (extreme: string) => {
    notification.error(
      `We’re sorry. The ${metaData.item?.title} has limited purchase quantity. We have changed your purchase quantity to the ${extreme} allowable.`,
    );
  };

  const showMinMaxAchieveNotification = (extreme: string, extremumValue?: number) => {
    notification.error(`Sorry, ${extreme} ${extremumValue} items are allowed.`);
  };

  const onChange = () => {
    let newValue = inputValue;
    if (elementMin && inputValue < elementMin) {
      newValue = elementMin;
      showQuantityAutoChangeNotification('min');
    }
    if (elementMax && inputValue > elementMax) {
      newValue = elementMax;
      showQuantityAutoChangeNotification('max');
    }
    props.onChange(newValue, { ...metaData, oldValue: inputValue, min: elementMin, max: elementMax });
    setInputValue(newValue);
  };

  const delayedUpdate = useCallback(debounce(onChange, delay), [inputValue]);

  const handleChange = (value: number) => {
    setInputValue(value);

    if (!isReadyToUpdate) {
      setReadyUpdateState(true);
    }
  };

  useEffect(() => {
    if (isReadyToUpdate) {
      delayedUpdate();
    }

    // eslint-disable-next-line
    return delayedUpdate?.cancel;
  }, [inputValue, delayedUpdate, isReadyToUpdate]);

  return (
    <NumberInput
      {...props}
      countable
      min={elementMin}
      max={elementMax}
      globalMin={itemMin || elementMin}
      globalMax={itemMax || elementMax}
      value={inputValue}
      onChange={handleChange}
      minMaxAchieveCallback={showMinMaxAchieveNotification}
    />
  );
};
