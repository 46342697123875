import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { UserIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box, InlineBox } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Pressable } from 'react-native';

export type HeaderAccountProps = {
  isAuthenticated: boolean;
  userCapitals?: string;
  onLoginClick: () => void;
  onOpenProfile: () => void;
  onLoginPressEnter: (event: any) => void;
  boxProps?: BoxProps<Theme>;
};
export const HeaderAccount: FC<HeaderAccountProps> = ({ userCapitals, isAuthenticated, onLoginClick, onOpenProfile, boxProps }) => {
  const { isMobile, isTablet, isDesktop, isPortraitMobile } = useWindowDimensions();
  const { header } = useComponentsConfig();
  const ref = useRef();
  const { isHovered } = useHover({}, ref);
  return !(isMobile || isPortraitMobile) ? (
    <Box testID="header-account" {...boxProps}>
      {isAuthenticated ? (
        <Pressable accessibilityLabel="Open User Profile" accessibilityHint="Press to open user profile" accessibilityRole="button" onPress={onOpenProfile}>
          <InlineBox alignItems="center">
            {!isMobile ? (
              <Box
                overflow="hidden"
                alignItems="center"
                justifyContent="center"
                borderRadius="rounded"
                width={30}
                height={30}
                testID="header-account-initials"
                backgroundColor={header?.circleBadge?.style?.backgroundColor}
              >
                <Text {...header?.text?.style} {...header?.circleBadge?.text?.style} fontSize="xs" fontWeight="400" lineHeight={30}>
                  {userCapitals}
                </Text>
              </Box>
            ) : null}
            {!isTablet && isDesktop ? (
              <Text ref={ref} textTransform="capitalize" {...header?.text?.style} {...(isHovered && header?.text?.hover?.style)} marginLeft={1}>
                account
              </Text>
            ) : null}
          </InlineBox>
        </Pressable>
      ) : (
        <Pressable accessibilityLabel="login" accessibilityHint="press to open login page" accessibilityRole="button" onPress={onLoginClick}>
          <InlineBox alignItems="center" testID="header-account-login">
            <InlineBox marginRight={1} borderRadius="rounded" overflow="hidden">
              <UserIcon width={24} height={24} fill={header?.text?.style?.color} stroke="transparent" />
            </InlineBox>
            <Text ref={ref} {...header?.text?.style} {...(isHovered && header?.text?.hover?.style)}>
              log in
            </Text>
          </InlineBox>
        </Pressable>
      )}
    </Box>
  ) : null;
};
