import type { IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import type { PropsWithChildren } from 'react';
import React, { useEffect, useReducer } from 'react';

type Action = { type: 'update'; payload: Partial<IStoreItemWithOptions> };
type Dispatch = (action: Action) => void;

const ItemFormContext = React.createContext<(IStoreItemWithOptions & { menuConceptUuid?: string }) | undefined>(undefined);
const ItemFormDispatchContext = React.createContext<Dispatch>(() => void 0);

interface Props {
  initial?: IStoreItemWithOptions;
}

const reducer = (state: IStoreItemWithOptions, action: Action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const ItemFormProvider: React.FC<PropsWithChildren<Props>> = ({ initial, children }) => {
  const [state, dispatch] = useReducer(reducer, {} as IStoreItemWithOptions);

  useEffect(() => {
    if (initial) dispatch({ type: 'update', payload: { ...initial } });
  }, [initial]);

  const handleDispatch = (action: Action) => {
    dispatch(action);
  };

  return (
    <ItemFormContext.Provider value={state}>
      <ItemFormDispatchContext.Provider value={handleDispatch}>{children}</ItemFormDispatchContext.Provider>
    </ItemFormContext.Provider>
  );
};

export const useItemFormContext = () => {
  const context = React.useContext(ItemFormContext);
  if (context === undefined) {
    throw new Error('useItemFormState must be used within a ItemFormContext');
  }
  return context;
};

export const useItemFormDispatch = () => {
  const context = React.useContext(ItemFormDispatchContext);
  if (context === undefined) {
    throw new Error('useItemFormDispatch must be used within a ItemFormDispatchContext');
  }
  return context;
};
