import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Input } from '@webstore-monorepo/ui/input';
import { Text } from '@webstore-monorepo/ui/text';
import type { ChangeEvent, FC } from 'react';
import React, { useEffect, useState } from 'react';

interface CustomAmountProps {
  amount: number;
  onChange: (amount: number) => void;
}

export const CustomAmount: FC<CustomAmountProps> = ({ onChange, amount }) => {
  const { isMobile } = useWindowDimensions();

  const amountString = amount.toFixed(2);
  const [value, setValue] = useState<string>(amountString);
  const handleInputChange = (e: ChangeEvent) => {
    const sanitizedValue = (e as unknown as string).replace(/,+/g, '.').replace(/[^\d.]/g, '');
    const firstDotIndex = sanitizedValue.indexOf('.');
    const result =
      firstDotIndex === -1
        ? sanitizedValue
        : sanitizedValue.substring(0, firstDotIndex + 1) +
          sanitizedValue
            .substring(firstDotIndex + 1)
            .replace(/\./g, '')
            .slice(0, 2);
    setValue(result);
  };

  useEffect(() => {
    if (value !== '' && amountString !== value) {
      const floatValue = parseFloat(value);
      onChange(Number((floatValue > 0 ? floatValue : 0).toFixed(2)));
    }
  }, [value, amountString, onChange]);

  const handleBlur = () => {
    setValue(value === '' || parseFloat(value) < 0 ? '0.00' : (parseFloat(value) || 0).toFixed(2));
  };

  return (
    <Box flexDirection="row" alignItems="center" mb={4} mr={3} flexGrow={isMobile ? 100 : 0} width={95} testID="custom-tip-wrapper">
      <Box position="relative" left={16} width={0}>
        <Text fontSize="m">$</Text>
      </Box>
      <Input
        backgroundColor="transparent"
        keyboardType="decimal-pad"
        style={{
          height: 44,
          paddingLeft: 26,
          /***
            keyboardType="decimal-pad" removes type="text" attribute from the input.
            Without this attribute, some styles including font-size which is set to
            16px in general.scss are not applied to the input. So without setting
            font-size explicitly to 16px, it is still 14px on mobile, which causes
            the input to be zoomed when focused on iPhone. See #FSMWO-263
           */
          fontSize: isMobile ? 16 : 14,
        }}
        onFocus={() => {
          if (parseFloat(value) === 0) {
            setValue('');
          }
        }}
        accessibilityLabel="please enter a custom tip"
        onBlur={handleBlur}
        onChangeText={handleInputChange}
        value={value}
        testID="custom-tip-input"
      />
    </Box>
  );
};
