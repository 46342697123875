import { PaymentMethodTypeEnum } from '@goparrot/common';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { UiSidebarFooter } from '@webstore-monorepo/ui/sidebar-footer';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import { useOrder, useOrderDispatch } from '../../contexts/order-provider';
import { useValidateGuestForm } from '../../hooks/useValidateGuestForm';

export type FooterProps = {
  isLoading?: boolean;
  isDisabled?: boolean;
  onBack: () => void;
  onProceed: () => Promise<void>;
  onStopLoading: () => void;
};
export const Footer: FC<PropsWithChildren<FooterProps>> = ({ isLoading, isDisabled, onProceed, children, onBack, onStopLoading }) => {
  const { total } = useCartState();
  const { notification } = usePlatformStoreState();
  const { selectedPaymentType } = useOrder();
  const { intl } = usePlatformStoreState();
  const { chargeOrder } = useOrderDispatch();
  const isButtonDisabled = selectedPaymentType?.type === PaymentMethodTypeEnum.CASH ? false : !selectedPaymentType?.last4Digits;
  const { validateGuestForm } = useValidateGuestForm();

  const handleCheckout = async () => {
    try {
      if (validateGuestForm() && selectedPaymentType?.type !== PaymentMethodTypeEnum.CASH && !selectedPaymentType?.last4Digits) {
        notification.error('Please select payment method');
        return;
      }

      if (!isButtonDisabled) {
        await onProceed();
        await chargeOrder();
      }
    } finally {
      onStopLoading();
    }
  };

  return (
    <UiSidebarFooter
      onBack={onBack}
      onCheckout={handleCheckout}
      isDisabled={isButtonDisabled || isDisabled}
      isLoading={isLoading}
      proceedButtonText={`Pay ${intl.formatNumber(total)}`}
    >
      {children}
    </UiSidebarFooter>
  );
};
