import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import { MenuIcon } from '@webstore-monorepo/shared/icons';
import { CircleBadge } from '@webstore-monorepo/ui/badge';
import { InlineBox } from '@webstore-monorepo/ui/box';
import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';

export type ProfileToggleProps = {
  isProfileOpen: boolean;
  showBadge?: boolean;
  onProfileClick: () => void;
};
export const ProfileToggle: React.FC<ProfileToggleProps> = ({ isProfileOpen, showBadge, onProfileClick }) => {
  const [openProfile, setOpenProfile] = useState(false);
  const [wasOpened, setWasOpened] = useState(openProfile);
  const { isMobile, isPortraitMobile } = useWindowDimensions();
  const { header } = useComponentsConfig();

  useEffect(() => {
    if (isProfileOpen) {
      setOpenProfile(true);
      setWasOpened(true);
    } else {
      setOpenProfile(false);
    }
  }, [isProfileOpen]);

  return isMobile || isPortraitMobile ? (
    <Pressable
      testID="profile-toggle-wrap"
      accessibilityLabel={`${openProfile ? 'open' : 'close'} profile`}
      accessibilityHint={`press to ${openProfile ? 'open' : 'close'} profile page`}
      accessibilityRole="button"
      onPress={onProfileClick}
    >
      <InlineBox>
        {openProfile ? (
          <CloseIcon testID="toggle-close-icon" fill={header?.text?.style?.color} stroke="transparent" />
        ) : (
          <>
            <MenuIcon testID="toggle-open-icon" fill={header?.text?.style?.color} stroke="transparent" height={20} width={20} />
            {showBadge && !wasOpened ? <CircleBadge width={15} height={15} /> : null}
          </>
        )}
      </InlineBox>
    </Pressable>
  ) : null;
};
