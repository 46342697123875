import React, { lazy, Suspense } from 'react';
import type { NavigateOptions } from 'react-router/dist/lib/context';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { WrappedInlineLoader } from '../../components/uielements/loaders';
import { APP_PATHS } from '../../routes/paths';
import { useAppContextState } from '../../shared/contexts/app-context-provider';
import { useAppHistory } from '../../shared/hooks/use-app-history';
import { lazyRetry } from '../../shared/utils/lazy-retry';
import { MENU_PATHS } from '../paths';
import { NativeComboItemBody } from './components/NativeComboItemBody';
import type { RouteStateParams } from './hooks/useIsNativeCombo';
import { useIsNativeCombo } from './hooks/useIsNativeCombo';

const ItemBundle = lazy(async () => lazyRetry(async () => import('./item-bundle-combo/ComboWrapper')));
const NativeCombo = lazy(async () => lazyRetry(async () => import('@webstore-monorepo/features/combo')));

const ComboSwitcher = () => {
  const isNativeCombo = useIsNativeCombo();

  if (isNativeCombo === null) {
    return null;
  }

  return <Suspense fallback={<WrappedInlineLoader />}>{isNativeCombo ? <NativeComboWrapper /> : <ItemBundle />}</Suspense>;
};

export const NativeComboWrapper = () => {
  const { comboUniqueName = '' } = useParams<{ comboUniqueName: string }>();
  const { state }: { state: RouteStateParams | null } = useLocation();
  const navigate = useNavigate();
  const history = useAppHistory();
  const { selectedMenuConceptUniqueNames, showAddonsFirst } = useAppContextState();

  const handleCloseModal = () => navigate(APP_PATHS.home);
  const handleItemGroupNavigate = (itemGroupUniqueName: string, routeParams?: NavigateOptions) =>
    navigate(MENU_PATHS.item_group.replace(':itemUniqueName', itemGroupUniqueName), routeParams);
  const handleCartNavigate = () => history.push(MENU_PATHS.cart);

  return (
    <NativeCombo
      showAddonsFirst={showAddonsFirst}
      comboUniqueName={comboUniqueName}
      selectedMenuConceptUniqueNames={selectedMenuConceptUniqueNames}
      routeParams={state ?? {}}
      onCloseModal={handleCloseModal}
      onGoToItemGroup={handleItemGroupNavigate}
      onGoToCart={handleCartNavigate}
      itemFormBody={NativeComboItemBody}
    />
  );
};

export default ComboSwitcher;
