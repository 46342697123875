import type { INestedStoreItemGroup, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { StoreItemVariantDesktopEnum, StoreItemVariantMobileEnum } from '@goparrot/webstore-sdk';
import { MenuItemCard } from '@webstore-monorepo/features/menu-item-card';
import { useTopSellingItems } from '@webstore-monorepo/shared/api/cart-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ArrowLeft } from '@webstore-monorepo/shared/icons';
import { ArrowRight } from '@webstore-monorepo/shared/icons';
import { Box, ScrollableBox } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import { TextGradient } from '@webstore-monorepo/ui/text-gradient';
import type { RefObject } from 'react';
import React, { createRef, forwardRef, useMemo, useRef } from 'react';

export type TopSellingItemsSliderProps = {
  onItemClick: (item: IStoreItemWithOptions | INestedStoreItemGroup) => void;
};
export const TopSellingItemsSlider = forwardRef(({ onItemClick }: TopSellingItemsSliderProps, forwardedRef) => {
  const { data: topSellingItems } = useTopSellingItems();
  const topItemsRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const { isMobile } = useWindowDimensions();
  const { menuList, topSellingItemsSlider, categoryItemsGroup, menuItemCard, menuItemCardMobile } = useComponentsConfig();
  const title = isMobile ? topSellingItemsSlider?.wrapperMobile?.options?.title : topSellingItemsSlider?.wrapper?.options?.title;
  const showImages = (isMobile ? menuList?.wrapperMobile?.options?.showImages : menuList?.wrapper?.options?.showImages) ?? true;

  const getCardDimensions = useMemo((): { width?: number } => {
    const variant = isMobile ? menuItemCardMobile?.wrapper?.options?.variant : menuItemCard?.wrapper?.options?.variant;
    if (variant === StoreItemVariantMobileEnum.THUMBNAIL_TOP || variant === StoreItemVariantDesktopEnum.THUMBNAIL_TOP) {
      return { width: 260 };
    }
    if (variant === StoreItemVariantMobileEnum.STRIP || variant === StoreItemVariantMobileEnum.LEGACY) {
      return { width: 288 };
    }
    if (
      variant === StoreItemVariantDesktopEnum.THUMBNAIL_RIGHT ||
      variant === StoreItemVariantDesktopEnum.THUMBNAIL_LEFT ||
      variant === StoreItemVariantMobileEnum.THUMBNAIL_LEFT ||
      variant === StoreItemVariantMobileEnum.THUMBNAIL_RIGHT
    ) {
      return { width: 370 };
    }
    return { width: 260 };
  }, [isMobile, menuItemCard?.wrapper?.options?.variant, menuItemCardMobile?.wrapper?.options?.variant]);

  return topSellingItems && topSellingItems?.length > 4 ? (
    <Box
      ref={forwardedRef}
      paddingLeft={1}
      paddingRight={1}
      marginTop={4}
      marginBottom={4}
      testID="top-selling-items-section"
      {...categoryItemsGroup?.wrapper?.style}
      {...(isMobile ? categoryItemsGroup?.wrapperMobile?.style : {})}
      {...topSellingItemsSlider?.wrapper?.style}
      {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.style : {})}
    >
      <Box
        {...categoryItemsGroup?.wrapper?.title?.style}
        {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.style : {})}
        {...topSellingItemsSlider?.wrapper?.title?.style}
        {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.title?.style : {})}
        marginBottom={6}
      >
        {(
          isMobile
            ? categoryItemsGroup?.wrapperMobile?.options?.showTitleTextGradient || topSellingItemsSlider?.wrapperMobile?.options?.showTextGradient
            : categoryItemsGroup?.wrapper?.options?.showTitleTextGradient || topSellingItemsSlider?.wrapper?.options?.showTextGradient
        ) ? (
          <TextGradient
            textAlign="center"
            fontSize="xxxl"
            colors={
              isMobile
                ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.colors ||
                  topSellingItemsSlider?.wrapperMobile?.title?.textGradient?.options?.colors
                : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.colors || topSellingItemsSlider?.wrapper?.title?.textGradient?.options?.colors
            }
            start={
              isMobile
                ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.start ||
                  topSellingItemsSlider?.wrapperMobile?.title?.textGradient?.options?.start
                : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.start || topSellingItemsSlider?.wrapper?.title?.textGradient?.options?.start
            }
            end={
              isMobile
                ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.end ||
                  topSellingItemsSlider?.wrapperMobile?.title?.textGradient?.options?.end
                : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.end || topSellingItemsSlider?.wrapper?.title?.textGradient?.options?.end
            }
            {...categoryItemsGroup?.wrapper?.title?.text?.style}
            {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
            {...topSellingItemsSlider?.wrapper?.title?.text?.style}
            {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.title?.text?.style : {})}
            {...categoryItemsGroup?.wrapper?.title?.textGradient?.style}
            {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.style : {})}
            {...topSellingItemsSlider?.wrapper?.title?.textGradient?.style}
            {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.title?.textGradient?.style : {})}
          >
            {title}
          </TextGradient>
        ) : (
          <Text
            fontSize="4xl"
            textAlign="center"
            {...categoryItemsGroup?.wrapper?.title?.text?.style}
            {...topSellingItemsSlider?.wrapper?.title?.text?.style}
            {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
            {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.title?.text?.style : {})}
          >
            {title}
          </Text>
        )}
      </Box>
      <ScrollableBox
        testID="top-selling-items-slider"
        itemRefs={topItemsRefs}
        showArrows
        paddingLeft="m"
        paddingRight="m"
        marginLeft="-s"
        marginRight="-s"
        leftIcon={
          <Box
            justifyContent="center"
            alignItems="center"
            width={48}
            height={48}
            backgroundColor="white"
            borderRadius="rounded"
            {...topSellingItemsSlider?.wrapper?.arrows?.style}
            {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.arrows?.style : {})}
          >
            <ArrowLeft
              width={23}
              height={22}
              testID="top-selling-arrow-left"
              stroke={
                (isMobile ? topSellingItemsSlider?.wrapperMobile?.arrows?.icon?.style?.color : topSellingItemsSlider?.wrapper?.arrows?.icon?.style?.color) ??
                '#000'
              }
              strokeWidth={2}
              {...topSellingItemsSlider?.wrapper?.arrows?.icon?.style}
              {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.arrows?.icon?.style : {})}
            />
          </Box>
        }
        rightIcon={
          <Box
            justifyContent="center"
            alignItems="center"
            width={48}
            height={48}
            backgroundColor="white"
            borderRadius="rounded"
            {...topSellingItemsSlider?.wrapper?.arrows?.style}
            {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.arrows?.style : {})}
          >
            <ArrowRight
              testID="top-selling-arrow-right"
              width={23}
              height={22}
              stroke={
                (isMobile ? topSellingItemsSlider?.wrapperMobile?.arrows?.icon?.style?.color : topSellingItemsSlider?.wrapper?.arrows?.icon?.style?.color) ??
                '#000'
              }
              strokeWidth={2}
              {...topSellingItemsSlider?.wrapper?.arrows?.icon?.style}
              {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.arrows?.icon?.style : {})}
            />
          </Box>
        }
        alignItems="stretch"
      >
        {topSellingItems?.map((item, index) => (
          <Box
            key={index}
            width={getCardDimensions.width}
            marginRight={topSellingItems?.length === index ? 'none' : 4}
            {...topSellingItemsSlider?.wrapper?.items?.style}
            {...(isMobile ? topSellingItemsSlider?.wrapperMobile?.items?.style : {})}
          >
            <MenuItemCard
              width="100%"
              showImage={showImages}
              ref={(topItemsRefs.current[index] = createRef())}
              testId={`topSellingItem-${item.type}-${item.uniqueName}`}
              item={item}
              boxProps={{ borderRadius: 'lg' }}
              onClick={onItemClick}
            />
          </Box>
        ))}
      </ScrollableBox>
    </Box>
  ) : null;
});
