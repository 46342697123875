import { CategoryMenuNavBar } from '@webstore-monorepo/features/category-menu-nav-bar';
import { useTopSellingItems, useWebStoreItems } from '@webstore-monorepo/shared/api/cart-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { ICategory } from '@webstore-monorepo/shared/interfaces';
import { parseURLSearchParams, setURLSearchParam } from '@webstore-monorepo/shared/utils/url';
import debounce from 'lodash/debounce';
import type { RefObject } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { APP_PATHS } from '../../routes/paths';
import { useAppContextState } from '../../shared/contexts/app-context-provider';
import { useAnalytics } from '../../shared/hooks/use-analytics';
import { useAppHistory } from '../../shared/hooks/use-app-history';

interface Props {
  innerRef: RefObject<HTMLDivElement>;
  stickyHeaderHeight: number;
  placeholderRemoved: boolean;
  menuListCategoryItemsRef: RefObject<RefObject<HTMLDivElement>[]>;
  onScrollToCategory?: (index: number) => void;
  onSearchChange: (searchStr: string) => void;
  onCloseSearch: () => void;
}
export const CategoryNavBar: React.FC<Props> = ({
  innerRef,
  placeholderRemoved,
  stickyHeaderHeight,
  onScrollToCategory,
  menuListCategoryItemsRef,
  onCloseSearch,
  onSearchChange,
}) => {
  const { menuList } = useComponentsConfig();
  const analytics = useAnalytics();
  const appState = useAppContextState();
  const { isDesktop } = useWindowDimensions();
  const [hasTopSelling, setHasTopSelling] = useState(false);
  const { selectedMenuConceptUniqueNames } = appState;
  const { data, isLoading: isWebstoreItemsLoading } = useWebStoreItems({ selectedMenuConceptUniqueNames });
  const { data: topSellingItems } = useTopSellingItems();
  const location = useLocation();
  const allItemsRendered = menuListCategoryItemsRef.current?.some((item) => item.current);
  const { topSellingItemsTitle } = menuList?.[isDesktop ? 'wrapper' : 'wrapperMobile']?.options || {};
  const { topSellingCategoryUuid } = menuList?.wrapper?.options || {}; // there is no uuid under wrapperMobile!
  const history = useAppHistory();
  const [selected, setSelected] = useState<string>();
  const [viewportHeight, setViewportHeight] = useState<number>(window.innerHeight);
  const headerContainerHeight = appState?.headerElementHeight ?? 0;
  const orderToTableBarHeight = appState?.orderToTableBarElementHeight ?? 0;

  useEffect(() => {
    const searchParams = parseURLSearchParams();
    if (searchParams.category) {
      let topSellingItemsCategory: ICategory | null = null;
      if ((topSellingItems?.length ?? 0) >= 4 && topSellingCategoryUuid && topSellingItemsTitle) {
        topSellingItemsCategory = {
          uniqueName: topSellingCategoryUuid ?? '',
          title: topSellingItemsTitle ?? '',
        };
        setHasTopSelling(true);
      }
      const categories = data?.storeItemsByCategory.length
        ? topSellingItemsCategory
          ? [topSellingItemsCategory, ...data.storeItemsByCategory]
          : data?.storeItemsByCategory
        : [];
      const found = categories.find((item: ICategory) => item.title === decodeURI(searchParams.category));
      setSelected(found?.uniqueName);
    }
  }, [data, location, topSellingCategoryUuid, topSellingItems?.length, topSellingItemsTitle]);

  const handleTopSellingScroll = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setSelected(topSellingCategoryUuid);
      }
    },
    [topSellingCategoryUuid],
  );

  const handleWindowScroll = useCallback((entries: IntersectionObserverEntry[]) => {
    if (entries.length > 0) {
      let maxRatio = 0;
      let maxEntry = null;
      entries.forEach((entry) => {
        if (entry.intersectionRatio > maxRatio) {
          maxRatio = entry.intersectionRatio;
          maxEntry = entry;
        }
      });
      if (maxEntry) {
        const target = (maxEntry as IntersectionObserverEntry).target;
        if (target instanceof HTMLElement) {
          setSelected(target.id);
        }
      }
    }
  }, []);

  const handleNavItemClick = (item: ICategory, categoryIndex: number) => {
    const searchString = setURLSearchParam('category', encodeURI(item.title.trim())).search.split('+').join(' ');
    const realIndex = hasTopSelling ? categoryIndex - 1 : categoryIndex;

    history.push(`${APP_PATHS.home}${searchString}`);

    if (realIndex === -1 && topSellingCategoryUuid) {
      document.getElementById(topSellingCategoryUuid)?.focus();
    } else {
      const itemUniqueName = data?.storeItemsByCategory[realIndex]?.items[0].uniqueName ?? '';
      document.getElementById(itemUniqueName)?.focus();
    }

    analytics.track('category_select', {
      uid: item.uniqueName,
    });

    if (item.uniqueName === topSellingCategoryUuid) {
      onScrollToCategory && onScrollToCategory(-1);
      return;
    }
    onScrollToCategory && onScrollToCategory(realIndex);
  };

  const handleSearchChange = (searchStr: string) => {
    onSearchChange(searchStr);
  };

  const handleCloseSearch = () => {
    onCloseSearch();
  };

  /**
   * Calculates sticky value for navigation component in relation with other sticky components
   * TODO: if other sticky components will apear, this logic should be generalized
   * and extracted in wrapper component. (after moving to using react app wrapper)
   */
  const handleSetStickyValues = useCallback(() => {
    if (innerRef?.current) {
      innerRef.current.style.top = `${(stickyHeaderHeight ? stickyHeaderHeight + headerContainerHeight : headerContainerHeight) - 1}px`;
    }
  }, [headerContainerHeight, innerRef, stickyHeaderHeight]);

  useEffect(() => {
    handleSetStickyValues();
  }, [handleSetStickyValues]);

  useEffect(() => {
    const topCategoriesElement = document.getElementById('placeholder-top-categories');
    topCategoriesElement && topCategoriesElement.parentNode?.removeChild(topCategoriesElement);
    const navigation = document.getElementsByClassName('navigation')[0] as HTMLElement;
    const isTouch =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      // @ts-ignore https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1029 and https://www.tabnine.com/code/javascript/functions/builtins/Navigator/msMaxTouchPoints
      window.navigator.msMaxTouchPoints > 0;

    if (navigation) {
      isTouch ? navigation.classList.add('no-touch') : navigation.classList.remove('no-touch');
    }
  }, []);

  useEffect(() => {
    const innerRefHeight = innerRef?.current?.clientHeight ?? 0;
    const headerHeight = Math.abs(headerContainerHeight + innerRefHeight + orderToTableBarHeight + 100);
    const topMargin = isNaN(headerHeight) ? 0 : headerHeight;
    const bottomMarginCalc = viewportHeight - headerHeight - 50;
    const bottomMargin = isNaN(bottomMarginCalc) ? 0 : bottomMarginCalc;
    const rootMargin = `-${topMargin}px 0px -${bottomMargin}px 0px`;
    const options: IntersectionObserverInit = {
      rootMargin,
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleWindowScroll, options);
    const topSellingObserver = new IntersectionObserver(handleTopSellingScroll, options);

    if (appState.topSellingItemsSliderRef) {
      topSellingObserver.observe(appState.topSellingItemsSliderRef);
    }
    if (!isWebstoreItemsLoading && placeholderRemoved && menuListCategoryItemsRef.current?.length && allItemsRendered) {
      menuListCategoryItemsRef.current.forEach((element) => {
        if (element.current) {
          observer.observe(element.current);
        }
      });
    }
    return () => {
      observer.disconnect();
      topSellingObserver.disconnect();
    };
  }, [
    placeholderRemoved,
    isWebstoreItemsLoading,
    viewportHeight,
    headerContainerHeight,
    innerRef,
    orderToTableBarHeight,
    handleWindowScroll,
    menuListCategoryItemsRef,
    menuListCategoryItemsRef.current?.length,
    allItemsRendered,
    handleTopSellingScroll,
    appState.topSellingItemsSliderRef,
  ]);

  const getViewportHeight = debounce(() => {
    setViewportHeight(window.innerHeight);
  }, 300);

  const handleResize = useCallback(() => {
    getViewportHeight();
    handleSetStickyValues();
  }, [getViewportHeight, handleSetStickyValues]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <nav ref={innerRef} style={{ zIndex: 99, position: 'sticky' }}>
      <CategoryMenuNavBar
        selectedItem={selected}
        selectedMenuConceptUniqueNames={selectedMenuConceptUniqueNames}
        onCategoryItemClick={handleNavItemClick}
        onSearchChange={handleSearchChange}
        onHideSearchBar={handleCloseSearch}
      />
    </nav>
  );
};
