import { StoreItemTypeEnum } from '@goparrot/common';
import type { IOrderPromotionElement } from '@goparrot/order-sdk';
import type { IOrderElement, IOrderProperty, OrderSelectionType } from '@goparrot/order-sdk';
import { OrderElementTypeEnum } from '@goparrot/order-sdk';
import { PromotionDiscountLevelEnum } from '@goparrot/promotions-sdk';
import type { IStoreItemOptionSelection, IStoreItemOptionWithSelections, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
export const showPaidModifiersDiscountNotice = (promotions: IOrderPromotionElement[], specificPromo?: IOrderPromotionElement) => {
  let hasDisabledCheckBasedPaidModifiers = false;

  const hasCheckBasedDiscountForPaidModifiers = promotions?.some(({ discount }) => {
    if (PromotionDiscountLevelEnum.CHECK_BASED !== discount.level) {
      return false;
    }

    if (discount.appliesToModifiers === false) {
      hasDisabledCheckBasedPaidModifiers = true;
      return false;
    }

    return true;
  });

  if (hasCheckBasedDiscountForPaidModifiers) {
    return false;
  }

  if (hasDisabledCheckBasedPaidModifiers) {
    if (!specificPromo) {
      return true;
    }

    return specificPromo.discount.appliesToModifiers === false;
  }

  return specificPromo && specificPromo.discount.appliesToModifiers === false;
};

export const hasItemPaidModifiers = (item: IStoreItemWithOptions | IOrderElement) =>
  item.properties?.some(
    (property: IStoreItemOptionWithSelections | IOrderProperty) => property.selected && !property.isHidden && property.selections.some(isSelectedAndPaid),
  );

const isSelectedAndPaid = (selection: OrderSelectionType | IStoreItemOptionSelection) => {
  if (OrderElementTypeEnum.SELECTION_GROUP === selection.type) {
    // @ts-ignore core-sdk 3.200.0 changes broke
    return selection.properties?.some(isSelectedAndPaid);
  }

  // @ts-ignore core-sdk 3.200.0 changes broke
  return selection.selected && !!selection.price;
};

export function findInvalidPropertyIndex(properties: IStoreItemOptionWithSelections[] = []): number {
  return properties.findIndex((property) => {
    const selectedItemsCount =
      property.selections &&
      property.selections
        .reduce<IStoreItemOptionSelection[]>((acc, prop) => {
          if (prop.type === StoreItemTypeEnum.ITEM_BASE_GROUP) {
            acc.push(...prop.properties);
          } else {
            acc.push(prop);
          }
          return acc;
        }, [])
        .filter(({ selected }) => selected).length;

    return selectedItemsCount > property.selections_max || (!!property.selections_min && selectedItemsCount < property.selections_min);
  });
}
