import type { AddExistingGiftCardDto, ReadGiftCardDto } from '@goparrot/giftcards-sdk';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import { api } from '../api';

export const useAttachGiftCard = (options?: UseMutationOptions<ReadGiftCardDto, AxiosError, AddExistingGiftCardDto>) => {
  const { user } = useUserContextState();
  return useMutation(
    async (data: AddExistingGiftCardDto): Promise<ReadGiftCardDto> => {
      return api.post(`/api/v2/users/${user?.userId}/giftcards`, data);
    },
    { ...options },
  );
};

export const useDetachGiftCard = (options?: UseMutationOptions<void, AxiosError, string>) => {
  const { user } = useUserContextState();
  return useMutation(
    async (uuid: string): Promise<void> => {
      return api.delete(`/api/v2/users/${user?.userId}/giftcards/${uuid}`);
    },
    { ...options },
  );
};
