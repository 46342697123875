import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export function useWindowWidth() {
  const [isMobile, setIsMobile] = useState<boolean | null>(window.innerWidth <= 768);

  const handleWidthChange = debounce(() => {
    window.innerWidth <= 768 ? setIsMobile(true) : setIsMobile(false);
  }, 300);

  useEffect(() => {
    window.addEventListener('resize', handleWidthChange);
    return () => {
      window.removeEventListener('resize', handleWidthChange);
    };
  }, [handleWidthChange]);

  return isMobile;
}
