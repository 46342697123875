import type { NativeComboItemFormAddonsProps } from '@webstore-monorepo/shared/interfaces';
import React from 'react';

import { AddOns, ItemModalDescription, ItemModalNotes } from '../../../components/item-modal/components';
const SHOW_STATES = {
  ADDONS: 'addons',
  DESCRIPTION: 'description',
};

export const NativeComboItemBody: React.FC<NativeComboItemFormAddonsProps> = ({
  showAddonsFirst = true,
  selectedItem,
  itemsRef,
  addToCartButtonClicked,
  onChangeProperty,
  onValidateSelection,
}) => {
  const showState = showAddonsFirst ? SHOW_STATES.ADDONS : SHOW_STATES.DESCRIPTION;
  const itemHasProps = selectedItem.properties?.length;

  return (
    <>
      {(showState === SHOW_STATES.DESCRIPTION || itemHasProps === 0) && <ItemModalDescription hasModifiers={false} />}
      {itemHasProps > 0 && showState === SHOW_STATES.ADDONS && <ItemModalDescription hasModifiers />}
      <AddOns
        itemsRef={itemsRef}
        onPropertiesChange={onChangeProperty}
        addToCartButtonClicked={addToCartButtonClicked}
        onValidateSelection={onValidateSelection}
      />
      <ItemModalNotes />
    </>
  );
};
