import { RPT_HEADER } from '@goparrot/webstore-gateway-public-sdk';
import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions, UseMutationResult } from 'react-query';
import { useMutation } from 'react-query';

export const usePatchVendorOrder = ({
  orderUuid,
  rpt,
  options,
}: {
  orderUuid: string;
  rpt?: string;
  options?: UseMutationOptions<unknown, AxiosError, void>;
}): UseMutationResult<unknown, AxiosError, void> => {
  return useMutation(async () => {
    return axios.patch(
      `/api/v2/vendor-orders/${orderUuid}/arrived`,
      undefined,
      rpt
        ? {
            headers: {
              [RPT_HEADER]: rpt,
            },
          }
        : {},
    );
  }, options);
};
