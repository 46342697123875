import { SupportedCurrencyEnum } from '@goparrot/common';
import type { IOrderServiceCharge, ServiceChargeType } from '@goparrot/order-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { FormattedNumber } from 'react-intl';

import { OrderTotalRow } from '../checkout-total-row';

interface DeliveryFeeProps {
  serviceCharges: IOrderServiceCharge[];
}

const getFormattedNumber: (value: number) => React.ReactElement = (value: number) => (
  <FormattedNumber value={value} style="currency" currency={SupportedCurrencyEnum.USD} />
);

const DELIVERY_FEE: ServiceChargeType = 'DELIVERY_FEE';
const DELIVERY_TIP: ServiceChargeType = 'DELIVERY_TIP';

export const DeliveryFee: React.FC<DeliveryFeeProps> = ({ serviceCharges }) => {
  const { intl } = usePlatformStoreState();
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const { showZeroDeliveryFee, deliveryFeeTooltip, dynamicDeliveryFeeTooltip } = isMobile
    ? cartScreen?.wrapperMobile?.orderTotal?.options || {}
    : cartScreen?.wrapper?.orderTotal?.options || {};
  const filteredServiceCharges = serviceCharges.filter(
    (serviceCharge: IOrderServiceCharge) => DELIVERY_TIP !== serviceCharge.type && (!!serviceCharge.total?.money || showZeroDeliveryFee),
  );
  const defaultDeliveryTooltipText =
    'Due to recent changes in 2018 NY wage laws, we have added a delivery fee at GoParrot to preserve our quality of service. Thank you for your support.';
  const defaultDynamicDeliveryFeeTooltipText = 'Delivery fee is dynamic and may change based on delivery address and other rules.';
  const deliveryTooltipText: string = deliveryFeeTooltip ?? defaultDeliveryTooltipText;
  const dynamicDeliveryFeeTooltipText: string = dynamicDeliveryFeeTooltip ?? defaultDynamicDeliveryFeeTooltipText;

  const deliveryFeeInfoText = (
    <>
      <Box marginBottom={4}>
        <Text>{deliveryTooltipText}</Text>
      </Box>
      <Box>
        <Text>{dynamicDeliveryFeeTooltipText}</Text>
      </Box>
    </>
  );

  return (
    <>
      {filteredServiceCharges.map((serviceCharge: IOrderServiceCharge) =>
        DELIVERY_FEE === serviceCharge.type ? (
          <OrderTotalRow
            key={serviceCharge.uuid}
            title={`${serviceCharge.isApproximate ? 'Approx. ' : ''}${serviceCharge.name}`}
            tooltip={deliveryFeeInfoText}
            amount={serviceCharge.total?.money ? (intl.formatNumber(serviceCharge.total?.money ?? 0) as string) : 'Delivery address required'}
          />
        ) : serviceCharge.total?.money ? (
          <OrderTotalRow title={serviceCharge.name} amount={getFormattedNumber(serviceCharge.total?.money)} />
        ) : null,
      )}
    </>
  );
};
