import { OrderFlowEnum } from '@goparrot/common';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { globalCache } from '@webstore-monorepo/shared/utils/global-cache';
import { useCallback, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MENU_PATHS } from '../../../pages/paths';
import { APP_PATHS } from '../../../routes/paths';
import { useAppContextState } from '../../contexts/app-context-provider';
import { isValidDelayedInfo } from '../../utils/cart';
import { useAppHistory } from '../use-app-history';

const SKIP_REDIRECT_ROUTES = [APP_PATHS.home, `${APP_PATHS.home}${MENU_PATHS.order_setup}`, `${APP_PATHS.home}${MENU_PATHS.cart}`];

export const useShowOrderSetup = () => {
  const history = useAppHistory();
  const cart = useCartState();
  const store = useStoreState();
  const location = useLocation();
  const appContext = useAppContextState();
  const openedSuccessfully = useRef(false);
  const [isOpenedOnce, setIsOpenedOnce] = useState(false);

  const handleGoToSetup = useCallback(() => {
    if (
      appContext.isDynamicMenuEnabled &&
      appContext.someOrderAheadAvailable &&
      !appContext.orderToTableInfo?.isOrderToTable &&
      !location.pathname.includes(MENU_PATHS.order_complete.split('/')[1]) &&
      !location.pathname.includes(MENU_PATHS.checkout) &&
      cart?.flow !== OrderFlowEnum.DIGITAL &&
      (!cart?.diningOptionInfo?.type ||
        !cart?.delayedInfo ||
        !isValidDelayedInfo(cart, store) ||
        ((globalCache.locationSelectorIsPickup || globalCache.locationSelectorAddress) && !isOpenedOnce)) &&
      !openedSuccessfully.current
    ) {
      if (globalCache.locationSelectorAddress || globalCache.locationSelectorIsPickup) {
        setIsOpenedOnce(true);
      }

      if (!SKIP_REDIRECT_ROUTES.includes(location.pathname)) {
        globalCache.initUrl = `${location.pathname}${location.search}`;
      }
      openedSuccessfully.current = true;
      history.push(MENU_PATHS.order_setup);
    } else {
      openedSuccessfully.current = false;
    }
  }, [appContext.isDynamicMenuEnabled, appContext.someOrderAheadAvailable, appContext.orderToTableInfo?.isOrderToTable, cart, store, location.pathname]);

  return handleGoToSetup;
};
