import React from 'react';
import { Badge } from '../Badge';
import { BADGE_VARIANTS, BadgeVariants } from '../constants';
import { getBadgeText } from './utils';

export interface SelectionBadgeProps {
  min: number;
  max: number;
  allSelected: number;
  allSelections: number;
  hasError?: boolean;
}
export const SelectionBadge: React.FC<SelectionBadgeProps> = ({ min, max, allSelections, allSelected, hasError }) => {
  const badgeStatus = allSelected === 0 ? BadgeVariants.active : BadgeVariants.inactive;

  return (
    <Badge
      data-testid={`selection-badge-${badgeStatus}`}
      backgroundColor={BADGE_VARIANTS.background[badgeStatus]}
      color={BADGE_VARIANTS.text[badgeStatus]}
      hasError={hasError}
    >
      {getBadgeText(min, max, allSelected, allSelections)}
    </Badge>
  );
};
