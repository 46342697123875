import { recursiveClean } from '@goparrot/common';
import type { ReadWebMerchantThemeDto } from '@goparrot/webstore-sdk';
import { createTheme } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { buttons as buttonVariants, restyleTheme } from '@webstore-monorepo/shared/theme';

import {
  convertBoxShadowToRnw,
  convertShorthandBorderToFull,
  convertUnspecifiedValueToPixels,
  extractColorValueFromString,
  extractCSSValue,
  extractKeysFromKeyString,
  extractSpacingKeyValues,
  getSpacingThemeKeyValuePairs,
} from './theme-utils';

export const getMergedThemeValues = (): Theme => {
  const webMerchantDto = window.SSRContext.webMerchant;
  const webMerchant = recursiveClean(webMerchantDto, { removeEmptyString: true });
  const webstoreLegacyTheme = window.SSRContext.webstore.metadata.theme;
  const { theme: storeLevelTheme = {} } = recursiveClean(window.SSRContext.webstore, { removeEmptyString: true });
  const { ':hover': hoveredMenuConceptsItemStyles = {} } = webstoreLegacyTheme?.menuConcepts?.bar?.item?.sm ?? {};

  const { theme: merchantLevelTheme = {} as ReadWebMerchantThemeDto } = webMerchant;

  const theme = createTheme({
    ...restyleTheme,
    colors: {
      [extractCSSValue(webstoreLegacyTheme.grayColor)]: extractCSSValue(webstoreLegacyTheme.grayColor),
      [extractCSSValue(webstoreLegacyTheme.grayColor2)]: extractCSSValue(webstoreLegacyTheme.grayColor2),
      [extractCSSValue(webstoreLegacyTheme.mainBg)]: extractCSSValue(webstoreLegacyTheme.mainBg),
      [extractCSSValue(webstoreLegacyTheme.mainColor)]: extractCSSValue(webstoreLegacyTheme.mainColor),
      [extractCSSValue(webstoreLegacyTheme.mainBgMobile)]: extractCSSValue(webstoreLegacyTheme.mainBgMobile),
      [extractCSSValue(webstoreLegacyTheme.textBase)]: extractCSSValue(webstoreLegacyTheme.textBase),
      [extractCSSValue(webstoreLegacyTheme.navColor)]: extractCSSValue(webstoreLegacyTheme.navColor),
      [extractCSSValue(webstoreLegacyTheme.navBg)]: extractCSSValue(webstoreLegacyTheme.navBg),
      [extractCSSValue(webstoreLegacyTheme.navColorHover)]: extractCSSValue(webstoreLegacyTheme.navColorHover),
      [extractCSSValue(webstoreLegacyTheme.navColorMobile)]: extractCSSValue(webstoreLegacyTheme.navColorMobile),
      [extractCSSValue(webstoreLegacyTheme.headerColor)]: extractCSSValue(webstoreLegacyTheme.headerColor),
      [extractCSSValue(webstoreLegacyTheme.headerNavHover)]: extractCSSValue(webstoreLegacyTheme.headerNavHover),
      [extractCSSValue(webstoreLegacyTheme.mainHoverColor)]: extractCSSValue(webstoreLegacyTheme.mainHoverColor),
      [extractCSSValue(webstoreLegacyTheme.headerBannerBg)]: extractCSSValue(webstoreLegacyTheme.headerBannerBg),
      [extractCSSValue(webstoreLegacyTheme.headerBannerColor)]: extractCSSValue(webstoreLegacyTheme.headerBannerColor),
      [extractCSSValue(webstoreLegacyTheme.headerCustomButtonColor)]: extractCSSValue(webstoreLegacyTheme.headerCustomButtonColor),
      [extractCSSValue(webstoreLegacyTheme.headerCustomButtonBg)]: extractCSSValue(webstoreLegacyTheme.headerCustomButtonBg),
      [extractCSSValue(webstoreLegacyTheme.headerCustomButtonColorHover)]: extractCSSValue(webstoreLegacyTheme.headerCustomButtonColorHover),
      [extractCSSValue(webstoreLegacyTheme.headerCustomButtonBgHover)]: extractCSSValue(webstoreLegacyTheme.headerCustomButtonBgHover),
      [extractCSSValue(webstoreLegacyTheme.cartHeaderCountBg)]: extractCSSValue(webstoreLegacyTheme.cartHeaderCountBg),
      [extractCSSValue(webstoreLegacyTheme.cartHeaderCountColor)]: extractCSSValue(webstoreLegacyTheme.cartHeaderCountColor),
      [extractCSSValue(webstoreLegacyTheme.footerBg)]: extractCSSValue(webstoreLegacyTheme.footerBg),
      [extractCSSValue(webstoreLegacyTheme.footerTextColor)]: extractCSSValue(webstoreLegacyTheme.footerTextColor),
      [extractCSSValue(webstoreLegacyTheme.navUnderline)]: extractCSSValue(webstoreLegacyTheme.navUnderline),
      [extractCSSValue(webstoreLegacyTheme.headerTopNavBar.backGround)]: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.backGround),
      [extractCSSValue(webstoreLegacyTheme.headerTopNavBar.textColor)]: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.textColor),
      [extractCSSValue(webstoreLegacyTheme.headerTopNavBar.textColorHover)]: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.textColorHover),
      [extractCSSValue(webstoreLegacyTheme.headerTopNavBar.activeLink?.underlineColor)]: extractCSSValue(
        webstoreLegacyTheme.headerTopNavBar.activeLink?.underlineColor,
      ),
      [extractCSSValue(webstoreLegacyTheme.heroblockTextColor)]: extractCSSValue(webstoreLegacyTheme.heroblockTextColor),
      [extractCSSValue(webstoreLegacyTheme.heroBlockScheduleColor)]: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleColor),
      [extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysColor)]: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysColor),
      [extractCSSValue(webstoreLegacyTheme.heroBlockScheduleHoursColor)]: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleHoursColor),
      [extractCSSValue(webstoreLegacyTheme.heroBlockBackgroundMobile)]: extractCSSValue(webstoreLegacyTheme.heroBlockBackgroundMobile),
      [extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexColor)]: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexColor),
      [extractCSSValue(webstoreLegacyTheme?.socialMedia?.FOOTER?.textColor)]: extractCSSValue(webstoreLegacyTheme?.socialMedia?.FOOTER?.textColor),
      [extractCSSValue(webstoreLegacyTheme?.socialMedia?.FOOTER?.iconsColor)]: extractCSSValue(webstoreLegacyTheme?.socialMedia?.FOOTER?.iconsColor),
      [extractCSSValue(webstoreLegacyTheme?.socialMedia?.SIDEBAR?.textColor)]: extractCSSValue(webstoreLegacyTheme?.socialMedia?.SIDEBAR?.textColor),
      [extractCSSValue(webstoreLegacyTheme?.socialMedia?.SIDEBAR?.iconsColor)]: extractCSSValue(webstoreLegacyTheme?.socialMedia?.SIDEBAR?.iconsColor),
      [extractCSSValue(webstoreLegacyTheme.orderToTable.textColor)]: extractCSSValue(webstoreLegacyTheme.orderToTable.textColor),
      [extractCSSValue(webstoreLegacyTheme.orderToTable.backgroundColor)]: extractCSSValue(webstoreLegacyTheme.orderToTable.backgroundColor),
      [extractCSSValue(webstoreLegacyTheme.orderToTable.badge.color)]: extractCSSValue(webstoreLegacyTheme.orderToTable.badge.color),
      [extractCSSValue(webstoreLegacyTheme.orderToTable.badge.backgroundColor)]: extractCSSValue(webstoreLegacyTheme.orderToTable.badge.backgroundColor),
      [extractCSSValue(webstoreLegacyTheme.orderToTable.iconColor)]: extractCSSValue(webstoreLegacyTheme.orderToTable.iconColor),
      [extractCSSValue(webstoreLegacyTheme.promotionsBar.background)]: extractCSSValue(webstoreLegacyTheme.promotionsBar.background),
      [extractCSSValue(webstoreLegacyTheme.promotionsBar.color)]: extractCSSValue(webstoreLegacyTheme.promotionsBar.color),
      [extractCSSValue(webstoreLegacyTheme.orderStatusBanner.background)]: extractCSSValue(webstoreLegacyTheme.orderStatusBanner.background),
      [extractCSSValue(webstoreLegacyTheme.orderStatusBanner.color)]: extractCSSValue(webstoreLegacyTheme.orderStatusBanner.color),
      [extractCSSValue(webstoreLegacyTheme.storeitemBorderColor)]: extractCSSValue(webstoreLegacyTheme.storeitemBorderColor),
      [extractCSSValue(webstoreLegacyTheme.storeitemBorderHoverColor)]: extractCSSValue(webstoreLegacyTheme.storeitemBorderHoverColor),
      [extractColorValueFromString(extractCSSValue(webstoreLegacyTheme.storeitemBorder))]: extractColorValueFromString(
        extractCSSValue(webstoreLegacyTheme.storeitemBorder),
      ),
      [extractCSSValue(webstoreLegacyTheme.storeitemTitleColor)]: extractCSSValue(webstoreLegacyTheme.storeitemTitleColor),
      [extractCSSValue(webstoreLegacyTheme.storeitemBackgroundColor)]: extractCSSValue(webstoreLegacyTheme.storeitemBackgroundColor),
      [extractCSSValue(webstoreLegacyTheme.storeitemBackgroundColorMobile)]: extractCSSValue(webstoreLegacyTheme.storeitemBackgroundColorMobile),
      [extractCSSValue(webstoreLegacyTheme.categoryColor)]: extractCSSValue(webstoreLegacyTheme.categoryColor),
      [extractCSSValue(webstoreLegacyTheme.backgroundCategory)]: extractCSSValue(webstoreLegacyTheme.backgroundCategory),
      [extractCSSValue(webstoreLegacyTheme.categorySubtitleBackground)]: extractCSSValue(webstoreLegacyTheme.categorySubtitleBackground),
      [extractCSSValue(webstoreLegacyTheme.categorySubtitleColor)]: extractCSSValue(webstoreLegacyTheme.categorySubtitleColor),
      [extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.color)]: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.color),
      [extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.color)]: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.color),
      [extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.background)]: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.background),
      [extractCSSValue(webstoreLegacyTheme.chevronBackgroundColorTopSellingScrollMenu)]: extractCSSValue(
        webstoreLegacyTheme.chevronBackgroundColorTopSellingScrollMenu,
      ),
      [extractCSSValue(webstoreLegacyTheme.chevronColorTopSellingScrollMenu)]: extractCSSValue(webstoreLegacyTheme.chevronColorTopSellingScrollMenu),
      [extractCSSValue(webstoreLegacyTheme.orderReminder?.summaryContainer?.background)]: extractCSSValue(
        webstoreLegacyTheme.orderReminder?.summaryContainer?.background,
      ),
      [extractCSSValue(webstoreLegacyTheme.orderReminder?.actionsContainer?.background)]: extractCSSValue(
        webstoreLegacyTheme.orderReminder?.actionsContainer?.background,
      ),
      [convertShorthandBorderToFull(webstoreLegacyTheme.menuItemsDivider).borderColor ?? '']:
        convertShorthandBorderToFull(webstoreLegacyTheme.menuItemsDivider).borderColor ?? '',
      [convertBoxShadowToRnw(extractCSSValue(webstoreLegacyTheme.storeitemBoxShadow)).shadowColor]: convertBoxShadowToRnw(
        extractCSSValue(webstoreLegacyTheme.storeitemBoxShadow),
      ).shadowColor,
      [convertBoxShadowToRnw(extractCSSValue(webstoreLegacyTheme.orderReminder?.boxShadow)).shadowColor]: convertBoxShadowToRnw(
        extractCSSValue(webstoreLegacyTheme.orderReminder?.boxShadow),
      ).shadowColor,
      [convertBoxShadowToRnw(extractCSSValue(webstoreLegacyTheme.storeitemBoxShadowHover)).shadowColor]: convertBoxShadowToRnw(
        extractCSSValue(webstoreLegacyTheme.storeitemBoxShadowHover),
      ).shadowColor,
      [extractCSSValue(webstoreLegacyTheme.prepTimeCartBlock?.color)]: extractCSSValue(webstoreLegacyTheme.prepTimeCartBlock?.color),
      [extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.background)]: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.background),
      [extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.color)]: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.color),
      [extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.background)]: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.background),
      [extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.color)]: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.color),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.container?.sm, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.item?.sm, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.activeItem?.sm, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.container?.sm, 'color'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.container?.sm, 'color'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.title?.sm, 'color'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.description?.sm, 'color'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.logoContainer?.sm, 'color'),
      ...extractKeysFromKeyString(hoveredMenuConceptsItemStyles, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme.orderReminder?.modal?.primaryText, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme.orderReminder?.modal?.secondaryText, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme.orderReminder?.summaryContainer?.title, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme.orderReminder?.summaryContainer?.text, 'color'),
      ...extractKeysFromKeyString(webstoreLegacyTheme.orderReminder?.modal?.primaryText, 'color'),
      ...restyleTheme.colors,
      ...{
        primaryColor: extractCSSValue(webstoreLegacyTheme.mainColor, restyleTheme.colors.primaryColor),
        primaryButtonColor: extractCSSValue(webstoreLegacyTheme.buttonBg, restyleTheme.colors.primaryButtonColor),
        primaryButtonBorderColor: extractCSSValue(webstoreLegacyTheme.mainColor, restyleTheme.colors.primaryButtonColor),
        primaryButtonColorHover: extractCSSValue(webstoreLegacyTheme.buttonBgHover, restyleTheme.colors.primaryButtonColorHover),
        primaryButtonTextColor: extractCSSValue(webstoreLegacyTheme.buttonColor, restyleTheme.colors.primaryButtonTextColor),
        secondaryButtonColor: extractCSSValue(webstoreLegacyTheme.buttonBg, restyleTheme.colors.primaryButtonColor),
        secondaryButtonColorHover: extractCSSValue(webstoreLegacyTheme.buttonBgHover, restyleTheme.colors.primaryButtonColorHover),
        secondaryButtonTextColor: extractCSSValue(webstoreLegacyTheme.buttonColor, restyleTheme.colors.primaryButtonTextColor),
        secondaryButtonBorderColor: extractCSSValue(webstoreLegacyTheme.buttonColor, restyleTheme.colors.primaryButtonTextColor),
        background: extractCSSValue(webstoreLegacyTheme.mainBg, restyleTheme.colors.background),
        primaryBackground: extractCSSValue(webstoreLegacyTheme.mainBg, restyleTheme.colors.primaryBackground),
      },
      ...merchantLevelTheme.customColors,
      ...storeLevelTheme.customColors,
      ...merchantLevelTheme.colors,
      ...storeLevelTheme.colors,
    },
    fontFamily: {
      // @ts-ignore: can be overridden
      primary: extractCSSValue(webstoreLegacyTheme.ffBase, restyleTheme.fontFamily.primary),
      // @ts-ignore: can be overridden
      secondary: extractCSSValue(webstoreLegacyTheme.ffSecondary, restyleTheme.fontFamily.secondary),
      // @ts-ignore: can be overridden
      text: extractCSSValue(webstoreLegacyTheme.ffBase, restyleTheme.fontFamily.text),
      [webstoreLegacyTheme.ffBase]: extractCSSValue(webstoreLegacyTheme.ffBase),
      [webstoreLegacyTheme.ffSecondary]: extractCSSValue(webstoreLegacyTheme.ffSecondary),
      [extractCSSValue(webstoreLegacyTheme.ffHeader)]: extractCSSValue(webstoreLegacyTheme.ffHeader),
      [extractCSSValue(webstoreLegacyTheme.ffTopCategories)]: extractCSSValue(webstoreLegacyTheme.ffTopCategories),
      [extractCSSValue(webstoreLegacyTheme.ffHeroBlockTitle)]: extractCSSValue(webstoreLegacyTheme.ffHeroBlockTitle),
      [extractCSSValue(webstoreLegacyTheme.heroBlockPlainTextFF)]: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTextFF),
      [extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFF)]: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFF),
      [extractCSSValue(webstoreLegacyTheme.ffHeroBlockDescription)]: extractCSSValue(webstoreLegacyTheme.ffHeroBlockDescription),
      [extractCSSValue(webstoreLegacyTheme.ffStoreItemMainSubtitle)]: extractCSSValue(webstoreLegacyTheme.ffStoreItemMainSubtitle),
      [extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontFamilyDesktop)]: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontFamilyDesktop),
      [extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontFamily)]: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontFamily),
      [extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontFamily)]: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontFamily),
      [extractCSSValue(webstoreLegacyTheme.itemIngredientsFF)]: extractCSSValue(webstoreLegacyTheme.itemIngredientsFF),
      [extractCSSValue(webstoreLegacyTheme.ffStoreItemMainTitle)]: extractCSSValue(webstoreLegacyTheme.ffStoreItemMainTitle),
      [extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.fontFamily)]: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.fontFamily),
      [extractCSSValue(webstoreLegacyTheme.categorySubtitleFontFamily)]: extractCSSValue(webstoreLegacyTheme.categorySubtitleFontFamily),
      [extractCSSValue(webstoreLegacyTheme.prepTimeCartBlock?.fontFamily)]: extractCSSValue(webstoreLegacyTheme.prepTimeCartBlock?.fontFamily),
      [extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.fontFamily)]: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.fontFamily),
      [extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.fontFamily)]: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.fontFamily),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.container?.sm, 'fontFamily'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.item?.sm, 'fontFamily'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.activeItem?.sm, 'fontFamily'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.container?.sm, 'fontFamily'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.container?.sm, 'fontFamily'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.title?.sm, 'fontFamily'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.description?.sm, 'fontFamily'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.logoContainer?.sm, 'fontFamily'),
      ...extractKeysFromKeyString(hoveredMenuConceptsItemStyles, 'fontFamily'),
      ...merchantLevelTheme.customFonts,
      ...storeLevelTheme.customFonts,
      ...merchantLevelTheme.fonts,
      ...storeLevelTheme.fonts,
    },
    textVariants: {
      ...restyleTheme.textVariants,
      defaults: {},
    },
    buttonVariants: {
      ...buttonVariants.buttonVariants,
      defaults: {},
    },
    borderRadii: {
      [extractCSSValue(webstoreLegacyTheme.storeitemImageBorderRadiusDesktop)]: extractCSSValue(webstoreLegacyTheme.storeitemImageBorderRadiusDesktop),
      [extractCSSValue(webstoreLegacyTheme.storeitemImageBorderRadiusMobile)]: extractCSSValue(webstoreLegacyTheme.storeitemImageBorderRadiusMobile),
      ...restyleTheme.borderRadii,
      ...merchantLevelTheme.borderRadii,
      ...storeLevelTheme.borderRadii,
    },
    fontSizes: {
      [extractCSSValue(webstoreLegacyTheme.headerFontSize)]: extractCSSValue(webstoreLegacyTheme.headerFontSize),
      [extractCSSValue(webstoreLegacyTheme.headerCustomButtonFontSize)]: extractCSSValue(webstoreLegacyTheme.headerCustomButtonFontSize),
      [extractCSSValue(webstoreLegacyTheme.headerTopNavBar.fontSize)]: extractCSSValue(webstoreLegacyTheme.headerTopNavBar.fontSize),
      [extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexFontSize)]: extractCSSValue(webstoreLegacyTheme.heroBlockPlainTexFontSize),
      [extractCSSValue(webstoreLegacyTheme.heroBlockScheduleHoursFontSize)]: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleHoursFontSize),
      [extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysFontSize)]: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleDaysFontSize),
      [extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFontSize)]: extractCSSValue(webstoreLegacyTheme.heroBlockScheduleFontSize),
      [extractCSSValue(webstoreLegacyTheme.menuTextFontSize)]: extractCSSValue(webstoreLegacyTheme.menuTextFontSize),
      [extractCSSValue(webstoreLegacyTheme.heroblockTitleFontSize)]: extractCSSValue(webstoreLegacyTheme.heroblockTitleFontSize),
      [extractCSSValue(webstoreLegacyTheme.heroblockFontSize)]: extractCSSValue(webstoreLegacyTheme.heroblockFontSize),
      [extractCSSValue(webstoreLegacyTheme?.socialMedia?.FOOTER?.fontSize)]: extractCSSValue(webstoreLegacyTheme?.socialMedia?.FOOTER?.fontSize),
      [extractCSSValue(webstoreLegacyTheme?.socialMedia?.SIDEBAR?.fontSize)]: extractCSSValue(webstoreLegacyTheme?.socialMedia?.SIDEBAR?.fontSize),
      [extractCSSValue(webstoreLegacyTheme.orderToTable.fontSize)]: extractCSSValue(webstoreLegacyTheme.orderToTable.fontSize),
      [extractCSSValue(webstoreLegacyTheme.promotionsBar.fontSize)]: extractCSSValue(webstoreLegacyTheme.promotionsBar.fontSize),
      [extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSize)]: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSize),
      [extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSizeMobile)]: extractCSSValue(webstoreLegacyTheme.itemDescriptionFontSizeMobile),
      [extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontSize)]: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontSize),
      [extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontSizeDesktop)]: extractCSSValue(webstoreLegacyTheme.storeitemPriceGroupFontSizeDesktop),
      [extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontSize)]: extractCSSValue(webstoreLegacyTheme.menuListItemSchedule?.fontSize),
      [extractCSSValue(webstoreLegacyTheme.storeitemTitleSize)]: extractCSSValue(webstoreLegacyTheme.storeitemTitleSize),
      [extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.fontSize)]: extractCSSValue(webstoreLegacyTheme.menuListCategorySchedule?.fontSize),
      [extractCSSValue(webstoreLegacyTheme.categoryTitleFontSize)]: extractCSSValue(webstoreLegacyTheme.categoryTitleFontSize),
      [extractCSSValue(webstoreLegacyTheme.categorySubtitleFontSize)]: extractCSSValue(webstoreLegacyTheme.categorySubtitleFontSize),
      [extractCSSValue(webstoreLegacyTheme.categoryTitleFontSizeDesktop)]: extractCSSValue(webstoreLegacyTheme.categoryTitleFontSizeDesktop),
      [extractCSSValue(webstoreLegacyTheme.prepTimeCartBlock?.fontSize)]: extractCSSValue(webstoreLegacyTheme.prepTimeCartBlock?.fontSize),
      [extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.fontSize)]: extractCSSValue(webstoreLegacyTheme.itemModalScheduleBlock?.fontSize),
      [extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.fontSize)]: extractCSSValue(webstoreLegacyTheme.prepTimeBlock?.fontSize),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.container?.sm, 'fontSize'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.item?.sm, 'fontSize'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.container?.sm, 'fontSize'),
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.activeItem?.sm, 'fontSize'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.container?.sm, 'fontSize'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.title?.sm, 'fontSize'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.description?.sm, 'fontSize'),
      // @ts-expect-error wrong key for the theme, only for legacy
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.logoContainer?.sm, 'fontSize'),
      ...extractKeysFromKeyString(hoveredMenuConceptsItemStyles, 'fontSize'),
      ...restyleTheme.fontSizes,
      ...merchantLevelTheme.fontSizes,
      ...storeLevelTheme.fontSizes,
    },
    spacing: {
      [extractCSSValue(webstoreLegacyTheme.heroBlockBannerRatio)]: convertUnspecifiedValueToPixels(extractCSSValue(webstoreLegacyTheme.heroBlockBannerRatio)),
      ...extractSpacingKeyValues(webstoreLegacyTheme.heroBlockPlainTexMargin, 'margin'),
      ...extractSpacingKeyValues(webstoreLegacyTheme.heroblockMargin, 'margin'),
      ...extractSpacingKeyValues(webstoreLegacyTheme.heroBlockScheduleMargin, 'margin'),
      // @ts-expect-error missing key in legacy theme
      ...extractSpacingKeyValues(webstoreLegacyTheme?.menuConcepts?.bar?.container?.sm?.padding, 'padding'),
      // @ts-expect-error missing key in legacy theme
      ...extractKeysFromKeyString(webstoreLegacyTheme?.menuConcepts?.bar?.activeItem?.sm?.padding, 'padding'),
      // @ts-expect-error missing key in legacy theme
      ...extractSpacingKeyValues(webstoreLegacyTheme?.menuConcepts?.bar?.item?.sm?.padding, 'padding'),
      // @ts-expect-error missing key in legacy theme
      ...extractSpacingKeyValues(webstoreLegacyTheme?.menuConcepts?.block?.container?.sm?.padding, 'padding'),
      // @ts-expect-error missing key in legacy theme
      ...extractSpacingKeyValues(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.container?.sm?.padding, 'padding'),
      // @ts-expect-error missing key in legacy theme
      ...extractSpacingKeyValues(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.title?.sm?.padding, 'padding'),
      // @ts-expect-error missing key in legacy theme
      ...extractSpacingKeyValues(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.description?.sm?.padding, 'padding'),
      // @ts-expect-error missing key in legacy theme
      ...extractSpacingKeyValues(webstoreLegacyTheme?.menuConcepts?.block?.infoBlock?.logoContainer?.sm?.padding, 'padding'),
      [extractCSSValue(webstoreLegacyTheme.menuVerticalGapDesktop)]: convertUnspecifiedValueToPixels(
        extractCSSValue(webstoreLegacyTheme.menuVerticalGapDesktop),
      ),
      [extractCSSValue(webstoreLegacyTheme.menuHorizontalGap)]: convertUnspecifiedValueToPixels(extractCSSValue(webstoreLegacyTheme.menuHorizontalGap)),
      [extractCSSValue(webstoreLegacyTheme.mobileMenuHorizontalGap)]: convertUnspecifiedValueToPixels(
        extractCSSValue(webstoreLegacyTheme.mobileMenuHorizontalGap),
      ),
      [extractCSSValue(webstoreLegacyTheme.menuVerticalGap)]: convertUnspecifiedValueToPixels(extractCSSValue(webstoreLegacyTheme.menuVerticalGap)),
      ...getSpacingThemeKeyValuePairs(webstoreLegacyTheme.categoryTitlePadding),
      ...getSpacingThemeKeyValuePairs(webstoreLegacyTheme.categorySubtitlePadding),
      ...getSpacingThemeKeyValuePairs(webstoreLegacyTheme.categoryTitlePaddingDesktop),
      ...getSpacingThemeKeyValuePairs(webstoreLegacyTheme.categorySubtitlePaddingDesktop),
      ...restyleTheme.spacing,
      ...merchantLevelTheme.spacing,
      ...storeLevelTheme.spacing,
    },
  });

  return theme;
};
