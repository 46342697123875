import { useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useViewportHeight = () => {
  const getViewportHeight = debounce(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 300);

  const handleResize = useCallback(() => {
    getViewportHeight();
  }, [getViewportHeight]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};
