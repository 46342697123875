import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useParentWidth } from '@webstore-monorepo/shared/hooks/use-parent-width';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import React, { forwardRef } from 'react';

import { MenuItemContent } from '../../menu-item-content';
import { MenuItemPriceGroup } from '../../menu-item-price-group';
import { MenuItemPrice } from '../../menu-item-price-group/menu-item-price';
import { MenuItemSchedule } from '../../menu-item-schedule';
import { MenuItemThumbnail } from '../../menu-item-thumbnail';
import { MenuItemTitle } from '../../menu-item-title';

export const CardMobileLegacy = forwardRef((props: BoxProps<Theme>, forwardedRef) => {
  const { menuItemCardMobile } = useComponentsConfig();
  const { parentWidth, onParentChange } = useParentWidth({ defaultWidth: 117 });

  return (
    <Box ref={forwardedRef} {...props} p={3} alignItems="flex-start" justifyContent="space-between" {...menuItemCardMobile?.wrapper?.style}>
      <Box width="100%">
        <MenuItemSchedule textProps={{ textAlign: 'left', fontSize: 'xxs' }} />
        <Box flexDirection="row" flexGrow={1} flexShrink={0} justifyContent="space-between" alignItems="flex-start">
          <MenuItemTitle marginRight={1} flexGrow={1} flexShrink={1} marginBottom={2} textProps={{ textAlign: 'left', fontSize: 'm' }} />
          <MenuItemPriceGroup width="auto">
            <MenuItemPrice textProps={{ textAlign: 'right', paddingRight: 1 }} />
          </MenuItemPriceGroup>
        </Box>
      </Box>
      <Box width="100%" flexDirection="row" flexGrow={1} flexShrink={0} alignItems="flex-start" justifyContent="space-between">
        <Box flexBasis="50%">
          <MenuItemContent flexGrow={1} flexShrink={0} marginBottom={4} paddingRight={4} textProps={{ textAlign: 'left' }} />
        </Box>
        <Box ref={onParentChange} flexBasis="50%" flexGrow={1} flexShrink={0} flexDirection="row">
          <MenuItemThumbnail parentWidth={parentWidth} />
        </Box>
      </Box>
    </Box>
  );
});
