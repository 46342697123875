import type { IFontAndShapeApp, SocialMediaDisplayAreaEnum, SocialMediaTypeEnum } from '@goparrot/webstore-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { SocialMediaIcon } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Image, Pressable } from 'react-native';

type Props = {
  displayArea: SocialMediaDisplayAreaEnum;
  baseTextStyles?: IFontAndShapeApp;
  onSocialLinkPress: (url: string) => void;
};

export const SocialMediaLinks: React.FC<Props> = ({ displayArea, baseTextStyles, onSocialLinkPress }) => {
  const { socialMedia } = useComponentsConfig();
  const textStyles = baseTextStyles
    ? { ...baseTextStyles, ...socialMedia?.wrapper?.[displayArea]?.text?.style }
    : socialMedia?.wrapper?.[displayArea]?.text?.style;
  if (
    !(
      socialMedia?.wrapper?.options?.isShown &&
      socialMedia?.wrapper?.options?.displayAreas?.includes(displayArea) &&
      !isEmpty(socialMedia?.wrapper?.options?.links)
    )
  ) {
    return null;
  }

  const links = socialMedia?.wrapper?.options.links;
  const followText = socialMedia?.wrapper?.options?.text ?? 'Follow us on social';

  const handleLinkPress = (url: string) => () => {
    onSocialLinkPress?.(url);
  };

  return (
    <>
      <Box marginBottom={1}>
        <Text fontSize="xxl" {...textStyles}>
          {followText}
        </Text>
      </Box>
      <Box flexDirection="row" justifyContent="center" alignItems="center" {...socialMedia?.wrapper?.style}>
        {links &&
          Object.keys(links).map((key, index) => (
            <Pressable
              accessibilityLabel={`${key} link`}
              accessibilityHint={`Press to open ${key}`}
              accessibilityRole="link"
              key={key}
              testID={`social-media-link-${key}`}
              onPress={handleLinkPress(links[key as SocialMediaTypeEnum]?.url ?? '')}
              {...socialMedia?.wrapper?.[displayArea]?.icon?.style}
              style={{ marginRight: index < Object.keys(links).length - 1 ? 13 : 0 }}
            >
              {links[key as SocialMediaTypeEnum]?.iconUrl ? (
                <Image
                  accessibilityLabel={key}
                  accessibilityHint="social media link"
                  accessibilityIgnoresInvertColors
                  accessibilityRole="none"
                  source={{ uri: links[key as SocialMediaTypeEnum]?.iconUrl }}
                  style={{ width: 32, height: 32 }}
                />
              ) : (
                <SocialMediaIcon socialType={key as SocialMediaTypeEnum} fill={socialMedia?.wrapper?.[displayArea]?.icon?.style?.color} stroke="transparent" />
              )}
            </Pressable>
          ))}
      </Box>
    </>
  );
};
