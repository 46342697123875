import React from 'react';
import { Navigate } from 'react-router';
import type { RouteObject } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';

import App from '../pages/App';
import { Menu } from '../pages/menu';
import { MENU_ROUTES } from '../pages/routes';
import { APP_PATHS } from './paths';

const routeMap = (parent: string) => (item: RouteObject) => ({ ...item, path: `${parent}${item.path}` });

export const APP_ROUTES: RouteObject[] = [
  {
    path: APP_PATHS.home,
    element: <Menu />,
    children: MENU_ROUTES.map(routeMap(APP_PATHS.home)),
  },
  {
    path: '*',
    element: <Navigate to="/menu" replace />,
  },
];

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <App />,
      children: APP_ROUTES,
    },
  ],
  {
    basename: window.baseUrl,
  },
);
