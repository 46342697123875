import type { IOrder } from '@goparrot/order-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { formatPrice } from '@webstore-monorepo/shared/utils/format-strings';
import { sliceByWord } from '@webstore-monorepo/shared/utils/text';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import { DateTime } from 'luxon';
import React from 'react';
import { Pressable } from 'react-native';

export type PreviousOrdersItemProps = {
  item: IOrder;
  onItemClick: (order: IOrder) => void;
};
export const PreviousOrdersItem = ({ item, onItemClick }: PreviousOrdersItemProps) => {
  const { isMobile } = useWindowDimensions();
  const { previousOrdersList } = useComponentsConfig();
  const { title, dateInfo, total } = item;

  const preparedTitle = sliceByWord(title, 65);
  const formattedPrice = formatPrice(total);
  const date = dateInfo ? DateTime.fromISO(dateInfo.startPrepAt as string).toLocaleString(DateTime.DATE_MED) : '';

  const handleItemClick = () => {
    onItemClick(item);
  };

  return (
    <Pressable
      accessibilityLabel={`previous order ${preparedTitle}`}
      accessibilityHint={`press previous order ${preparedTitle} to show details`}
      accessibilityRole="button"
      testID={`previous-order-item-${item.uuid}`}
      onPress={handleItemClick}
      style={{ height: '100%' }}
    >
      <Box
        height="100%"
        borderRadius="md"
        borderColor="gray200"
        borderWidth={1}
        backgroundColor="gray100"
        paddingTop={4}
        paddingRight={4}
        paddingBottom={4}
        paddingLeft={4}
        justifyContent="space-between"
      >
        <Box>
          <Box flexDirection="row" justifyContent="space-between" marginBottom={2}>
            <Box {...previousOrdersList?.wrapper?.items?.date?.text?.style} {...(isMobile ? previousOrdersList?.wrapperMobile?.items?.date?.text?.style : {})}>
              <Text
                fontSize="m"
                color="gray600"
                {...previousOrdersList?.wrapper?.items?.date?.text?.style}
                {...(isMobile ? previousOrdersList?.wrapperMobile?.items?.date?.text?.style : {})}
              >
                {date}
              </Text>
            </Box>
          </Box>
          <Box
            marginBottom={4}
            {...previousOrdersList?.wrapper?.items?.content?.style}
            {...(isMobile ? previousOrdersList?.wrapperMobile?.items?.content?.style : {})}
          >
            <Text
              fontSize="l"
              color="black"
              numberOfLines={2}
              {...previousOrdersList?.wrapper?.items?.content?.text?.style}
              {...(isMobile ? previousOrdersList?.wrapperMobile?.items?.content?.text?.style : {})}
            >
              {preparedTitle}
            </Text>
          </Box>
        </Box>
        <Box {...previousOrdersList?.wrapper?.items?.total?.style} {...(isMobile ? previousOrdersList?.wrapperMobile?.items?.total?.style : {})}>
          <Text
            fontSize="l"
            color="black"
            {...previousOrdersList?.wrapper?.items?.total?.text?.style}
            {...(isMobile ? previousOrdersList?.wrapperMobile?.items?.total?.text?.style : {})}
          >
            {formattedPrice}
          </Text>
        </Box>
      </Box>
    </Pressable>
  );
};
