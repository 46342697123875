import { SocialMediaDisplayAreaEnum } from '@goparrot/webstore-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box, InlineBox } from '@webstore-monorepo/ui/box';
import { SocialMediaLinks } from '@webstore-monorepo/ui/social-media-links';
import { Text } from '@webstore-monorepo/ui/text';
import DOMPurify from 'dompurify';
import type { FC } from 'react';
import React from 'react';
import { RenderHTML } from 'react-native-render-html';

import { getFormattedStoreInformation } from './utils';

type Props = {
  onSocialLinkClick: (url: string) => void;
};
export const AppFooter: FC<Props> = ({ onSocialLinkClick }) => {
  const webstore = useWebStore();
  const store = useStoreState();
  const { storeTitle } = webstore;
  const { footer, socialMedia } = useComponentsConfig();
  const disclaimer = footer?.wrapper?.options?.disclaimer;
  const formattedStoreInformation =
    footer?.wrapper?.options?.contactsTemplate && getFormattedStoreInformation(store, footer?.wrapper?.options?.contactsTemplate);
  const date = new Date().getFullYear();
  const { isMobile } = useWindowDimensions();

  const renderersProps = {
    a: {
      onPress: (_event: any, href: string) => onSocialLinkClick(href),
    },
  };

  return (
    <Box
      testID="footer"
      paddingLeft={2}
      paddingRight={2}
      flexGrow={1}
      flexShrink={0}
      flexBasis={0}
      position="relative"
      paddingTop={10}
      paddingBottom={10}
      flexDirection="row"
      {...footer?.wrapper?.style}
    >
      <Box flexGrow={1} flexShrink={0} flexBasis={0} alignItems="center" justifyContent="center">
        <Box marginBottom={6}>
          {formattedStoreInformation ? (
            <InlineBox
              flexGrow={1}
              flexShrink={0}
              flexBasis={0}
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
              testID="footer-store-contacts"
              flexDirection={isMobile ? 'column' : 'row'}
            >
              {formattedStoreInformation.map((item, index) => (
                <Box
                  accessibilityLabel="store information"
                  accessibilityHint="store information item"
                  accessibilityRole="text"
                  flexDirection="row"
                  alignItems="center"
                  testID={`footer-store-contacts-${index}`}
                  key={index}
                >
                  <Text {...footer?.wrapper?.text?.style} textAlign="center">
                    {/*// @ts-ignore*/}
                    <RenderHTML
                      renderersProps={renderersProps}
                      source={{ html: DOMPurify.sanitize(item) }}
                      tagsStyles={{ a: { textDecorationLine: 'none' } }}
                    />
                  </Text>
                  {!isMobile ? (
                    formattedStoreInformation.length - 1 !== index ? (
                      <Text paddingLeft={3} paddingRight={3} {...footer?.wrapper?.text?.style}>
                        |
                      </Text>
                    ) : null
                  ) : null}
                </Box>
              ))}
            </InlineBox>
          ) : null}
        </Box>
        {disclaimer ? (
          <Box marginBottom={3}>
            <Box>
              <Text accessibilityLabel="disclaimer" accessibilityHint="footer disclaimer" textAlign="center" {...footer?.wrapper?.text?.style}>
                <RenderHTML renderersProps={renderersProps} source={{ html: DOMPurify.sanitize(disclaimer) }} enableCSSInlineProcessing={false} />
              </Text>
            </Box>
          </Box>
        ) : null}
        <Box marginBottom={6}>
          <Box testID="copyright" justifyContent="center" alignItems="center">
            <Text fontSize="s" lineHeight={20} {...footer?.wrapper?.text?.style}>
              Copyright <Text {...footer?.wrapper?.text?.style}>&copy;</Text> {date} {storeTitle}.
            </Text>
            <Text fontSize="s" lineHeight={20} {...footer?.wrapper?.text?.style}>
              All rights reserved.
            </Text>
          </Box>
        </Box>
        {socialMedia?.wrapper?.options?.isShown ? (
          <Box marginBottom={5} testID="social-wrapper">
            <SocialMediaLinks
              displayArea={SocialMediaDisplayAreaEnum.FOOTER}
              baseTextStyles={footer?.wrapper?.text?.style}
              onSocialLinkPress={onSocialLinkClick}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
