import { StoreItemValidityEnum } from '@goparrot/common';
import type { PreviewTerminologyDto, ReadStoreLoyaltyRewardDto } from '@goparrot/loyalty-sdk';
import type { IOrderElement, IOrderPromotionElement, OrderSelectionType } from '@goparrot/order-sdk';
import { OrderElementTypeEnum } from '@goparrot/order-sdk';
import type { IPromotionWithApplicationState } from '@goparrot/promotions-sdk';
import { PromotionSubTypeEnum } from '@goparrot/promotions-sdk';
import type { IReadGrammarNumberFormDto } from '@goparrot/store-v2-sdk';
import { ItemCounter } from '@webstore-monorepo/features/item-counter';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon, DefaultCartImage, NoteIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import {
  findInvalidPropertyIndex,
  hasItemPaidModifiers,
  isItemAddedWithDiscount,
  isItemDiscounted,
  isQuantityDisabledForDiscountedItem,
} from '@webstore-monorepo/shared/utils/cart';
import { getPromoItemQtyInputProps, isBogo, isPromoCode, showPaidModifiersDiscountNotice } from '@webstore-monorepo/shared/utils/promotions';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { LazyImage } from '@webstore-monorepo/ui/lazy-image';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Platform, Pressable } from 'react-native';

import { useCalculateCartItemsParams } from './hooks/useCalculateCartItemsParams';
import { OrderItemWarningText } from './OrderItemWarningText';
import { PrepTime } from './PrepTime';

interface Props {
  item: IOrderElement;
  isReadOnly?: boolean;
  onRemoveItemFromCart?: (item: IOrderElement) => void;
  onChangeItemQuantity?: (value: number) => void;
  onEditItemClick?: (itemId: string, state?: any) => void;
  promotions?: IPromotionWithApplicationState[];
  loyalties?: ReadStoreLoyaltyRewardDto[];
  loyaltyPoints: IReadGrammarNumberFormDto;
  isOrderDetails?: boolean;
  orderPromotions?: IOrderPromotionElement[];
  borderColor?: keyof Theme['colors'];
}

const pluralize = (points: number, pointNames: PreviewTerminologyDto): string => ([1, -1].includes(points) ? pointNames.singular : pointNames.plural);

export const OrderItem: React.FC<Props> = ({
  item,
  isReadOnly,
  onChangeItemQuantity,
  promotions,
  loyalties,
  onRemoveItemFromCart,
  onEditItemClick,
  loyaltyPoints,
  isOrderDetails = false,
  orderPromotions = [],
  borderColor = 'gray100',
}) => {
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const cartOptions = isMobile && Platform.OS === 'web' ? cartScreen?.wrapperMobile : cartScreen?.wrapper;

  const {
    relatedPromotion,
    itemTotalPrice,
    isLoyaltyReward,
    loyaltyAmount,
    elementAllowedMinQuantity,
    elementAllowedMaxQuantity,
    baseItemAllowedMinQuantity,
    baseItemAllowedMaxQuantity,
    storeId,
    cartPromotions,
  } = useCalculateCartItemsParams(item, orderPromotions, isOrderDetails);

  const loyaltyPointNames = pluralize(loyaltyAmount || 0, loyaltyPoints);
  // this logic should be moved to backend in future
  const showQuantityForDiscountedItem = isItemAddedWithDiscount(item) || isQuantityDisabledForDiscountedItem(item);
  const isDiscounted = isItemDiscounted(item);
  const { analytics, intl } = usePlatformStoreState();
  const imageUrl = item?.image_url_square || item?.image_url || cartOptions?.items?.image?.options?.defaultImage;

  const qtyInputProps = getPromoItemQtyInputProps({
    cartPromo: relatedPromotion,
    promotions,
    loyalties,
    storeId,
    item,
  });

  const handleEditItem = () => {
    const updateItem = {
      ...item,
      editable: true,
    };
    analytics.track('cart_item_edit', {
      uid: updateItem.uniqueName,
    });

    if (onEditItemClick) {
      onEditItemClick(item.uniqueNameOriginal || '', {
        uniqueName: updateItem.uniqueName,
        uniqueNameOriginal: updateItem.uniqueNameOriginal,
        quantity: updateItem.quantity,
        menuConceptUuid: updateItem.menuConceptUuid,
        withPromo: relatedPromotion
          ? {
              uuid: relatedPromotion.uuid,
              subtype: relatedPromotion?.subtype,
              targetQty: qtyInputProps.defaultQty,
              numberOfApplies: qtyInputProps.appliesNumber,
            }
          : undefined,
      });
    }
  };

  const getModifiers = (selections: OrderSelectionType[]): string => {
    return selections
      .map(
        (selection) =>
          selection.selected &&
          (selection.type === OrderElementTypeEnum.SELECTION_GROUP
            ? selection.properties
                .map((prop) => prop.selected && prop.title)
                .filter((selected) => !!selected)
                .join(', ')
            : `${selection.title} (+${intl.formatNumber(selection.price)})`),
      )
      .filter((selected) => !!selected)
      .join(', ');
  };

  const renderImage = () => {
    return (
      <Box overflow="hidden" marginRight={4} width={70} height={70} alignItems="center" {...cartOptions?.items?.image?.style}>
        {imageUrl ? (
          //@ts-expect-error allow string value for width
          <Box width={Platform.OS === 'web' ? 'max-content' : '100%'} height={cartOptions?.items?.image?.style?.height || 70}>
            <LazyImage
              style={{ flex: 1 }}
              height={cartOptions?.items?.image?.style?.height || 70}
              accessibilityLabel={item.title}
              accessibilityHint="your order item thumbnail"
              src={imageUrl}
              alt={item.title}
              resizeMode="cover"
            />
          </Box>
        ) : (
          <DefaultCartImage width={70} height={70} fill="gray300" stroke="transparent" />
        )}
      </Box>
    );
  };

  return (
    <Box borderBottomWidth={1} borderColor={borderColor} {...cartOptions?.items?.separator?.style} testID="cart-item">
      <Box paddingTop={4} paddingBottom={4} flexDirection="row">
        {isReadOnly ? (
          renderImage()
        ) : (
          <Pressable
            accessibilityLabel={`Cart item ${item.title}`}
            accessibilityHint={`press on the menu item ${item.title} to edit it`}
            accessibilityRole="button"
            onPress={handleEditItem}
            style={{ alignSelf: 'flex-start' }}
          >
            {renderImage()}
          </Pressable>
        )}
        <Box flex={1}>
          <Box>
            <Box flexDirection="row" justifyContent="space-between">
              {!isReadOnly ? (
                <Pressable
                  accessibilityLabel={`Cart item ${item.title}`}
                  accessibilityHint={`press on the menu item ${item.title} to edit it`}
                  accessibilityRole="button"
                  onPress={!isReadOnly ? handleEditItem : () => null}
                  style={{ alignSelf: 'flex-start', marginBottom: 1, flex: 1 }}
                >
                  <Text lineHeight={24} color="text" fontSize="m" fontWeight="700" testID="item-title" {...cartOptions?.items?.title?.style}>
                    {item.titleOriginal || item.title}
                  </Text>
                </Pressable>
              ) : (
                <Box flexDirection="row" flex={1}>
                  <Text lineHeight={24} color="text" fontSize="m" fontWeight="700" testID="item-title" {...cartOptions?.items?.title?.style}>
                    {item.titleOriginal || item.title}
                  </Text>
                </Box>
              )}
            </Box>

            {isReadOnly && (
              <Text lineHeight={22} marginTop={2} color="text" testID="item-quantity" {...cartOptions?.items?.quantity?.style}>
                {cartOptions?.items?.quantity?.options?.text || 'Qty'}: {item.quantity}
              </Text>
            )}

            {(!item.validity || item.validity !== StoreItemValidityEnum.MISSING_ITEM) && !isReadOnly ? (
              <Text
                color="text"
                accessibilityLabel={`Item price ${item.price}`}
                lineHeight={24}
                fontSize="m"
                marginTop={2}
                testID="item-price"
                {...cartOptions?.items?.price?.style}
              >
                {intl.formatNumber(item.price)}
              </Text>
            ) : null}

            {item.metadata.prepTime ? <PrepTime prepTime={item.metadata.prepTime} /> : null}

            {isDiscounted ? (
              <Text lineHeight={24} marginTop={2} fontSize="m" fontWeight="700" color="success" testID="discount-name" {...cartOptions?.items?.discount?.style}>
                {relatedPromotion?.title}
                {relatedPromotion?.subtype === PromotionSubTypeEnum.LOYALTY ? ` (${loyaltyAmount} ${loyaltyPointNames})` : null}
              </Text>
            ) : null}

            {!isOrderDetails ? (
              <>
                {item.validity === StoreItemValidityEnum.MISSING_SUB_ITEM ? (
                  <OrderItemWarningText header="Some of the item modifications are unavailable." description="Click the 'Edit' button to edit them." />
                ) : null}

                {/*@ts-ignore*/}
                {findInvalidPropertyIndex(item?.properties) !== -1 ? (
                  <OrderItemWarningText header="Item modifications are required." description="Click the 'Edit' button to edit them." />
                ) : null}

                {item.validity === StoreItemValidityEnum.MISSING_ITEM ? (
                  <OrderItemWarningText header="This item is unavailable." description="Please remove it from the cart." />
                ) : null}

                {(item.validity === StoreItemValidityEnum.EXCEEDED_MAX_QUANTITY || item.validity === StoreItemValidityEnum.SUBSIDED_MIN_QUANTITY) &&
                !isItemDiscounted(item) ? (
                  <OrderItemWarningText
                    header={`Item has limited purchase quantity. Please choose between ${baseItemAllowedMinQuantity} and ${baseItemAllowedMaxQuantity}`}
                  />
                ) : null}
              </>
            ) : null}

            {hasItemPaidModifiers(item) && showPaidModifiersDiscountNotice(cartPromotions, relatedPromotion) ? (
              <Text
                marginTop={3}
                lineHeight={22}
                fontSize="m"
                fontWeight="700"
                color="warningText"
                testID="paid-modifiers"
                {...cartOptions?.items?.modifiers?.style}
              >
                Paid modifiers are not discounted
              </Text>
            ) : null}

            {item.properties?.map(
              (property) =>
                property.selected &&
                !property.isHidden && (
                  <Box marginTop={3} key={property.uid} testID="item-property">
                    <Text color="text" lineHeight={22} fontSize="s" fontWeight="700" {...cartOptions?.items?.subTitle?.style}>
                      {property.title}
                    </Text>
                    <Box testID="checkOuter">
                      <Text lineHeight={22} fontSize="s" color="gray500" {...cartOptions?.items?.text?.style}>
                        {getModifiers(property.selections)}
                      </Text>
                    </Box>
                  </Box>
                ),
            )}

            {item.metadata.notes ? (
              <Box marginTop={3} testID="item-metadata-notes">
                <Box alignItems="center" flexDirection="row">
                  {cartOptions?.itemsList?.itemNotesMessage ? (
                    <Text color="text" marginLeft={1} lineHeight={22} fontSize="s" fontWeight="700" {...cartOptions?.items?.subTitle?.style}>
                      {cartOptions?.itemsList?.itemNotesMessage}
                    </Text>
                  ) : (
                    <>
                      <NoteIcon stroke="none" fill={cartOptions?.items?.subTitle?.style?.color || 'gray900'} width={24} height={24} />
                      <Text color="text" marginLeft={1} lineHeight={22} fontSize="s" fontWeight="700" {...cartOptions?.items?.subTitle?.style}>
                        Note
                      </Text>
                    </>
                  )}
                </Box>
                <Text lineHeight={22} fontSize="s" color="gray500" {...cartOptions?.items?.text?.style}>
                  {item.metadata.notes}
                </Text>
              </Box>
            ) : null}
          </Box>

          {!isReadOnly && (
            <Box marginTop={3} flexDirection="row" alignItems="center">
              {(!item.validity || item.validity !== StoreItemValidityEnum.MISSING_ITEM) && onChangeItemQuantity ? (
                showQuantityForDiscountedItem ? (
                  (isBogo(relatedPromotion) && !isPromoCode(relatedPromotion)) || isLoyaltyReward ? (
                    <Box testID="item-counter-wrapper">
                      <ItemCounter
                        key={item.quantity}
                        disabled={!isLoyaltyReward}
                        step={qtyInputProps.defaultQty}
                        elementMin={qtyInputProps.defaultQty}
                        elementMax={qtyInputProps.appliesNumber * qtyInputProps.defaultQty + item.quantity}
                        onChange={onChangeItemQuantity}
                        value={item.quantity}
                      />
                    </Box>
                  ) : (
                    <Box testID="item-counter-wrapper">
                      <ItemCounter
                        key={item.quantity}
                        disabled={true}
                        step={qtyInputProps.defaultQty}
                        elementMin={qtyInputProps.defaultQty}
                        elementMax={qtyInputProps.appliesNumber * qtyInputProps.defaultQty + item.quantity}
                        onChange={onChangeItemQuantity}
                        value={item.quantity}
                      />
                    </Box>
                  )
                ) : (
                  <Box testID="item-counter-wrapper">
                    <ItemCounter
                      key={item.quantity}
                      disabled={isQuantityDisabledForDiscountedItem(item)}
                      itemMin={baseItemAllowedMinQuantity}
                      itemMax={baseItemAllowedMaxQuantity}
                      elementMin={elementAllowedMinQuantity}
                      elementMax={elementAllowedMaxQuantity}
                      onChange={(value: number) => {
                        onChangeItemQuantity(value);
                      }}
                      value={item.quantity}
                      metaData={{ item }}
                    />
                  </Box>
                )
              ) : (
                <Text color="text" testID="item-quantity" {...cartOptions?.items?.quantity?.style}>
                  {cartOptions?.items?.quantity?.options?.text || 'Qty'}: {item.quantity}
                </Text>
              )}

              {(!item.validity || item.validity !== StoreItemValidityEnum.MISSING_ITEM) && !isReadOnly ? (
                <Box marginLeft={4} aria-label={`Item total ${isDiscounted ? itemTotalPrice.discounted : itemTotalPrice.origin}`} testID="item-total">
                  <Box flexDirection="row">
                    <Text
                      lineHeight={24}
                      fontSize="m"
                      marginRight={2}
                      textDecorationLine={isDiscounted ? 'line-through' : undefined}
                      {...cartOptions?.items?.price?.style}
                      color={isDiscounted ? 'gray400' : cartOptions?.items?.price?.style?.color || 'gray900'}
                    >
                      {intl.formatNumber(itemTotalPrice.origin)}
                    </Text>
                    {isDiscounted && (
                      <Text lineHeight={24} fontSize="m" {...cartOptions?.items?.price?.style}>
                        {intl.formatNumber(itemTotalPrice.discounted)}
                      </Text>
                    )}
                  </Box>
                  {/*TODO: Loyalty*/}
                  {/*{relatedPromotion?.subtype === PromotionSubTypeEnum.LOYALTY && (*/}
                  {/*  <LoyaltyBadge*/}
                  {/*    amount={relatedPromotion.loyaltyInfo.rule.points * item.quantity}*/}
                  {/*    loyaltyPointIcon={getLoyaltyRewardIcon(webstore.metadata)}*/}
                  {/*    pointNames={appContext.loyaltyPointName}*/}
                  {/*  />*/}
                  {/*)}*/}
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
        {!isReadOnly && onRemoveItemFromCart ? (
          <Box marginLeft={1}>
            <Button
              testID="remove-item-from-cart"
              accessibilityLabel="Remove item from cart"
              buttonStyle={{
                alignItems: 'flex-start',
              }}
              color="gray400"
              variant="link"
              onPress={() => onRemoveItemFromCart(item)}
              leftIcon={<CloseIcon stroke="gray900" width={14} height={14} strokeWidth={1.5} />}
            />
          </Box>
        ) : null}

        {isReadOnly && (
          <Box marginLeft={2} alignItems="flex-end">
            {isDiscounted && (
              <Text lineHeight={24} color="text" fontSize="m" testID="item-discounted-price" {...cartOptions?.items?.price?.style}>
                {intl.formatNumber(itemTotalPrice.discounted)}
              </Text>
            )}
            <Text
              lineHeight={24}
              fontSize="m"
              textDecorationLine={isDiscounted ? 'line-through' : undefined}
              testID="item-price"
              {...cartOptions?.items?.price?.style}
              color={isDiscounted ? 'gray400' : cartOptions?.items?.price?.style?.color || 'gray900'}
            >
              {intl.formatNumber(itemTotalPrice.origin)}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};
