import type { IMenuConcept } from '@goparrot/storeitems-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef } from 'react';
import { Image, Pressable } from 'react-native';

type MenuConceptsItemProps = {
  isActive: boolean;
  onClick: (uniqueName: string, title: string) => void;
  item: IMenuConcept;
  boxProps?: BoxProps<Theme>;
};
export const MenuConceptsItem = forwardRef(({ isActive, onClick, item, boxProps }: MenuConceptsItemProps, forwardedRef) => {
  const { menuConceptsBar } = useComponentsConfig();
  const { uniqueName, title, imageUrlSquare } = item;
  const { isMobile } = useWindowDimensions();

  const itemStyles = {
    ...menuConceptsBar?.wrapper?.item?.style,
    ...(isMobile ? { ...menuConceptsBar?.wrapper?.itemMobile?.style, ...(isActive ? menuConceptsBar?.wrapper?.itemMobileActive?.style : {}) } : {}),
    ...(isActive
      ? {
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.2,
          shadowRadius: 4,
          ...menuConceptsBar?.wrapper?.itemActive?.style,
        }
      : {}),
  };
  const textStyles = {
    ...menuConceptsBar?.wrapper?.text?.style,
    ...(isMobile ? { ...menuConceptsBar?.wrapper?.textMobile?.style, ...(isActive ? menuConceptsBar?.wrapper?.textMobileActive?.style : {}) } : {}),
    ...(isActive ? menuConceptsBar?.wrapper?.textActive?.style : {}),
  };
  const { isHovered } = useHover({}, forwardedRef);

  const handleClick = () => {
    onClick(uniqueName, title);
  };

  return (
    <Pressable
      accessibilityLabel={`menu concept ${title}`}
      accessibilityHint={`press on the menu concept ${title} to change it`}
      accessibilityRole="button"
      onPress={handleClick}
      style={{ height: '100%', justifyContent: 'center' }}
    >
      <Box
        ref={forwardedRef}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        borderRadius="md"
        backgroundColor="white"
        width={120}
        height={68}
        {...boxProps}
        {...itemStyles}
        {...(isHovered ? menuConceptsBar?.wrapper?.itemHover?.style : {})}
      >
        {imageUrlSquare ? (
          <Box>
            <Image
              accessibilityLabel={title}
              accessibilityHint="menu concepts item image"
              accessibilityIgnoresInvertColors
              accessibilityRole="none"
              source={{ uri: imageUrlSquare }}
              resizeMode="contain"
              style={{ width: 100, height: 48 }}
            />
          </Box>
        ) : (
          <Box
            width={menuConceptsBar?.wrapper?.itemHover?.style?.width ?? 120}
            height={menuConceptsBar?.wrapper?.itemHover?.style?.height ?? 68}
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            flexShrink={0}
            //@ts-expect-error allow string value for flexBasis
            flexBasis="fit-content"
            paddingLeft={2}
            paddingRight={2}
          >
            <Text numberOfLines={2} fontSize="l" textAlign="center" {...textStyles} {...(isHovered ? menuConceptsBar?.wrapper?.textHover?.style : {})}>
              {title}
            </Text>
          </Box>
        )}
      </Box>
    </Pressable>
  );
});
