import { DiningOptionEnum, OrderGratuityTypeEnum, SupportedCurrencyEnum } from '@goparrot/common';
import type { IOrder } from '@goparrot/order-sdk';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ChevronDown } from '@webstore-monorepo/shared/icons';
import { ChevronUp } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import React, { useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { Pressable } from 'react-native';

import { OrderTotalRow } from './checkout-total-row';
import { DeliveryFee } from './delivery-fee';

export interface OrderTotalProps {
  lastOrder?: IOrder;
  showTip?: boolean;
  placement?: 'content' | 'footer';
  page?: 'cart' | 'order-confirmation' | 'order-complete';
}

const getFormattedNumber: (value: number) => React.ReactElement = (value: number) => (
  <FormattedNumber value={value} style="currency" currency={SupportedCurrencyEnum.USD} />
);

export const OrderTotal: React.FC<OrderTotalProps> = ({ lastOrder, showTip, placement = 'content', page = 'cart' }) => {
  const cart = useCartState();
  const store = useStoreState();
  const isInFooter = placement === 'footer';
  const isInOrderComplete = page === 'order-complete';
  const { tipMaxAmount = 0 } = store.delivery || {};
  const [isCollapsed, setIsCollapsed] = useState(isInFooter);
  const order = lastOrder || cart;
  const isDelivery = order.diningOptionInfo?.type === DiningOptionEnum.DELIVERY;
  const { cartScreen, orderConfirmationScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const options =
    page === 'cart' || page === 'order-complete'
      ? isMobile
        ? cartScreen?.wrapperMobile
        : cartScreen?.wrapper
      : isMobile
      ? orderConfirmationScreen?.wrapperMobile
      : orderConfirmationScreen?.wrapper;
  const toggleCollapse = () => setIsCollapsed((prev) => !prev);

  const tipTooltip = isDelivery && tipMaxAmount > 0 ? `Maximum allowed limit for the tip is $${(tipMaxAmount / 100).toFixed(2)}` : undefined;

  return order ? (
    <Box
      accessibilityLabel="order total"
      paddingTop={isInFooter ? 4 : 'none'}
      paddingBottom={isInOrderComplete ? 'none' : 2}
      paddingLeft={isInFooter ? 4 : 'none'}
      paddingRight={isInFooter ? 4 : 'none'}
      borderBottomColor={isInFooter ? 'gray200' : 'transparent'}
      borderBottomWidth={isInFooter ? 1 : 0}
      testID="order-total"
    >
      {isInFooter ? (
        <>
          <OrderTotalRow
            bold
            fontSize="m"
            title={
              <Box flexWrap="nowrap" alignItems="center" flexDirection="row">
                <Text>Total</Text>
                <Box marginLeft={1}>
                  <Pressable accessibilityRole="button" onPress={toggleCollapse}>
                    {isCollapsed ? <ChevronDown width={14} height={14} /> : <ChevronUp width={14} height={14} />}
                  </Pressable>
                </Box>
              </Box>
            }
            amount={getFormattedNumber(order.total)}
          />
          <Box height={0} mb={isCollapsed ? 'none' : 2} />
        </>
      ) : null}
      {!isCollapsed ? (
        <>
          <OrderTotalRow title="Subtotal" amount={getFormattedNumber(order.grossTotal)} />

          {order.total_discount ? <OrderTotalRow title="Discount" amount={getFormattedNumber(-Math.abs(order.total_discount))} /> : null}

          <OrderTotalRow title={options?.orderTotal?.options?.taxTitle || 'Tax'} amount={getFormattedNumber(order.total_tax)} />

          {order.total_gratuity && showTip ? (
            <OrderTotalRow
              title={order.tipsInfo?.type === OrderGratuityTypeEnum.PERCENT ? `Tip (${order?.tipsInfo?.amount}%)` : 'Tip'}
              amount={getFormattedNumber(order.total_gratuity)}
              tooltip={tipTooltip}
            />
          ) : null}

          <DeliveryFee serviceCharges={order.serviceCharges} />
        </>
      ) : null}
      {isInOrderComplete ? <Separator mt={2} mb={4} color="gray200" /> : null}
      {!isInFooter ? <OrderTotalRow bold title="Total" amount={getFormattedNumber(order.total)} /> : null}
    </Box>
  ) : null;
};
