import { checkIfItemsInView } from '@webstore-monorepo/shared/utils/view';
import type { RefObject } from 'react';

export const checkIfItemsCoverWidth = (itemsRef: RefObject<HTMLElement>[] = [], parent?: HTMLElement | null): boolean => {
  const clearItems = itemsRef?.map((item) => item.current)?.filter(Boolean);
  if (parent) {
    const visibleItems = checkIfItemsInView(clearItems, parent);
    return visibleItems.length < clearItems.length;
  }
  return false;
};
