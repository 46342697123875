import type { IOrderElement, IOrderPromotionElement } from '@goparrot/order-sdk';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { calcItemTotalPrice, getAllowedQuantity, getBaseItemAllowedQuantity, getRelatedPromotion } from '@webstore-monorepo/shared/utils/cart';
import { isLoyalty } from '@webstore-monorepo/shared/utils/promotions';

export const useCalculateCartItemsParams = (item: IOrderElement, orderPromotions: IOrderPromotionElement[], isOrderDetails = false) => {
  const cart = useCartState();

  if (isOrderDetails) {
    const relatedPromotion = getRelatedPromotion(item, orderPromotions);
    const itemTotalPrice = calcItemTotalPrice(item, relatedPromotion);
    const isLoyaltyReward = isLoyalty(relatedPromotion);
    const loyaltyAmount = relatedPromotion?.loyaltyInfo?.rule?.points * item.quantity || 0;

    return {
      relatedPromotion,
      itemTotalPrice,
      isLoyaltyReward,
      loyaltyAmount,
      cartPromotions: orderPromotions,
    };
  }

  const relatedPromotion = getRelatedPromotion(item, cart.promotions);
  const itemTotalPrice = calcItemTotalPrice(item, relatedPromotion);
  const isLoyaltyReward = isLoyalty(relatedPromotion);
  const loyaltyAmount = relatedPromotion?.loyaltyInfo?.rule?.points * item.quantity || 0;
  const elementAllowedMinQuantity = getAllowedQuantity('min', cart, item);
  const elementAllowedMaxQuantity = getAllowedQuantity('max', cart, item);

  const baseItemAllowedMinQuantity = getBaseItemAllowedQuantity('min')(cart, item);
  const baseItemAllowedMaxQuantity = getBaseItemAllowedQuantity('max')(cart, item);

  return {
    relatedPromotion,
    itemTotalPrice,
    isLoyaltyReward,
    loyaltyAmount,
    elementAllowedMinQuantity,
    elementAllowedMaxQuantity,
    baseItemAllowedMinQuantity,
    baseItemAllowedMaxQuantity,
    storeId: cart.storeId,
    cartPromotions: cart.promotions,
  };
};
