import type { IStoreItemOptionWithSelections } from '@goparrot/storeitems-sdk';
import type { IStoreItemOptionSelection } from '@goparrot/storeitems-sdk';
import { OpenCollapseModifierGroupEnum } from '@goparrot/webstore-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ExclamationCircle } from '@webstore-monorepo/shared/icons';
import { CircleBadge } from '@webstore-monorepo/ui/badge';
import { Box } from '@webstore-monorepo/ui/box';
import { Collapsable } from '@webstore-monorepo/ui/collapsable';
import { Text } from '@webstore-monorepo/ui/text';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';

import type { SelectionItemType } from './SelectionItem';
import { SelectionItem } from './SelectionItem';

export interface PackingGroupProps {
  itemRef?: any;
  isReadOnly?: boolean;
  packingGroupItem: IStoreItemOptionWithSelections;
  openCollapseModifierGroup?: OpenCollapseModifierGroupEnum;
  onUpdate: (items: IStoreItemOptionSelection[]) => void;
}
export const SelectionGroup: React.FC<PackingGroupProps> = ({ itemRef, isReadOnly, openCollapseModifierGroup, packingGroupItem, onUpdate }) => {
  const { title, selections: preSelectedSelections, selections_min, selections_max } = packingGroupItem;
  const isRequired = selections_min > 0;
  const [selections, setSelections] = useState<IStoreItemOptionSelection[]>([]);
  const allSelected = selections.filter((item) => item.selected).length;
  const isDefaultOpen = {
    [OpenCollapseModifierGroupEnum.ALL]: true,
    [OpenCollapseModifierGroupEnum.NONE]: false,
    [OpenCollapseModifierGroupEnum.REQUIRED]: isRequired,
  };
  const { orderConfirmationScreen: orderConfirmationComponent } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const orderConfirmationCustomisation = isMobile ? orderConfirmationComponent?.wrapperMobile : orderConfirmationComponent?.wrapper;
  const [isOpen, setIsOpen] = useState((openCollapseModifierGroup && isDefaultOpen[openCollapseModifierGroup]) || false);
  const orderedSelections = useMemo(() => {
    return orderBy(selections, ['show_order']);
  }, [selections]);

  useEffect(() => {
    if (preSelectedSelections) {
      setSelections(preSelectedSelections);
    }
  }, [preSelectedSelections]);

  const handlePropertyUpdate = (item: SelectionItemType) => {
    let updated;
    if (selections_min <= 1 && selections_max === 1) {
      updated = selections.map((selection) => (selection.uid === item.uid ? { ...selection, ...item } : { ...selection, selected: false }));
      onUpdate(updated);
      return setSelections(updated);
    }
    updated = selections.map((selection) => (selection.uid === item.uid ? { ...selection, ...item } : selection));
    setSelections(updated);
    onUpdate(updated);
  };

  return (
    <Collapsable
      isDefaultOpen={isOpen}
      onToggleCollapse={() => setIsOpen((prev) => !prev)}
      title={
        <Box flexDirection="row" alignItems="center" ref={itemRef}>
          <Box flexGrow={1}>
            <Text
              fontWeight="500"
              fontSize="xs"
              textTransform="uppercase"
              color="black"
              {...orderConfirmationCustomisation?.packingInstructions?.groupTitle?.style}
            >
              {title}
            </Text>
          </Box>
          {selections_max && allSelected === 0 && !(selections_min && allSelected < selections_min) ? (
            <CircleBadge
              backgroundColor="gray100"
              top={0}
              right={0}
              left={0}
              position="relative"
              paddingTop={1}
              paddingBottom={1}
              paddingLeft={2}
              paddingRight={2}
              marginLeft={2}
              width="auto"
              height="auto"
            >
              <Text color="gray500">Choose up to {selections_max}</Text>
            </CircleBadge>
          ) : null}
          {allSelected > 0 && allSelected >= selections_min ? (
            <CircleBadge
              backgroundColor="gray100"
              top={0}
              right={0}
              left={0}
              position="relative"
              paddingTop={1}
              paddingBottom={1}
              paddingLeft={2}
              paddingRight={2}
              marginLeft={2}
              width="auto"
              height="auto"
            >
              <Text color="gray500">{allSelected} Selected</Text>
            </CircleBadge>
          ) : null}
          {selections_min && allSelected < selections_min ? (
            <CircleBadge
              backgroundColor={allSelected < selections_min ? 'critical40' : 'gray100'}
              top={0}
              right={0}
              left={0}
              position="relative"
              paddingTop={1}
              paddingBottom={1}
              paddingLeft={2}
              paddingRight={2}
              marginLeft={2}
              width="auto"
              height="auto"
            >
              <Box alignItems="center" flexDirection="row">
                {allSelected < selections_min ? (
                  <Box marginRight={1}>
                    <ExclamationCircle strokeWidth={0} width={16} height={16} fill="criticalText" />
                  </Box>
                ) : null}
                <Text color={allSelected < selections_min ? 'criticalText' : 'gray500'}>Select at least {selections_min}</Text>
              </Box>
            </CircleBadge>
          ) : null}
        </Box>
      }
    >
      {orderedSelections.map((selection) => (
        <SelectionItem
          key={selection.uid}
          disabled={
            isReadOnly ||
            (selections.length === 1 && selections_min === 1 && selections_max === 1) ||
            (selections_max !== 1 && selections_max === allSelected && !selection.selected)
          }
          onSelectionUpdate={handlePropertyUpdate}
          selection={selection}
        />
      ))}
    </Collapsable>
  );
};
