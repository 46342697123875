import { useTheme } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import type { Theme } from '@webstore-monorepo/shared/theme';
import DOMPurify from 'dompurify';
import type { FC } from 'react';
import React from 'react';

export const Description: FC<{ descriptionInfo: string }> = ({ descriptionInfo }) => {
  const theme = useTheme<Theme>();
  const { heroBlock } = useComponentsConfig();

  return (
    <div
      style={{
        color: theme.colors[heroBlock?.descriptionBlock?.text?.style?.color ?? 'white'],
        fontFamily: theme.fontFamily[heroBlock?.descriptionBlock?.text?.style?.fontFamily ?? 'primary'],
        fontSize: theme.fontSizes[heroBlock?.descriptionBlock?.text?.style?.fontSize ?? 'm'],
        // @ts-ignore
        fontWeight: heroBlock?.descriptionBlock?.text?.style?.fontWeight ?? 'normal',

        lineHeight: heroBlock?.descriptionBlock?.text?.style?.lineHeight,
      }}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(descriptionInfo) }}
    />
  );
};
