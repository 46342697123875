import { OrderFlowEnum, PaymentMethodTypeEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { ReadGiftCardDto } from '@goparrot/giftcards-sdk';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Checkbox } from '@webstore-monorepo/ui/checkbox';
import { Separator } from '@webstore-monorepo/ui/separator';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Pressable } from 'react-native';

import { useFeatureFlags } from '../../contexts/feature-flags-provider';
import { useGiftCardDispatch, useGiftCardState } from '../../contexts/gift-card-provider';
import { useOrder } from '../../contexts/order-provider';
import { BlockTitle } from '../block-title';
import { AddNewGiftCard } from './AddNewGiftCard';

export type GiftCardsType = {
  isPaymentLoading?: boolean;
};
export const GiftCards: FC<GiftCardsType> = ({ isPaymentLoading }) => {
  const { selectedPaymentType } = useOrder();
  const cart = useCartState();
  const { user } = useUserContextState();
  const { giftcardsPaymentsEnabled } = useFeatureFlags();
  const { intl } = usePlatformStoreState();
  const [isVisible, setIsVisible] = useState(false);
  const { giftCards, giftCardPaymentSources } = useGiftCardState();
  const { onDetachGiftCard, onToggleGiftCard } = useGiftCardDispatch();
  const handleToggleGiftCards = (giftCard: ReadGiftCardDto) => () => {
    onToggleGiftCard(giftCard.uuid, giftCard.provider.gan.substring(12));
  };

  const handleOpenAddGiftCardModal = () => {
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsVisible(false);
  };

  const handleDetachGiftCard = (item: ReadGiftCardDto) => () => {
    onDetachGiftCard(item.uuid);
  };

  if (cart?.flow === OrderFlowEnum.DIGITAL) {
    return null;
  }

  return giftcardsPaymentsEnabled && selectedPaymentType?.type !== PaymentMethodTypeEnum.CASH ? (
    <Box testID="payment-gift-cards">
      <BlockTitle>Gift cards</BlockTitle>
      <Text fontSize="m" mb={4}>
        Your gift card balance will be applied in full to your order. You’ll also need to provide a credit card number to cover tips and any possible overages.
      </Text>

      {giftCards.map((item) =>
        item ? (
          <Box
            key={item.uuid}
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            px={4}
            py={4}
            marginBottom={4}
            borderWidth={1}
            borderColor="gray300"
            borderRadius="md"
            testID="payment-gift-card"
          >
            <Checkbox
              checked={giftCardPaymentSources.some((source) => [item.uuid, item.provider.id].includes(source.id))}
              labelPlacement="after"
              onPress={handleToggleGiftCards(item)}
              label={
                <Text>
                  {'•••• '.repeat(3)} {item.provider.gan.substring(12)} -{' '}
                  <Text fontWeight="bold">
                    {intl.formatNumber(item.balance.amount / 100 || 0, {
                      style: 'currency',
                      currency: item.balance.currency,
                      maximumFractionDigits: item.uuid ? 2 : 0,
                    })}
                  </Text>
                </Text>
              }
            />
            {user.type === UserTypeEnum.AUTHENTICATED ? (
              <Pressable testID={`close-icon-${item.uuid}`} onPress={handleDetachGiftCard(item)}>
                <CloseIcon />
              </Pressable>
            ) : null}
          </Box>
        ) : null,
      )}
      <Box flexDirection="row" justifyContent="flex-start" marginTop={4} mb={4}>
        <Button isLoading={isPaymentLoading} onPress={handleOpenAddGiftCardModal} testID="payment-add-gift-card">
          Add new gift card
        </Button>
      </Box>
      <AddNewGiftCard isVisible={isVisible} onClose={handleCloseModal} />
      <Separator />
    </Box>
  ) : null;
};
