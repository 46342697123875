import type { IOrder } from '@goparrot/order-sdk';
import { OrderDelayedInfoUtil } from '@goparrot/order-sdk';
import { DelayedOrdersUtils, DiningOptionsUtil, HumanReadableUtils, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { StoreItemValidityEnum } from '@goparrot/common';
import { getDiffInMinutes } from '@webstore-monorepo/shared/utils/cart';
import { getDiningOptionRelevantReceiveMethod } from '@webstore-monorepo/shared/utils/dining-options';

export const hasInValidElementsInCart = (cart: IOrder) => {
  if (cart.validity !== StoreItemValidityEnum.VALID) {
    return cart.elements.some(
      (el) => el.validity === StoreItemValidityEnum.SUBSIDED_MIN_QUANTITY || el.validity === StoreItemValidityEnum.EXCEEDED_MAX_QUANTITY,
    );
  }
};

export function isValidDelayedInfo(cart: IOrder, store: ReadStoreDto) {
  const { isDisabledASAP: isDisabledASAPPickup } = store?.pickup?.delayedOrders ?? {};
  const { isDisabledASAP: isDisabledASAPDelivery } = store?.delivery?.delayedOrders ?? {};
  const { delayedInfo, dateInfo, diningOptionInfo } = cart;
  const { type: diningOptionFromCart } = diningOptionInfo || {};
  const isDisabledASAP =
    !!diningOptionFromCart && DiningOptionsUtil.isDeliveryRequired(cart.diningOptionInfo?.type) ? isDisabledASAPDelivery : isDisabledASAPPickup;
  const { isOpen, todayDeliveryHours, todayDineInHours, ...rest } = HumanReadableUtils.getHumanReadableDataFromStore(store);

  if (!delayedInfo) {
    return false;
  }

  if (OrderDelayedInfoUtil.isASAP(cart) && isDisabledASAP) {
    return false;
  }
  if (delayedInfo && !OrderDelayedInfoUtil.isASAP(cart) && delayedInfo?.date && getDiffInMinutes(delayedInfo?.date, dateInfo?.startPrepAt) < 0) {
    return false;
  }

  if (!isOpen && OrderDelayedInfoUtil.isASAP(cart)) {
    return false;
  }
  const receiveMethod = getDiningOptionRelevantReceiveMethod(cart.diningOptionInfo?.type);
  try {
    if (!OrderDelayedInfoUtil.isASAP(cart) && delayedInfo && delayedInfo.day && delayedInfo.hour) {
      DelayedOrdersUtils.getAndValidateSelectedTime(store, receiveMethod, delayedInfo.day, delayedInfo.hour);
    }
  } catch (e) {
    return false;
  }

  return true;
}
