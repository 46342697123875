import type { WeeklyWorkingSchedule } from '@goparrot/store-v2-sdk';
import type { StoreItemVariantDesktopEnum, StoreItemVariantMobileEnum } from '@goparrot/webstore-sdk';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useEffect, useState } from 'react';

export type LayoutCustomizationProps = {
  hidePrice?: boolean;
  maxContentHeight?: number;
};

type Props = {
  title: string;
  isHovered?: boolean;
  showImage?: boolean;
  content?: string;
  orderDate?: string;
  imageUrl?: string;
  layoutCustomization?: LayoutCustomizationProps;
  pricePrefixText?: string;
  hoverImageUrl?: string;
  calories?: string;
  price?: string;
  imageText?: string;
  schedule?: WeeklyWorkingSchedule[];
  variantDesktop?: StoreItemVariantDesktopEnum;
  variantMobile?: StoreItemVariantMobileEnum;
  columnsNumberDesktop: number;
};

export const MenuItemCardStateContext = createContext<Props>({
  title: '',
  columnsNumberDesktop: 4,
});

export const MenuItemCardProvider: React.FC<PropsWithChildren<Props>> = ({ children, isHovered, ...props }) => {
  const [state, setState] = useState<Props>(() => ({
    ...props,
  }));

  useEffect(() => {
    if (state.isHovered !== isHovered) {
      setState((prev) => ({ ...prev, isHovered }));
    }
  }, [isHovered, state.isHovered]);

  return <MenuItemCardStateContext.Provider value={state}>{children}</MenuItemCardStateContext.Provider>;
};

export const useMenuItemCardState = () => {
  const context = useContext(MenuItemCardStateContext);
  if (context === undefined) {
    throw new Error('useMenuItemCardState must be used within a MenuItemCardProvider');
  }
  return context;
};
