import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { Box } from '@webstore-monorepo/ui/box';
import React, { useEffect, useRef } from 'react';

import { usePromoPopupContext } from '../PromoPopupProvider';
import { AddToCartButton } from './AddToCartButton';
import { NoButton } from './NoButton';
import { PopupImage } from './PopupImage';
import { Title } from './Title';

export const DesktopView = () => {
  const { promoPopup } = useComponentsConfig();
  const { wrapperStyles, titleWrapperStyles, detailsStyles } = usePromoPopupContext();
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <Box
      ref={ref}
      accessibilityRole="alert"
      accessibilityViewIsModal={true}
      // @ts-ignore
      accessibilityLabelledBy="promo-popup-title"
      testID="promo-popup-desktop"
      overflow="hidden"
      {...wrapperStyles}
      {...promoPopup?.wrapper?.style}
    >
      <Box alignItems="center" justifyContent="center" {...promoPopup?.wrapper?.image?.style}>
        <Box maxWidth={137} maxHeight={137}>
          <PopupImage width="auto" height="auto" maxWidth={137} maxHeight={137} />
        </Box>
      </Box>
      <Box {...detailsStyles}>
        <Box nativeID="promo-popup-title" {...titleWrapperStyles} {...promoPopup?.wrapper?.title?.style}>
          <Title />
        </Box>
        <Box
          flexDirection="row"
          alignItems="center"
          justifyContent="space-evenly"
          paddingRight={3}
          paddingLeft={3}
          paddingBottom={4}
          {...promoPopup?.wrapper?.buttonsWrapper?.style}
        >
          <NoButton />
          <AddToCartButton />
        </Box>
      </Box>
    </Box>
  );
};
