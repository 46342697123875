import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

export const CircleBadge: FC<PropsWithChildren<BoxProps<Theme>>> = ({ children, ...props }) => {
  const { header } = useComponentsConfig();

  return (
    <Box
      position="absolute"
      top={-5}
      left={20}
      width={20}
      height={20}
      borderRadius="rounded"
      alignItems="center"
      justifyContent="center"
      backgroundColor="black"
      testID="circle-badge"
      {...header?.circleBadge?.style}
      {...props}
    >
      {children ? (
        <Text lineHeight={16.8} fontSize="xs" fontWeight="600" textAlign="center" {...header?.circleBadge?.text?.style}>
          {children}
        </Text>
      ) : null}
    </Box>
  );
};
