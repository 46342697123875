import type { Theme } from '@webstore-monorepo/shared/theme';
import { useTheme } from '@webstore-monorepo/shared/theme';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import type { Ref } from 'react';
import React, { forwardRef } from 'react';

import type { TextGradientProps } from './interfaces';

export const TextGradient = forwardRef((props: TextPropsExtended & TextGradientProps, forwardedRef: Ref<HTMLDivElement>) => {
  const theme = useTheme<Theme>();
  const { ...rest } = props;
  // @ts-expect-error no includes on type
  const isGradient = props.color?.includes('gradient');

  return (
    // @ts-ignore
    <div style={{ width: props.width ?? '100%', textAlign: props.textAlign }}>
      <span
        ref={forwardedRef}
        // @ts-ignore
        style={{
          ...(isGradient
            ? {
                backgroundImage: props?.color,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }
            : {}),
          // @ts-ignore
          lineHeight: 'initial',
          // @ts-ignore
          margin: 0,
          // @ts-ignore
          padding: 0,
          ...rest,
          // @ts-ignore
          fontSize: theme.fontSizes[props.fontSize],
          // @ts-ignore
          color: isGradient ? props.color : theme.colors[props.color],
          // @ts-ignore
          fontFamily: theme.fontFamily[props.fontFamily] ? theme.fontFamily[props.fontFamily] : props.fontFamily,
        }}
      >
        {props.children}
      </span>
    </div>
  );
});
