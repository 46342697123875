import type { INestedStoreItemGroup, IStoreItemWithOptions } from '@goparrot/storeitems-sdk';
import { useTheme } from '@shopify/restyle';
import { MenuItemCard } from '@webstore-monorepo/features/menu-item-card';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { EmptyFallback } from '@webstore-monorepo/ui/error-fallback';
import type { FC } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import type { LayoutChangeEvent } from 'react-native';
import { FlatGrid } from 'react-native-super-grid';
import { v4 as uuid } from 'uuid';

type MenuItemCardListProps = {
  items: (IStoreItemWithOptions | INestedStoreItemGroup)[];
  onItemClick: (item: IStoreItemWithOptions | INestedStoreItemGroup) => void;
};
export const MenuItemCardList: FC<MenuItemCardListProps> = ({ items, onItemClick }) => {
  const { menuList } = useComponentsConfig();
  const { isMobile, isDesktop } = useWindowDimensions();
  const theme = useTheme<Theme>();
  const { columnsNumberDesktop = 4, verticalGap = 2, horizontalGap = 2, showDivider: showDividerDesktop } = menuList?.wrapper?.options ?? {};
  const {
    verticalGap: verticalGapMobile,
    showDivider: showDividerMobile,
    columnsNumberMobile = 2,
    horizontalGap: mobileHorizontalGap = 2,
  } = menuList?.wrapperMobile?.options ?? {};
  const noImages = items.every((item) => !item.image_url);
  const [mountId, setMountId] = useState('');
  const renderRef = useRef<{ height: number; width: number }>();
  const showImages = (isMobile ? menuList?.wrapperMobile?.options?.showImages : menuList?.wrapper?.options?.showImages) ?? true;
  // @ts-expect-error legacy value can be a string
  const horizontalGapInPx = Math.ceil(horizontalGap ? parseFloat(theme.spacing[horizontalGap]) : 0);
  // @ts-expect-error legacy value can be a string
  const mobileHorizontalGapInPx = Math.ceil(mobileHorizontalGap ? parseFloat(theme.spacing[mobileHorizontalGap]) : 0);
  // @ts-expect-error legacy value can be a string
  const verticalGapInPx = Math.ceil(verticalGap ? parseFloat(theme.spacing[verticalGap]) : 0);
  // @ts-expect-error legacy value can be a string
  const verticalGapMobileInPx = Math.ceil(verticalGap ? parseFloat(theme.spacing[verticalGapMobile]) : 0);
  const showDivider = isMobile ? showDividerMobile : showDividerDesktop;

  const handleClick = (item: IStoreItemWithOptions | INestedStoreItemGroup) => {
    onItemClick(item);
  };

  const handleLayout = useCallback((event: LayoutChangeEvent) => {
    // this is used to re-render the list to update childrens width and height.
    if (renderRef.current?.width !== event.nativeEvent.layout.width) {
      renderRef.current = { width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height };
      setMountId(uuid());
    }
  }, []);

  const dividerStyles = isMobile ? menuList?.wrapperMobile?.divider?.style : menuList?.wrapper?.divider?.style;
  const wrapperStyles = isMobile ? menuList?.wrapperMobile?.style : menuList?.wrapper?.style;

  return (
    <ErrorBoundary FallbackComponent={EmptyFallback}>
      <FlatGrid
        testID="menu-item-card-list"
        maxItemsPerRow={isDesktop ? columnsNumberDesktop : isMobile && columnsNumberMobile > 2 ? 2 : columnsNumberMobile}
        // @ts-expect-error style types aren't compatible
        additionalRowStyle={{
          paddingLeft: 0,
          paddingBottom: 0,
          ...wrapperStyles,
          marginBottom: showDivider ? 0 : isMobile ? verticalGapMobileInPx : verticalGapInPx,
        }}
        onLayout={handleLayout}
        spacing={showDivider ? 0 : isMobile ? (columnsNumberMobile === 1 ? 0 : mobileHorizontalGapInPx) : horizontalGapInPx}
        data={items}
        style={{
          paddingTop: 0,
          marginBottom: 0,
          overflow: 'visible',
          backgroundColor: wrapperStyles?.backgroundColor ? theme.colors[wrapperStyles?.backgroundColor] : '',
        }}
        renderItem={({ item, index }) => (
          <MenuItemCard
            key={`${mountId} ${item.uniqueName}`}
            width="100%"
            columnsNumberDesktop={columnsNumberDesktop}
            showImage={showImages === false ? showImages : !noImages}
            item={item}
            onClick={handleClick}
            boxProps={{
              ...(showDivider ? { ...dividerStyles, ...(index === 0 ? { borderTopWidth: dividerStyles?.borderBottomWidth } : {}) } : {}),
            }}
          />
        )}
      />
    </ErrorBoundary>
  );
};
