import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useState } from 'react';
import type { IReadPromotionDto } from '@goparrot/promotions-sdk';
import type { BoxProps } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';

type Props = {
  promotion?: IReadPromotionDto;
  wrapperStyles: BoxProps<Theme>;
  detailsStyles: BoxProps<Theme>;
  titleWrapperStyles: BoxProps<Theme>;
  onCancel: () => void;
  onAddToCart: () => void;
};

export const PromoPopupStateContext = createContext<Props>({
  wrapperStyles: {},
  titleWrapperStyles: {},
  detailsStyles: {},
  onCancel: () => undefined,
  onAddToCart: () => undefined,
});

export const PromoPopupProvider: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const [state] = useState<Props>({
    ...props,
  });

  return <PromoPopupStateContext.Provider value={state}>{children}</PromoPopupStateContext.Provider>;
};

export const usePromoPopupContext = () => {
  const context = useContext(PromoPopupStateContext);
  if (context === undefined) {
    throw new Error('usePromoPopupState must be used within a PromoPopupProvider');
  }
  return context;
};
