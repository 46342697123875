import { PaymentMethodTypeEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { ReadGiftCardDto } from '@goparrot/giftcards-sdk';
import { GiftCardStatusEnum } from '@goparrot/giftcards-sdk';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import type { PropsWithChildren } from 'react';
import React, { createContext, useContext, useState } from 'react';

import { useDetachGiftCard } from '../../api/mutations/use-gift-card';
import { useGetGiftCards } from '../../queries/use-get-gift-cards';

export type GiftCardStateContext = {
  giftCards: ReadGiftCardDto[];
  giftCardPaymentSources: GiftCardPaymentSource[];
};

type GiftCardDispatchContext = {
  onToggleGiftCard: (giftCardId: string, last4Digits: string) => void;
  onDetachGiftCard: (uuid: string) => void;
  onAddNewGiftCard: (giftCard: ReadGiftCardDto, last4Digits: string) => void;
};
interface GiftCardPaymentSource {
  id?: string;
  uuid?: string;
  last4Digits: string;
  type: PaymentMethodTypeEnum;
}

export const GiftCardStateContext = createContext<GiftCardStateContext>({
  giftCards: [],
  giftCardPaymentSources: [],
});

const GiftCardDispatchContext = createContext<GiftCardDispatchContext>({
  onToggleGiftCard: () => {},
  onDetachGiftCard: () => {},
  onAddNewGiftCard: () => Promise.resolve(),
});

export const GiftCardProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { notification, analytics } = usePlatformStoreState();
  const [giftCards, setGiftCards] = useState<ReadGiftCardDto[]>([]);
  const { user } = useUserContextState();
  const isAuthenticated = user.type === UserTypeEnum.AUTHENTICATED;
  const [giftCardPaymentSources, setGiftCardPaymentSources] = useState<GiftCardPaymentSource[]>([]);
  const { mutateAsync: mutateDetachGiftCard } = useDetachGiftCard({
    onSuccess: () => {
      analytics.track('gift_card_detach_success');
      refetch();
    },
    onError: (error: any) => {
      analytics.track('gift_card_detach_error', { error });
      notification.error(error);
    },
  });
  const { refetch } = useGetGiftCards({
    onSuccess: (data) => {
      const giftCardList = data.rows.filter((r) => r.status === GiftCardStatusEnum.ACTIVE);
      setGiftCards(giftCardList);
      analytics.track('gift_card_list_fetch_success', { giftCardList });
    },
  });

  const handleDetachGiftCard = async (uuid: string) => {
    if (!window.confirm('Are you sure you want to remove this Gift Card?')) return false;
    analytics.track('gift_card_detach', { uuid });
    if (isAuthenticated) {
      await mutateDetachGiftCard(uuid);
    }
    setGiftCards(giftCards.filter((giftCard) => giftCard.uuid !== uuid));
    setGiftCardPaymentSources(giftCardPaymentSources.filter((giftCard) => giftCard.id !== uuid));
  };

  const handleToggleGiftCard = (giftCardId: string, last4Digits: string) => {
    let newSources: GiftCardPaymentSource[];
    if (giftCardPaymentSources.some((source) => source.id === giftCardId)) {
      newSources = giftCardPaymentSources.filter((source) => source.id !== giftCardId);
      analytics.track('gift_card_toggle_removed', { last4Digits, oldGiftCardList: giftCardPaymentSources, newGiftCardList: newSources });
    } else {
      newSources = [
        ...giftCardPaymentSources,
        {
          id: giftCardId,
          last4Digits,
          type: PaymentMethodTypeEnum.GIFT_CARD,
        },
      ];
      analytics.track('gift_card_toggle_added', { last4Digits, oldGiftCardList: giftCardPaymentSources, newGiftCardList: newSources });
    }

    setGiftCardPaymentSources(newSources);
  };

  const handleAddNewGiftCard = (giftCard: ReadGiftCardDto, last4Digits: string) => {
    refetch();
    analytics.track('gift_card_added', { last4Digits, newGiftCard: giftCard, oldGiftCardList: giftCardPaymentSources });
    setGiftCardPaymentSources([
      ...giftCardPaymentSources,
      {
        last4Digits,
        id: giftCard.uuid,
        type: PaymentMethodTypeEnum.GIFT_CARD,
      },
    ]);
  };

  return (
    <GiftCardStateContext.Provider
      value={{
        giftCards,
        giftCardPaymentSources,
      }}
    >
      <GiftCardDispatchContext.Provider
        value={{ onAddNewGiftCard: handleAddNewGiftCard, onDetachGiftCard: handleDetachGiftCard, onToggleGiftCard: handleToggleGiftCard }}
      >
        {children}
      </GiftCardDispatchContext.Provider>
    </GiftCardStateContext.Provider>
  );
};

export const useGiftCardState = () => {
  const context = useContext(GiftCardStateContext);
  if (context === undefined) {
    throw new Error('useGiftCardState must be used within a GiftCardProvider');
  }
  return context;
};

export const useGiftCardDispatch = () => {
  const context = useContext(GiftCardDispatchContext);
  if (context === undefined) {
    throw new Error('useGiftCardDispatch must be used within a GiftCardProvider');
  }
  return context;
};
