import type { WebMerchantThemeFontSizes } from '@goparrot/webstore-sdk';

import { getCssVariableValue } from '../shared/utils/get-css-variable-value';

const formatAndSplitSpacingValue = (value: string): string[] => {
  const splittedValue = value.split(' ');
  switch (splittedValue.length) {
    case 1:
      return [splittedValue[0], splittedValue[0], splittedValue[0], splittedValue[0]];
    case 2:
      return [splittedValue[0], splittedValue[1], splittedValue[0], splittedValue[1]];
    case 3:
      return [splittedValue[0], splittedValue[1], splittedValue[2], splittedValue[1]];
    default:
      return splittedValue;
  }
};
/**
 * @param key
 * @param value
 * returns { keyTop: value[0], keyRight: value[1], keyBottom: value[2], keyLeft: value[3] }
 */
export const getFourDirectionalSpacing = (key: string, value: string[]) => {
  return {
    [`${key}Top`]: convertUnspecifiedValueToPixels(value[0]),
    [`${key}Right`]: convertUnspecifiedValueToPixels(value[1]),
    [`${key}Bottom`]: convertUnspecifiedValueToPixels(value[2]),
    [`${key}Left`]: convertUnspecifiedValueToPixels(value[3]),
  };
};

export const getSpacingValues = (value: string, key: 'margin' | 'padding' = 'margin'): Record<string, string> => {
  const extractedValue = extractCSSValue(value);
  const formattedValue = formatAndSplitSpacingValue(extractedValue);

  return formattedValue.length > 1 ? getFourDirectionalSpacing(key, formattedValue) : { [key]: convertUnspecifiedValueToPixels(extractedValue) };
};

export const getSpacingThemeKeyValuePairs = (value: string): Record<string, string> => {
  const extractedValue = extractCSSValue(value);
  const formattedValue = formatAndSplitSpacingValue(extractedValue);

  return formattedValue.length > 1
    ? formattedValue.reduce((prev, curr) => ({ ...prev, [convertUnspecifiedValueToPixels(curr)]: convertUnspecifiedValueToPixels(curr) }), {})
    : { [value]: convertUnspecifiedValueToPixels(extractedValue) };
};
/**
 * Extracting legacy values like (padding|margin): 1px | (padding|margin): 1px 1px and converting them to 4 directional key value pairs for the new theme (paddingTop, bottom, left, right)
 * @param value
 * @param key margin | padding
 */
export const extractSpacingKeyValues = (value: string, key: 'margin' | 'padding'): Record<string, number> => {
  const extractedValue = extractCSSValue(value);
  const formattedValue = formatAndSplitSpacingValue(extractedValue);
  return formattedValue.length > 1
    ? extractKeys(formattedValue, key)
    : {
        [convertUnspecifiedValueToPixels(extractedValue)]: convertUnspecifiedValueToPixels(extractedValue),
      };
};

export const extractCSSValue = (value?: string, fallbackValue?: string): string => {
  if (!value && fallbackValue) {
    return fallbackValue;
  }
  if (value?.toString()?.includes('var')) {
    const parsedValue: string = value.replace(';', '').replace('var(', '').replace(')', '');
    return getCssVariableValue(parsedValue).trim();
  }
  return value ?? '';
};
/**
 * Convert margin to 4 margin values
 * @param value
 * @param searchKey
 */
const extractKeys = (value: string[], searchKey: 'margin' | 'padding'): Record<string, any> => {
  const themeValue = getFourDirectionalSpacing(searchKey, value);

  return Object.values(themeValue).reduce((acc, curr) => ({ ...acc, [convertUnspecifiedValueToPixels(curr)]: convertUnspecifiedValueToPixels(curr) }), {});
};

export const convertBoxShadowToRnw = (value: string) => {
  const result = {
    shadowColor: '',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
  };
  const spreaded = value?.trim().split(' ');
  const getColorAndOpacity = (color: string): { color: string; opacity: number } => {
    if (color.includes('#')) return { color, opacity: 1 };
    const [red, green, blue] = color.split(',').map((item) => item.replace(/\D/g, ''));
    return { color: `rgb(${red},${green},${blue})`, opacity: parseFloat(color.split(',').reverse()[0]) };
  };
  if (spreaded.length === 3) {
    const { color, opacity } = getColorAndOpacity(extractCSSValue(spreaded[2]));
    result.shadowColor = color;
    result.shadowOpacity = opacity;
  }
  if (spreaded.length === 4) {
    const { color, opacity } = getColorAndOpacity(extractCSSValue(spreaded[3]));
    result.shadowColor = color;
    result.shadowOpacity = opacity;
  }
  if (spreaded.length === 5) {
    const { color, opacity } = getColorAndOpacity(extractCSSValue(spreaded[4]));
    result.shadowColor = color;
    result.shadowOpacity = opacity;
    result.shadowRadius = Math.abs(parseFloat(spreaded[2]));
  }
  result.shadowOffset = {
    width: Math.abs(parseFloat(spreaded[0])) ?? 0,
    height: Math.abs(parseFloat(spreaded[1])) ?? 0,
  };
  return result;
};

/**
 * Add pixel values to a theme value
 * @param value
 */
export const convertUnspecifiedValueToPixels = (value: string = '') => {
  if (value === 'auto') return value;
  if (value?.includes('px')) return value;
  if (value?.includes('em')) return value;
  if (value?.includes('rem')) return value;
  if (value?.includes('%')) return value;
  return `${value}px`;
};

export const extractKeysFromKeyString = (values: Record<string, string> = {}, keySearch: string): Record<string, any> =>
  Object.keys(values).reduce((previousValue, currentValue = '') => {
    if (keySearch === 'color' && currentValue === 'border') {
      const borderColor = values[currentValue].split(' ')[2];
      return { ...previousValue, [extractCSSValue(borderColor)]: extractCSSValue(borderColor) };
    }
    if (currentValue.toLowerCase().includes(keySearch.toLowerCase())) {
      return { ...previousValue, [extractCSSValue(values[currentValue])]: extractCSSValue(values[currentValue]) };
    }
    return { ...previousValue };
  }, {});

export const extractColorValueFromString = (str: string): string => {
  const regularExpression = new RegExp(/#(?:[0-9a-fA-F]{3}){1,2}/, 'g');
  const rgbMatch = str.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/) ?? [];
  const hexMatch = str.match(regularExpression) ?? [];
  // @ts-ignore for some reason crashes the jenkins pipeline
  return hexMatch.length > 0 ? hexMatch.join() : rgbMatch[0];
};

export const convertShorthandBorderToFull = (
  value?: string,
  direction: 'all' | 'top' | 'right' | 'bottom' | 'left' = 'all',
): { borderColor?: string; borderWidth?: string; borderStyle?: string } => {
  if (!value) return {};
  const [width, style, color] = value.split(' ');
  let borderDirection = {};
  switch (direction) {
    case 'top':
      borderDirection = {
        borderTopWidth: width,
      };
      break;
    case 'right':
      borderDirection = {
        borderRightWidth: width,
      };
      break;
    case 'bottom':
      borderDirection = {
        borderBottomWidth: width,
      };
      break;
    case 'left':
      borderDirection = {
        borderLeftWidth: width,
      };
      break;
    case 'all':
      borderDirection = {
        borderWidth: width,
      };
      break;
  }
  return {
    borderColor: extractCSSValue(color),
    borderStyle: style,
    ...borderDirection,
  };
};

export const extractAndMapToTheme = (values?: Record<string, string>) => {
  if (!values) return {};
  return Object.keys(values).reduce((prevValue, currentKey) => {
    switch (currentKey) {
      case 'box-shadow':
      case 'boxShadow':
        return { ...prevValue, ...convertBoxShadowToRnw(values[currentKey]) };
      case 'border':
        return { ...prevValue, ...convertShorthandBorderToFull(values[currentKey]) };
      case 'margin':
        return { ...prevValue, ...extractSpacingKeyValues(extractCSSValue(values[currentKey]), 'margin') };
      case 'padding':
        return { ...prevValue, ...extractSpacingKeyValues(extractCSSValue(values[currentKey]), 'padding') };
      case 'background':
        return { ...prevValue, backgroundColor: extractCSSValue(values[currentKey]) };
      default:
        return { ...prevValue, [currentKey]: extractCSSValue(values[currentKey]) };
    }
  }, {});
};

export const findCorrectAlign = (align: string): string => {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return align;
  }
};

/**
 * EM imitation. Find closest lower
 * @param fontSize
 * @param direction
 * @param adjustBy
 */
export const convertLegacyFontSize = (fontSize: string, direction?: 'higher' | 'lower', adjustBy?: number): keyof WebMerchantThemeFontSizes => {
  const themeFontSizes: Record<number, keyof WebMerchantThemeFontSizes> = {
    10: 'xxs',
    12: 'xs',
    13: 's',
    15: 'm',
    18: 'l',
    20: 'xl',
    24: 'xxl',
    34: 'xxxl',
    44: '4xl',
    60: '5xl',
  };
  const parsedNumber = parseInt(fontSize, 10);

  const findClosest = Object.keys(themeFontSizes).reduce((acc, curr) => {
    const parsedB = parseInt(curr, 10);
    return Math.abs(parsedB - parsedNumber) < Math.abs(acc - parsedNumber) ? parsedB : acc;
  }, 0);
  if (direction && adjustBy) {
    const fontSizeArray = Object.keys(themeFontSizes).map((key) => key);
    const indexOfClosest = fontSizeArray.findIndex((value) => value === findClosest.toString());
    const correctIndex = direction === 'lower' ? indexOfClosest - adjustBy : indexOfClosest + adjustBy;
    return themeFontSizes[parseInt(fontSizeArray[correctIndex], 10)];
  }
  return themeFontSizes[findClosest];
};
