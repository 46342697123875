import type { PreviewTerminologyDto } from '@goparrot/loyalty-sdk';
import type { IFontAndShapeApp, IShapeStylesApp } from '@goparrot/webstore-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { LoyaltyPoint } from '@webstore-monorepo/shared/icons';
import { InlineBox } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Image } from 'react-native';

type Props = {
  pointNames?: PreviewTerminologyDto;
  loyaltyBase?: {
    pointsAvailable: number;
  };
  amount?: number;
  overwriteStyles?: IFontAndShapeApp;
  overwriteHoverStyles?: IFontAndShapeApp;
  wrapperStyles?: IShapeStylesApp;
};

const pluralize = (points: number, pointNames: PreviewTerminologyDto): string => ([1, -1].includes(points) ? pointNames.singular : pointNames.plural);

export const LoyaltyBadge: FC<Props> = ({ amount, loyaltyBase, pointNames, overwriteStyles, overwriteHoverStyles, wrapperStyles }) => {
  const balance = amount || (loyaltyBase?.pointsAvailable ?? 0);
  const { loyaltyBadge } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const ref = useRef();
  const { isHovered } = useHover({}, ref);
  const { dictionary } = useMerchantState();
  const loyaltyNames = pointNames ?? dictionary.loyaltyPoints;

  return !isMobile ? (
    // @ts-ignore
    <InlineBox testID="loyalty-badge" ref={ref} alignItems="center" justifyContent="center" {...wrapperStyles}>
      <InlineBox alignItems="center" justifyContent="center" height={18} width={18}>
        {loyaltyBadge?.wrapper?.options?.rewardIcon ? (
          <Image
            accessibilityLabel="loyalty badge"
            accessibilityHint="loyalty badge image"
            accessibilityIgnoresInvertColors
            accessibilityRole="none"
            testID="custom-loyalty-badge-icon"
            source={{ uri: loyaltyBadge?.wrapper?.options?.rewardIcon }}
            resizeMode="contain"
            style={{ width: 18, height: 18 }}
          />
        ) : (
          <LoyaltyPoint testID="loyalty-badge-icon" width={18} fill={overwriteStyles?.color} stroke={overwriteStyles?.color} />
        )}
      </InlineBox>
      {!isMobile ? (
        <InlineBox marginLeft={2}>
          <Text fontWeight="normal" {...overwriteStyles} {...(isHovered ? overwriteHoverStyles : {})}>
            {balance}
          </Text>
          {loyaltyNames ? (
            <Text fontSize="xs" {...overwriteStyles} {...(isHovered ? overwriteHoverStyles : {})} textTransform="lowercase" marginLeft={1}>
              {pluralize(balance, loyaltyNames)}
            </Text>
          ) : null}
        </InlineBox>
      ) : null}
    </InlineBox>
  ) : null;
};
