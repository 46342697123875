import styled from 'styled-components';

import { media } from '../../../shared/utils/media-queries';

export const Container = styled.div`
  display: flex;
  border-radius: 5px;
  background: #ffffff;
  min-height: 136px;
  position: fixed;
  top: auto;
  bottom: 20px;
  right: 0;
  left: 0;
  width: 98%;
  margin: 0 auto;
  z-index: 1500;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;

  > a {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 14px;
    cursor: pointer;
    z-index: 1000;
  }

  .close-icon {
    fill: #a2a2a2;
  }

  &.popup-enter {
    opacity: 0;
    transform: scale(0.9);
  }

  &.popup-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }

  &.popup-exit {
    opacity: 1;
  }

  &.popup-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  ${media.tablet} {
    right: 10px;
    left: auto;
    margin: 0;
    max-width: 450px;
  }
`;
