import { DiningOptionEnum } from '@goparrot/common';
import type { ICartAction } from '@goparrot/order-sdk';
import { CartActionTypeEnum } from '@goparrot/order-sdk';
import type { BoxProps } from '@shopify/restyle/dist/createBox';
import { useCartHandleActions, useClearCartMutation } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { AnalyticsActionsEnum } from '@webstore-monorepo/shared/enums';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import { CutleryIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Input } from '@webstore-monorepo/ui/input';
import { Text } from '@webstore-monorepo/ui/text';
import type { ChangeEvent } from 'react';
import React, { forwardRef, useEffect, useState } from 'react';
import { Image, Pressable } from 'react-native';

import type { IOrderToTableInfo } from './order-to-table-helper';

export type OrderToTableBarProps = {
  isRemovable?: boolean;
  isSticky?: boolean;
  isOnMainPage?: boolean;
  tableNumber?: string;
  orderToTableInfo?: IOrderToTableInfo;
  onTableNumberChange?: (value: string, isValid: boolean) => void;
  onAfterConfirmAlert?: (data: { isOrderToTable: boolean }) => void;
  onAfterUpdateCartActions?: () => void;
};

export const OrderToTableBar = forwardRef<HTMLDivElement, OrderToTableBarProps>(
  ({ tableNumber, onTableNumberChange, isRemovable, isOnMainPage = true, orderToTableInfo, onAfterUpdateCartActions, onAfterConfirmAlert }, ref) => {
    const { orderToTableBar } = useComponentsConfig();
    const cart = useCartState();
    const cartDispatch = useCartDispatch();
    const [isRemoved, setRemoveState] = useState(false);
    const [isAlertMode, setAlertMode] = useState(false);
    const tableNumberFromCart = cart?.diningOptionInfo?.details?.tableNumber;
    const { badgeIconUrl, componentsOrder, hideSubtext, hideText, subtext, text } = orderToTableBar?.wrapper?.options ?? {};
    const { analytics } = usePlatformStoreState();
    const { mutate: mutateUpdateCartActions } = useCartHandleActions({
      onSuccess: (cart) => {
        onAfterUpdateCartActions && onAfterUpdateCartActions();
        cartDispatch({ type: 'update', payload: cart });
      },
    });
    const { mutate: onClearCart, isLoading: isLoadingAlertConfirm } = useClearCartMutation({
      onSuccess: (cart) => {
        setRemoveState(true);
        const info = { isOrderToTable: false };

        onAfterConfirmAlert && onAfterConfirmAlert(info);
        cartDispatch({ type: 'initiate', payload: cart });
      },
    });

    const handleConfirmAlert = () => {
      onClearCart();
    };

    const handleRemoveIcon = () => {
      setAlertMode(true);
    };

    const handleAlertCancel = () => setAlertMode(false);

    const handleTableNumberChange = (e: ChangeEvent<any>) => {
      const { value } = e.target;

      onTableNumberChange && onTableNumberChange(value, !!value);
    };

    const handleUpdateDiningOptionInfo = async (actions: ICartAction[]) => {
      mutateUpdateCartActions(actions);
      analytics.track(AnalyticsActionsEnum.ORDER_TO_TABLE_SET_ACTIONS, { actions });
    };

    useEffect(() => {
      if (orderToTableInfo?.isOrderToTable && tableNumberFromCart !== orderToTableInfo?.tableNumber && isOnMainPage) {
        const actions = [
          {
            type: CartActionTypeEnum.SET_DINING_OPTION_DETAILS,
            payload: {
              data: {
                tableNumber: orderToTableInfo?.tableNumber,
              },
              type: DiningOptionEnum.DINE_IN,
            },
          },
          {
            type: CartActionTypeEnum.SET_DINING_OPTION,
            payload: {
              type: DiningOptionEnum.DINE_IN,
            },
          },
          {
            type: CartActionTypeEnum.SET_DELAYED_INFO,
            payload: {
              delayedInfo: { isASAP: true },
            },
          },
          {
            type: CartActionTypeEnum.SET_RECIPIENT,
            payload: {},
          },
        ];

        handleUpdateDiningOptionInfo(actions);
      }
    }, [orderToTableInfo]);

    const badgeStyles: BoxProps<Theme> = {
      position: 'relative',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'gray300',
      borderRadius: 'xs',
      flex: 1,
      paddingTop: 'px',
      paddingBottom: 'px',
      paddingLeft: 'xxs',
      paddingRight: 'xxs',
      marginLeft: 's',
      backgroundColor: 'white',
      ...orderToTableBar?.badge?.style,
    };

    const BadgeBlock = () => (
      <Box testID="badge-block" flexDirection="row" alignItems="center">
        <Box>
          {badgeIconUrl ? (
            <Image
              accessibilityLabel="Badge"
              accessibilityHint="Order to table badge"
              accessibilityIgnoresInvertColors
              accessibilityRole="none"
              source={{ uri: badgeIconUrl }}
              style={{ width: 32, height: 32 }}
            />
          ) : (
            <CutleryIcon stroke={orderToTableBar?.icon?.style?.color} />
          )}
        </Box>
        {onTableNumberChange ? (
          <Box
            aria-label={`Your table number is ${orderToTableInfo?.tableNumber}`}
            {...badgeStyles}
            paddingLeft="none"
            paddingRight="none"
            paddingTop="none"
            paddingBottom="none"
            borderWidth={0}
          >
            <Input
              value={tableNumber}
              onChangeText={handleTableNumberChange}
              color={orderToTableBar?.badge?.style?.color}
              fontWeight={orderToTableBar?.badge?.style?.fontWeight}
              fontFamily={orderToTableBar?.badge?.style?.fontFamily}
              textTransform={orderToTableBar?.badge?.style?.textTransform}
              lineHeight={orderToTableBar?.badge?.style?.lineHeight}
              // @ts-ignore
              fontSize={orderToTableBar?.badge?.style?.fontSize}
              style={{ height: 38, width: 40, paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}
            />
          </Box>
        ) : (
          <Box
            position="relative"
            width={40}
            height={38}
            alignItems="center"
            justifyContent="center"
            aria-label={`Your table number is ${orderToTableInfo?.tableNumber}`}
            {...badgeStyles}
          >
            <Text {...orderToTableBar?.badge?.style} textAlign="center">
              {tableNumber ?? tableNumberFromCart ?? orderToTableInfo?.tableNumber}
            </Text>
            {isRemovable ? (
              <Pressable
                accessibilityLabel="Close order to table"
                accessibilityHint="press on the order to table bar to close it"
                accessibilityRole="button"
                testID="remove-table-number-icon"
                onPress={handleRemoveIcon}
                style={{
                  position: 'absolute',
                  right: -9,
                  top: -8,
                }}
              >
                <Box
                  backgroundColor={orderToTableBar?.badge?.style?.backgroundColor ?? 'white'}
                  height={16}
                  width={16}
                  minWidth={16}
                  borderRadius="rounded"
                  borderWidth={1}
                  alignItems="center"
                  justifyContent="center"
                  borderStyle="solid"
                  borderColor="gray300"
                >
                  <CloseIcon width={6} height={6} fill="transparent" stroke="gray500" />
                </Box>
              </Pressable>
            ) : null}
          </Box>
        )}
      </Box>
    );

    const TextBlock = () => (
      <Box testID="text-block" marginLeft="l" marginRight="l" flexDirection="row" flexShrink={1}>
        <Box width="100%" flexShrink={1} flexGrow={0}>
          {!hideText ? (
            <Text fontSize="m" {...orderToTableBar?.wrapper?.text?.style} fontWeight="700">
              {text}
            </Text>
          ) : null}
          {!hideSubtext ? (
            <Text fontSize="s" {...orderToTableBar?.wrapper?.subtext?.style}>
              {subtext}
            </Text>
          ) : null}
        </Box>
      </Box>
    );

    const renderBoxes = () => {
      if (componentsOrder?.indexOf('badge') === 0) {
        return (
          <>
            <BadgeBlock />
            <TextBlock />
          </>
        );
      }
      return (
        <>
          <TextBlock />
          <BadgeBlock />
        </>
      );
    };

    return orderToTableInfo?.isOrderToTable && !isRemoved ? (
      <Box
        testID="order-to-table-bar"
        ref={ref}
        key={`update-ref-${isAlertMode}`}
        flexDirection="row"
        alignItems="center"
        padding={3}
        zIndex={100}
        width="100%"
        {...orderToTableBar?.wrapper?.style}
      >
        {isAlertMode ? (
          <Box
            testID="alert-mode"
            flexGrow={1}
            flexShrink={0}
            flexBasis="auto"
            flexDirection="row"
            alignItems="center"
            width="100%"
            justifyContent={isOnMainPage ? 'center' : 'space-between'}
          >
            <Text {...orderToTableBar?.wrapper?.text?.style} textTransform="none" fontSize="xs" marginRight={2}>
              Are you sure? This action will clear the cart
            </Text>
            <Box flexDirection="row" alignItems="center" justifyContent="center">
              <Button
                size="sm"
                onPress={handleConfirmAlert}
                isLoading={isLoadingAlertConfirm}
                buttonStyle={{
                  minHeight: 34,
                  minWidth: 94,
                }}
              >
                Yes
              </Button>
              <Box marginLeft={2}>
                <Button
                  size="sm"
                  variant="stroked"
                  onPress={handleAlertCancel}
                  isDisabled={isLoadingAlertConfirm}
                  buttonStyle={{
                    minHeight: 34,
                    minWidth: 94,
                  }}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            testID="order-to-table-boxes"
            flexGrow={1}
            flexShrink={0}
            flexBasis="auto"
            flexDirection="row"
            alignItems="center"
            width="100%"
            justifyContent={isOnMainPage ? 'center' : 'space-between'}
          >
            {renderBoxes()}
          </Box>
        )}
      </Box>
    ) : null;
  },
);
