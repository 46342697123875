import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import React, { forwardRef } from 'react';

import { MenuItemThumbnail } from '../../menu-item-thumbnail';
import { MenuItemTitle } from '../../menu-item-title';

export const CardMobileStrip = forwardRef((props: BoxProps<Theme>, forwardedRef) => {
  const { menuItemCardMobile } = useComponentsConfig();

  return (
    <Box
      ref={forwardedRef}
      flexDirection="row"
      alignItems="center"
      paddingTop={3}
      paddingBottom={3}
      paddingRight={3}
      {...props}
      {...menuItemCardMobile?.wrapper?.style}
    >
      <Box>
        <MenuItemThumbnail parentWidth={48} overwriteHeight={48} resizeMode="cover" />
      </Box>
      <MenuItemTitle textProps={{ fontSize: 'm', textAlign: 'left' }} marginLeft={4} flexGrow={0} flexShrink={1} />
    </Box>
  );
});
