import React from 'react';
import type { Props } from 'react-select';
import SelectDefault, { mergeStyles } from 'react-select';
import { useTheme } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import type { SelectProps } from './interfaces';
import { getControlStyles, getIndicatorSeparatorStyles, getPlaceholderStyles, getValueContainerStyles } from './constants';

export const Select = <T extends Props>({ ...props }: SelectProps<T>) => {
  const theme = useTheme<Theme>();
  return (
    <SelectDefault
      styles={mergeStyles(
        {
          control: (base) => ({
            ...base,
            ...getControlStyles(theme),
          }),
          indicatorSeparator: (base) => ({
            ...base,
            ...getIndicatorSeparatorStyles(),
          }),
          valueContainer: (base) => ({
            ...base,
            ...getValueContainerStyles(theme),
          }),
          placeholder: (base) => ({
            ...base,
            ...getPlaceholderStyles(theme),
          }),
        },
        props.styles ?? {},
      )}
      {...props}
    />
  );
};
