import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../../MenuItemCardProvider';

export const MenuItemCalories = forwardRef((props: BoxProps<Theme> & { textProps?: TextPropsExtended }, forwardedRef) => {
  const { calories, isHovered } = useMenuItemCardState();
  const { isMobile } = useWindowDimensions();
  const { menuItemCard, menuItemCardMobile } = useComponentsConfig();
  const { textProps, ...boxProps } = props;

  return calories ? (
    <Box
      ref={forwardedRef}
      flexGrow={1}
      flexShrink={0}
      flexBasis="auto"
      testID="menu-item-calories"
      {...boxProps}
      {...menuItemCard?.calories?.style}
      {...(isMobile ? menuItemCardMobile?.calories?.style : {})}
      {...(isHovered ? menuItemCard?.caloriesHover?.style : {})}
    >
      <Text
        {...textProps}
        {...menuItemCard?.priceGroup?.text?.style}
        {...(isMobile ? menuItemCardMobile?.priceGroup?.text?.style : {})}
        {...menuItemCard?.calories?.text?.style}
        {...(isMobile ? menuItemCardMobile?.calories?.text?.style : {})}
        {...(isHovered ? menuItemCard?.caloriesHover?.text?.style : {})}
      >
        {calories} Cal
      </Text>
    </Box>
  ) : null;
});
