import type { PrimitiveType } from 'intl-messageformat';
import cloneDeep from 'lodash/cloneDeep';
import type { IPromotionWithApplicationState } from '@goparrot/promotions-sdk';
import { intl, PromotionApplicationStateEnum } from '../../shared/services/v2';
import type { SortedPromotionListType } from './interface';

const BASE_PATH = 'promo';
export const getPromoIntlString = (path: string, values?: Record<string, PrimitiveType>) => intl.formatMessage({ id: `${BASE_PATH}.${path}` }, values);

const initPromoList: SortedPromotionListType = {
  [PromotionApplicationStateEnum.APPLIED]: [],
  [PromotionApplicationStateEnum.APPLICABLE]: [],
  [PromotionApplicationStateEnum.NON_APPLICABLE]: [],
};

const getPromoApplicationState = ({ isApplicable, isApplied }: IPromotionWithApplicationState) => {
  if (isApplied) {
    return PromotionApplicationStateEnum.APPLIED;
  }

  if (isApplicable) {
    return PromotionApplicationStateEnum.APPLICABLE;
  }

  return PromotionApplicationStateEnum.NON_APPLICABLE;
};

export const preparePromos = (
  list: IPromotionWithApplicationState[] = [],
  redirectedPromoUuid?: string | null,
): { list: SortedPromotionListType; redirectedPromo?: IPromotionWithApplicationState } => {
  const idx = redirectedPromoUuid ? list.findIndex(({ promotion }) => promotion.uuid === redirectedPromoUuid) : -1;

  if (idx !== -1) {
    const cloned = cloneDeep(list);
    const [redirectedPromo] = cloned.splice(idx, 1);

    return { list: sortPromosByStatus(cloned), redirectedPromo };
  }

  return { list: sortPromosByStatus(list) };
};

const sortPromosByStatus = (promotions: IPromotionWithApplicationState[] = []) =>
  promotions.reduce((acc, promoItem: IPromotionWithApplicationState): SortedPromotionListType => {
    const state = getPromoApplicationState(promoItem);
    acc[state].push(promoItem);

    return acc;
  }, cloneDeep(initPromoList));
