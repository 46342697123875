import styled from 'styled-components';

export const CollapsableWrapper = styled.div<{ marginBottom?: string }>`
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: var(--item-modifier-group-card-border-color);
  background-color: #fff;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '10px')};
  width: 100%;
`;

export const Details = styled.details`
  padding: 0;
`;

export const Summary = styled.summary`
  border-bottom: 1px solid rgba(45, 43, 45, 0.1);
  padding: 15px 13px;
  cursor: pointer;
  list-style-type: none;
  &:focus {
    outline: 1;
  }
  &:before {
    content: '';
    display: none;
  }
  &::-webkit-details-marker {
    display: none;
  }
`;

export const SummaryWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
