import { Box } from '@webstore-monorepo/ui/box';
import type { FC } from 'react';
import React from 'react';

import { BlockTitle } from '../block-title';
import { Cash } from './Cash';
import { CreditCard } from './CreditCard';

export type PaymentBlockType = {
  isPaymentLoading?: boolean;
};
export const PaymentBlock: FC<PaymentBlockType> = ({ isPaymentLoading }) => {
  return (
    <Box testID="checkout-payment-section">
      <BlockTitle>Payment Method</BlockTitle>
      <CreditCard isPaymentLoading={isPaymentLoading} />
      <Cash />
    </Box>
  );
};
