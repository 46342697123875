import { UserTypeEnum } from '@goparrot/customer-sdk';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { type Theme, useTheme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Checkbox } from '@webstore-monorepo/ui/checkbox';
import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';

import { useOrder, useOrderDispatch } from '../../../contexts/order-provider';
import { useSquareContext } from '../../../contexts/SquareProvider';
import { squareIframeCustomStyles } from './styles';

export const CreditCardForm: FC = () => {
  const { user } = useUserContextState();
  const { squarePayments } = useSquareContext();
  const { squareCard, shouldSaveCard } = useOrder();
  const { onSetSquareCard, onShouldSaveCardToggle } = useOrderDispatch();
  const cardForm = useRef<HTMLDivElement | null>(null);
  const theme = useTheme<Theme>();
  const inited = useRef(false);

  useEffect(() => {
    const initCard = async () => {
      try {
        if (cardForm.current && !inited.current && !squareCard) {
          inited.current = true;
          const squareCard = await squarePayments?.card({
            style: squareIframeCustomStyles(theme),
          });
          await squareCard?.attach(cardForm.current);
          if (squareCard) {
            onSetSquareCard(squareCard);
          } else {
            inited.current = false;
          }
        }
      } catch (e) {
        inited.current = false;
      }
    };
    initCard();

    return () => {
      if (squareCard) {
        onSetSquareCard(undefined);
      }
    };
  }, [squareCard, onSetSquareCard, squarePayments, theme]);

  return (
    <>
      <Box ref={cardForm} />
      {user.type === UserTypeEnum.AUTHENTICATED ? (
        <Box my={4} flexDirection="row" alignItems="center" justifyContent="space-between">
          <Checkbox
            label="Save for future payments"
            fullWidth
            labelPlacement="before"
            containerStyles={{
              justifyContent: 'space-between',
            }}
            checked={shouldSaveCard}
            onPress={onShouldSaveCardToggle}
          />
        </Box>
      ) : null}
    </>
  );
};
