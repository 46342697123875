import { CountryISOCodeEnum, CurrencyEnum } from '@goparrot/common';
import { ReactNativePostMessagesEnum } from '@webstore-monorepo/shared/enums';

type CardNonceRequest = {
  orderTotal: number;
  countryCode: CountryISOCodeEnum;
  currencyCode: CurrencyEnum;
};
export const postMessageRequestApplePayCardNonce = ({
  orderTotal,
  countryCode = CountryISOCodeEnum.United_States,
  currencyCode = CurrencyEnum.USD,
}: CardNonceRequest) => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: ReactNativePostMessagesEnum.REQUEST_APPLE_PAY_CARD_NONCE,
      payload: orderTotal,
      payloadV2: {
        price: orderTotal,
        currencyCode: currencyCode,
        countryCode: countryCode,
      },
    }),
  );
};

export const postMessageRequestGooglePayCardNonce = ({
  orderTotal,
  countryCode = CountryISOCodeEnum.United_States,
  currencyCode = CurrencyEnum.USD,
  squareLocationId,
}: CardNonceRequest & { squareLocationId: string }) => {
  window.ReactNativeWebView?.postMessage(
    JSON.stringify({
      type: ReactNativePostMessagesEnum.REQUEST_GOOGLE_PAY_CARD_NONCE,
      payload: {
        price: orderTotal,
        squareLocationId: squareLocationId,
        currencyCode: currencyCode,
        countryCode: countryCode,
      },
    }),
  );
};
