import type { IOrder, IOrderPromotionElement } from '@goparrot/order-sdk';
import type { IPromocodeTemplate } from '@goparrot/promotions-sdk';
import { PromotionSubTypeEnum } from '@goparrot/promotions-sdk';
import type { IReadWebStoreDto } from '@goparrot/webstore-sdk';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useCallback } from 'react';

import axios from '../../api/setup';
import { FB_PIXEL_TRACKING } from '../../services';

const MAP_TRACK_ACTIONS_TO_FB_PIXEL_ACTIONS: { [key: string]: string } = {
  account_submit_registration_data_result: FB_PIXEL_TRACKING.COMPLETE_REGISTRATION,
  item_add_to_cart: FB_PIXEL_TRACKING.ADD_TO_CART,
  packing_instructions_add_item_to_cart: FB_PIXEL_TRACKING.ADD_TO_CART,
  recommended_items_add_item_to_cart: FB_PIXEL_TRACKING.ADD_TO_CART,
  transaction_succeed: FB_PIXEL_TRACKING.PURCHASE,
  checkout_initiated: FB_PIXEL_TRACKING.INITIATE_CHECKOUT,
  page_load: FB_PIXEL_TRACKING.VIEW_CONTENT,
};
const GTAGS = ['transaction_succeed'];
const GTAG_RESTRICTED_PROPS = ['userId', 'first_name', 'last_name', 'email', 'promotions'];

export const useAnalytics = () => {
  const webstore = useWebStore();
  const cart = useCartState();

  const addTrack = useCallback(
    (tag: string, additionalData: Record<any, any>): void => {
      axios.post(`${window.WEBSTORE_GATEWAY_URL}/api/v2/analytics`, {
        storeId: webstore.storeId,
        cartId: cart?._id,
        actionName: tag,
        additionalData,
      });
    },
    [cart, webstore],
  );

  const hj = useCallback(() => {
    if (window.hj && cart?._id) {
      window.hj('identify', cart?._id, {});
    }
  }, [cart]);

  const ym = useCallback(() => {
    if (window.ym && cart?._id) {
      window.ym(66684697, 'userParams', {
        cartId: cart?._id,
        UserID: cart?.userId,
      });
    }
  }, [cart]);

  const fbPixelTrack = useCallback((tag: string, additionalData: Record<any, any>): void => {
    const fbTrackAction = MAP_TRACK_ACTIONS_TO_FB_PIXEL_ACTIONS[tag];
    if (window.fbq && fbTrackAction) {
      window.fbq('track', fbTrackAction, additionalData);
    }
  }, []);

  const pushGtag = useCallback(
    (tag: any, additionalData: Record<string, any>): void => {
      const gtagConfig = webstore.metadata?.gTags?.[tag];

      // Quick fix for GA gTag
      // TODO: get the detailed strategy for gTags processing and refactor
      if (gtagConfig?.conversionId && gtagConfig?.conversionLabel) {
        const requiredDataProps: string[] = gtagConfig.data || [];
        let analyticsData = { ...additionalData };

        if (GTAGS.includes(tag)) {
          analyticsData = getFilteredPurchaseDataForTag(analyticsData, webstore);
        }

        const tagAdditionalData = getRequiredDataForTag(requiredDataProps, analyticsData);

        window?.dataLayer.push({
          event: 'conversion',
          send_to: `${gtagConfig.conversionId}/${gtagConfig.conversionLabel}`,
          ...tagAdditionalData,
        });
      }
    },
    [webstore.metadata?.gTags],
  );

  /**
   * Function that pull's the required data that is described in gtagConfig
   * @param {*} requiredDataProps Property from tag information that describes needed fields for analytics
   * @param {*} additionalData Data that comes from generic tracking system ex: {userId: USER_ID, cart: CART_OBJECT}
   */
  function getRequiredDataForTag(requiredDataProps: string[], additionalData: Record<any, any> = {}) {
    const requiredDataObject: { [x: string]: any } = {};
    requiredDataProps.forEach((item: any) => {
      if (additionalData[item]) {
        requiredDataObject[item] = additionalData[item];
      }
    });

    return requiredDataObject;
  }

  const handleTrack = useCallback(
    (tag: string, additionalData: Record<any, any> = {}): void => {
      pushGtag(tag, additionalData);
      addTrack(tag, additionalData);
      if (tag === 'page_load') {
        hj();
        ym();
      }

      if (GTAGS.includes(tag)) {
        GTAG_RESTRICTED_PROPS.forEach((field: string) => delete additionalData[field]);
      }

      fbPixelTrack(tag, additionalData);
    },
    [addTrack, fbPixelTrack, hj, pushGtag, ym],
  );

  return {
    track: handleTrack,
  };
};

const generatePurchaseGtagInfo = (lastOrder: IOrder, webstore: IReadWebStoreDto | null) => {
  const products: any[] = [];
  let promo = '';

  lastOrder?.elements.forEach((item: any) => {
    if (item.type === 'promotion') {
      promo = item.title;
      return;
    }

    products.push({
      name: item.titleOriginal, // Name or ID is required.
      id: item.uniqueNameOriginal,
      price: item.price,
      category: item.metadata.category,
      quantity: item.quantity,
    });
  });

  const actionField = {
    id: lastOrder?.orderNumber,
    affiliation: webstore?.storeName,
    revenue: lastOrder?.total,
    tax: lastOrder?.total_tax,
    shipping: lastOrder?.deliveryFeeAmount,
    coupon: promo,
  };

  return {
    event: 'eec.purchase',
    ecommerce: {
      purchase: {
        actionField,
        products,
      },
    },
  };
};

const getFilteredPurchaseDataForTag = (additionalData: Record<string, any>, webstore: IReadWebStoreDto | null): Record<string, any> => {
  window?.dataLayer.push(generatePurchaseGtagInfo(additionalData.lastOrder, webstore));
  const { promotions } = additionalData;

  const promoCode = promotions.find(
    (promo: IOrderPromotionElement & IPromocodeTemplate) => PromotionSubTypeEnum.PROMOCODE === promo.subtype && promo.isTemplate,
  )?.promoCode;

  if (!promoCode) {
    GTAG_RESTRICTED_PROPS.forEach((field: string) => delete additionalData[field]);

    return additionalData;
  }

  return { ...additionalData, promoCode };
};
