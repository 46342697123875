import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import { H2 } from '@webstore-monorepo/ui/html-elements';
import { Text } from '@webstore-monorepo/ui/text';
import { TextGradient } from '@webstore-monorepo/ui/text-gradient';
import type { FC } from 'react';
import React from 'react';

export type CategoryItemsGroupTitleProps = {
  title: string;
};
export const CategoryItemsGroupTitle: FC<CategoryItemsGroupTitleProps> = ({ title }) => {
  const { categoryItemsGroup } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();

  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      flexShrink={0}
      {...categoryItemsGroup?.wrapper?.title?.style}
      {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.style : {})}
    >
      <Box width="100%">
        <H2
          style={{
            textAlign: isMobile
              ? categoryItemsGroup?.wrapperMobile?.options?.showTitleTextGradient
                ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.style?.textAlign
                : categoryItemsGroup?.wrapperMobile?.title?.text?.style?.textAlign
              : categoryItemsGroup?.wrapper?.options?.showTitleTextGradient
              ? categoryItemsGroup?.wrapper?.title?.textGradient?.style?.textAlign
              : categoryItemsGroup?.wrapper?.title?.text?.style?.textAlign,
          }}
        >
          {(isMobile ? categoryItemsGroup?.wrapperMobile?.options?.showTitleTextGradient : categoryItemsGroup?.wrapper?.options?.showTitleTextGradient) ? (
            <TextGradient
              textAlign="center"
              fontSize="4xl"
              colors={
                isMobile
                  ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.colors
                  : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.colors
              }
              start={
                isMobile
                  ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.start
                  : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.start
              }
              end={
                isMobile ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.end : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.end
              }
              {...categoryItemsGroup?.wrapper?.title?.text?.style}
              {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
              {...categoryItemsGroup?.wrapper?.title?.textGradient?.style}
              {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.style : {})}
            >
              {title}
            </TextGradient>
          ) : (
            <Text
              fontSize="xxxl"
              textAlign="center"
              {...categoryItemsGroup?.wrapper?.title?.text?.style}
              {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
            >
              {title}
            </Text>
          )}
        </H2>
      </Box>
    </Box>
  );
};
