import { StoreItemValidityEnum } from '@goparrot/common';
import type { IOrderElement, IOrderPromotionElement } from '@goparrot/order-sdk';
import { PromotionSubTypeEnum } from '@goparrot/promotions-sdk';
import { ItemCounter } from '@webstore-monorepo/features/item-counter';
import type { RemoveComboParams } from '@webstore-monorepo/shared/api/cart-api';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { ChevronDown, CloseIcon, DefaultCartImage } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import type { ComboConfig } from '@webstore-monorepo/shared/utils/cart';
import { getComboTotalPrice, getRelatedPromotion } from '@webstore-monorepo/shared/utils/cart';
import { hasItemPaidModifiers } from '@webstore-monorepo/shared/utils/modifiers';
import { showPaidModifiersDiscountNotice } from '@webstore-monorepo/shared/utils/promotions';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { LazyImage } from '@webstore-monorepo/ui/lazy-image';
import { Text } from '@webstore-monorepo/ui/text';
import React, { useEffect, useState } from 'react';
import { Animated, Platform, Pressable } from 'react-native';

import { ComboDetails } from './ComboDetails';

const animationSpeed = 200;

interface Props {
  isLoading: boolean;
  combo: ComboConfig;
  promotions: IOrderPromotionElement[];
  showPrice?: boolean;
  onEdit?: (comboUniqueName: string, uniqueName: string) => void;
  onUpdate?: (combo: ComboConfig, qty: number) => void;
  onRemove?(promoUuid: string, type: RemoveComboParams['type']): void;
  isOrderDetails?: boolean;
  borderBottomColor?: keyof Theme['colors'];
}

export const OrderComboItem = ({ onUpdate, combo, onEdit, promotions, isLoading, onRemove, borderBottomColor = 'gray100', isOrderDetails = false }: Props) => {
  const cart = useCartState();
  const { comboGroup, items } = combo;
  const { uniqueNameOriginal, uniqueName, title, image_url_square, image_url, price, quantity, tag, type } = comboGroup;
  const [showDetails, setShowDetails] = useState(false);
  const { intl } = usePlatformStoreState();
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const cartOptions = isMobile && Platform.OS === 'web' ? cartScreen?.wrapperMobile : cartScreen?.wrapper;
  const imageUrl = image_url_square || image_url || cartOptions?.items?.image?.options?.defaultImage;
  const relatedPromotion = getRelatedPromotion(comboGroup, cart.promotions);
  const totalPrice = getComboTotalPrice(comboGroup, relatedPromotion);
  const isDiscounted = !!totalPrice.discounted;

  const [rotateZ] = useState(() => new Animated.Value(0));
  const spin = rotateZ.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '180deg'],
  });

  const comboContains = items
    .map(({ titleOriginal }: IOrderElement) => titleOriginal)
    .slice(0, 3)
    .join(', ');

  const promo = promotions.find((p) => p.uuid === tag?.promotionUuid);

  const showPaidModifiersMessage = items.some((item: IOrderElement) => hasItemPaidModifiers(item) && showPaidModifiersDiscountNotice(promotions, promo));

  const isValid = StoreItemValidityEnum.VALID === comboGroup.validity && items.every((i: IOrderElement) => StoreItemValidityEnum.VALID === i.validity);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    Animated.timing(rotateZ, {
      toValue: showDetails ? 1 : 0,
      duration: animationSpeed,
      useNativeDriver: true,
    }).start();
  }, [showDetails]);

  const renderShowMore = () => {
    return (
      <Pressable onPress={toggleDetails}>
        <Box flexDirection="row" alignItems="center">
          <Text fontWeight="500" color="primaryButtonColor" marginRight={1} {...cartOptions?.items?.link?.style}>
            {showDetails ? 'Show less' : 'Show more'}
          </Text>
          <Animated.View style={{ transform: [{ rotateZ: spin }] }}>
            <ChevronDown width={14} height={14} fill={cartOptions?.items?.link?.style?.color || 'primaryButtonColor'} />
          </Animated.View>
        </Box>
      </Pressable>
    );
  };

  return (
    <Box>
      <Box
        marginRight="-4"
        marginLeft="-4"
        paddingLeft={4}
        paddingRight={4}
        backgroundColor={cartOptions?.items?.comboBackground?.style?.backgroundColor}
        borderBottomWidth={1}
        borderColor={borderBottomColor}
      >
        <Box {...cartOptions?.items?.separator?.style}>
          <Box paddingTop={4} paddingBottom={4} flexDirection="row" testID={`recommended-item-${uniqueName}`}>
            {onEdit ? (
              <Pressable
                accessibilityLabel={`Cart item ${title}`}
                accessibilityHint={`press on the menu item ${title} to edit it`}
                accessibilityRole="button"
                onPress={() => onEdit(uniqueNameOriginal as unknown as string, uniqueName)}
                style={{ alignSelf: 'flex-start' }}
              >
                <Box overflow="hidden" marginRight={4} width={70} height={70} alignItems="center" {...cartOptions?.items?.image?.style}>
                  {imageUrl ? (
                    //@ts-expect-error allow string value for width
                    <Box width={Platform.OS === 'web' ? 'max-content' : '100%'} height={cartOptions?.items?.image?.style?.height || 70}>
                      <LazyImage
                        style={{ flex: 1 }}
                        height={cartOptions?.items?.image?.style?.height || 70}
                        accessibilityLabel={title}
                        accessibilityHint="your order item thumbnail"
                        src={imageUrl}
                        alt={title}
                        resizeMode="cover"
                      />
                    </Box>
                  ) : (
                    <DefaultCartImage width={70} height={70} fill="gray300" stroke="transparent" />
                  )}
                </Box>
              </Pressable>
            ) : (
              <Box overflow="hidden" marginRight={4} width={70} height={70} alignItems="center" {...cartOptions?.items?.image?.style}>
                {imageUrl ? (
                  //@ts-expect-error allow string value for width
                  <Box width={Platform.OS === 'web' ? 'max-content' : '100%'} height={cartOptions?.items?.image?.style?.height || 70}>
                    <LazyImage
                      style={{ flex: 1 }}
                      width={cartOptions?.items?.image?.style?.width || 70}
                      accessibilityLabel={title}
                      accessibilityHint="your order item thumbnail"
                      src={imageUrl}
                      alt={title}
                      resizeMode="cover"
                    />
                  </Box>
                ) : (
                  <DefaultCartImage width={70} height={70} fill="gray300" stroke="transparent" />
                )}
              </Box>
            )}
            <Box flex={1}>
              <Box marginBottom={1}>
                <Box marginBottom={2} flexDirection="row" justifyContent="space-between">
                  {onEdit ? (
                    <Pressable
                      accessibilityLabel={`Cart item ${title}`}
                      accessibilityHint={`press on the menu item ${title} to edit it`}
                      accessibilityRole="button"
                      onPress={() => onEdit(uniqueNameOriginal as unknown as string, uniqueName)}
                      style={{ alignSelf: 'flex-start', marginBottom: 1, flex: 1 }}
                    >
                      <Text color="text" lineHeight={24} fontSize="m" fontWeight="700" testID="item-title" {...cartOptions?.items?.title?.style}>
                        {title}
                      </Text>
                    </Pressable>
                  ) : (
                    <Text color="text" lineHeight={24} fontSize="m" fontWeight="700" testID="item-title" {...cartOptions?.items?.title?.style}>
                      {title}
                    </Text>
                  )}
                </Box>
                {onEdit ? (
                  <Text
                    color="text"
                    accessibilityLabel={`Item price ${price}`}
                    lineHeight={24}
                    fontSize="m"
                    marginBottom={3}
                    testID="item-price"
                    {...cartOptions?.items?.price?.style}
                  >
                    {intl.formatNumber(price)}
                  </Text>
                ) : null}
                <Box>
                  {isDiscounted && relatedPromotion ? (
                    <Text
                      lineHeight={24}
                      marginTop={2}
                      fontSize="m"
                      fontWeight="700"
                      color="success"
                      testID="discount-name"
                      {...cartOptions?.items?.discount?.style}
                    >
                      {relatedPromotion.title}
                    </Text>
                  ) : null}
                  {!isValid && !isOrderDetails && (
                    <Text color="danger" lineHeight={24}>
                      This item is unavailable
                    </Text>
                  )}
                  <Text lineHeight={22} fontSize="s" color="gray500" {...cartOptions?.items?.text?.style}>
                    {comboContains}
                  </Text>
                  {!onEdit ? (
                    <Text marginTop={2} color="text" testID="item-quantity" {...cartOptions?.items?.quantity?.style}>
                      {cartOptions?.items?.quantity?.options?.text || 'Qty'}: {quantity}
                    </Text>
                  ) : null}
                  {showPaidModifiersMessage && (
                    <Text
                      marginTop={3}
                      fontWeight="700"
                      fontSize="m"
                      lineHeight={24}
                      color="warningText"
                      testID="paid-modifiers"
                      {...cartOptions?.items?.modifiers?.style}
                    >
                      Paid modifiers are not discounted
                    </Text>
                  )}
                  <Box marginTop={2} testID="show-nore-button">
                    {renderShowMore()}
                  </Box>
                </Box>
                {onEdit ? (
                  <Box marginTop={4} flexDirection="row" alignItems="center">
                    {onUpdate && (
                      <Box>
                        <ItemCounter
                          key={quantity}
                          disabled={isLoading}
                          step={1}
                          elementMin={1}
                          elementMax={10}
                          onChange={(quantity) => onUpdate(combo, quantity)}
                          value={quantity}
                        />
                      </Box>
                    )}
                    <Box marginLeft={4} aria-label={`Item total ${isDiscounted ? totalPrice.discounted : totalPrice.origin}`}>
                      <Box flexDirection="row">
                        <TotalPrice isDiscounted={isDiscounted} value={intl.formatNumber(totalPrice.origin)} style={cartOptions?.items?.price?.style} />
                        {isDiscounted && <DiscountedTotalPrice value={intl.formatNumber(totalPrice.discounted)} style={cartOptions?.items?.price?.style} />}
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Box>
            </Box>
            {onRemove ? (
              <Box marginLeft={1}>
                <Button
                  accessibilityLabel="Remove item from cart"
                  buttonStyle={{
                    alignItems: 'flex-start',
                  }}
                  color="gray400"
                  variant="link"
                  onPress={() => !isLoading && onRemove(uniqueName, type as RemoveComboParams['type'])}
                  leftIcon={<CloseIcon stroke="gray900" width={14} height={14} strokeWidth={1.5} />}
                />
              </Box>
            ) : null}

            {!onEdit ? (
              <Box marginLeft={2} alignItems="flex-end">
                {isDiscounted && <DiscountedTotalPrice value={intl.formatNumber(totalPrice.discounted)} style={cartOptions?.items?.price?.style} />}
                <TotalPrice isDiscounted={isDiscounted} value={intl.formatNumber(totalPrice.origin)} style={cartOptions?.items?.price?.style} />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box>{showDetails ? <ComboDetails items={items} isOrderDetails={isOrderDetails} /> : null}</Box>
      </Box>
    </Box>
  );
};

type DiscountedPriceProps = {
  value: string | React.ReactNode;
  style?: Record<string, any>;
};

const DiscountedTotalPrice: React.FC<DiscountedPriceProps> = ({ value, style }) => (
  <Text testID="total-price-discounted" lineHeight={24} color="text" fontSize="m" {...style}>
    {value}
  </Text>
);

type TotalPriceProps = DiscountedPriceProps & {
  isDiscounted?: boolean;
};

const TotalPrice: React.FC<TotalPriceProps> = ({ value, isDiscounted, style }) => (
  <Text
    accessibilityLabel={`Item total price ${value}`}
    testID="total-price"
    lineHeight={24}
    fontSize="m"
    marginRight={2}
    textDecorationLine={isDiscounted ? 'line-through' : undefined}
    color={isDiscounted ? 'gray400' : style?.color || 'gray900'}
    {...style}
  >
    {value}
  </Text>
);
