import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../MenuItemCardProvider';

export const MenuItemSchedule = forwardRef((props: BoxProps<Theme> & { textProps?: TextPropsExtended }, forwardedRef) => {
  const { schedule, isHovered } = useMenuItemCardState();
  const { isMobile } = useWindowDimensions();
  const { menuItemCard, menuItemCardMobile } = useComponentsConfig();
  const { textProps, ...boxProps } = props;

  return schedule && menuItemCard?.schedule?.options?.text ? (
    <Box
      ref={forwardedRef}
      width="100%"
      marginBottom={1}
      {...boxProps}
      {...menuItemCard?.schedule?.style}
      {...(isMobile ? menuItemCardMobile?.schedule?.style : {})}
      {...(isHovered ? menuItemCard?.scheduleHover?.style : {})}
    >
      <Text
        fontSize="xs"
        {...textProps}
        {...menuItemCard?.schedule?.text?.style}
        {...(isMobile ? menuItemCardMobile?.schedule?.text?.style : {})}
        {...(isHovered ? menuItemCard?.scheduleHover?.text?.style : {})}
      >
        {isMobile ? menuItemCardMobile?.schedule?.options?.text : menuItemCard?.schedule?.options?.text}
      </Text>
    </Box>
  ) : null;
});
