import type { IMenuConcept } from '@goparrot/storeitems-sdk';
import { useGetMenuConcepts } from '@webstore-monorepo/shared/api/cart-api';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box, ScrollableBox } from '@webstore-monorepo/ui/box';
import orderBy from 'lodash/orderBy';
import type { FC, RefObject } from 'react';
import React, { createRef, useRef, useState } from 'react';

import { MenuConceptsDetails } from './components/MenuConceptsDetails';
import { MenuConceptsItem } from './MenuConceptsItem';

export type MenuConceptsBarProps = {
  // uniqueName
  selectedMenuConcept: string;
  onSelectMenuConcept: (uniqueName: string, title: string) => void;
};
export const MenuConceptsBar: FC<MenuConceptsBarProps> = ({ selectedMenuConcept, onSelectMenuConcept }) => {
  const { menuConceptsBar } = useComponentsConfig();
  const { width } = useWindowDimensions();
  const { data: menuConcepts } = useGetMenuConcepts({
    onSuccess: (data) =>
      orderBy(
        data.filter(({ isEnabled }) => isEnabled),
        'showOrder',
      ),
  });
  const [selectedConcept, setSelectedConcept] = useState<IMenuConcept>();
  const wrapperRef = useRef<HTMLElement>(null);
  const itemsRef = useRef<RefObject<HTMLElement>[]>([]);
  const itemsCount = useRef(itemsRef.current.length);
  const isItemsCoverParent = useRef(false);
  const prevWidth = useRef(width);
  const itemsWidthCount = itemsRef.current.reduce((prev, item) => prev + (item?.current?.offsetWidth ?? 0), 0);

  if ((itemsRef.current.length > itemsCount.current || prevWidth.current !== width) && itemsWidthCount) {
    itemsCount.current = itemsRef.current.length;
    prevWidth.current = width;
    isItemsCoverParent.current = itemsWidthCount >= prevWidth.current;
  }

  if (menuConcepts && menuConcepts.length > 0 && selectedConcept?.uniqueName !== selectedMenuConcept) {
    const found = menuConcepts.find((item) => item.uniqueName === selectedMenuConcept);
    const nextSelected = found ? found : menuConcepts[0];
    setSelectedConcept(nextSelected);
  }

  return menuConcepts?.length ? (
    <>
      <Box
        ref={wrapperRef}
        paddingTop={3}
        paddingBottom={3}
        paddingLeft={4}
        paddingRight={5}
        backgroundColor="white"
        alignItems="center"
        justifyContent="center"
        {...menuConceptsBar?.wrapper?.style}
      >
        <ScrollableBox
          flexDirection="row"
          height={75}
          maxHeight={75}
          alignItems="center"
          width="100%"
          justifyContent={isItemsCoverParent.current ? 'flex-start' : 'center'}
          showShadows={isItemsCoverParent.current}
          backgroundColor={menuConceptsBar?.wrapper?.style?.backgroundColor}
        >
          {menuConcepts?.map((item, index) => (
            <MenuConceptsItem
              ref={(itemsRef.current[index] = createRef())}
              key={index}
              item={item}
              isActive={selectedMenuConcept === item.uniqueName}
              onClick={onSelectMenuConcept}
              boxProps={{ marginLeft: index === 0 ? 'none' : 4 }}
            />
          ))}
        </ScrollableBox>
      </Box>
      <MenuConceptsDetails selectedConcept={selectedConcept} />
    </>
  ) : null;
};
