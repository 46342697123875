import UAParser from 'ua-parser-js';

export function getUserAgentInfo() {
  const result = new UAParser().getResult();

  return {
    device: result.device,
    browser: result.browser,
    os: result.os,
  };
}
