import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { sanitizeUrl } from '@webstore-monorepo/shared/utils/url';
import { Box, InlineBox } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Linking } from 'react-native';

export type HeaderLinksProps = {
  giftCardEnabled: boolean;
  onBuyGiftCardClick: () => void;
  onCustomLinkClick?: (url: string, openInNewTab?: boolean) => void;
};

export const HeaderLinks: FC<HeaderLinksProps> = ({ giftCardEnabled, onBuyGiftCardClick, onCustomLinkClick }) => {
  const { isMobile, isPortraitMobile } = useWindowDimensions();
  const homeRef = useRef();
  const giftCardsRef = useRef();
  const customTitleRef = useRef();
  const { isHovered: isHomeHovered } = useHover({}, homeRef);
  const { isHovered: isGiftCardsHovered } = useHover({}, giftCardsRef);
  const { isHovered: isCustomTitleHovered } = useHover({}, customTitleRef);
  const { header } = useComponentsConfig();
  const { homeButtonText = 'home', homeButtonUrl = '#' } = header?.headerLinks?.options ?? {};
  const customHeaderButton = header?.headerLinks?.customLink?.options ?? {};
  const homeButtonTextLow = homeButtonText.toLowerCase();
  const customHeaderButtonLow = customHeaderButton?.title?.toLowerCase();

  const handleOpenHomeButton = () => {
    Linking.canOpenURL(homeButtonUrl).then(async () => {
      return Linking.openURL(homeButtonUrl);
    });
  };

  const handleCustomButtonClick = () => {
    if (customHeaderButton) {
      onCustomLinkClick && onCustomLinkClick(sanitizeUrl(customHeaderButton.url ?? ''), customHeaderButton.openInNewTab);
    }
  };

  const customHeaderButtonStyles =
    customHeaderButton &&
    (customHeaderButton?.showAsButton
      ? {
          variant: 'contained',
          borderRadius: 'xs',
          ...header?.headerLinks?.customLink?.style,
          backgroundColor: isCustomTitleHovered
            ? header?.headerLinks?.customLinkHover?.style?.backgroundColor
            : header?.headerLinks?.customLink?.style?.backgroundColor,
        }
      : {
          variant: 'link',
          ...header?.headerLinks?.customLink?.style,
          backgroundColor: 'transparent',
        });

  return !(isMobile || isPortraitMobile) ? (
    <InlineBox testID="header-links">
      <Box alignItems="center" justifyContent="center">
        <Button ref={homeRef} variant="link" accessibilityRole="link" onPress={handleOpenHomeButton}>
          <Text
            {...header?.text?.style}
            {...header?.headerLinks?.style}
            {...(isHomeHovered && header?.text?.hover?.style)}
            {...(isHomeHovered && header?.headerLinks?.hover?.style)}
          >
            {homeButtonTextLow}
          </Text>
        </Button>
      </Box>
      {giftCardEnabled ? (
        <Box marginLeft={4} alignItems="center" justifyContent="center">
          <Button ref={giftCardsRef} variant="link" onPress={onBuyGiftCardClick}>
            <Text
              accessibilityRole="none"
              textTransform="capitalize"
              {...header?.text?.style}
              {...header?.headerLinks?.style}
              {...(isGiftCardsHovered && header?.text?.hover?.style)}
              {...(isGiftCardsHovered && header?.headerLinks?.hover?.style)}
            >
              gift cards
            </Text>
          </Button>
        </Box>
      ) : null}
      {customHeaderButton?.url ? (
        <Box marginLeft={4} alignItems="center" justifyContent="center">
          {/*// @ts-ignore */}
          <Button ref={customTitleRef} accessibilityRole="link" onPress={handleCustomButtonClick} {...customHeaderButtonStyles}>
            <Text
              {...header?.text?.style}
              {...header?.headerLinks?.customLink?.style}
              {...(isCustomTitleHovered && header?.text?.hover?.style)}
              {...(isCustomTitleHovered && header?.headerLinks?.customLinkHover?.style)}
            >
              {customHeaderButtonLow}
            </Text>
          </Button>
        </Box>
      ) : null}
    </InlineBox>
  ) : null;
};
