import type { IReadPromotionDto } from '@goparrot/promotions-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { EmptyFallback } from '@webstore-monorepo/ui/error-fallback';
import type { FC } from 'react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { DesktopView, MobileView } from './components';
import { PromoPopupProvider } from './PromoPopupProvider';

export type PromoPopupProps = {
  promotion: IReadPromotionDto;
  onCancel: (uuid: string) => void;
  onAddToCart: (uuid: string) => void;
};
export const PromoPopup: FC<PromoPopupProps> = ({ promotion, onAddToCart, onCancel }) => {
  const { isMobile } = useWindowDimensions();

  const wrapperStyles: BoxProps<Theme> = {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: 1,
    flexGrow: 0,
    borderRadius: 'md',
  };

  const detailsStyles: BoxProps<Theme> = {
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexShrink: 1,
    flexGrow: 0,
  };

  const titleWrapperStyles: BoxProps<Theme> = {
    paddingTop: isMobile ? 4 : 2,
    paddingRight: isMobile ? 9 : 9,
    paddingBottom: isMobile ? 4 : 2,
    paddingLeft: isMobile ? 4 : 4,
  };

  const handleAddToCart = () => {
    onAddToCart(promotion.uuid);
  };

  const handleCancel = () => {
    onCancel(promotion.uuid);
  };

  return (
    <ErrorBoundary FallbackComponent={EmptyFallback}>
      <PromoPopupProvider
        promotion={promotion}
        onCancel={handleCancel}
        onAddToCart={handleAddToCart}
        titleWrapperStyles={titleWrapperStyles}
        wrapperStyles={wrapperStyles}
        detailsStyles={detailsStyles}
      >
        {isMobile ? <MobileView /> : <DesktopView />}
      </PromoPopupProvider>
    </ErrorBoundary>
  );
};
