import { OrderFlowEnum, OrderGratuityTypeEnum } from '@goparrot/common';
import { CartActionTypeEnum } from '@goparrot/order-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useCartHandleActions } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CircledExclamation } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import debounce from 'lodash/debounce';
import isObject from 'lodash/isObject';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';

import { CustomAmount } from './cusmot-amount';
import { TipsOption } from './tips-option';
import type { DefaultTip, State } from './types';
import { AmountTypeEnum } from './types';
import { defaultGratuityType, defaultGratuityValues, getGratuityState } from './utils';

export interface CartTipsProps {
  onSetTipsRequestState?: (state: boolean) => void;
  isDisabled?: boolean;
  showSelectGratuityNotification?: boolean;
  boxProps?: BoxProps<Theme> & React.RefAttributes<unknown>;
}

const defaultTipsRange = {
  type: OrderGratuityTypeEnum.PERCENT,
  range: [15, 20, 25],
} as DefaultTip;

export const Tips = forwardRef(
  ({ onSetTipsRequestState, isDisabled, showSelectGratuityNotification, boxProps }: CartTipsProps, forwardedRef: React.ForwardedRef<any>) => {
    const { analytics, notification } = usePlatformStoreState();
    const cart = useCartState();
    const { isMobile } = useWindowDimensions();
    const { mutateAsync: handleActions } = useCartHandleActions();
    const cartDispatch = useCartDispatch();
    const { cartScreen } = useComponentsConfig();
    const { customMessages, defaultTip } = (isMobile && Platform.OS === 'web'
      ? cartScreen?.wrapperMobile?.gratuity?.options
      : cartScreen?.wrapper?.gratuity?.options) || {
      defaultTip: defaultTipsRange,
    };
    const { title, description, notification: notSelectedError } = customMessages || {};
    const gratuityType: OrderGratuityTypeEnum = (defaultTip?.type as OrderGratuityTypeEnum) || defaultGratuityType;

    const [gratuityAmount, setGratuity] = useState<State>(() =>
      getGratuityState({
        cartGratuityAmount: cart.tipsInfo?.amount,
        cartGratuityType: cart.tipsInfo?.type, // when user choose custom amount, type will be 'fixed'.
        defaultGratuity: defaultTip as DefaultTip,
        originGratuityType: gratuityType,
      }),
    );
    const isCustomAmount = gratuityAmount.type === AmountTypeEnum.CUSTOM;
    const presetList: number[] = defaultTip?.range?.length ? defaultTip.range : defaultGratuityValues[gratuityType];

    const selectNonPreSetAmount = (type: AmountTypeEnum) => () =>
      setGratuity({
        value: 0,
        type: type,
      });

    const selectPreSetAmount = (amount: number) => {
      setGratuity({
        value: amount,
        type: AmountTypeEnum.PRE_SET,
      });
    };

    useEffect(() => {
      if (!cart.tipsInfo && defaultTip) {
        setGratuity({
          value: defaultTip.amount,
          type: AmountTypeEnum.PRE_SET,
        });
      }
    }, [defaultTip, cart.tipsInfo]);

    const updateCartWithGratuity: (type: OrderGratuityTypeEnum, amount: number, amountType?: AmountTypeEnum) => void = useRef(
      debounce(async (type: OrderGratuityTypeEnum, amount: number, amountType?: AmountTypeEnum) => {
        analytics.track('checkout_tip_selected', { type, amount, amountType });
        onSetTipsRequestState && onSetTipsRequestState(true);
        try {
          const newCart = await handleActions([
            {
              type: CartActionTypeEnum.SET_GRATUITY,
              payload: {
                type,
                amount,
              },
            },
          ]);
          if (isObject(newCart)) {
            cartDispatch({ type: 'update', payload: newCart });
          }
        } catch (error: any) {
          if (error?.data?.message?.includes('maximum allowed weight')) {
            notification.error(error.data.message);
          } else {
            notification.error('Oops! Something went wrong!');
          }
        } finally {
          onSetTipsRequestState && onSetTipsRequestState(false);
        }
      }, 300),
    ).current;

    const onChangeCustomAmount = useRef(
      debounce((value: number) => {
        setGratuity(() => ({ type: AmountTypeEnum.CUSTOM, value }));
      }, 500),
    ).current;

    const getNextGratuityType: () => OrderGratuityTypeEnum = useCallback(
      () => (isCustomAmount ? OrderGratuityTypeEnum.FIX : gratuityType),
      [gratuityType, isCustomAmount],
    );

    useEffect(() => {
      const nextGratuityType = getNextGratuityType();
      // no need to update gratuity if amount or type was not changed
      // e.g 'NO_TIP' -> 'CUSTOM' with amount 0
      // but we must update gratuity if we change from custom amount to preset percentage
      if (
        (gratuityAmount.value === undefined || gratuityAmount.value === cart.tipsInfo?.amount) &&
        !(gratuityType === OrderGratuityTypeEnum.PERCENT && gratuityAmount.type === AmountTypeEnum.PRE_SET && cart.tipsInfo?.type === OrderGratuityTypeEnum.FIX)
      ) {
        return;
      }
      updateCartWithGratuity(nextGratuityType, gratuityAmount.value || 0, gratuityAmount.type);
    }, [cart.tipsInfo?.amount, cart.tipsInfo?.type, gratuityAmount, gratuityType, updateCartWithGratuity, getNextGratuityType]);

    if (cart?.flow === OrderFlowEnum.DIGITAL) {
      return null;
    }

    return (
      <Box testID="tip-block" ref={forwardedRef} mb={4} {...boxProps}>
        <Text fontSize="xs" textTransform="uppercase" mb={2} fontWeight="500">
          {title || 'Tip'}
        </Text>
        <Box flexDirection="row" flexWrap="wrap" mr="-s">
          {presetList.map((preSetGratuity) => (
            <TipsOption
              key={`gratuity-${gratuityType}-${preSetGratuity}`}
              gratuityType={gratuityType}
              preSetGratuity={preSetGratuity}
              gratuityAmount={gratuityAmount}
              selected={!isCustomAmount && gratuityAmount.value === preSetGratuity}
              onSelect={selectPreSetAmount}
              isDisabled={isDisabled}
            />
          ))}
          <TipsOption
            gratuityType={gratuityType}
            selected={!isCustomAmount && gratuityAmount.value === 0}
            onSelect={selectNonPreSetAmount(AmountTypeEnum.NO_TIP)}
            isDisabled={isDisabled}
            preSetGratuity={0}
            gratuityAmount={gratuityAmount}
            customLabel="No tip"
          />
          <TipsOption
            gratuityType={gratuityType}
            selected={isCustomAmount}
            onSelect={selectNonPreSetAmount(AmountTypeEnum.CUSTOM)}
            isDisabled={isDisabled}
            preSetGratuity={0}
            gratuityAmount={gratuityAmount}
            customLabel="Custom"
          />
          {isCustomAmount && <CustomAmount amount={gratuityAmount.value ?? 0} onChange={onChangeCustomAmount} />}
        </Box>
        {showSelectGratuityNotification && (
          <Box
            padding={4}
            borderRadius="xs"
            borderColor="errorBorderColor"
            borderStyle="solid"
            borderWidth={1}
            marginBottom={4}
            flexDirection="row"
            alignItems="center"
            backgroundColor="errorBg"
          >
            <CircledExclamation fill="errorIconFill" />
            <Text marginLeft={3} fontSize="m">
              {notSelectedError || 'Please select tip amount'}
            </Text>
          </Box>
        )}
      </Box>
    );
  },
);
