import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

import { usePromoPopupContext } from '../PromoPopupProvider';

export const Title = () => {
  const { isMobile } = useWindowDimensions();
  const { promoPopup } = useComponentsConfig();
  const { promotion } = usePromoPopupContext();

  let customTitle = isMobile ? promoPopup?.wrapperMobile?.options?.title : promoPopup?.wrapper?.options?.title;
  customTitle = customTitle?.replace('{title}', promotion?.title ?? '');

  return (
    <Text
      accessibilityLiveRegion="assertive"
      fontSize="m"
      lineHeight={20}
      fontWeight="700"
      {...promoPopup?.wrapper?.title?.text?.style}
      {...(isMobile ? promoPopup?.wrapperMobile?.title?.text?.style : {})}
    >
      {customTitle ?? `You have unlocked the promotion ${promotion?.title}`}
    </Text>
  );
};
