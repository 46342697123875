import { StoreItemValidityEnum } from '@goparrot/common';
import type { IOrderElement } from '@goparrot/order-sdk';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

const warningMessage: Partial<Record<StoreItemValidityEnum, string>> = {
  [StoreItemValidityEnum.SUBSIDED_MIN_QUANTITY]: 'Item quantity subsides minimum allowed quantity.',
  [StoreItemValidityEnum.EXCEEDED_MAX_QUANTITY]: 'Item quantity exceeds maximum allowed quantity.',
  [StoreItemValidityEnum.MISSING_ITEM]: 'This item is unavailable. Please remove it from the cart.',
  [StoreItemValidityEnum.MISSING_SUB_ITEM]: 'Some of the item modifications are unavailable.',
};

export const ItemValidationWarning: React.FC<{ item: IOrderElement }> = ({ item }) => {
  const message = warningMessage[item.validity];

  return message ? (
    <Text lineHeight={22} color="danger">
      {message}
    </Text>
  ) : null;
};
