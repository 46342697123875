import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

export const ErrorFallback: React.FC = ({ error }: any) => {
  const { intl } = usePlatformStoreState();

  console.log(error);
  return (
    <Box margin={4} alignContent="center" justifyContent="center">
      <Text>{intl.getIntlString('general.error')}</Text>
      <p>{error.message}</p>
    </Box>
  );
};
