import { useTheme } from '@shopify/restyle';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import React from 'react';
import { mergeStyles } from 'react-select';
import type { Props } from 'react-select/async';
import AsyncSelectDefault from 'react-select/async';

import { getControlStyles, getDropdownIndicatorStyles, getIndicatorSeparatorStyles, getPlaceholderStyles, getValueContainerStyles } from './constants';
import type { SelectPropsAsync } from './interfaces';

export const AsyncSelect = <T extends Props<any>>({ ...props }: SelectPropsAsync<T>) => {
  const theme = useTheme<Theme>();
  const { isMobile } = useWindowDimensions();
  return (
    <AsyncSelectDefault
      styles={mergeStyles(
        {
          control: (base) => ({
            ...base,
            ...getControlStyles(theme, props.hasError),
          }),
          indicatorSeparator: (base) => ({
            ...base,
            ...getIndicatorSeparatorStyles(),
          }),
          valueContainer: (base) => ({
            ...base,
            ...getValueContainerStyles(theme),
          }),
          placeholder: (base) => ({
            ...base,
            ...getPlaceholderStyles(theme, isMobile, props.hasError),
          }),
          dropdownIndicator: (base) => ({
            ...base,
            ...getDropdownIndicatorStyles(theme, props.hasError),
          }),
        },
        props.styles ?? {},
      )}
      {...props}
    />
  );
};
