import type { PropsWithChildren } from 'react';
import React, { useState } from 'react';

import { ChevronDown } from '../icons/ChevronDown';
import { ChevronUp } from '../icons/ChevronUp';
import * as s from './Collapse.styled';

interface Props {
  title: string | React.ReactNode;
  defaultOpened?: boolean;
}

const Collapse: React.FC<PropsWithChildren<Props>> = ({ title, defaultOpened, children }) => {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const toggleCollapse = () => setIsOpen((prev) => !prev);

  return (
    <s.CollapsableWrapper>
      <s.Details key={'' + isOpen} open={isOpen} role="group">
        <s.Summary role="button" aria-expanded={isOpen} tabIndex={0} onClick={toggleCollapse}>
          <s.SummaryWrap>
            <div>{title}</div>
            <div>{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
          </s.SummaryWrap>
        </s.Summary>
        {children}
      </s.Details>
    </s.CollapsableWrapper>
  );
};

export default Collapse;
