import { OrderGratuityTypeEnum, SupportedCurrencyEnum } from '@goparrot/common';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import type { FC } from 'react';
import React from 'react';
import { FormattedNumber } from 'react-intl';

import type { State } from '../types';

interface TipsOptionProps {
  gratuityType: OrderGratuityTypeEnum;
  preSetGratuity: number;
  gratuityAmount: State;
  selected?: boolean;
  onSelect: (amount: number) => void;
  isDisabled?: boolean;
  customLabel?: string;
}

export const TipsOption: FC<TipsOptionProps> = ({ gratuityType, preSetGratuity, onSelect, selected, isDisabled, customLabel }) => {
  const handlePress = () => {
    onSelect(preSetGratuity);
  };
  return (
    <Box mr={3} mb={4} testID="tip-option">
      <Button
        borderRadius="xs"
        colorScheme="primary"
        variant="stroked"
        buttonStyle={{
          borderColor: selected ? 'primaryButtonBorderColor' : 'transparent',
          backgroundColor: 'gray100',
          minWidth: 40,
          fontSize: 'm',
          paddingTop: 1,
          paddingBottom: 1,
          paddingLeft: 2,
          paddingRight: 2,
          fontWeight: '600',
          borderWidth: 1,
          borderStyle: 'solid',
          color: 'gray900',
        }}
        borderStyle="solid"
        alignContent="center"
        onPress={handlePress}
        isDisabled={isDisabled}
      >
        {customLabel ||
          (gratuityType === OrderGratuityTypeEnum.PERCENT ? (
            `${preSetGratuity}%`
          ) : (
            <FormattedNumber
              value={preSetGratuity}
              style="currency"
              currencyDisplay="symbol"
              currency={SupportedCurrencyEnum.USD}
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          ))}
      </Button>
    </Box>
  );
};
