import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { ReactNativePostMessagesEnum } from '@webstore-monorepo/shared/enums';
import { ChevronThin } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { Pressable } from 'react-native';

interface HeaderMobileApp {
  storeName: string;
  storeAddress: string;
}

export const HeaderMobileApp: React.FC<PropsWithChildren<HeaderMobileApp>> = ({ children, storeAddress, storeName }) => {
  const { header } = useComponentsConfig();

  const onBackClickHandler = () => {
    if (window.ReactNativeHideBackToLocations) {
      window.ReactNativeWebView?.postMessage(ReactNativePostMessagesEnum.BACK_TO_HOME);
    } else {
      window.ReactNativeWebView?.postMessage(ReactNativePostMessagesEnum.BACK_TO_LOCATIONS);
    }
  };

  return (
    <Box flexDirection="row" alignItems="center" flexGrow={1} flexShrink={1}>
      <Pressable onPress={onBackClickHandler} testID="back-button">
        <Box p={4}>
          <ChevronThin fill="transparent" stroke={header?.text?.style?.color} />
        </Box>
      </Pressable>
      <Box flexDirection="column" flexShrink={1} flexGrow={1} py={1}>
        <Text {...header?.text?.style} fontSize="m">
          {storeName}
        </Text>
        <Text {...header?.text?.style} textTransform="capitalize" fontSize="xs" fontWeight="400">
          {storeAddress}
        </Text>
      </Box>
      {children}
    </Box>
  );
};
