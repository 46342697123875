import { useEffect, useState } from 'react';

export const useGetImageDimensions = (imageUrl?: string): { height: number; width: number; aspectRatio: number } => {
  const [imageDimensions, setImageDimensions] = useState({ height: 0, width: 0, aspectRatio: 0 });

  useEffect(() => {
    let isMounted = true;
    if (imageUrl) {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        if (isMounted) {
          setImageDimensions({
            height: img.naturalHeight,
            width: img.naturalWidth,
            aspectRatio: img.naturalHeight / img.naturalWidth,
          });
        }
      };
      img.onerror = (err) => {
        console.log('img error');
        console.error(err);
      };
    }
    return () => {
      isMounted = false;
    };
  }, [imageUrl]);

  return imageDimensions;
};

export const getImageDimensions = async (src: string): Promise<{ height: number; width: number; aspectRatio: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        height: img.naturalHeight,
        width: img.naturalWidth,
        aspectRatio: img.naturalHeight / img.naturalWidth,
      });
    img.onerror = (err) => {
      console.log('img error');
      console.error(err);
    };
    img.src = src;
  });
};

export const useGetSmallestAspectRatioInImageUrls = (items: string[]): { width: number; height: number; aspectRatio: number } => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0, aspectRatio: 0 });

  useEffect(() => {
    if (items.length) {
      items.forEach((item) => {
        if (item) {
          const img = new Image();
          img.src = item;

          img.onload = () => {
            const aspectRatio = img.height / img.width;
            setDimensions((prev) =>
              prev.aspectRatio === 0 || aspectRatio < prev.aspectRatio
                ? {
                    height: img.height,
                    width: img.width,
                    aspectRatio,
                  }
                : prev,
            );
          };
          img.onerror = (err) => {
            console.log('img error');
            console.error(err);
          };
        }
      });
    }
  }, [items]);

  return dimensions;
};
