import { ReadTemporaryUserDto } from '@goparrot/customer-sdk';
import { useHasLoyaltyQuery } from '@webstore-monorepo/shared/api/loyalty-api';
import type { GetApplicablePromotionsQueryResult } from '@webstore-monorepo/shared/api/promotions-api';
import { useGetApplicablePromotions } from '@webstore-monorepo/shared/api/promotions-api';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import type { FC } from 'react';
import { useEffect } from 'react';

import { useAppContextDispatch } from '../../contexts/app-context-provider';
import type { AppState } from '../../interfaces/AppState';
import { useHasMobileApps } from '../../queries/useGlobal';

export const PrefetchData: FC = () => {
  const appDispatch = useAppContextDispatch();
  const { user } = useUserContextState();
  const merchant = useMerchantState();
  const isAuthenticated = user?.type === 'AUTHENTICATED';
  const { data: merchantAppList } = useHasMobileApps();
  const { data: loyaltyBase } = useHasLoyaltyQuery(window.storeId, {
    enabled: isAuthenticated && merchant?.featuresFlags?.showLoyaltyManagement,
  });
  useGetApplicablePromotions({
    onSuccess: (data: GetApplicablePromotionsQueryResult) => appDispatch({ type: 'update', payload: data }),
  });

  useEffect(() => {
    const handleFetch = () => {
      if (!(user instanceof ReadTemporaryUserDto) && user?.merchantUserId && user?.merchantId) {
        const result: AppState = {} as AppState;
        if (loyaltyBase) {
          const { loyaltyProgram: { programUuid, terminology } = {}, availablePoints } = loyaltyBase;
          result.loyaltyBase = programUuid ? { pointsAvailable: availablePoints } : undefined;
          result.loyaltyPointName = terminology;
        }
        if (merchantAppList) {
          result.merchantHasApp = merchantAppList?.length > 0;
        }
        appDispatch({ type: 'update', payload: { ...result } });
      }
    };
    handleFetch();
  }, [appDispatch, loyaltyBase, merchantAppList, user]);

  return null;
};
