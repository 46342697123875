import type { IShapeStylesApp } from '@goparrot/webstore-sdk';
import { ArrowLeft } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import type { PropsWithChildren } from 'react';
import React from 'react';

export type UiSidebarFooterProps = {
  onBack?: () => void;
  onCheckout?: () => void;
  wrapperStyle?: IShapeStylesApp;
  backButtonStyle?: IShapeStylesApp;
  proceedToCheckoutStyle?: IShapeStylesApp;
  isLoading?: boolean;
  isDisabled?: boolean;
  showProceed?: boolean;
  proceedButtonText?: string | React.ReactNode;
  customElement?: string | React.ReactNode;
};

export const UiSidebarFooter = ({
  onBack,
  onCheckout,
  isDisabled,
  isLoading,
  showProceed = true,
  proceedButtonText,
  backButtonStyle,
  proceedToCheckoutStyle,
  wrapperStyle,
  children,
  customElement,
}: PropsWithChildren<UiSidebarFooterProps>) => {
  const handleBack = () => {
    if (onBack) {
      onBack();
    }
  };

  const handleCheckout = () => {
    if (onCheckout) {
      onCheckout();
    }
  };

  return (
    <Box flexDirection="column" shadowOffset={{ height: -1, width: 0 }} shadowOpacity={0.1} shadowColor="black" testID="ui-sidebard-footer">
      <Box>{children}</Box>
      <Box
        minHeight={80}
        p={4}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        //@ts-expect-error allow string value for paddingBottom
        style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) * 0.6 + 16px)' }}
        {...wrapperStyle}
      >
        <Button
          testID="ui-back-btn"
          fontSize="m"
          borderRadius="xs"
          onPress={handleBack}
          size="custom"
          buttonStyle={{
            width: 44,
            height: 44,
            justifyContent: 'center',
            backgroundColor: 'gray50',
          }}
          leftIcon={<ArrowLeft stroke="black" />}
          {...backButtonStyle}
        />
        <Box flexDirection="row" alignItems="stretch" justifyContent="space-between">
          {customElement ? customElement : null}
          {showProceed ? (
            <Button
              testID="ui-proceed-btn"
              aria-disabled={isDisabled}
              onPress={handleCheckout}
              isLoading={isLoading}
              isDisabled={isDisabled}
              {...proceedToCheckoutStyle}
            >
              {proceedButtonText ?? 'Proceed to checkout'}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
