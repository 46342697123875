import type { BoxProps } from '@shopify/restyle';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { onKeyEnterAndSpace } from '@webstore-monorepo/shared/utils/forms';
import type { FC, PropsWithChildren } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { AccessibilityNavHelperProps } from './interfaces';

export const UiAccessibilityNavigationHelper: FC<PropsWithChildren<BoxProps<Theme> & AccessibilityNavHelperProps>> = ({
  onEnter,
  accessibilityLabel,
  accessibilityHint,
  accessibilityRole = 'button',
  children,
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState({
    backgroundColor: '#fff',
    position: 'absolute',
    top: '50px',
    left: '0',
    opacity: 0,
    padding: '8px',
    transition: 'opacity 0.3s',
  });

  const handleBlur = useCallback(() => {
    setStyles((prev) => ({ ...prev, opacity: 0, zIndex: '-1' }));
  }, []);

  const handleFocus = useCallback(() => {
    setStyles((prev) => ({ ...prev, opacity: 1, zIndex: 100 }));
  }, []);

  const handleKeyUp = useCallback((event: any) => {
    if (onEnter) {
      onKeyEnterAndSpace(event, onEnter);
    }
  }, []);

  useEffect(() => {
    boxRef.current?.addEventListener('focus', handleFocus);
    boxRef.current?.addEventListener('blur', handleBlur);
    boxRef.current?.addEventListener('keyup', handleKeyUp);

    return () => {
      boxRef.current?.removeEventListener('focus', handleFocus);
      boxRef.current?.removeEventListener('blur', handleBlur);
      boxRef.current?.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleBlur, handleKeyUp, handleFocus]);

  return (
    // @ts-ignore
    <div aria-label={accessibilityLabel} aria-describedby={accessibilityHint} role={accessibilityRole} tabIndex={0} ref={boxRef} style={styles}>
      {children}
    </div>
  );
};
