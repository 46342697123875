import { StoreItemValidityEnum } from '@goparrot/common';
import type { IOrder, IOrderElement, IOrderPromotionDiscount, IOrderPromotionElement } from '@goparrot/order-sdk';
import type { IPromotionWithApplicationState, IReadPromotionDiscountDto, IReadPromotionDto, ReadPromotionTargetDto } from '@goparrot/promotions-sdk';
import { PromotionDiscountBehaviourEnum, PromotionDiscountLevelEnum, PromotionSubTypeEnum, PromotionTargetTypeEnum } from '@goparrot/promotions-sdk';
import difference from 'lodash/difference';

export const getApplicablePromotions = (promotions: IPromotionWithApplicationState[]): IPromotionWithApplicationState[] =>
  promotions.filter((promo) => promo.isApplicable);

export const isPromoCodeOrCheckBasedDiscount = (promo: IOrderPromotionElement): boolean => {
  return PromotionDiscountLevelEnum.CHECK_BASED === promo.discount.level || PromotionSubTypeEnum.PROMOCODE === promo.subtype;
};

export const isBogo = (promotion?: IOrderPromotionElement): boolean => promotion?.discount?.level === PromotionDiscountLevelEnum.BOGO;

export const isPromoCode = (promotion?: IOrderPromotionElement): boolean => promotion?.subtype === PromotionSubTypeEnum.PROMOCODE;

export const isLoyalty = (promotion?: IOrderPromotionElement): boolean => promotion?.subtype === PromotionSubTypeEnum.LOYALTY;

export const hasCheckBasedDiscounts = (promotions: IOrderPromotionElement[]): boolean => {
  return promotions.some(isPromoCodeOrCheckBasedDiscount);
};

export const getPromoTargets = (targets: ReadPromotionTargetDto[] = [], storeId?: string): ReadPromotionTargetDto[] => {
  const storeSpecificTargets = targets.filter((t) => t.storeId === storeId);

  return storeSpecificTargets.length ? storeSpecificTargets : targets;
};

export const applyPromoCodeValidation = (newCart: IOrder, existingCart: IOrder): string | undefined => {
  if (newCart.validity === StoreItemValidityEnum.VALID) return;

  const [promo] = difference(newCart.promotions, existingCart.promotions);
  const item = newCart.elements.find((el) => el.tag?.promotionUuid === promo.uuid);

  if (StoreItemValidityEnum.SUBSIDED_MIN_QUANTITY === item?.validity) {
    const itemConfigMinQuantity = Math.max(item.metadata?.minQuantity || 0, 1);

    return `We’re sorry. Promotion can’t be added. Min ${itemConfigMinQuantity} items of <strong>${item.title}</strong> are allowed.`;
  }
  if (StoreItemValidityEnum.EXCEEDED_MAX_QUANTITY === item?.validity) {
    return "We're sorry. Promotion can't be added. You already have the maximum quantity of this item in your cart. Please adjust the quantity and try again.";
  }
  return;
};

export const hasAddItemBehaviour = (discount: IOrderPromotionDiscount | IReadPromotionDiscountDto) =>
  PromotionDiscountBehaviourEnum.ADD_ITEM === (discount as IReadPromotionDiscountDto).behaviour ||
  PromotionDiscountBehaviourEnum.ADD_ITEM === (discount as IOrderPromotionDiscount).behavior;

export const isPromotionRemovable = (promo: IOrderPromotionElement | IReadPromotionDto): boolean =>
  // @ts-ignore add usage of promotion-sdk to core-sdk/order
  PromotionSubTypeEnum.PROMOTION !== promo.subtype ||
  ([PromotionDiscountLevelEnum.ITEM_BASED, PromotionDiscountLevelEnum.BOGO].includes(promo.discount.level) && hasAddItemBehaviour(promo.discount));

export const findPromoTarget = (targets: ReadPromotionTargetDto[], item?: IOrderElement) =>
  targets.find(
    (target) =>
      (target.type === PromotionTargetTypeEnum.ITEM && target.uuid === item?.uniqueName) ||
      (target.type === PromotionTargetTypeEnum.CATEGORY && item?.metadata.categories.includes(target.uuid)),
  );
