import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import type { ICategory } from '@webstore-monorepo/shared/interfaces';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef } from 'react';
import { Image, Pressable } from 'react-native';

export type CategoryMenuNavBarItemProps = {
  item: ICategory;
  selected: boolean;
  showIcon?: boolean;
  isTopItem?: boolean;
  onItemClick: (item: ICategory) => void;
};
export const CategoryMenuNavBarItem = forwardRef(({ item, isTopItem, selected, onItemClick, showIcon }: CategoryMenuNavBarItemProps, ref) => {
  const { categoriesNavBar } = useComponentsConfig();
  const { isHovered: itemHovered } = useHover({}, ref);
  const { title, uniqueName, metadata } = item;
  const { icon, selectedIcon } = isTopItem ? categoriesNavBar?.wrapper?.options?.topItemCategory || {} : metadata || {};
  const hasIcon = !!icon;
  const hasSelectedIcon = !!selectedIcon;

  const renderIcon = () => {
    const selectedIconBlock = (
      <Image
        accessibilityIgnoresInvertColors
        accessibilityLabel="category selected icon"
        accessibilityHint="category item icon"
        accessibilityRole="none"
        source={{ uri: selectedIcon }}
        style={{ width: 46, height: 46 }}
      />
    );
    const unselectedIconBlock = (
      <Image
        accessibilityIgnoresInvertColors
        accessibilityLabel="category not-selected icon"
        accessibilityHint="category item icon"
        source={{ uri: icon }}
        style={{ width: 46, height: 46 }}
      />
    );
    const imageWrapperStyles: BoxProps<Theme> = {
      maxWidth: 46,
      width: 46,
      height: 'auto',
      maxHeight: 46,
      borderRadius: 'rounded',
      overflow: 'hidden',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 2,
      ...categoriesNavBar?.wrapper?.item?.iconBlock?.style,
      ...(selected ? categoriesNavBar?.wrapper?.selectedItem?.iconBlock?.style : {}),
      ...(itemHovered ? categoriesNavBar?.wrapper?.itemHover?.iconBlock?.style : {}),
    };

    if (selected && hasSelectedIcon) {
      return (
        <Box testID="selected-icon" {...imageWrapperStyles}>
          {selectedIconBlock}
        </Box>
      );
    }

    if (!selected && hasIcon) {
      return (
        <Box testID="unselected-icon" {...imageWrapperStyles}>
          {unselectedIconBlock}
        </Box>
      );
    }

    return (
      <Box
        testID="title-icon-block"
        {...imageWrapperStyles}
        overflow="hidden"
        borderRadius="rounded"
        borderWidth={1}
        height={46}
        justifyContent="center"
        alignItems="center"
        borderColor="black"
        {...categoriesNavBar?.wrapper?.item?.iconBlock?.style}
        {...(selected ? categoriesNavBar?.wrapper?.selectedItem?.iconBlock?.style : {})}
        {...(itemHovered ? categoriesNavBar?.wrapper?.itemHover?.iconBlock?.style : {})}
      >
        <Text
          textTransform="uppercase"
          fontSize="l"
          lineHeight={47}
          {...categoriesNavBar?.wrapper?.item?.iconBlock?.text?.style}
          {...(selected ? categoriesNavBar?.wrapper?.selectedItem?.iconBlock?.text?.style : {})}
          {...(itemHovered ? categoriesNavBar?.wrapper?.itemHover?.iconBlock?.text?.style : {})}
        >
          {title.charAt(0)}
        </Text>
      </Box>
    );
  };

  const handleClick = () => {
    if (!selected) {
      onItemClick(item);
    }
  };

  const textStyles: Partial<TextPropsExtended> = {
    fontWeight: '500',
    fontSize: 'l',
    color: 'black',
    textAlign: 'center',
    ...categoriesNavBar?.wrapper?.item?.text?.style,
    ...(selected ? categoriesNavBar?.wrapper?.selectedItem?.text?.style : {}),
    ...(itemHovered ? categoriesNavBar?.wrapper?.itemHover?.text?.style : {}),
  };

  return (
    <Pressable
      nativeID={`navbar-category-item-${uniqueName}`}
      accessibilityLabel={`category ${title}`}
      accessibilityHint={`press on the category ${title} to scroll to it`}
      accessibilityRole="button"
      onPress={handleClick}
    >
      <Box
        ref={ref}
        testID={`category-item-${uniqueName}`}
        marginLeft={3}
        marginRight={3}
        paddingTop={4}
        paddingBottom={4}
        {...categoriesNavBar?.wrapper?.item?.style}
        {...(selected ? categoriesNavBar?.wrapper?.selectedItem?.style : {})}
        {...(itemHovered ? categoriesNavBar?.wrapper?.itemHover?.style : {})}
      >
        {showIcon ? (
          <>
            <Box justifyContent="center" alignItems="center">
              {renderIcon()}
            </Box>
            <Text accessibilityRole="text" {...textStyles}>
              {title}
            </Text>
          </>
        ) : (
          <Text accessibilityRole="text" {...textStyles}>
            {title}
          </Text>
        )}
      </Box>
      {selected ? (
        <Box
          testID="selected-underline"
          flexDirection="row"
          width="100%"
          height={3}
          opacity={1}
          position="absolute"
          bottom={0}
          backgroundColor="black"
          {...(selected ? categoriesNavBar?.wrapper?.selectedItem?.underlineBlock?.style : {})}
          {...(itemHovered ? categoriesNavBar?.wrapper?.itemHover?.underlineBlock?.style : {})}
        />
      ) : null}
    </Pressable>
  );
});
