export const sliceByWord = (string?: string, length?: number) => {
  if (!string) return;
  if (!length) return string;

  let result;

  if (string.length < length) {
    result = string;
  } else {
    const lastSpaceIndex = string.lastIndexOf(' ', length);

    result = string
      .substr(0, ~lastSpaceIndex ? lastSpaceIndex : length)
      .trim()
      .replace(/,$/, '');

    if (string.length > length) result += '...';
  }

  return result;
};
