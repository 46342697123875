import { Box } from '@webstore-monorepo/ui/box';
import type { FC, PropsWithChildren } from 'react';
import React from 'react';

import type { ContentProps } from './components/content';
import { Content } from './components/content';
import type { ExpressCheckoutProps } from './components/express-checkout';
import { ExpressCheckout } from './components/express-checkout';
import type { FooterProps } from './components/footer';
import { Footer } from './components/footer';
import type { GiftCardsType } from './components/gift-cards';
import { GiftCards } from './components/gift-cards';
import type { GuestCheckoutFormBlockType } from './components/guest-checkout-form-block';
import { GuestCheckoutFormBlock } from './components/guest-checkout-form-block';
import type { HeaderProps } from './components/header';
import { Header } from './components/header';
import type { PackingInstructionsProps } from './components/packing-instructions';
import { PackingInstructions } from './components/packing-instructions';
import type { PaymentBlockType } from './components/payment-block';
import { PaymentBlock } from './components/payment-block';
import type { RedeemRewardsBlockType } from './components/redeem-rewards-block';
import { RedeemRewardsBlock } from './components/redeem-rewards-block';
import { FeatureFlagsProvider } from './contexts/feature-flags-provider';
import { GiftCardProvider } from './contexts/gift-card-provider';
import { OrderProvider } from './contexts/order-provider';
import { PaymentProvider } from './contexts/PaymentProvider';
import { SquareProvider } from './contexts/SquareProvider';

type PaymentCompositionType = {
  Header: React.FC<HeaderProps>;
  Content: React.FC<ContentProps>;
  ExpressCheckout: React.FC<ExpressCheckoutProps>;
  PaymentBlock: React.FC<PaymentBlockType>;
  GuestCheckoutFormBlock: React.FC<GuestCheckoutFormBlockType>;
  GiftCards: React.FC<GiftCardsType>;
  RedeemRewardsBlock: React.FC<RedeemRewardsBlockType>;
  PackingInstructions: React.FC<PackingInstructionsProps>;
  Footer: React.FC<PropsWithChildren<FooterProps>>;
};

type PaymentType = {
  orderId: string;
  onPostCharge: () => void;
  onPaymentUnauthorized?: () => void;
};

export const Payment: PaymentCompositionType & FC<PropsWithChildren<PaymentType>> = ({ orderId, children, onPostCharge, onPaymentUnauthorized }) => {
  return (
    <PaymentProvider orderId={orderId} onPostCharge={onPostCharge} onPaymentUnauthorized={onPaymentUnauthorized}>
      <FeatureFlagsProvider>
        <SquareProvider>
          <GiftCardProvider>
            <OrderProvider>
              <Box testID="payment-screen" height="100%" backgroundColor="white">
                {children}
              </Box>
            </OrderProvider>
          </GiftCardProvider>
        </SquareProvider>
      </FeatureFlagsProvider>
    </PaymentProvider>
  );
};

Payment.ExpressCheckout = ExpressCheckout;
Payment.Header = Header;
Payment.Content = Content;
Payment.PaymentBlock = PaymentBlock;
Payment.GuestCheckoutFormBlock = GuestCheckoutFormBlock;
Payment.PackingInstructions = PackingInstructions;
Payment.GiftCards = GiftCards;
Payment.RedeemRewardsBlock = RedeemRewardsBlock;
Payment.Footer = Footer;
