import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import { TextGradient } from '@webstore-monorepo/ui/text-gradient';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../MenuItemCardProvider';

export const MenuItemTitle = forwardRef((props: BoxProps<Theme> & { textProps?: TextPropsExtended }, forwardedRef) => {
  const { title, isHovered } = useMenuItemCardState();
  const { isMobile } = useWindowDimensions();
  const { menuItemCard, menuItemCardMobile } = useComponentsConfig();
  const { textProps, ...boxProps } = props;
  const menuItemCardByDevice = isMobile ? menuItemCardMobile : menuItemCard;

  return title ? (
    <Box
      ref={forwardedRef}
      overflow="hidden"
      margin="none"
      testID="menu-item-title"
      {...boxProps}
      {...menuItemCard?.title?.style}
      {...(isHovered ? menuItemCard?.titleHover?.style : {})}
    >
      {menuItemCardByDevice?.wrapper?.options?.showTextGradient ? (
        <TextGradient
          fontWeight="bold"
          fontSize={isMobile ? 'l' : 'xxl'}
          numberOfLines={2}
          colors={menuItemCardByDevice?.title?.textGradient?.options?.colors}
          start={menuItemCardByDevice?.title?.textGradient?.options?.start}
          end={menuItemCardByDevice?.title?.textGradient?.options?.end}
          {...textProps}
          {...menuItemCard?.title?.text?.style}
          {...menuItemCard?.title?.textGradient?.style}
          {...(isMobile ? menuItemCardMobile?.title?.text?.style : {})}
          {...(isMobile ? menuItemCardMobile?.title?.textGradient?.style : {})}
          {...(isHovered ? menuItemCard?.titleHover?.text?.style : {})}
          {...(isHovered ? menuItemCard?.titleHover?.textGradient?.style : {})}
        >
          {title}
        </TextGradient>
      ) : (
        <Text
          fontWeight="bold"
          fontSize={isMobile ? 'l' : 'xxl'}
          numberOfLines={2}
          {...textProps}
          {...menuItemCard?.title?.text?.style}
          {...(isMobile ? menuItemCardMobile?.title?.text?.style : {})}
          {...(isHovered ? menuItemCard?.titleHover?.text?.style : {})}
        >
          {title}
        </Text>
      )}
    </Box>
  ) : null;
});
