import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { LazyImage } from '@webstore-monorepo/ui/lazy-image';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef, useRef } from 'react';
import type { ImageResizeMode } from 'react-native';

import { useMenuItemCardState } from '../../MenuItemCardProvider';

export type MenuItemThumbnailProps = { parentWidth: number; overwriteHeight?: number; resizeMode?: ImageResizeMode; boxProps?: BoxProps<Theme> };

export const MenuItemThumbnail = forwardRef(({ resizeMode, parentWidth, overwriteHeight, boxProps }: MenuItemThumbnailProps, forwardedRef) => {
  const { showImage, isHovered, imageUrl, hoverImageUrl, imageText, title } = useMenuItemCardState();
  const { menuItemCard, menuItemCardMobile } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const isFull = isMobile
    ? menuItemCardMobile?.thumbnailBlockWrapper?.options?.fullArtwork ?? true
    : menuItemCard?.thumbnailBlockWrapper?.options?.fullArtwork ?? true;
  const minBlockHeight = useRef(0);

  return showImage ? (
    <Box ref={forwardedRef} overflow="hidden" width="100%" {...boxProps} padding={!isFull ? 4 : 'none'}>
      <Box
        overflow="hidden"
        position="relative"
        {...menuItemCard?.thumbnailBlockWrapper?.block?.style}
        {...(isMobile ? menuItemCardMobile?.thumbnailBlockWrapper?.block?.style : {})}
        minHeight={minBlockHeight.current}
      >
        <Box
          borderRadius={
            isMobile ? menuItemCardMobile?.thumbnailBlockWrapper?.block?.style?.borderRadius : menuItemCard?.thumbnailBlockWrapper?.block?.style?.borderRadius
          }
        >
          {hoverImageUrl || imageUrl ? (
            <LazyImage
              accessibilityLabel={title}
              accessibilityHint={title}
              src={!isHovered ? imageUrl : hoverImageUrl ? hoverImageUrl : imageUrl}
              resizeMode={
                resizeMode
                  ? resizeMode
                  : (isMobile
                      ? menuItemCardMobile?.thumbnailBlockWrapper?.block?.options?.resizeMode
                      : menuItemCard?.thumbnailBlockWrapper?.block?.options?.resizeMode) ?? 'contain'
              }
              width={parentWidth}
              height={overwriteHeight ?? 'auto'}
            />
          ) : null}
        </Box>
        {imageText ? (
          <Box position="absolute" top={0} left={0} width="100%" height="100%" flexDirection="row" alignItems="center" justifyContent="center">
            <Text color="gray200" fontSize="xxl">
              {imageText}
            </Text>
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : null;
});

MenuItemThumbnail.displayName = 'MenuItemThumbnail';
