import { OrderFlowEnum } from '@goparrot/common';
import type { IOrder } from '@goparrot/order-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useClearCartMutation } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { DeleteIcon, ForkKnifeIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Pressable } from 'react-native';

import { Subtitle } from '../components/Subtitle';

const optionsWrapper: BoxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  minHeight: 24,
  flexBasis: 'auto',
  flexGrow: 1,
  flexShrink: 1,
  paddingTop: 'none',
  paddingBottom: 1,
  paddingLeft: 'none',
  paddingRight: 'none',
};

export type OrderToTableProps = {
  onAfterRemove?: (data: { isOrderToTable: boolean }) => void;
  isReadOnly?: boolean;
  onChangeTable?: () => void;
};
export const OrderToTableSidebar: FC<OrderToTableProps> = ({ isReadOnly = false, onChangeTable, onAfterRemove }) => {
  const cart = useCartState();
  const { isMobile } = useWindowDimensions();
  const cartDispatch = useCartDispatch();
  const { tableNumber } = cart.diningOptionInfo.details || {};
  const [isRemoved, setRemoveState] = useState(false);
  const [isAlertMode, setAlertMode] = useState(false);

  const handlePressDelete = () => {
    setAlertMode(true);
  };
  const handleConfirmAlert = () => {
    onClearCart();
  };

  const handleCancelDelete = () => {
    setAlertMode(false);
  };

  const { mutate: onClearCart, isLoading } = useClearCartMutation({
    onSuccess: (cart: IOrder) => {
      setRemoveState(true);
      cartDispatch({ type: 'initiate', payload: cart });
      onAfterRemove && onAfterRemove({ isOrderToTable: false });
    },
  });

  if (cart?.flow === OrderFlowEnum.DIGITAL) {
    return null;
  }

  if (isRemoved) {
    return null;
  }

  return (
    <Box marginBottom={8} alignItems="flex-start" justifyContent="flex-start" testID="cart-order-to-table">
      <Box marginBottom={4}>
        <Text textTransform="uppercase" fontSize="xs">
          Order to table
        </Text>
      </Box>
      {isAlertMode ? (
        <Box width="100%" alignContent="center" justifyContent="center">
          <Text mb={4} textAlign="center">
            Are you sure? This action will clear the cart
          </Text>
          <Box flexDirection="row" alignItems="center" justifyContent="center">
            <Button
              size="sm"
              onPress={handleConfirmAlert}
              isLoading={isLoading}
              buttonStyle={{
                minHeight: 34,
                minWidth: 94,
              }}
            >
              Yes
            </Button>
            <Box marginLeft={2}>
              <Button
                size="sm"
                variant="stroked"
                onPress={handleCancelDelete}
                isDisabled={isLoading}
                buttonStyle={{
                  minHeight: 34,
                  minWidth: 94,
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box flexDirection="column" width="100%">
          <Box {...optionsWrapper} paddingBottom={isMobile ? 1 : 'none'}>
            <ForkKnifeIcon testID="order-setup-status-orderType-to-table-icon" fill="black" stroke="transparent" />
            <Box flexGrow={isMobile ? 1 : 0} flexShrink={1}>
              <Text paddingLeft={4} fontSize="m" paddingBottom={1} paddingRight={isMobile ? 'none' : 3}>
                Table Number:
              </Text>
              {isMobile ? <Subtitle isMobile={isMobile} onChangeTable={onChangeTable} isReadOnly={isReadOnly} /> : null}
            </Box>
            <Text fontSize="m">{tableNumber}</Text>
            {!isReadOnly ? (
              <Pressable
                accessibilityLabel="cancel order to table"
                accessibilityHint="press trash can button to cancel order to table option"
                accessibilityRole="button"
                testID="order-to-table-remove-icon"
                onPress={handlePressDelete}
              >
                <Box marginLeft={4}>
                  <DeleteIcon fill="gray" stroke="transparent" />
                </Box>
              </Pressable>
            ) : null}
          </Box>
          {isMobile ? null : <Subtitle isMobile={isMobile} onChangeTable={onChangeTable} isReadOnly={isReadOnly} />}
        </Box>
      )}
    </Box>
  );
};
