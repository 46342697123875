import { useCallback, useMemo } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../../routes/paths';

export type UseAppHistory = NavigateFunction & {
  replace: (route: string) => void;
  push: (route: string, state?: any) => void;
  onGoToParent: () => void;
};

export const useAppHistory = (): UseAppHistory => {
  const navigate = useNavigate();
  const location = useLocation();

  const handlePush = useCallback(
    (route: string, state?: any) => {
      const appPaths = Object.values(APP_PATHS);
      if (Object.values(appPaths).some((item) => route.includes(item))) {
        navigate(route, { state });
        return;
      }
      const parentRoute = appPaths.find((item) => location.pathname?.includes(item as string));
      if (!parentRoute) {
        navigate(APP_PATHS.home, { state });
        return;
      }

      navigate(`${parentRoute}${route}`, { state });
    },
    [history, location.pathname],
  );

  const replace = useCallback(
    (route: string) => {
      navigate(route, { replace: true });
    },
    [navigate],
  );

  const handleGoToParent = useCallback(() => {
    const { state } = location;
    const appPaths = Object.values(APP_PATHS);
    const parentRoute = appPaths.find((item) => location.pathname?.includes(item as string));
    navigate(parentRoute ? parentRoute : APP_PATHS.home, { state });
  }, [navigate, location.pathname, location.state]);

  // @ts-ignore
  return useMemo(
    () => ({
      navigate,
      replace,
      push: handlePush,
      onGoToParent: handleGoToParent,
    }),
    [handleGoToParent, handlePush, navigate],
  );
};
