import { UserTypeEnum } from '@goparrot/customer-sdk';
import { AppHeader } from '@webstore-monorepo/features/app-header';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useMerchantState } from '@webstore-monorepo/shared/contexts/merchant-provider';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { AbilityEnum } from '@webstore-monorepo/shared/enums';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { LocalStorage } from '@webstore-monorepo/shared/utils/local-storage';
import { Box } from '@webstore-monorepo/ui/box';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Key } from 'ts-keycode-enum';

import { handleOpenLinkInRN } from '../../gp-react-native';
import { MENU_PATHS } from '../../pages/paths';
import { getPromoIntlString } from '../../pages/promos/utils';
import { Analytics } from '../../shared/constants/analytics';
import { useAppContextDispatch, useAppContextState } from '../../shared/contexts/app-context-provider';
import { useAnalytics } from '../../shared/hooks/use-analytics';
import { useAppHistory } from '../../shared/hooks/use-app-history';
import { HeaderMobileApp } from './components';

export const Header: React.FC = () => {
  const appStateDispatch = useAppContextDispatch();
  const store = useStoreState();
  const location = useLocation();
  const { featuresFlags } = useMerchantState();
  const currentState = location.pathname;
  const appContext = useAppContextState();
  const { user } = useUserContextState();
  const history = useAppHistory();
  const { header } = useComponentsConfig();
  const showPromos = store.featuresFlags?.showDealsPage && !!appContext.promotions?.length;
  const showGiftCards = !featuresFlags?.usesThirdPartyGiftCardProvider && !!appContext.storePermissions?.includes(AbilityEnum.BUY_GIFTCARDS_PAGE);
  const analytics = useAnalytics();
  const { isDesktop, width } = useWindowDimensions();
  const localStorage = new LocalStorage();

  const handleLoginClick = () => {
    analytics.track(Analytics.account_login_click);
    history.push(MENU_PATHS.login);
  };

  const handleOpenProfile = () => {
    analytics.track(Analytics.profile_opened);
    history.push(MENU_PATHS.profile);
  };

  const handleLoginPress = (event: any) => {
    const keyCode = event.which || event.keyCode;
    if (Key.Enter === keyCode) {
      analytics.track(Analytics.account_login_click);
      history.push(MENU_PATHS.login);
    }
  };

  const handleLoyaltyHistoryClick = () => {
    analytics.track(Analytics.account_my_loyalty_click);
    localStorage.set('loyaltyFrom', 'header');
    history.push(MENU_PATHS.loyalty_info);
  };

  const handlePromosClick = () => {
    history.push(MENU_PATHS.promos);
  };

  const handleBuyGiftCardsClick = () => {
    history.push(MENU_PATHS.buy_gift_card);
  };

  const handleCartClick = () => {
    analytics.track(Analytics.cart_opened, {});
    history.push(MENU_PATHS.cart);
  };

  const getWrapperWidth = () => {
    if (isDesktop && width > 1400) {
      return 1400;
    }
    return '100%';
  };

  useEffect(() => {
    const container = document.getElementById('placeholder-header');
    if (container) {
      container?.parentNode?.removeChild(container);
    }
  }, []);

  const handleAppHeaderRefChange = useCallback(
    (node: HTMLElement) => {
      if (node !== null) {
        appStateDispatch({ type: 'update', payload: { headerElementHeight: node.getBoundingClientRect().height } });
      }
    },
    [appStateDispatch],
  );

  return (
    <header id="main-header" style={{ position: 'fixed', top: 0, width: '100%', zIndex: 98 }}>
      <AppHeader ref={handleAppHeaderRefChange}>
        <AppHeader.TopNavBar onItemClick={handleOpenLinkInRN} />
        <AppHeader.InfoBar />
        <Box
          minHeight={window.ReactNativeWebView ? 60 : header?.headerWrapper?.style?.height}
          width={getWrapperWidth()}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            flexDirection="row"
            paddingLeft={window.ReactNativeWebView ? 'none' : 5}
            paddingRight={window.ReactNativeWebView ? 4 : 5}
            flexGrow={1}
            width="100%"
          >
            {window.ReactNativeWebView ? (
              <HeaderMobileApp storeAddress={store.location.formattedAddress} storeName={store.title}>
                <AppHeader.CartButton onCartClick={handleCartClick} boxProps={{ marginRight: 6 }} />
                <AppHeader.ProfileToggle isProfileOpen={currentState === MENU_PATHS.profile} onProfileClick={handleOpenProfile} />
              </HeaderMobileApp>
            ) : (
              <Box flexDirection="row" flexGrow={1}>
                <Box flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1} flexShrink={0} flexBasis={isDesktop ? '33%' : 0}>
                  <AppHeader.ProfileToggle isProfileOpen={currentState === MENU_PATHS.profile} onProfileClick={handleOpenProfile} />
                  <AppHeader.Links giftCardEnabled={showGiftCards} onBuyGiftCardClick={handleBuyGiftCardsClick} onCustomLinkClick={handleOpenLinkInRN} />
                </Box>
                <AppHeader.Logo onPress={handleOpenLinkInRN} />
                <Box flexDirection="row" justifyContent="flex-end" alignItems="center" flexGrow={1} flexShrink={0} flexBasis={isDesktop ? '33%' : 0}>
                  {showPromos ? (
                    <AppHeader.Promo
                      title={getPromoIntlString('page.title')}
                      promosAmount={appContext.numberOfApplicablePromos ?? 0}
                      onPromoClick={handlePromosClick}
                      boxProps={{ marginRight: 4 }}
                    />
                  ) : null}
                  {user.type === UserTypeEnum.AUTHENTICATED && !!appContext.loyaltyBase ? (
                    <AppHeader.Loyalty
                      loyaltyBase={appContext.loyaltyBase}
                      loyaltyPointName={appContext.loyaltyPointName ?? { plural: '', singular: '' }}
                      onLoyaltyHistoryClick={handleLoyaltyHistoryClick}
                      boxProps={{ marginRight: 4 }}
                    />
                  ) : null}
                  <AppHeader.Account
                    userCapitals={`${user?.profile?.first_name[0]}${user?.profile?.last_name[0]}`.toUpperCase()}
                    isAuthenticated={user?.type === UserTypeEnum.AUTHENTICATED}
                    onLoginPressEnter={handleLoginPress}
                    onLoginClick={handleLoginClick}
                    onOpenProfile={handleOpenProfile}
                    boxProps={{ marginRight: 4 }}
                  />
                  <AppHeader.CartButton onCartClick={handleCartClick} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </AppHeader>
    </header>
  );
};
