import React from 'react';

import type { SelectionBadgeProps } from '../../../badges';
import { SelectionBadge } from '../../../badges';
import * as s from './CollapseHeader.styled';

interface Props {
  title: string;
  required: boolean;
  allSelected: number;
  helperText: string;
  hasError?: boolean;
  badgeObj: SelectionBadgeProps;
}

export const CollapseHeader: React.FC<Props> = ({ title, badgeObj, hasError, allSelected, required, helperText }) => (
  <s.Header>
    <s.OptionTitle data-testid={`itemOptionTitle-${title}`} required={required && allSelected > 1}>
      {title}
      {required && (
        <span className="required" aria-label="Required">
          *
        </span>
      )}
      {(required || allSelected > 0) && <SelectionBadge {...badgeObj} hasError={hasError} />}
    </s.OptionTitle>
    {helperText && (
      <div>
        <s.HelperText>{helperText}</s.HelperText>
      </div>
    )}
  </s.Header>
);
