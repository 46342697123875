export const CHECKOUT_STEPS = {
  RECOMMEND: 'recommend',
  SELECT_RECEIVE_METHOD: 'selectReceiveMethod',
  SELECT_DINING_OPTION: 'selectDiningOption',
  PAYMENT: 'payment',
  LOYALTY: 'loyalty',
  ORDER_CONFIRMATION: 'orderConfirmation',
  ORDER_COMPLETE: 'orderComplete',
  PACKING_INSTRUCTIONS: 'packingInstructions',
};

export const FB_PIXEL_TRACKING = {
  INITIATE_CHECKOUT: 'InitiateCheckout',
  ADD_TO_CART: 'AddToCart',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  PURCHASE: 'Purchase',
  VIEW_CONTENT: 'ViewContent',
  FIND_LOCATION: 'FindLocation',
};

export const errorMessage = 'Sorry, seems that something went wrong. Please try again later or skip this step.';

export const iframeStyleVariables = {
  mainColor: '--main-color',
  textBase: '--loyalty-header-color',
  buttonColor: '--button-color',
  buttonBg: '--button-bg',
  buttonBgHover: '--button-bg-hover',
  buttonColorHover: '--button-color-hover',
  font: '--ff-base',
};

export const loyaltyPostMessages = {
  MOUNTED: 'MOUNTED',
  DISCOUNT_REWARD_SELECTED: 'DISCOUNT_REWARD_SELECTED',
  ITEM_REWARD_SELECTED: 'ITEM_REWARD_SELECTED',
  CANCEL_DISCOUNT_SUCCESS: 'CANCEL_DISCOUNT_SUCCESS',
  DISCOUNT_REWARD_ADDED: 'DISCOUNT_REWARD_ADDED',
  OPTIONS: 'OPTIONS',
  ERROR: 'ERROR',
  SET_POPUP_ON_LOGIN: 'POPUP_ON_LOGIN',
  USER_BALANCE: 'USER_BALANCE',
};
