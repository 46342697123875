import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { Box } from '@webstore-monorepo/ui/box';
import React, { useEffect, useRef } from 'react';

import { usePromoPopupContext } from '../PromoPopupProvider';
import { AddToCartButton } from './AddToCartButton';
import { NoButton } from './NoButton';
import { PopupImage } from './PopupImage';
import { Title } from './Title';

export const MobileView = () => {
  const { promoPopup } = useComponentsConfig();
  const { wrapperStyles, titleWrapperStyles, detailsStyles } = usePromoPopupContext();
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  return (
    <Box
      ref={ref}
      accessibilityRole="alert"
      accessibilityViewIsModal={true}
      // @ts-ignore
      accessibilityLabelledBy="promo-popup-title"
      testID="promo-popup-mobile"
      overflow="hidden"
      {...wrapperStyles}
      {...promoPopup?.wrapperMobile?.style}
      flexDirection="column"
    >
      <Box flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <Box paddingTop={4} paddingBottom={4} paddingLeft={4} justifyContent="center" alignItems="center" alignSelf="center">
          <Box maxWidth={100} maxHeight={100}>
            <PopupImage width="auto" height="auto" maxWidth={100} maxHeight={100} />
          </Box>
        </Box>
        <Box {...detailsStyles}>
          <Box nativeID="promo-popup-title" {...titleWrapperStyles} {...promoPopup?.wrapperMobile?.title?.style}>
            <Title />
          </Box>
        </Box>
      </Box>
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        borderTopWidth={1}
        borderColor="primaryColor"
        {...promoPopup?.wrapperMobile?.buttonsWrapper?.style}
      >
        <Box flexDirection="row" flexShrink={0} flexGrow={1}>
          <NoButton size="full-width" borderRadius="none" />
        </Box>
        <Box flexDirection="row" flexShrink={0} flexGrow={1}>
          <AddToCartButton size="full-width" borderRadius="none" />
        </Box>
      </Box>
    </Box>
  );
};
