import type { BoxProps } from '@shopify/restyle';
import { ChevronDown } from '@webstore-monorepo/shared/icons';
import { ChevronUp } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { FC, PropsWithChildren } from 'react';
import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';

type CollapsableProps = {
  isDefaultOpen?: boolean;
  onToggleCollapse?: (isOpen: boolean) => void;
  title: string | React.ReactNode;
  wrapperStyles?: BoxProps<Theme>;
  showIcon?: boolean;
};

// TODO: add icon support when Andrey adds the shared icons

export const Collapsable: FC<PropsWithChildren<CollapsableProps>> = ({ title, isDefaultOpen, wrapperStyles, showIcon = true, onToggleCollapse, children }) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen ?? false);

  const handleToggleCollapse = () => {
    const newOpen = !isOpen;
    setIsOpen(newOpen);
    onToggleCollapse && onToggleCollapse(newOpen);
  };

  useEffect(() => {
    setIsOpen(!!isDefaultOpen);
  }, [isDefaultOpen]);

  return (
    <>
      <Pressable testID="collapsable" onPress={handleToggleCollapse}>
        <Box flexDirection="row" justifyContent="space-between" alignItems="center" {...wrapperStyles}>
          <Box flexGrow={1} marginRight={showIcon ? 4 : 'none'}>
            {title}
          </Box>
          {showIcon ? isOpen ? <ChevronUp width={15} height={15} /> : <ChevronDown width={15} height={15} /> : null}
        </Box>
      </Pressable>
      {isOpen ? children : null}
    </>
  );
};
