import type { ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { useGetLegacyPaymentApiQuery } from '../queries/use-get-legacy-payment-api-query';

interface IPaymentContext {
  paymentStore?: ReadPaymentStoreDto;
  onPostCharge: () => void;
}

export const PaymentContext = createContext<IPaymentContext>({
  onPostCharge: () => undefined,
});

type PaymentProviderType = {
  orderId: string;
  onPostCharge: () => void;
  onPaymentUnauthorized?: () => void;
};
export const PaymentProvider: React.FC<PropsWithChildren<PaymentProviderType>> = ({ orderId, onPostCharge, onPaymentUnauthorized, children }) => {
  const { data } = useGetLegacyPaymentApiQuery(orderId, {
    onError: (error) => {
      if (error.response?.status === 401 && onPaymentUnauthorized) {
        onPaymentUnauthorized();
      }
    },
  });

  const handlePostCharge = useCallback(() => {
    onPostCharge();
  }, [onPostCharge]);

  const value = useMemo(
    () => ({
      paymentStore: data?.paymentStore,
      onPostCharge: handlePostCharge,
    }),
    [data?.paymentStore, handlePostCharge],
  );

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>;
};

export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error('useSquareContext must be used within a PaymentProvider');
  }
  return context;
};
