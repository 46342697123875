import { StoreItemTypeEnum } from '@goparrot/common';
import type { INestedStoreItemGroup, IStoreItemWithOptions, TimedMenuWebstoreItemType, WebstoreItemType } from '@goparrot/storeitems-sdk';
import { MenuItemCardList } from '@webstore-monorepo/features/menu-item-card-list';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box, ColFull } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import { TextGradient } from '@webstore-monorepo/ui/text-gradient';
import React, { useEffect, useState } from 'react';

type MenuItemsSearchResultProps = {
  searchStr: string;
  items: (WebstoreItemType | TimedMenuWebstoreItemType)[];
  onMenuItemClick: (item: IStoreItemWithOptions | INestedStoreItemGroup) => void;
};

export const MenuItemsSearchResult = ({ searchStr, items, onMenuItemClick }: MenuItemsSearchResultProps) => {
  const [searchResult, setSearchResult] = useState<IStoreItemWithOptions[] | INestedStoreItemGroup[]>([]);
  const { isMobile } = useWindowDimensions();
  const { categoryItemsGroup } = useComponentsConfig();
  const title = searchResult.length > 0 ? `${searchResult.length} item${searchResult.length > 1 ? 's' : ''} found` : 'Sorry! No items were found!';

  const handleMenuItemClick = (item: IStoreItemWithOptions | INestedStoreItemGroup) => {
    onMenuItemClick(item);
  };

  useEffect(() => {
    if (!items.length) {
      return;
    }

    function mergeItems(arr: any[]) {
      const uniqueObjects = new Set();
      let newArr: any[] = [];
      for (let i = 0; i < arr.length; i++) {
        if ('items' in arr[i]) {
          const items = mergeItems(arr[i].items);
          newArr = newArr.concat(items);
        }
        uniqueObjects.add(JSON.stringify(arr[i]));
      }
      newArr = newArr.concat(Array.from(uniqueObjects).map((obj: unknown) => JSON.parse(obj as string)));
      return newArr;
    }

    const allItems = mergeItems(items);
    const filtered = allItems.filter((item: IStoreItemWithOptions) => {
      const searchArr = searchStr.split(' ');
      return searchArr.every((searchItem) =>
        item.metadata.additional_title
          ? item.metadata.additional_title.toLocaleLowerCase().includes(searchItem.trim().toLocaleLowerCase())
          : item.title.toLocaleLowerCase().includes(searchItem.trim().toLocaleLowerCase()),
      );
    });
    if (filtered) {
      setSearchResult(
        filtered
          .filter((item) => (item.type === StoreItemTypeEnum.STORE_ITEM_GROUP ? item.items.length > 0 : true))
          .map((item) => ({ ...item, title: item.metadata.additional_title || item.title })),
      );
    }
  }, [items, searchStr]);

  return (
    <Box marginTop={5} marginBottom={6} paddingLeft={5} paddingRight={5} flexDirection="row" alignItems="center" justifyContent="center">
      <ColFull>
        <Box
          accessibilityLabel={title}
          accessibilityHint="Search result text"
          accessibilityRole="text"
          focusable
          width="100%"
          marginBottom={8}
          {...categoryItemsGroup?.wrapper?.title?.style}
          {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.style : {})}
        >
          {(isMobile ? categoryItemsGroup?.wrapperMobile?.options?.showTitleTextGradient : categoryItemsGroup?.wrapper?.options?.showTitleTextGradient) ? (
            <TextGradient
              fontSize="4xl"
              colors={
                isMobile
                  ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.colors
                  : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.colors
              }
              start={
                isMobile
                  ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.start
                  : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.start
              }
              end={
                isMobile ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.options?.end : categoryItemsGroup?.wrapper?.title?.textGradient?.options?.end
              }
              {...categoryItemsGroup?.wrapper?.title?.text?.style}
              {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
              {...categoryItemsGroup?.wrapper?.title?.textGradient?.style}
              {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.textGradient?.style : {})}
            >
              {title}
            </TextGradient>
          ) : (
            <Text
              fontSize="4xl"
              lineHeight={57}
              {...categoryItemsGroup?.wrapper?.title?.text?.style}
              {...(isMobile ? categoryItemsGroup?.wrapperMobile?.title?.text?.style : {})}
            >
              {title}
            </Text>
          )}
        </Box>
        {searchResult.length ? <MenuItemCardList items={searchResult} onItemClick={handleMenuItemClick} /> : null}
      </ColFull>
    </Box>
  );
};
