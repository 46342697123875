import type { IReadPaymentCardDto } from '@goparrot/payment-sdk';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import type { AxiosError } from 'axios';
import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import { paymentCardsApi } from '../api/api';
import { QueryKeysEnum } from './keys';

export const useGetClientCards = (options?: UseQueryOptions<IReadPaymentCardDto[], AxiosError, IReadPaymentCardDto[], QueryKeysEnum[]>) => {
  const store = useStoreState();
  const { user } = useUserContextState();
  const fetchApi = async () => {
    return await paymentCardsApi.list(store?.storeId ?? '', user?.userId ?? '');
  };

  return useQuery([QueryKeysEnum.GET_CARD_LIST], fetchApi, { enabled: !!store?.storeId && !!user?.userId, ...options });
};
