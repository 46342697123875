import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { CircleBadge } from '@webstore-monorepo/ui/badge';
import { Box, InlineBox } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Pressable } from 'react-native';

export type HeaderPromoProps = {
  onPromoClick(): void;
  promosAmount: number;
  title: string;
  boxProps?: BoxProps<Theme>;
};
export const HeaderPromo: FC<HeaderPromoProps> = ({ onPromoClick, title, promosAmount, boxProps }) => {
  const { isMobile, isDesktop, isPortraitMobile } = useWindowDimensions();
  const { header } = useComponentsConfig();
  const ref = useRef();
  const { isHovered } = useHover({}, ref);
  const titleLow = title?.toLowerCase();

  return !(isMobile || isPortraitMobile) ? (
    <Pressable
      testID="promos-wrapper"
      accessibilityLabel={`promo page, currently available ${promosAmount} promotions`}
      accessibilityHint="press to open promo page"
      accessibilityRole="button"
      onPress={onPromoClick}
    >
      <InlineBox position="relative" flexDirection="row" alignItems="center" {...boxProps}>
        <Box ref={ref} flexDirection="row" flexBasis="auto">
          {!isDesktop ? (
            <Box
              width={30}
              height={30}
              borderRadius="rounded"
              justifyContent="center"
              alignItems="center"
              borderWidth={1}
              borderColor={header?.circleBadge?.style?.backgroundColor}
            >
              <Text fontWeight="600" lineHeight={29} fontSize="m" color={header?.circleBadge?.style?.backgroundColor}>
                %
              </Text>
            </Box>
          ) : (
            <Text textTransform="uppercase" {...header?.text?.style} {...(isHovered && header?.text?.hover?.style)}>
              {titleLow}
            </Text>
          )}
          {promosAmount ? (
            //@ts-expect-error allow string value for left
            <CircleBadge width={16} height={16} left="unset" right={-10} top={-12}>
              {promosAmount}
            </CircleBadge>
          ) : null}
        </Box>
      </InlineBox>
    </Pressable>
  ) : null;
};
