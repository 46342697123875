import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import type { TextPropsExtended } from '@webstore-monorepo/ui/text';
import { Text } from '@webstore-monorepo/ui/text';
import React, { forwardRef } from 'react';

import { useMenuItemCardState } from '../../../MenuItemCardProvider';

export const MenuItemPrice = forwardRef((props: BoxProps<Theme> & { textProps?: TextPropsExtended }, forwardedRef) => {
  const { price, pricePrefixText, isHovered, layoutCustomization } = useMenuItemCardState();
  const { isMobile } = useWindowDimensions();
  const { menuItemCard, menuItemCardMobile } = useComponentsConfig();
  const { textProps, ...boxProps } = props;

  return price && !layoutCustomization?.hidePrice ? (
    <Box
      ref={forwardedRef}
      flexGrow={1}
      flexShrink={1}
      flexBasis="auto"
      testID="menu-item-price"
      {...boxProps}
      {...menuItemCard?.price?.style}
      {...(isMobile ? menuItemCardMobile?.price?.style : {})}
      {...(isHovered ? menuItemCard?.priceHover?.style : {})}
    >
      <Text
        fontStyle="italic"
        numberOfLines={5}
        {...menuItemCard?.priceGroup?.text?.style}
        {...(isMobile ? menuItemCardMobile?.priceGroup?.text?.style : {})}
        {...menuItemCard?.price?.text?.style}
        {...(isMobile ? menuItemCardMobile?.price?.text?.style : {})}
        {...textProps}
        {...(isHovered ? menuItemCard?.priceHover?.text?.style : {})}
      >
        {pricePrefixText ? pricePrefixText : ''} {price}
      </Text>
    </Box>
  ) : null;
});
