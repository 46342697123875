import { useAppContextDispatch } from '../../../shared/contexts/app-context-provider';
import { useAppHistory } from '../../../shared/hooks/use-app-history';

export const useModalUtils = () => {
  const appStateDispatch = useAppContextDispatch();
  const history = useAppHistory();

  const onModalClose = (cb?: () => void) => {
    appStateDispatch({ type: 'update', payload: { selectedItemGroups: undefined } });

    typeof cb === 'function' ? cb() : history.onGoToParent();
  };

  return {
    onModalClose,
  };
};
