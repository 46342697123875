import type { IStoreItemOptionSelection, IStoreItemOptionWithSelections } from '@goparrot/storeitems-sdk';
import type { OpenCollapseModifierGroupEnum } from '@goparrot/webstore-sdk';
import type { SelectablePackingInstructions } from '@webstore-monorepo/shared/interfaces';
import { Box } from '@webstore-monorepo/ui/box';
import { Separator } from '@webstore-monorepo/ui/separator';
import type { MutableRefObject, RefObject } from 'react';
import React, { createRef, Fragment } from 'react';

import { SelectionGroup } from './SelectionGroup';
import type { SelectionItemType } from './SelectionItem';
import { SelectionItem } from './SelectionItem';

export interface PackingInstruinItemProps {
  itemRef?: MutableRefObject<RefObject<HTMLElement>[]>;
  isReadOnly?: boolean;
  packingInstructionsItem: SelectablePackingInstructions;
  openCollapseModifierGroup?: OpenCollapseModifierGroupEnum;
  onUpdatePackingInstructions: (updateItems: IStoreItemOptionWithSelections[]) => void;
  onUpdateSingle: (packingItem: SelectablePackingInstructions) => void;
}

export const PackingInstructionItem: React.FC<PackingInstruinItemProps> = ({
  itemRef,
  isReadOnly,
  packingInstructionsItem,
  openCollapseModifierGroup,
  onUpdatePackingInstructions,
  onUpdateSingle,
}) => {
  const handleUpdateSelections = (uid: string) => (selectedItems: IStoreItemOptionSelection[]) => {
    const updated = packingInstructionsItem.properties.map((item) => (item.uid === uid ? { ...item, selections: [...selectedItems], selected: true } : item));

    onUpdatePackingInstructions(updated);
  };

  const handleChangeSingle = (item: SelectionItemType) => {
    onUpdateSingle({ ...packingInstructionsItem, selected: item.selected });
  };

  return (
    <Box testID={`packing-instructions-item-${packingInstructionsItem.title}`}>
      {packingInstructionsItem.properties.length ? (
        <Box>
          {packingInstructionsItem.properties.map((property, index) => (
            <Fragment key={property.uid}>
              <Box marginBottom={8} testID="packing-instructions-item-collapsible">
                <SelectionGroup
                  isReadOnly={isReadOnly}
                  openCollapseModifierGroup={openCollapseModifierGroup}
                  itemRef={itemRef && (itemRef.current[index] = createRef())}
                  packingGroupItem={property}
                  onUpdate={handleUpdateSelections(property.uid)}
                />
              </Box>
              <Separator />
            </Fragment>
          ))}
        </Box>
      ) : (
        <>
          <Box mb={8}>
            <SelectionItem
              single
              disabled={isReadOnly}
              onSelectionUpdate={handleChangeSingle}
              selection={{
                title: packingInstructionsItem.title,
                uid: packingInstructionsItem.uniqueName,
                price: packingInstructionsItem.price,
                selected: packingInstructionsItem.selected,
              }}
            />
          </Box>
          <Separator />
        </>
      )}
    </Box>
  );
};
