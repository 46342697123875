import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import React from 'react';

import { Description } from './Description';
import * as s from './ItemModalDescription.styled';

interface Props {
  hasModifiers: boolean;
}
export const ItemModalDescription: React.FC<Props> = ({ hasModifiers }) => {
  const { metadata } = useWebStore();
  const area = hasModifiers ? 'hasModifiers' : 'noModifiers';

  return (
    <>
      <s.Wrapper>
        <Description
          showPreview={metadata.itemModalConfig?.desktop?.[area]?.image}
          showSubtitle={metadata.itemModalConfig?.desktop?.[area]?.description}
          showIngredients={metadata.itemModalConfig?.desktop?.[area]?.ingredients}
        />
      </s.Wrapper>
      <s.MobileWrapper>
        <Description
          showPreview={metadata.itemModalConfig?.mobile?.[area]?.image}
          showSubtitle={metadata.itemModalConfig?.mobile?.[area]?.description}
          showIngredients={metadata.itemModalConfig?.mobile?.[area]?.ingredients}
        />
      </s.MobileWrapper>
    </>
  );
};
