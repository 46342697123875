import type { IStoreItemOptionSelection } from '@goparrot/storeitems-sdk';
import { formatPrice } from '@webstore-monorepo/shared/utils/format-strings';
import { Box } from '@webstore-monorepo/ui/box';
import { Checkbox } from '@webstore-monorepo/ui/checkbox';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';

export type SelectionItemType = Pick<IStoreItemOptionSelection, 'price' | 'uid' | 'selected' | 'title'>;

export interface Props {
  selection: SelectionItemType;
  onSelectionUpdate: (item: SelectionItemType) => void;
  disabled?: boolean;
  single?: boolean;
}

export const SelectionItem: React.FC<Props> = ({ selection, onSelectionUpdate, disabled, single }) => {
  const handleToggle = () => {
    const updatedSelection = { ...selection, selected: !selection.selected };
    onSelectionUpdate(updatedSelection);
  };

  return (
    <Box marginTop={single ? 'none' : 4} flexGrow={1} flexDirection="row" justifyContent="space-between">
      <Checkbox checked={selection.selected} label={selection.title} isDisabled={disabled} onPress={handleToggle} />
      <Text>{formatPrice(selection.price, false)}</Text>
    </Box>
  );
};
