import type { IOrder, IUpdateItemBundleAction, IUpdateItemComboAction } from '@goparrot/order-sdk';
import { CartActionTypeEnum, OrderElementTypeEnum } from '@goparrot/order-sdk';
import type { RemoveComboParams } from '@webstore-monorepo/shared/api/cart-api';
import { useRemoveComboMutation, useUpdateComboMutation } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import type { Theme } from '@webstore-monorepo/shared/theme';
import type { ComboConfig } from '@webstore-monorepo/shared/utils/cart';
import { groupItemsByPropertyUid, resetItemsQuantity, useExtractCombos } from '@webstore-monorepo/shared/utils/cart';
import { Box } from '@webstore-monorepo/ui/box';
import React from 'react';

import { OrderComboItem } from './OrderComboItem';

interface Props {
  onComboEdit?: (uid: string, uniqueName: string) => void;
  isEditable?: boolean;
  lastOrder?: IOrder;
  borderBottomColor?: keyof Theme['colors'];
}
export const OrderComboList: React.FC<Props> = ({ isEditable, onComboEdit, lastOrder, borderBottomColor }) => {
  const cart = useCartState();
  const order = lastOrder || cart;
  const { intl } = usePlatformStoreState();
  const cartDispatch = useCartDispatch();
  const combos = useExtractCombos(order);

  const { notification } = usePlatformStoreState();
  const updateComboMutation = useUpdateComboMutation({
    onSuccess: (cart, { comboTitle }) => {
      cartDispatch({ type: 'update', payload: cart });
      notification.success(
        intl.getIntlString('combo.notification.update.success', {
          strong: (chunks: string) => <strong>{chunks}</strong>,
          itemTitle: comboTitle,
        }) as string,
      );
    },
    onError: (_, { comboTitle }) => notification.success(`There was an error while updating ${comboTitle}`),
  });

  const removeComboMutation = useRemoveComboMutation({
    onSuccess: (cart) => {
      cartDispatch({ type: 'update', payload: cart });
      notification.success('Item was successfully removed from cart');
    },
    onError: () => notification.success('There was an error while removing item'),
  });

  const handleRemove = (uuid: string, type: RemoveComboParams['type']) => {
    isEditable && removeComboMutation.mutate({ uuid, type });
  };

  const handleUpdate = (combo: ComboConfig, quantity: number) => {
    if (isEditable) {
      const storeItems = resetItemsQuantity(combo.items, combo.comboGroup.quantity);

      const action: IUpdateItemBundleAction | IUpdateItemComboAction = {
        type: OrderElementTypeEnum.COMBO === combo.comboGroup.type ? CartActionTypeEnum.UPDATE_ITEM_COMBO : CartActionTypeEnum.UPDATE_ITEM_BUNDLE,
        payload: {
          properties: groupItemsByPropertyUid(storeItems),
          quantity,
          uuid: combo.comboGroup.uniqueName,
        },
      };

      updateComboMutation.mutate({ action, comboTitle: combo.comboGroup.title });
    }
  };

  return (
    <Box>
      {Object.keys(combos).map((comboId) => (
        <OrderComboItem
          isLoading={updateComboMutation.isLoading || removeComboMutation.isLoading}
          key={comboId}
          combo={combos[comboId]}
          onRemove={isEditable ? handleRemove : undefined}
          onEdit={isEditable ? onComboEdit : undefined}
          onUpdate={isEditable ? handleUpdate : undefined}
          promotions={order.promotions}
          borderBottomColor={borderBottomColor}
        />
      ))}
    </Box>
  );
};
