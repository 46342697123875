import type { ILegacyPaymentContext, ReadPaymentStoreDto } from '@goparrot/payment-sdk';
import { LegacyPaymentFacade } from '@goparrot/payment-sdk';
import { getInterceptors } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { AxiosError } from 'axios';
import type { UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { axiosPaymentConfig } from '../api/api';
import { QueryKeysEnum } from './keys';

interface IPaymentContext {
  paymentStore?: ReadPaymentStoreDto;
  store?: ILegacyPaymentContext['store'];
  order?: ILegacyPaymentContext['order'];
  user?: ILegacyPaymentContext['user'];
  merchant?: ILegacyPaymentContext['merchant'];
}
export const useGetLegacyPaymentApiQuery = (
  orderId: string,
  options?: UseQueryOptions<IPaymentContext | undefined, AxiosError, IPaymentContext, QueryKeysEnum[]>,
): UseQueryResult<IPaymentContext, AxiosError> => {
  const fetchData = async (): Promise<IPaymentContext | undefined> => {
    const legacyPaymentApi = new LegacyPaymentFacade(axiosPaymentConfig, getInterceptors());
    const data = await legacyPaymentApi.getContext(orderId, ['store', 'user', 'merchant']);
    return data as IPaymentContext;
  };

  return useQuery([QueryKeysEnum.GET_LEGACY_PAYMENT_API], fetchData, { enabled: !!orderId, retry: 0, ...options });
};
