import React from 'react';
import type { PreviewTerminologyDto } from '@goparrot/loyalty-sdk';

interface Props {
  pointNames?: PreviewTerminologyDto;
  loyaltyPointIcon: string;
  loyaltyBase?: {
    pointsAvailable: number;
  };
  amount?: number;
}

const pluralize = (points: number, pointNames: PreviewTerminologyDto): string => ([1, -1].includes(points) ? pointNames.singular : pointNames.plural);

export const LoyaltyBadge: React.FunctionComponent<Props> = ({ loyaltyBase, loyaltyPointIcon, pointNames, amount }) => {
  const balance = amount || (loyaltyBase?.pointsAvailable ?? 0);

  return (
    <span className="loyalty-points" title={pointNames?.plural || 'points'}>
      {undefined !== pointNames ? <span className="label">{pluralize(balance, pointNames)}</span> : null}
      <span className="icon">
        <img src={loyaltyPointIcon} alt="Reward icon" />
      </span>
      <span className="amount">{balance}</span>
    </span>
  );
};
