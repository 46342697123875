import { PaymentMethodTypeEnum } from '@goparrot/common';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import { useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { CashIcon } from '@webstore-monorepo/shared/icons';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React from 'react';
import { Pressable } from 'react-native';

import { useFeatureFlags } from '../../../contexts/feature-flags-provider';
import { useOrder, useOrderDispatch } from '../../../contexts/order-provider';

export const Cash = () => {
  const { isPayByCashEnabled } = useFeatureFlags();
  const cart = useCartState();
  const { selectedPaymentType } = useOrder();
  const { onSelectPaymentType } = useOrderDispatch();
  const isCardSelected = selectedPaymentType?.type === PaymentMethodTypeEnum.CASH;
  const isOrderContainingGiftCards = cart.giftcards?.length;
  const { user } = useUserContextState();
  const isAuthenticatedUser = user.type === UserTypeEnum.AUTHENTICATED;

  const handleSelectPaymentType = () => {
    onSelectPaymentType({ type: PaymentMethodTypeEnum.CASH });
  };

  return isAuthenticatedUser && isPayByCashEnabled && !isOrderContainingGiftCards ? (
    <Pressable onPress={handleSelectPaymentType}>
      <Box
        marginTop={4}
        py={6}
        px={4}
        borderColor={isCardSelected ? 'primary' : 'gray200'}
        borderWidth={1}
        borderRadius="md"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        testID="checkout-cash"
      >
        <Text fontWeight="500" lineHeight={24} fontSize="m" color="gray900">
          Cash
        </Text>
        <CashIcon fill="transparent" stroke="gray900" />
      </Box>
    </Pressable>
  ) : null;
};
