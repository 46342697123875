import { badgeBackgroundActive, badgeBackgroundInactive, badgeTextColorActive, badgeTextColorInactive } from '../../../shared/constants/css-variables';

export enum BadgeVariants {
  'active' = 'active',
  'inactive' = 'inactive',
}

export const BADGE_VARIANTS = {
  background: {
    [BadgeVariants.active]: badgeBackgroundActive,
    [BadgeVariants.inactive]: badgeBackgroundInactive,
  },
  text: {
    [BadgeVariants.active]: badgeTextColorActive,
    [BadgeVariants.inactive]: badgeTextColorInactive,
  },
};
