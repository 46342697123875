import type { IReadAbstractStoreScheduleDto, ReadStoreDto } from '@goparrot/store-v2-sdk';
import { HumanReadableUtils } from '@goparrot/store-v2-sdk';
import { TemplateTypes } from '@webstore-monorepo/shared/enums';
import { renderToString } from '@webstore-monorepo/shared/utils/custom-render';
import get from 'lodash/get';
import type { ReactNode } from 'react';
import React from 'react';

import { ScheduleTemplateComponent } from './components';
import type { HeroBlockOptions } from './HeroBlock.interface';

export const getDescriptionInfo = (store: ReadStoreDto, description: string, heroBlockOptions: HeroBlockOptions) => {
  const { haveCustomSchedule } = store.delivery ?? {};
  const { haveDineInHours } = store.schedule ?? {};

  const TemplatesDefinition = {
    OrderingHours: {
      type: TemplateTypes.SCHEDULE,
      path: 'schedule.weekly',
    },
    DeliveryHours: {
      type: TemplateTypes.SCHEDULE,
      path: haveCustomSchedule ? 'delivery.weeklySchedule' : 'schedule.weekly',
    },
    DineInHours: {
      type: TemplateTypes.SCHEDULE,
      path: 'schedule.dineIn',
    },
    RestaurantAddress: {
      type: TemplateTypes.TEXT,
      path: 'location.formattedAddress',
    },
    PhoneNumber: {
      type: TemplateTypes.TEXT,
      path: 'contactInfo.phone',
    },
  };
  const replacer = (matched: string): string => {
    if (matched === 'DineInHours' && !haveDineInHours) {
      return '';
    }
    const templateItem = TemplatesDefinition[matched as keyof typeof TemplatesDefinition];
    const data = get(store, templateItem.path, null) as unknown as IReadAbstractStoreScheduleDto;

    if (templateItem.type === TemplateTypes.SCHEDULE) {
      return renderToString(<ScheduleTemplateComponent {...{ schedule: HumanReadableUtils.getWeeklyWorkingHours(data), heroBlockOptions, description }} />);
    }

    return renderToString(<div data-testId="description-info">{data as ReactNode}</div>);
  };

  const regex = /OrderingHours|DeliveryHours|DineInHours|RestaurantAddress|PhoneNumber/gi;

  const matchedTemaplates = description?.replace(regex, replacer);

  return matchedTemaplates;
};
