import type { PreviewTerminologyDto } from '@goparrot/loyalty-sdk';
import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { InlineBox } from '@webstore-monorepo/ui/box';
import { LoyaltyBadge } from '@webstore-monorepo/ui/loyalty-badge';
import type { FC } from 'react';
import React from 'react';
import { Pressable } from 'react-native';

export type HeaderLoyaltyProps = {
  loyaltyPointName: PreviewTerminologyDto;
  loyaltyBase?: {
    pointsAvailable: number;
  };
  onLoyaltyHistoryClick: () => void;
  boxProps?: BoxProps<Theme>;
};

export const HeaderLoyalty: FC<HeaderLoyaltyProps> = ({ onLoyaltyHistoryClick, loyaltyBase, loyaltyPointName, boxProps }) => {
  const { isMobile, isPortraitMobile } = useWindowDimensions();
  const { header } = useComponentsConfig();
  const balance = loyaltyBase?.pointsAvailable ?? 0;

  return !(isMobile || isPortraitMobile) ? (
    <Pressable
      accessibilityLabel={`Loyalty Program, you currently have ${balance} points`}
      accessibilityHint="press to open loyalty program page"
      accessibilityRole="button"
      onPress={onLoyaltyHistoryClick}
    >
      <InlineBox {...boxProps}>
        <LoyaltyBadge
          loyaltyBase={loyaltyBase}
          pointNames={loyaltyPointName}
          overwriteStyles={header?.text?.style}
          overwriteHoverStyles={header?.text?.hover?.style}
        />
      </InlineBox>
    </Pressable>
  ) : null;
};
