export const MOBILE_THRESHOLD = 768;

export enum DeviceSource {
  APP_IOS = 'APP_IOS',
  APP_ANDROID = 'APP_ANDROID',
  WEB_MOBILE = 'WEB_MOBILE',
  WEB_DESKTOP = 'WEB_DESKTOP',
  UNKNOWN_DEVICE = 'UNKNOWN_DEVICE',
}
export const getDeviceSource = () => {
  if (window.ReactNativePlatformOS === 'ios') return DeviceSource.APP_IOS;
  if (window.ReactNativePlatformOS === 'android') return DeviceSource.APP_ANDROID;
  if (window.innerWidth <= MOBILE_THRESHOLD) return DeviceSource.WEB_MOBILE;
  if (window.innerWidth > MOBILE_THRESHOLD) return DeviceSource.WEB_DESKTOP;
  return DeviceSource.UNKNOWN_DEVICE;
};
