import React from 'react';

import type { ImageProps } from './Image';
import { Image } from './Image';
import { imagePipe } from './utils';

interface LazyImageProps extends ImageProps {
  width?: number | 'auto';
  quality?: string;
  imageSrc: string;
  noFilter?: boolean;
  title?: string;
}

export const LazyImage: React.FC<LazyImageProps> = (props) => {
  const { imageSrc, width, quality, className, alt, noFilter, title } = props;

  if (noFilter) {
    return <Image dataSrc={imageSrc} lazyload className={className} alt={alt} title={title} />;
  }

  return (
    <Image
      className={className}
      alt={alt}
      title={title}
      lazyload
      src={`${imagePipe(imageSrc, width, quality, '2', 'lqip')}`}
      dataSrcSet={`
      ${imagePipe(imageSrc, width, quality, '1')} 1x,
      ${imagePipe(imageSrc, width, quality, '1.5')} 1.5x,
      ${imagePipe(imageSrc, width, quality, '2')} 2x,
      ${imagePipe(imageSrc, width, quality, '3')} 3x`}
    />
  );
};
