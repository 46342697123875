import { DiningOptionEnum } from '@goparrot/common';
import type { IOrder } from '@goparrot/order-sdk';
import { CartActionTypeEnum } from '@goparrot/order-sdk';
import { useCartHandleActions } from '@webstore-monorepo/shared/api/cart-api';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { useTheme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import { Button } from '@webstore-monorepo/ui/button';
import { Input } from '@webstore-monorepo/ui/input';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { Modal, Pressable } from 'react-native';

type DiningOptionsProps = {
  isVisible: boolean;
  onClose: () => void;
  onAfterSave?: (tableNumber: number) => void;
};

export const OrderToTableDialog: FC<DiningOptionsProps> = ({ isVisible, onAfterSave, onClose }) => {
  const cart = useCartState();
  const cartDispatch = useCartDispatch();
  const { tableNumber } = cart.diningOptionInfo.details || {};
  const { height, isMobile } = useWindowDimensions();
  const theme = useTheme<Theme>();
  const parentVerticalPadding = 6;
  const buttonsMarginBottom = 6;
  const parentVerticalPaddingValue = theme.spacing[parentVerticalPadding];
  const buttonsMarginBottomValue = theme.spacing[buttonsMarginBottom];
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const mainContentHeight = isMobile ? height - 48 - parentVerticalPaddingValue * 2 - buttonsMarginBottomValue : 'auto';
  const [value, setValue] = useState(tableNumber);

  useEffect(() => {
    setValue(tableNumber);
  }, [tableNumber, isVisible]);

  const { mutate: mutateUpdateCartActions } = useCartHandleActions({
    onSuccess: (cart: IOrder) => {
      setIsLoading(false);
      cartDispatch({ type: 'update', payload: cart });
      onAfterSave && onAfterSave(value);
    },
    onError: () => {
      // TODO: treat error
      setIsLoading(false);
    },
  });

  const handleProceed = () => {
    if (isNaN(parseInt(value)) || parseInt(value) <= 0) {
      return;
    }

    const actions = [
      {
        type: CartActionTypeEnum.SET_DINING_OPTION_DETAILS,
        payload: {
          data: {
            tableNumber: value,
          },
          type: DiningOptionEnum.DINE_IN,
        },
      },
      {
        type: CartActionTypeEnum.SET_DINING_OPTION,
        payload: {
          type: DiningOptionEnum.DINE_IN,
        },
      },
      {
        type: CartActionTypeEnum.SET_DELAYED_INFO,
        payload: {
          delayedInfo: { isASAP: true },
        },
      },
      {
        type: CartActionTypeEnum.SET_RECIPIENT,
        payload: {},
      },
    ];
    setIsLoading(true);
    mutateUpdateCartActions(actions);
  };

  const handleChangeInput = (value: string) => {
    setValue(value);
    setIsValid(parseInt(value) > 0);
    setIsFocused(false);
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedInt = parseInt(value);
    const stringValue = value === '' ? '' : isNaN(parsedInt) ? '' : String(parsedInt);
    setValue(stringValue);
    setIsFocused(true);
  };

  const handleClear = () => {
    setValue('');
    setIsValid(false);
    setIsFocused(true);
  };
  return (
    <Modal animationType="fade" visible={isVisible} transparent onRequestClose={onClose}>
      <Box flexDirection="row" justifyContent="center" minHeight="100%">
        <Pressable onPress={onClose} style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Box backgroundColor="modalBackdrop" position="absolute" top={0} bottom={0} left={0} right={0} />
        </Pressable>
        <Box
          backgroundColor="white"
          flexGrow={1}
          maxWidth={670}
          marginTop="none"
          marginBottom="none"
          borderRadius={isMobile ? 'none' : 'lg'}
          paddingTop={parentVerticalPadding}
          paddingBottom={parentVerticalPadding}
          paddingLeft={4}
          paddingRight={4}
          style={{
            marginTop: isMobile ? 0 : 'auto',
            marginBottom: isMobile ? 0 : 'auto',
          }}
        >
          <Box marginBottom={buttonsMarginBottom} px={4}>
            <Box flexDirection="row" alignItems="center" justifyContent="space-between">
              <Button size="xxs" backgroundColor="gray100" onPress={onClose}>
                <CloseIcon stroke="black" fill="black" width={14} height={14} strokeWidth={1.5} />
              </Button>
              <Button isDisabled={!isValid || isLoading} onPress={handleProceed} buttonStyle={{ minWidth: 70 }}>
                Save
              </Button>
            </Box>
          </Box>
          <Box height={mainContentHeight} overflow="scroll" px={4}>
            <Text fontSize="xxl" fontWeight="700" mb={4}>
              Order to table
            </Text>
            <Text fontSize="m" mb={6}>
              The order will be brought to the table soon.{' '}
            </Text>
            <Text fontSize="l" fontWeight="700" mb={4}>
              Table number
            </Text>
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              paddingLeft={3}
              paddingRight={3}
              borderWidth={1}
              borderColor="gray300"
              backgroundColor="white"
              borderRadius="xs"
            >
              <Input
                value={value}
                // @ts-ignore
                onBlur={handleBlur}
                // @ts-ignore
                onChangeText={handleChangeInput}
                placeholder="Enter table number"
                borderWidth={0}
                backgroundColor="transparent"
                padding="none"
              />
              <Button variant="link" onPress={handleClear} buttonStyle={{ width: 32, height: 32 }}>
                <CloseIcon fill="gray300" />
              </Button>
            </Box>
            <Box height={36}>
              {!isValid && isFocused ? (
                <Text color="danger" paddingTop={3}>
                  Table number is required
                </Text>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
