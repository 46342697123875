import type { IPromotionWithApplicationState, IReadPromotionDto } from '@goparrot/promotions-sdk';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { CloseIcon } from '@webstore-monorepo/shared/icons';
import { Box, ColFull } from '@webstore-monorepo/ui/box';
import { EmptyFallback } from '@webstore-monorepo/ui/error-fallback';
import { Text } from '@webstore-monorepo/ui/text';
import type { FC } from 'react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Pressable } from 'react-native';

type PromotionExtended = IReadPromotionDto & {
  hidden?: boolean;
};
type PromotionListType = IPromotionWithApplicationState & {
  promotion: PromotionExtended;
};

type PromotionsBarProps = {
  promotionList?: PromotionListType[];
  onClosePromotion: (promotion: PromotionExtended, idx: number) => void;
};
export const PromotionsBar: FC<PromotionsBarProps> = ({ promotionList, onClosePromotion }) => {
  const { promotionsBar } = useComponentsConfig();

  const handleClosePromotion = (promotion: PromotionExtended, idx: number) => (): void => {
    onClosePromotion(promotion, idx);
  };

  return (
    <ErrorBoundary FallbackComponent={EmptyFallback}>
      {!!promotionList?.length && promotionList?.length > 0 ? (
        <Box testID="promotions-bar-wrapper">
          {promotionList.map(({ promotion }, index) =>
            !promotion.hidden ? (
              <Box
                key={promotion.uuid}
                testID={`promotions-bar-item-${promotion.uuid}`}
                justifyContent="center"
                alignItems="center"
                paddingTop={3}
                paddingBottom={3}
                {...promotionsBar?.item?.style}
              >
                <ColFull paddingLeft={4} paddingRight={4}>
                  <Box flexDirection="row" justifyContent="space-between" alignItems="center">
                    <Text {...promotionsBar?.item?.text?.style}>{promotion.message || promotion.title}</Text>
                    <Pressable
                      accessibilityLabel={`close promotion description ${promotion.message || promotion.title}`}
                      accessibilityHint={`Press to close promo item ${promotion.message || promotion.title}`}
                      accessibilityRole="button"
                      testID={`promotions-bar-item-close-${promotion.uuid}`}
                      onPress={handleClosePromotion(promotion, index)}
                    >
                      <CloseIcon width={12} height={12} stroke={promotionsBar?.item?.text?.style?.color} fill="transparent" />
                    </Pressable>
                  </Box>
                </ColFull>
              </Box>
            ) : null,
          )}
        </Box>
      ) : null}
    </ErrorBoundary>
  );
};
