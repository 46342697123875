import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

export const useParentWidth = ({ defaultWidth }: { defaultWidth: number }) => {
  const [parentWidth, setParentWidth] = useState(defaultWidth);

  const handleParentChange = useCallback(
    (node: any) => {
      if (node !== null) {
        const width = node.getBoundingClientRect()?.width;
        if (width && width > parentWidth) {
          setParentWidth(Math.round(width));
        }
      }
    },
    [parentWidth],
  );

  const handleChange = debounce(handleParentChange, 500);

  return { parentWidth, onParentChange: handleChange };
};
