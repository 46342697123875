import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { Box } from '@webstore-monorepo/ui/box';
import React, { forwardRef } from 'react';

import type {
  HeaderAccountProps,
  HeaderCartButtonProps,
  HeaderLinksProps,
  HeaderLogoProps,
  HeaderLoyaltyProps,
  HeaderPromoProps,
  ProfileToggleProps,
  TopNavBarProps,
} from './components';
import { HeaderAccount, HeaderCartButton, HeaderLinks, HeaderLogo, HeaderLoyalty, HeaderPromo, InfoBar, ProfileToggle, TopNavBar } from './components';

type PageHeaderCompositionProps = {
  TopNavBar: React.FC<TopNavBarProps>;
  InfoBar: React.FC;
  Account: React.FC<HeaderAccountProps>;
  CartButton: React.FC<HeaderCartButtonProps>;
  Links: React.FC<HeaderLinksProps>;
  Promo: React.FC<HeaderPromoProps>;
  Logo: React.FC<HeaderLogoProps>;
  Loyalty: React.FC<HeaderLoyaltyProps>;
  ProfileToggle: React.FC<ProfileToggleProps>;
};
// @ts-ignore
export const AppHeader: PageHeaderCompositionProps &
  React.ForwardRefExoticComponent<React.PropsWithoutRef<{ readonly children?: any }> & React.RefAttributes<unknown>> = forwardRef(({ children }, ref) => {
  const { header } = useComponentsConfig();
  const { isDesktop } = useWindowDimensions();
  const { height, ...restStyles } = header?.wrapper?.style ?? {};
  return (
    <Box
      ref={ref}
      width="100%"
      {...(isDesktop
        ? {
            shadowOffset: { width: 0, height: 2 },
            shadowColor: 'black',
            shadowRadius: 1,
            shadowOpacity: 0.05,
          }
        : {})}
      minHeight={height ?? 60}
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
      {...restStyles}
    >
      {children}
    </Box>
  );
});
AppHeader.TopNavBar = TopNavBar;
AppHeader.InfoBar = InfoBar;
AppHeader.Account = HeaderAccount;
AppHeader.CartButton = HeaderCartButton;
AppHeader.Links = HeaderLinks;
AppHeader.Promo = HeaderPromo;
AppHeader.Logo = HeaderLogo;
AppHeader.Loyalty = HeaderLoyalty;
AppHeader.ProfileToggle = ProfileToggle;
