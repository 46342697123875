import type { CreatePaymentCardDto, ReadPaymentCardDto } from '@goparrot/payment-sdk';
import { useStoreState } from '@webstore-monorepo/shared/contexts/store-provider';
import { useUserContextState } from '@webstore-monorepo/shared/contexts/user-context-provider';
import type { AxiosError } from 'axios';
import type { UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import { paymentCardsApi } from '../api';

export const useStoreCardMutation = (options?: UseMutationOptions<ReadPaymentCardDto, AxiosError, CreatePaymentCardDto>) => {
  const store = useStoreState();
  const { user } = useUserContextState();
  return useMutation(
    async (card: CreatePaymentCardDto) => {
      if (!store || !user) {
        throw new Error('No store or user');
      }
      return paymentCardsApi.create(store.storeId, user.userId, { ...card });
    },
    { ...options },
  );
};

export const useRemoveCardMutation = (options?: UseMutationOptions<void, AxiosError, string>) => {
  const store = useStoreState();
  const { user } = useUserContextState();
  return useMutation(
    async (cardId: string) => {
      if (!store || !user) {
        throw new Error('No store or user');
      }
      return paymentCardsApi.delete(store.storeId, user.userId, cardId);
    },
    { ...options },
  );
};
