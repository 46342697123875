import type { BoxProps } from '@shopify/restyle';
import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useParentWidth } from '@webstore-monorepo/shared/hooks/use-parent-width';
import type { Theme } from '@webstore-monorepo/shared/theme';
import { Box } from '@webstore-monorepo/ui/box';
import React, { forwardRef } from 'react';

import { MenuItemContent } from '../../menu-item-content';
import { MenuItemPriceGroup } from '../../menu-item-price-group';
import { MenuItemCalories } from '../../menu-item-price-group/menu-item-calories';
import { MenuItemPrice } from '../../menu-item-price-group/menu-item-price';
import { MenuItemSchedule } from '../../menu-item-schedule';
import { MenuItemThumbnail } from '../../menu-item-thumbnail';
import { MenuItemTitle } from '../../menu-item-title';

export const CardMobileThumbnailLeft = forwardRef((props: BoxProps<Theme>, forwardedRef) => {
  const { menuItemCardMobile } = useComponentsConfig();
  const { parentWidth, onParentChange } = useParentWidth({ defaultWidth: 188 });

  return (
    <Box ref={forwardedRef} {...props} justifyContent="space-between" p={4} {...menuItemCardMobile?.wrapper?.style}>
      <Box height="100%" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
        <Box ref={onParentChange} flexBasis="50%">
          <MenuItemThumbnail parentWidth={parentWidth} />
        </Box>
        <Box justifyContent="space-between" flexBasis="50%" height="100%" flexGrow={1} flexShrink={1} paddingLeft={4}>
          <Box>
            <MenuItemSchedule textProps={{ textAlign: 'left' }} />
            <MenuItemTitle marginBottom={2} textProps={{ textAlign: 'left' }} />
            <MenuItemContent marginBottom={4} textProps={{ textAlign: 'left' }} />
          </Box>
          <MenuItemPriceGroup>
            <MenuItemPrice textProps={{ textAlign: 'left' }} />
            <MenuItemCalories marginLeft={2} textProps={{ textAlign: 'right' }} />
          </MenuItemPriceGroup>
        </Box>
      </Box>
    </Box>
  );
});
