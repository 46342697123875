import styled from 'styled-components';

import { media } from '../../../../../shared/utils/media-queries';

export const Header = styled.div`
  padding-bottom: 5px;
  > div {
    display: flex;
  }
`;

export const OptionTitle = styled.span<{ required?: boolean }>`
  font-size: 18px;
  color: ${(props) => (props.required ? 'var(--item-modifier-group-card-border-required-text-color)' : 'inherit')};
  ${media.desktop} {
    font-size: 20px;
    font-weight: 400;
  }
`;

export const HelperText = styled.span`
  font-size: 12px;
  color: var(--add-ons-validate-color);
`;
