import { datadogRum } from '@datadog/browser-rum';
import { PaymentMethodTypeEnum, PaymentProviderEnum } from '@goparrot/common';
import type { CreateUserProfileDto, ReadUserDto } from '@goparrot/customer-sdk';
import { UserTypeEnum } from '@goparrot/customer-sdk';
import type { CreatePaymentCardDto, IReadSquareCardMetadataDto, PaymentSourceDto, PaymentSourceEnum, ReadPaymentCardDto } from '@goparrot/payment-sdk';
import { PaymentOrderFacade, SquareCardBrandEnum } from '@goparrot/payment-sdk';
import type { UserContextWithAuthDto, UserLoggedInContextDto } from '@goparrot/webstore-gateway-public-sdk';
import type { Card, CardInputEvent, SqEvent } from '@square/web-payments-sdk-types';
import { useGetFullCart, useSynchronizeWithWithPOSMutation } from '@webstore-monorepo/shared/api/cart-api';
import { createNewTempContext, getCurrentContext, useEditUserMutation, useRegisterGuestUserMutation } from '@webstore-monorepo/shared/api/users-api';
import { getInterceptors } from '@webstore-monorepo/shared/contexts/axios-provider';
import { useCartDispatch, useCartState } from '@webstore-monorepo/shared/contexts/cart-provider';
import { usePlatformStoreState } from '@webstore-monorepo/shared/contexts/platform-provider';
import { useUserContextState, useUserContextStateDispatch } from '@webstore-monorepo/shared/contexts/user-context-provider';
import { useWebStore } from '@webstore-monorepo/shared/contexts/webstore-provider';
import { useGetFeatureFlag } from '@webstore-monorepo/shared/hooks/use-get-feature-flag';
import type { UserFields } from '@webstore-monorepo/shared/interfaces';
import type { PropsWithChildren } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { axiosPaymentConfig } from '../../api/api';
import { useStoreCardMutation } from '../../api/mutations/use-store-card-mutation';
import { getProviderSpecificErrorData, getSpecificErrorForGuestForm } from '../../api/utils';
import { useSquareVerifyBuyer } from '../../hooks/useSquareVerifyBuyer';
import { useGetClientCards } from '../../queries/use-get-client-cards';
import { getDeviceSource } from '../../utils/device-source';
import { useGiftCardState } from '../gift-card-provider';
import { usePaymentContext } from '../PaymentProvider';

export interface IOrderContext {
  shouldSaveCard: boolean;
  squareCard?: Card;
  guestFormRef: React.RefObject<HTMLFormElement> | null;
  guestFormBlockRef: React.RefObject<HTMLDivElement> | null;
  selectedPaymentType?: PaymentSourceDto;
}

type OrderDispatchType = {
  chargeOrder: (paymentSourceDto?: PaymentSourceDto) => Promise<void>;
  onSetSquareCard: (card?: Card) => void;
  onShouldSaveCardToggle: () => void;
  onSaveCardToUser: (user?: UserContextWithAuthDto['user']) => Promise<PaymentSourceDto | ReadPaymentCardDto | undefined>;
  onSelectPaymentType: (paymentSourceDto?: PaymentSourceDto) => void;
};

const paymentOrderApi = new PaymentOrderFacade(axiosPaymentConfig, getInterceptors());

export const OrderContext = createContext<IOrderContext>({
  shouldSaveCard: true,
  squareCard: undefined,
  guestFormRef: null,
  guestFormBlockRef: null,
  selectedPaymentType: {
    type: PaymentMethodTypeEnum.CREDIT_CARD,
  },
});
export const OrderDispatch = createContext<OrderDispatchType>({
  chargeOrder: () => Promise.resolve(),
  onShouldSaveCardToggle: () => {},
  onSetSquareCard: () => {},
  onSaveCardToUser: () => Promise.resolve({} as PaymentSourceDto | ReadPaymentCardDto),
  onSelectPaymentType: () => {},
});

export const OrderProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { onPostCharge } = usePaymentContext();
  const guestFormRef = useRef<HTMLFormElement>(null);
  const guestFormBlockRef = useRef<HTMLDivElement>(null);
  const isGuestCheckoutEnabled = useGetFeatureFlag('isGuestCheckoutEnabled');
  const cart = useCartState();
  const cartDispatch = useCartDispatch();
  const webstore = useWebStore();
  const { user } = useUserContextState();
  const { onLogin } = useUserContextStateDispatch();
  const { notification, analytics } = usePlatformStoreState();
  const { giftCardPaymentSources } = useGiftCardState();
  const { onVerifyBuyer } = useSquareVerifyBuyer();
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentSourceDto>({
    type: PaymentMethodTypeEnum.CREDIT_CARD,
  });
  const { refetch: fetchCart } = useGetFullCart({
    enabled: false,
  });
  const { mutateAsync: onSyncWithPOS } = useSynchronizeWithWithPOSMutation();
  const { data: userCards } = useGetClientCards({
    staleTime: 4000,
    enabled: user.type === UserTypeEnum.AUTHENTICATED,
  });
  const [squareCard, setSquareCard] = useState<Card>();
  const [shouldSaveCard, setShouldSaveCard] = useState(true);
  const { mutateAsync: onCreateCard } = useStoreCardMutation({
    onError: (error) => {
      const providerSpecificError = getProviderSpecificErrorData(error);
      analytics.track('payment_card_add_error', { error: providerSpecificError ?? error });
      notification.error(providerSpecificError ?? error);
    },
  });
  const { mutateAsync: onCreateGuestUser } = useRegisterGuestUserMutation();
  const { mutateAsync: onUpdateGuestUser } = useEditUserMutation(user?.userId);

  const handleChangeSaveCard = () => {
    setShouldSaveCard((prev) => !prev);
  };

  const handleTokenize = useCallback(async () => {
    try {
      if (squareCard) {
        const tokenResult = await squareCard.tokenize();
        if (tokenResult.status === 'OK') {
          analytics.track('payment_card_tokenize');
          return tokenResult;
        }
      }
    } catch (e) {
      analytics.track('payment_card_tokenize_error', { error: e });
    }
  }, [analytics, squareCard]);

  const handleSetCard = useCallback((card?: Card) => {
    setSquareCard(card);
  }, []);

  useEffect(() => {
    if (selectedPaymentType?.type !== PaymentMethodTypeEnum.CREDIT_CARD && userCards && userCards.length === 0) {
      setSquareCard(undefined);
    }
  }, [selectedPaymentType, userCards]);

  const handleFocusClass = useCallback(async (currentState: SqEvent<CardInputEvent>) => {
    if (currentState.detail.currentState.isCompletelyValid) {
      handleSelectPaymentType({
        type: PaymentMethodTypeEnum.CREDIT_CARD,
        last4Digits: '1', // add some digits to unlock the save card button
      });
    } else {
      handleSelectPaymentType({
        type: PaymentMethodTypeEnum.CREDIT_CARD,
      });
    }
  }, []);

  useEffect(() => {
    if (squareCard && (userCards?.length === 0 || !userCards)) {
      squareCard?.addEventListener('errorClassRemoved', handleFocusClass);
      squareCard?.addEventListener('focusClassRemoved', handleFocusClass);
    }
    return () => {
      squareCard?.removeEventListener('errorClassRemoved', handleFocusClass);
      squareCard?.removeEventListener('focusClassRemoved', handleFocusClass);
    };
  }, [userCards, squareCard, handleFocusClass]);

  const handleSaveCardToUser = useCallback(
    async (user?: UserContextWithAuthDto['user']) => {
      try {
        const tokenResult = await handleTokenize();
        if (tokenResult?.token) {
          const readPaymentSourceDto: PaymentSourceDto = {
            type: PaymentMethodTypeEnum.CREDIT_CARD,
            brand: tokenResult.details?.card?.brand,
            last4Digits: tokenResult.details?.card?.last4,
            cardNonce: tokenResult.token,
          };
          if (user?.type === UserTypeEnum.AUTHENTICATED && shouldSaveCard && cart) {
            const verificationToken = await onVerifyBuyer(tokenResult.token, 'STORE', cart);
            const createPaymentCardDto: CreatePaymentCardDto = {
              last4Digits: tokenResult.details?.card?.last4 ?? '',
              metadata: {
                cardNonce: tokenResult.token ?? '',
                cardBrand: (tokenResult.details?.card?.brand as unknown as SquareCardBrandEnum) ?? SquareCardBrandEnum.VISA,
                expMonth: tokenResult.details?.card?.expMonth ?? 0,
                expYear: tokenResult.details?.card?.expYear ?? 0,
                postalCode: tokenResult.details?.billing?.postalCode,
                provider: PaymentProviderEnum.SQUARE,
                verificationToken,
              },
            };
            if (!userCards?.find((card) => card.last4Digits === createPaymentCardDto.last4Digits)) {
              const createdCard = await onCreateCard(createPaymentCardDto);
              analytics.track('payment_card_add_success');
              return createdCard;
            }
          }
          return readPaymentSourceDto;
        }
      } catch (e) {
        analytics.track('payment_card_add_failed', { error: e });
      }
    },
    [analytics, handleTokenize, onCreateCard, onVerifyBuyer, cart, shouldSaveCard, userCards],
  );

  const handleSelectPaymentType = (paymentType?: PaymentSourceDto) => {
    if (!paymentType) {
      setSelectedPaymentType({ type: PaymentMethodTypeEnum.CREDIT_CARD });
      return;
    }
    setSelectedPaymentType(paymentType);
  };

  const handleCreateGuestUser = useCallback(async () => {
    try {
      const userFields: UserFields = guestFormRef.current?.values as UserFields;
      const userProfile: CreateUserProfileDto = {
        email: userFields.email,
        first_name: userFields.firstName,
        last_name: userFields.lastName,
        phone_number: userFields.formattedPhoneNumber ?? '',
      };
      const guestUserContext = await onCreateGuestUser(userProfile);
      onLogin(guestUserContext);
      return guestUserContext;
    } catch (error: any) {
      if (error) {
        const providerSpecificError = getSpecificErrorForGuestForm(error);
        analytics.track('guest_checkout_form_fail', { error, providerSpecificError });
        notification.error(providerSpecificError);
      }
      throw undefined;
    }
  }, [onCreateGuestUser]);

  const handleUpdateGuestUser = useCallback(async () => {
    try {
      const userFields: UserFields = guestFormRef.current?.values as UserFields;
      const userProfile: Partial<ReadUserDto> = {
        profile: {
          email: userFields.email,
          first_name: userFields.firstName,
          last_name: userFields.lastName,
          phone_number: userFields.formattedPhoneNumber ?? userFields.phoneNumber ?? '',
        },
      };
      const guestUserContext = await onUpdateGuestUser(userProfile);
      onLogin({ user: guestUserContext as ReadUserDto });
      return guestUserContext;
    } catch (error: any) {
      if (error) {
        const providerSpecificError = getSpecificErrorForGuestForm(error);
        analytics.track('guest_checkout_form_fail', { error, providerSpecificError });
        notification.error(providerSpecificError);
      }
      throw undefined;
    }
  }, [onCreateGuestUser]);

  const handleGetUserContext = useCallback(
    async (user?: UserContextWithAuthDto['user']) => {
      const context =
        isGuestCheckoutEnabled && user?.type === UserTypeEnum.GUEST
          ? await createNewTempContext(webstore.merchantId, webstore.storeId)
          : await getCurrentContext(webstore.merchantId, webstore.storeId);
      if (isGuestCheckoutEnabled && user?.type === UserTypeEnum.GUEST) {
        onLogin(context as UserLoggedInContextDto);
      }
      const { data } = await fetchCart();
      cartDispatch({ type: 'initiate', payload: data });
    },
    [cartDispatch, isGuestCheckoutEnabled, onLogin, webstore.merchantId, webstore.storeId],
  );

  const chargeOrder = useCallback(
    async (paymentSourceDto?: PaymentSourceDto, user?: UserContextWithAuthDto['user']) => {
      try {
        let localPaymentType: PaymentSourceDto = { ...selectedPaymentType };
        // if there are no ccs on the user then the form is present directly on the page, so we need to tokenize and save the card if needed
        if (!userCards?.length && selectedPaymentType.last4Digits === '1' && selectedPaymentType?.type === PaymentMethodTypeEnum.CREDIT_CARD) {
          const card = await handleSaveCardToUser(user);
          if (!card) return;
          localPaymentType =
            card && Object.keys(card).includes('lastUsed')
              ? {
                  type: PaymentMethodTypeEnum.CREDIT_CARD,
                  brand: ((card as ReadPaymentCardDto).metadata as IReadSquareCardMetadataDto).cardBrand,
                  last4Digits: card.last4Digits,
                  cardNonce: ((card as ReadPaymentCardDto).metadata as IReadSquareCardMetadataDto).cardId,
                }
              : ({ ...card } as PaymentSourceDto);
        }
        await onSyncWithPOS(cart?._id);
        analytics.track('charge_order_init', { giftCardPaymentSources, paymentSourceDto, localPaymentType });
        const paymentSource = !paymentSourceDto ? localPaymentType : paymentSourceDto;
        const isCashPayment = paymentSource?.type === PaymentMethodTypeEnum.CASH;
        if (paymentSource.type === PaymentMethodTypeEnum.CREDIT_CARD && !paymentSource.verificationToken && cart) {
          paymentSource.verificationToken = await onVerifyBuyer(paymentSource.id ?? paymentSource.cardNonce ?? '', 'CHARGE', cart);
        }
        const paymentSources = isCashPayment ? [paymentSource] : [...giftCardPaymentSources, paymentSource];
        analytics.track('charge_order_start', {
          paymentSources,
          isCashPayment,
          giftCardPaymentSources,
          paymentSource,
          verificationToken: paymentSource.verificationToken,
          source: getDeviceSource() as unknown as PaymentSourceEnum,
        });
        const data = await paymentOrderApi.charge(cart?._id, paymentSources, { source: getDeviceSource() as unknown as PaymentSourceEnum });
        await handleGetUserContext(user);
        sessionStorage.setItem('last_order', JSON.stringify(data));
        analytics.track('charge_order_success');

        const { total: value, currency, promotions } = cart ?? {};
        const { first_name, last_name, email } = user?.profile || {};

        datadogRum.addAction('transaction_succeed');

        analytics.track('transaction_succeed', {
          cart,
          orderNumber: cart?.orderNumber,
          value,
          currency,
          promotions,
          userId: user?.userId,
          first_name,
          last_name,
          email,
        });
        onPostCharge();
      } catch (error: any) {
        if (error) {
          const providerSpecificError = getProviderSpecificErrorData(error);
          analytics.track('charge_order_fail', { error, providerSpecificError });
          notification.error(providerSpecificError ?? error, 'chargeOrder');
        }
        throw undefined;
      }
    },
    [
      selectedPaymentType,
      userCards?.length,
      onSyncWithPOS,
      cart,
      analytics,
      giftCardPaymentSources,
      handleGetUserContext,
      user?.profile,
      user?.userId,
      onPostCharge,
      handleSaveCardToUser,
      onVerifyBuyer,
      notification,
    ],
  );

  const handlePreCharge = useCallback(
    async (paymentSourceDto?: PaymentSourceDto) => {
      try {
        if (isGuestCheckoutEnabled && user.type === UserTypeEnum.TEMPORARY && guestFormRef.current && !guestFormRef.current?.isValid) return;
        if (isGuestCheckoutEnabled && user.type === UserTypeEnum.GUEST && guestFormRef.current && guestFormRef.current?.isValid) {
          const userContext = (await handleUpdateGuestUser()) as ReadUserDto;
          return chargeOrder(paymentSourceDto, userContext);
        }
        if (isGuestCheckoutEnabled && user.type === UserTypeEnum.TEMPORARY) {
          const userContext = await handleCreateGuestUser();
          return chargeOrder(paymentSourceDto, userContext?.user);
        }
        return chargeOrder(paymentSourceDto, user);
      } catch (e) {
        throw undefined;
      }
    },
    [chargeOrder, user, handleCreateGuestUser, isGuestCheckoutEnabled, user.type],
  );
  if (!cart) {
    return null;
  }

  return (
    <OrderContext.Provider value={{ guestFormRef, guestFormBlockRef, shouldSaveCard, squareCard, selectedPaymentType }}>
      <OrderDispatch.Provider
        value={{
          chargeOrder: handlePreCharge,
          onSelectPaymentType: handleSelectPaymentType,
          onShouldSaveCardToggle: handleChangeSaveCard,
          onSetSquareCard: handleSetCard,
          onSaveCardToUser: handleSaveCardToUser,
        }}
      >
        {children}
      </OrderDispatch.Provider>
    </OrderContext.Provider>
  );
};

export const useOrder = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error('useOrder must be used within a OrderProvider');
  }
  return context;
};

export const useOrderDispatch = () => {
  const context = useContext(OrderDispatch);
  if (context === undefined) {
    throw new Error('useOrderDispatch must be used within a OrderProvider');
  }
  return context;
};
