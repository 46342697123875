import { ReactNativePostMessagesEnum } from '@webstore-monorepo/shared/enums';
import { useEffect } from 'react';

interface WebstoreEventsHookParams {
  onPayCardNonceSuccess?: (cardNonce: string) => void;
}

export function useReactNativeEvents({ onPayCardNonceSuccess }: WebstoreEventsHookParams) {
  useEffect(() => {
    const action = (result: MessageEvent) => {
      if (
        [ReactNativePostMessagesEnum.REQUEST_APPLE_PAY_CARD_NONCE_SUCCESS, ReactNativePostMessagesEnum.REQUEST_GOOGLE_PAY_CARD_NONCE_SUCCESS].includes(
          result.data.type,
        )
      ) {
        const cardNonce = result.data.payload?.nonce;
        if (cardNonce && onPayCardNonceSuccess) {
          onPayCardNonceSuccess(cardNonce);
        }
      }
    };

    window.addEventListener('message', action, false);

    return () => {
      window.removeEventListener('message', action);
    };
  }, [onPayCardNonceSuccess]);
}
