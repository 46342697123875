import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useHover } from '@webstore-monorepo/shared/hooks/use-hover';
import { Box } from '@webstore-monorepo/ui/box';
import { Text } from '@webstore-monorepo/ui/text';
import React, { useRef } from 'react';
import { Image, Pressable } from 'react-native';

interface NavBarLink {
  name?: string;
  url?: string;
  iconUrl?: string;
  isSelected?: boolean;
  onClick: () => void;
  height?: number;
  width?: number;
}

export const TopNavBarItem: React.FC<NavBarLink> = ({ name, url, iconUrl, isSelected, onClick, height, width }) => {
  const { header } = useComponentsConfig();
  const ref = useRef();
  const { isHovered } = useHover({}, ref);
  const { shadowColor, shadowOffset, shadowOpacity, ...activeItemStyles } = header?.topNavBar?.activeItem?.style ?? {};
  const { height: itemHeight } = header?.topNavBar?.style ?? {};

  const handleClick = () => {
    onClick();
  };

  return (
    <Box
      ref={ref}
      borderWidth={0}
      height={itemHeight}
      justifyContent="center"
      alignItems="center"
      paddingLeft={4}
      paddingRight={4}
      flexShrink={1}
      flexDirection="column"
      {...(isSelected ? { ...activeItemStyles } : {})}
    >
      <Pressable
        focusable={!!url}
        accessibilityLabel={name}
        accessibilityHint={`Press on the ${name} to open the link`}
        accessibilityRole="link"
        onPress={handleClick}
        style={{
          flexGrow: 1,
          flexBasis: 'auto',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {isSelected ? (
          <Box
            flexDirection="row"
            width="100%"
            height={shadowOffset?.height ?? 3}
            opacity={shadowOpacity ?? 1}
            position="absolute"
            top={0}
            backgroundColor={shadowColor}
          />
        ) : null}
        {name ? (
          <Text
            textAlign="center"
            textTransform="capitalize"
            letterSpacing={1}
            fontSize="m"
            {...header?.topNavBar?.item?.style}
            {...(isSelected ? header?.topNavBar?.activeItemText?.style : {})}
            {...(isHovered ? header?.topNavBar?.itemHover?.style : {})}
          >
            {name}
          </Text>
        ) : null}
        {iconUrl ? (
          <Box marginLeft={1}>
            <Image
              accessibilityLabel={name}
              accessibilityHint="top navbar item"
              accessibilityIgnoresInvertColors
              accessibilityRole="none"
              source={{ uri: iconUrl }}
              resizeMode="contain"
              style={{ width: width ?? 19, height: height ?? 24 }}
            />
          </Box>
        ) : null}
      </Pressable>
    </Box>
  );
};

export type TopNavBarProps = {
  onItemClick: (url: string, openInNewTab?: boolean) => void;
};

export const TopNavBar: React.FC<TopNavBarProps> = ({ onItemClick }) => {
  const { header } = useComponentsConfig();
  const { isShown, links } = header?.topNavBar?.options ?? {};
  const { height, ...topNavBarStyle } = header?.topNavBar?.style ?? {};

  const handleItemClick = (url?: string, openInNewTab?: boolean) => () => {
    if (url) {
      onItemClick(url, openInNewTab);
    }
  };

  return isShown && links && links?.length > 0 ? (
    <Box
      testID="top-nav-bar"
      paddingLeft={3}
      paddingRight={3}
      width="100%"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      minHeight={height ? height : 40}
      {...topNavBarStyle}
    >
      {links.map((item, index) => (
        <TopNavBarItem key={index} onClick={handleItemClick(item.url, item.openInNewTab)} {...item} />
      ))}
    </Box>
  ) : null;
};
