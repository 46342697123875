import { useComponentsConfig } from '@webstore-monorepo/shared/contexts/components-config-provider';
import { useWindowDimensions } from '@webstore-monorepo/shared/hooks/use-window-dimensions';
import { UiSidebarHeader } from '@webstore-monorepo/ui/sidebar-header';
import type { FC } from 'react';
import React from 'react';

export type HeaderProps = {
  title: string;
  onClose: () => void;
};
export const Header: FC<HeaderProps> = ({ title, onClose }) => {
  const { cartScreen } = useComponentsConfig();
  const { isMobile } = useWindowDimensions();
  const options = isMobile ? cartScreen?.wrapperMobile : cartScreen?.wrapper;

  return (
    <UiSidebarHeader
      onClose={onClose}
      iconColor={options?.header?.closeButton?.style?.color}
      showIcon={options?.header?.closeButton?.options?.show}
      wrapperStyle={options?.header?.style}
      closeButtonStyles={options?.header?.closeButton?.style}
      titleStyles={options?.header?.title?.style}
    >
      {title}
    </UiSidebarHeader>
  );
};
